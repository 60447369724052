import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isDefined, SampleModels } from 'src/app/data.models';
import { RootStore, SampleProjectActions } from 'src/app/store/actions';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { LoggerService } from '../logger.service';
import { SampleService } from './sample.service';
import { UtilService } from '../util.service';
import { SampleProjectBase } from '../../sample.models';


@Injectable({
  providedIn: 'root'
})
export class SampleProjectService extends SampleService<SampleModels.SampleProjectBase> {

  public currentID: string = "";

  private get placeholderStats(): SampleModels.SampleStatsBase {
    return {
      "total": 25000,
      "available": 24500,
      "selected": 24000,
      "invited": 20000,
      "started": 5000,
      "finished": 4000,
      "qualified": 1000,
      "invalidated": 20,
      "invalidatedQualified": 15,
      "invalidatedDisqualified": 5,
      "feasible": 10000.5
    };
  }
  private current$: BehaviorSubject<SampleModels.SampleProjectBase> = new BehaviorSubject(null);
  private current: SampleModels.SampleProjectBase;

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private store: Store<RootStore>,
    private util: UtilService,
  ) {
    super('sampleProject');
  }

  private sanitize(sp: SampleProjectBase) {
    delete sp.createdAt;
    delete sp.updatedAt;
    if (isDefined(sp.xid) && !sp.xid) {
      delete sp.xid;
    }
    return sp;
  }

  /**
   * @method SampleProjectService.create
   * @description Saves a new SampleModels.SampleProject to the server
   * @param {SampleModels.SampleProjectBase} sampleProject The new SampleModels.SampleProject to be saved
   * @returns Observable<SampleModels.SampleProject | null>
   */
  public create(sampleProject: SampleModels.SampleProjectBase): Observable<SampleModels.SampleProject> {
    return this.api.post<SampleModels.SampleProjectBase>(this.endpoint, this.sanitize(sampleProject), true, environment.samplingServicesUrl)
      .pipe(map((resp: SampleModels.SampleProjectBase) => {
        this.logger.log(resp);
        if (resp) {
          const sp = new SampleModels.SampleProject(resp);
          this.store.dispatch(new SampleProjectActions.CreateSuccess(sp));
          return sp;
        }
        return null;
      }), catchError((err: any) => {
        this.store.dispatch(new SampleProjectActions.CreateError(err));
        return throwError(err);
      }));
  }

  /**
   * @method SampleProjectService.clone
   * @description Retrieves an up to date version (fresh from the server) of the
                  SampleModels.SampleProject with the id supplied and then returns a clone of it
   * @param {string} id The ID of the SampleModels.SampleProject to be cloned
   * @returns Observable<SampleModels.SampleProject>
   */
  public clone(id: string): Observable<SampleModels.SampleProject> {
    return this.getByID(id).pipe(map((sampleProject: SampleModels.SampleProjectBase) => {
      if (sampleProject) {
        let project = new SampleModels.SampleProject(this.util.copyObj(sampleProject));
        delete project.id;
        project.name = "Copy of " + sampleProject.name;
        return project;
      }
      return null;
    }));
  }

  /**
   * @method SampleProjectService.getArchived
   * @description Gets all and returns the subject which contains a filtered list of all containing only archived items
   * @returns Observable<SampleModels.SampleProject[]>
   */
  public getArchived(): Observable<SampleModels.SampleProject[]> {
    return this.getAll({ filter: 'archived' });
  }

  /**
   * @method SampleProjectService.getAll
   * @description Retrieves a list of all SampleProjects in AWS
   * @returns Observable<SampleModels.SampleProject[]>
   */
  public getAll(filter: any = {}): Observable<SampleModels.SampleProject[]> {
    return this.api.get(this.endpoint, filter, environment.samplingServicesUrl)
      .pipe(
        map((resp: SampleModels.SamplingAPIResponse<SampleModels.SampleProjectBase>) => {
          const projects = resp.results.map(sp => new SampleModels.SampleProject(sp));
          this.store.dispatch(new SampleProjectActions.GetAllSuccess(projects));
          return projects;
        }),
        catchError((err: Error) => {
          this.store.dispatch(new SampleProjectActions.GetAllError(err))
          return of(null as any)
        })
      );
  }

  /**
   * @method SampleProjectService.getByID
   * @description Retrieves a SampleModels.SampleProject for a given ID
   * @param {string} id The ID of the SampleModels.SampleProject to be retrieved
   * @returns Observable<SampleModels.SampleProject>
   */
  public getByID(id: string = this.currentID, error?: Function, context?: any): Observable<SampleModels.SampleProject> {
    this.currentID = id || this.currentID;
    return this.api.get(this.endpoint + '?id=' + this.currentID, null, environment.samplingServicesUrl).pipe(map((sp: any) => {
      if (sp && sp.id === this.currentID) {
        const project = new SampleModels.SampleProject(sp);
        this.store.dispatch(new SampleProjectActions.GetSuccess(project));
        return project;
      }
      return null;
    }));
  }

  /**
   * @method SampleProjectService.update
   * @description Updates a SampleModels.SampleProject in AWS
   * @returns Observable<SampleModels.SampleProject>
   */
  public update(sampleProject: SampleModels.SampleProjectBase): Observable<SampleModels.SampleProject> {
    return this.api.put(this.endpoint, this.sanitize(sampleProject), true, environment.samplingServicesUrl)
      .pipe(map((resp: SampleModels.SampleProjectBase) => {
        if (resp) {
          const sp = new SampleModels.SampleProject(resp);
          this.store.dispatch(new SampleProjectActions.UpdateSuccess(sp));
          return sp;
        }
        return null;
      }));
  }

  /**
   * @method SampleProjectService.archive
   * @description Updates the `archive` property of the given SampleModels.SampleProject
   * @param {SampleModels.SampleProjectBase} sampleProject The SampleModels.SampleProject to be archived
   * @param {boolean} archive The value to be assigned to `archive` property of the SampleModels.SampleProject. By default, it simply inverts the current value
   * @returns Observable<SampleModels.SampleProject>
   */
  public archive(sampleProjectID: string, archive: boolean): Observable<SampleModels.SampleProject>;
  public archive(sampleProject: SampleModels.SampleProjectBase, archive?: boolean): Observable<SampleModels.SampleProject>;
  public archive(...args: any[]): Observable<SampleModels.SampleProjectBase>{
    const archive = typeof args[1] === "undefined" ? !args[0].archived : args[1];
    const id = typeof args[0] === "string" ? args[0] : args[0].id;
    return this.update({ id: id, archived: archive });
  }
}
