import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggerService, DialogService } from 'src/app/services';
import { AlertMessage } from 'src/app/data.models';

const DATA_KEY: string = "alert-data";

@Component({
  selector: 'alert-dialog',
  templateUrl: './alert.dialog.html'
})
export class AlertDialog implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  loading: boolean;

  data: AlertMessage;

  constructor(
    private logger: LoggerService,
    private dialogService: DialogService,
  ) { }

  close() {
    return this.dialogService.close();
  }

  confirm(data: any = true) {
    return this.dialogService.close('dialog', data);
  }

  ngOnInit() {
    if (this.dialogService.current.data) {
      this.data = this.dialogService.current.data as AlertMessage;
    }
    else {
      setTimeout(() => this.dialogService.close(), 10);
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    this.data = null;
  }
}
