import * as BaseModels from '../../base.models';
import { DataItem, ComplexFilterValue } from '../../data.models';
import { PanelAdminSurveyBase } from 'src/app/components/product-tests/product-tests.models';
import { CampaignBase } from '../recruit/recruit.models';
export { CampaignBase }

export interface EmailAccount {
  id?: number;
  address?: string;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
  bounces?: number;
  opted_out?: boolean;
  primary?: boolean;
  dropped?: boolean;
  drop_reason?: any;
  confirmed?: boolean;
  burner?: boolean;
}

export interface Phone {
  id?: number;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
  primary?: boolean;
  carrier_name?: string;
  carrier_type?: string;
  mobile?: boolean;
  number?: string;
  recruitment_source?: boolean;
  sms_opt_in?: boolean;
  verification_code?: number;
  verified?: boolean;
  verify_attempts?: number;
}

export interface Source {
  id?: number;
  name?: string;
  created_at?: string;
  updated_at?: string;
  total_spend?: number;
  archived?: boolean;
}

export interface ClientProfile {
  id?: number;
  ip_address?: string;
  user_agent?: string;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
  ip_country?: string;
  proxy_score?: number;
  minfraud_ip_risk_score?: number;
}

export interface Flag {
  id?: number;
  name?: string;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
  fatal?: boolean;
  value?: any;
  related_user_id?: number;
}

export interface AccessToken {
  id?: number;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
  used?: boolean;
  value?: string;
}

export interface MemberStats {
  selected?: number;
  invited?: number;
  started?: number;
  finished?: number;
  qualified?: number;
  invalidated?: number;
  invalidatedQualified?: number;
  invalidatedDisqualified?: number;
}

export interface InvitationPagerFilters {
  started?: boolean;
  finished?: boolean;
  qualified?: boolean;
  user_id?: string;
  hash_value?: string;
}

export interface MiscPayoutPagerFilters {
  user_id?: string;
}

export interface CommunityRewardPagerFilters {
  rewardable_id?: string | number;
  rewardable_type?: "User";
}

export enum InvitationStatus {
  Disqualified = "T",
  OverQuota = "Q",
  Qualified = "C",
  TerminatedInvalidated = "V",
  Removed = "R",
  Reverse = "Rv",

  T = "Disqualified",
  Q = "OverQuota",
  C = "Qualified",
  V = "TerminatedInvalidated",
  R = "Removed",
  Rv = "Reverse"
}

export enum PartnerSurveyStatus {
  Qualified = "C",
  Unqualified = "U",
  Reverse = "R",

  C = "Qualified",
  U = "Unqualified",
  R = "Reverse",
}

export interface MiscPayout {
  created_at?: string;
  description?: string;
  id: number;
  survey?: PanelAdminSurveyBase;
  survey_id: number;
  user_id: number;
  reward: CommunityReward;
  reward_value?: number;
  updated_at?: string;
}

export interface Invitation {
  survey_id: number;
  reward: CommunityReward;
  id: number;
  blocked?: boolean;
  created_at?: string;
  delivered?: boolean;
  finished?: boolean;
  finished_at?: string;
  name?: string;
  open?: boolean;
  qualified?: boolean;
  started?: boolean;
  started_at?: string;
  survey?: PanelAdminSurveyBase;
  updated_at?: string;
  reward_value?: number;
  hash_value?: string;
}

export enum CommunityRewardType {
  Amazon = 'Amazon',
  Bitcoin = 'Bitcoin',
  PayPal = 'PayPal',
  Rewardlink = 'Rewardlink',
  Habitat = 'Habitat',
  Tango = 'Tango',
  Walmart = 'Walmart',
  Starbucks = 'Starbucks',
  GameStop = 'GameStop'
}

export const rewardTypeMap: { [key: string]: DataItem } = {
  [CommunityRewardType.Amazon]: {
    value: CommunityRewardType.Amazon,
    viewValue: "Amazon"
  },
  [CommunityRewardType.Bitcoin]: {
    value: CommunityRewardType.Bitcoin,
    viewValue: "Bitcoin"
  },
  [CommunityRewardType.PayPal]: {
    value: CommunityRewardType.PayPal,
    viewValue: "PayPal"
  },
  [CommunityRewardType.Habitat]: {
    value: CommunityRewardType.Habitat,
    viewValue: "Habitat"
  },
  [CommunityRewardType.Tango]: {
    value: CommunityRewardType.Tango,
    viewValue: "Tango"
  },
  [CommunityRewardType.Walmart]: {
    value: CommunityRewardType.Walmart,
    viewValue: "Walmart"
  },
  [CommunityRewardType.Starbucks]: {
    value: CommunityRewardType.Starbucks,
    viewValue: "Starbucks"
  },
  [CommunityRewardType.GameStop]: {
    value: CommunityRewardType.GameStop,
    viewValue: "GameStop"
  },
};

export const rewardTypes: DataItem[] = Object.values(rewardTypeMap);

export interface CommunityReward {
  id?: number;
  rewardable_id?: number;
  type?: CommunityRewardType;
  value?: string;
  delivered?: boolean;
  returned?: boolean;
  created_at?: string;
  updated_at?: string;
  recipient_email?: string;
  pay_key?: string;
  fees?: string;
  error_message?: string;
  transaction_id?: string;
  rewardable_type?: string;
  delivery_attempts?: number;
  user_paid_fees?: string;
  reimbursement?: boolean;
}

export interface BankRedemption {
  type?: CommunityRewardType
  recipient_email?: string;
}

export interface PollfishSurveyPagerFilters {
  txid?: string;
  status?: string;
  user_id?: string;
}

export interface PartnerSurveyPagerFilters {
  finished?: boolean;
  qualified?: boolean;
  reversed?: boolean;
  user_id?: string;
  survey_platform?: string | ComplexFilterValue<string>;
  code_name?: string;
  case_id?: string;
  sess_id?: string;
}

export interface PollfishSurveyParams {
  device_id?: string;
  cpa?: string;
  request_uuid?: string;
  timestamp?: string;
  tx_id?: string;
  signature?: string;
  status?: string;
  reason?: string;
  reward_name?: string;
  reward_value?: string;
  click_id?: string;
  controller?: string;
  action?: string;
}

export interface PollfishSurveyAttempt {
  id?: number;
  user_id?: number;
  tx_id?: string;
  finished_at?: string;
  status?: string;
  reason?: string;
  cpa?: string;
  reward_value?: string;
  params?: PollfishSurveyParams;
  user?: CommunityMemberBase,
  reward?: CommunityReward;
}

export interface InbrainSurveyAttempt {
  id?: number;
  user_id?: number;
  reward_id?: string;
  finished_at?: string;
  reward_type?: string;
  reason?: string;
  revenue_amount?: string;
  reward_value?: string;
  params?: PollfishSurveyParams;
  user?: CommunityMemberBase,
  reward?: CommunityReward;
}

export interface PartnerSurveyAttempt {
  id?: number;
  user_id?: number;
  started_at?: string;
  finished_at?: string;
  finished?: boolean;
  qualified?: boolean;
  reversed?: boolean;
  revenue_amount?: number;
  revenue_value?: number;
  reward_value?: number;
  platform_fee?: number;
  reward?: CommunityReward;
}

export interface MemberLoyaltyBonus {
  id?: number;
  user_id?: number;
  surveys?: PanelAdminSurveyBase[];
  credits?: string;
  reward_value?: string;
  user?: CommunityMemberBase,
  reward?: CommunityReward;
}

export interface MemberProfileSnapshot {
  country?: string;
  yob?: string;
  gender?: string;
  zip?: string;
  age?: string;
  age_group?: string;
  neighborhood?: string;
  ethnicity?: string;
  industry_personal?: string;
  income?: string;
}

export interface MemberProfile {
  answer_errors?: any[];
  answers?: any;
  demos?: any;
  geos?: any;
  surveys?: any;
  survey_counts?: any;
  recent_survey_counts?: any;
  trailing_survey_counts?: any;
  p_finish?: number;
  user_id?: number;
  pid?: string;
  campaign_id?: number;
  source_id?: number;
  usable?: boolean;
  activated?: boolean;
  active?: boolean;
  validated_status?: boolean;
  created_at?: string;
  updated_at?: string;
  snapshot?: MemberProfileSnapshot;
}

export interface CommunityMemberBase extends BaseModels.APIBase {
  activated?: boolean;
  active?: boolean;
  address?: any;
  agreed?: boolean;
  agreed_version?: string;
  auth_token?: string;
  banked_rewards?: string;
  campaign?: CampaignBase;
  campaign_id?: number;
  client_profiles?: ClientProfile[];
  confirmed?: boolean;
  created_at?: string;
  default_reward_email?: string;
  email_accounts?: EmailAccount[];
  fbclid?: string;
  flags?: Flag[];
  hold?: boolean;
  in_the_usa?: boolean;
  inbrain_approved?: boolean;
  member_access_tokens?: AccessToken[];
  minfraud_email_insights?: string;
  minfraud_insights?: string;
  minfraud_ip_risk_score?: number;
  minfraud_risk_score?: number;
  name?: string;
  notes?: string;
  notes_last_saved_at?: string;
  offer_code?: string;
  opted_in?: boolean;
  opted_out?: boolean;
  optimizer_opted_in?: boolean;
  optimizer_setting?: number;
  partner_survey_approved?: boolean;
  partner_survey_max?: string;
  password_hash?: string;
  phones?: Phone[];
  pid?: string;
  pollfish_approved?: boolean;
  primary_email_account?: EmailAccount;
  primary_phone?: Phone;
  product_test_approved?: boolean;
  provider?: any;
  provider_uid?: any;
  removed?: boolean;
  removed_at?: string;
  source?: Source;
  temp_parent?: any;
  test_user?: {
    id?: number;
    created_at?: string;
    updated_at?: string;
    user_id?: number;
  };
  tid?: string;
  twitter_handle?: string;
  twitter_name?: string;
  updated_at?: string;
  usable?: boolean;
  validated?: boolean;
}

export class CommunityMember extends BaseModels.ApiDefaults implements CommunityMemberBase {
  auth_token: string;
  agreed: boolean;
  activated: boolean;
  agreed_version: string;
  active: boolean;
  address: any;
  banked_rewards: string;
  campaign: CampaignBase;
  campaign_id: number;
  confirmed: boolean;
  created_at: string;
  default_reward_email: string;
  client_profiles: ClientProfile[];
  email_accounts: EmailAccount[];
  fbclid: string;
  flags: Flag[];
  hold: boolean;
  in_the_usa: boolean;
  inbrain_approved: boolean;
  member_access_tokens: AccessToken[];
  minfraud_email_insights: string;
  minfraud_insights: string;
  minfraud_ip_risk_score: number;
  minfraud_risk_score: number;
  name: string;
  notes: string;
  notes_last_saved_at: string;
  offer_code: string;
  opted_in: boolean;
  opted_out: boolean;
  optimizer_opted_in: boolean;
  optimizer_setting: number;
  partner_survey_approved: boolean;
  partner_survey_max: string;
  password_hash: string;
  phones: Phone[];
  pid: string;
  pollfish_approved: boolean;
  product_test_approved: boolean;
  provider: any;
  provider_uid: any;
  primary_email_account: EmailAccount;
  primary_phone: Phone;
  recaptcha_score: string|number;
  removed: boolean;
  removed_at: string;
  source: Source;
  temp_parent: any;
  tid: string;
  twitter_handle: string;
  twitter_name: string;
  updated_at: string;
  usable: boolean;
  validated: boolean;
  test_user: {
    id: number;
    created_at: string;
    updated_at: string;
    user_id: number;
  };

  constructor(data?: CommunityMemberBase) {
    super([
      'activated',
      'active',
      'address',
      'agreed',
      'agreed_version',
      'auth_token',
      'banked_rewards',
      'campaign',
      'campaign_id',
      'client_profiles',
      'confirmed',
      'created_at',
      'default_reward_email',
      'email_accounts',
      'fbclid',
      'flags',
      'hold',
      'in_the_usa',
      'inbrain_approved',
      'member_access_tokens',
      'minfraud_email_insights',
      'minfraud_insights',
      'minfraud_ip_risk_score',
      'minfraud_risk_score',
      'name',
      'notes',
      'notes_last_saved_at',
      'opted_in',
      'opted_out',
      'offer_code',
      'optimizer_opted_in',
      'optimizer_setting',
      'partner_survey_approved',
      'partner_survey_max',
      'password_hash',
      'phones',
      'pid',
      'pollfish_approved',
      'primary_email_account',
      'primary_phone',
      'product_test_approved',
      'provider',
      'provider_uid',
      'recaptcha_score',
      'removed',
      'removed_at',
      'source',
      'temp_parent',
      'tid',
      'twitter_handle',
      'twitter_name',
      'updated_at',
      'usable',
      'validated',
    ], data);
  }

  update(data: CommunityMemberBase) {
    return this["__patch__"](data);
  }

  get email_address(): string {
    return (this.primary_email_account && this.primary_email_account.address) || (this.email_accounts && this.email_accounts[0] && this.email_accounts[0].address) || "";
  }
}
export interface CommunityMemberPagerFilters {
  campaign_id?: string;
  campaigns?: { source_id?: string; };
  removed?: boolean;
  hold?: boolean;
  in_the_usa?: boolean;
}

export interface BlacklistedEmail {
  id?: number;
  email_address?: string;
  created_at?: string;
  updated_at?: string;
}
