import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { COOKIE_NAMES } from '../base.models';
import { LoggerService } from './logger.service';

export interface HistoryItem {
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  private pauseUpdates: boolean;
  history: HistoryItem[] = [];


  constructor(
    private router: Router,
    private logger: LoggerService,
  ) {
    const history = window.localStorage.getItem(COOKIE_NAMES.history);
    if (history) {
      try {
        this.history = JSON.parse(history);
      } catch (e) {
        this.logger.warn("HistoryService 29: " + e.message);
        this.history = [];
      }
    }
  }

  update(url: string) {
    if (!url || this.pauseUpdates) {
      return;
    }
    const isDialogRoute = url.indexOf("(") > -1;
    if (isDialogRoute) {
      return;
    }
    if (this.history.length) {
      const last: HistoryItem = this.history[this.history.length - 1];
      if (url === last.url) {
        return;
      }
      if (this.history.length > 1) {
        const prev: HistoryItem = this.history[this.history.length - 2];
        if (url === prev.url) {
          this.remove();
          return;
        }
      }
    }
    this.add({ url });
  }

  /**
   * @method HistoryService.setBusy
   * @description Set the status of the app to (not) busy
   * @returns void
   */
  add(item: HistoryItem): HistoryItem {
    if (item) {
      this.history.push(item);
      window.localStorage.setItem(COOKIE_NAMES.history, JSON.stringify(this.history));
      return item;
    }
    return null;
  }

  remove(): HistoryItem {
    if (this.history.length) {
      const item = this.history.pop();
      window.localStorage.setItem(COOKIE_NAMES.history, JSON.stringify(this.history));
      return item;
    }
    return null;
  }

  back(fallbackURL: string = "/") {
    this.pauseUpdates = true;
    const curr = this.remove();
    const prev = this.history[this.history.length - 1];
    if (prev) {
      this.router.navigateByUrl(prev.url).then(() => {
        this.pauseUpdates = false;
      });
    }
    else {
      this.router.navigateByUrl(fallbackURL).then(() => {
        this.pauseUpdates = false;
      });
    }
  }
}
