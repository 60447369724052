<div class="dialog-inner">
  <button type="button" mat-icon-button (click)="close()" class="dialog-close">
    <mat-icon>close</mat-icon>
  </button>
  <h1 mat-dialog-title>
    <ng-content select="[dialog-title]"></ng-content>
  </h1>
  <mat-dialog-content>
    <ng-content select="[dialog-content]"></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="justify-content-center pb-2 d-flex w-100">
      <ng-content select="[dialog-actions]"></ng-content>
    </div>
    <div class="justify-content-center d-flex w-100 text-center">
      <ng-content select="[dialog-error]"></ng-content>
    </div>
  </mat-dialog-actions>
</div>
<div class="dialog-overlay" (click)="closeOnOverlayClick && close()">
</div>
<app-loader [loading]="busy" [overlay]="true"></app-loader>
