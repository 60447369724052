import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SampleModels } from 'src/app/data.models';
import * as SampleActions from 'src/app/store/sample/sample.actions';
import { RootStore } from 'src/app/store/store';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { AppStateService } from '../app-state.service';
import { LoggerService } from '../logger.service';
import { SampleProjectService } from './sample-project.service';
import { SampleService } from './sample.service';
import { UtilService } from '../util.service';




@Injectable({
  providedIn: 'root'
})
export class SampleDetailsService extends SampleService<SampleModels.Sample> {

  private get currentID() {
    return this.sampleProjectService.currentID || "";
  }

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private snackBar: MatSnackBar,
    private sampleProjectService: SampleProjectService,
    private store: Store<RootStore>,
    private utilService: UtilService,
    private appState: AppStateService,
  ) {
    super('sample');
  }

  /**
   * @method SampleDetailsService.create
   * @description Create a new Sample
   * @param {Sample} sample The new sample to be saved
   * @param {string} sampleProjectID (default: this.currentID) The ID of the SampleProject to attach the new profile to.
   * @returns Observable<Sample>
   */
  public create(sample: SampleModels.SampleBase, sampleProjectID: string = this.currentID): Observable<SampleModels.Sample> {
    if (!sample.sampleProjectId) {
      if (!sampleProjectID) {
        return throwError(new Error("Sample Project ID is required"))
      }
      sample.sampleProjectId = sampleProjectID;
    }

    return this.api.post<SampleModels.SampleBase>(this.endpoint, sample, true, environment.samplingServicesUrl)
      .pipe(map(resp => {
        if (resp) {
          const s = new SampleModels.Sample(resp);
          this.store.dispatch(new SampleActions.CreateSuccess(s));
          return s;
        }
        return null;
      }), catchError(err => {
        this.store.dispatch(new SampleActions.CreateError(err));
        return throwError(err);
      }));
  }

  /**
   * @method SampleDetailsService.cancel
   * @description Cancels a Sample
   * @param {string} sampleProjectID The ID of the Sample to cancel
   * @returns Observable<boolean>
   */
  public cancel(id: string): Observable<boolean> {
    const url = this.endpoint + '/cancel';
    return this.api.put<any>(url, { id }, true, environment.samplingServicesUrl)
      .pipe(map(() => true), catchError(() => of(false)));
  }

  /**
   * @method SampleDetailsService.getByProject
   * @description Retrieves a list of SampleDetailss belonging to a SampleProject with a given ID
   * @param {string} sampleProjectID The ID of the SampleProject for which the profiles should be retrieved
   * @returns Observable<Sample[]>
   */
  public getByProject(sampleProjectID: string = this.currentID): Observable<SampleModels.Sample[]> {
    const id = sampleProjectID || this.currentID;
    const url = this.sampleProjectService.endpoint + '/' + id + '/samples';
    return this.api.get<SampleModels.SamplingAPIResponse<SampleModels.SampleBase>>(url, {}, environment.samplingServicesUrl)
      .pipe(
        map(resp => {
          if (resp && resp.results) {
            const results = resp.results.map(p => new SampleModels.Sample(p));
            this.store.dispatch(new SampleActions.GetAllSuccess(results));
            return results;
          }
          return null;
        }),
        catchError((err: Error) => {
          this.store.dispatch(new SampleActions.GetAllError(err));
          return of(null);
        })
      );
  }

  /**
   * @method SampleDetailsService.getByID
   * @description Retrieves a specified Sample with a given ID
   * @param {string} id The ID of the Sample to be retrieved
   * @returns Observable<Sample>
   */
  public getByID(id: string): Observable<SampleModels.Sample> {
    return this.api.get<SampleModels.SampleBase>(this.endpoint + '/' + id, null, environment.samplingServicesUrl).pipe(map(resp => {
      return new SampleModels.Sample(resp);
    }));
  }

  /**
   * @method SampleDetailsService.update
   * @description Retrieves a list of SampleDetailss belonging to a SampleProject with a given ID
   * @param {string} id The ID of the SampleProject for which the profiles should be retrieved
   * @returns Observable<Sample[]>
   */
  public update(sample: SampleModels.SampleBase): Observable<SampleModels.Sample> {
    return this.api.put<SampleModels.SampleBase>(this.endpoint, sample, true, environment.samplingServicesUrl).pipe(map(resp => {
      if (resp) {
        const s = new SampleModels.Sample(resp);
        this.store.dispatch(new SampleActions.UpdateSuccess(s));
        return s;
      }
      return null;
    }), catchError(err => {
      this.store.dispatch(new SampleActions.UpdateError(sample.id, err));
      return throwError(err);
    }));
  }
}
