import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SampleModels } from 'src/app/data.models';
import * as SampleDistributionActions from 'src/app/store/sample/sample-distribution.actions';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert.service';
import { ApiService } from '../api.service';
import { AppStateService } from '../app-state.service';
import { LoggerService } from '../logger.service';
import { UtilService } from '../util.service';
import { SampleService } from './sample.service';



@Injectable({
  providedIn: 'root'
})
export class SampleDistributionService extends SampleService<SampleModels.SampleDistributionBase> {

  public currentID: string = "";
  private current: BehaviorSubject<SampleModels.SampleDistributionBase> = new BehaviorSubject(null);
  private getAllSub: Subscription;

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private store: Store<{ reward: SampleModels.ISampleStore }>,
    private alert: AlertService,
    private util: UtilService,
    private appState: AppStateService,
  ) {
    super('sampleDistribution');
  }

  unwrapError(data: any): string[] {
    const messages: string[] = [];
    if (typeof data === "string") {
      messages.push(data)
    }
    else if (Object.keys(data).length) {
      Object.values(data).forEach(v => messages.push(...this.unwrapError(v)));
    }
    return messages;
  }

  /**
   * @method SampleDistributionService.create
   * @description Saves a new SampleDistribution to the server
   * @param {SampleDistribution} sampleDistribution The new SampleDistribution to be saved
   * @returns Observable<SampleDistribution | null>
   */
  public create(sampleDistribution: SampleModels.SampleDistributionBase): Observable<SampleModels.SampleDistribution | null> {
    return this.api.post<SampleModels.SampleDistributionBase>(this.endpoint, sampleDistribution, true, environment.samplingServicesUrl, null, null, false)
      .pipe(map((resp: SampleModels.SampleDistributionBase) => {
        this.logger.log(resp);
        if (resp) {
          const sd = new SampleModels.SampleDistribution(resp);
          this.store.dispatch(new SampleDistributionActions.CreateSuccess(sd));
          return sd;
        }
        return null;
      }), catchError((err: any) => {
        const messages = [];
        if (err && err.error && err.error.errors) {
          err.error.errors.forEach(e => {
            if (e.data) {
              messages.push(...this.unwrapError(e.data));
            }
            else if (e.message) {
              messages.push(e.message);
            }
          })
        }
        else if (err.message) {
          messages.push(err.message);
        }
        const error = new Error(messages.join('<br>'));
        this.store.dispatch(new SampleDistributionActions.CreateError(error));
        return throwError(error);
      }));
  }

  /**
   * @method SampleDistributionService.clone
   * @description Retrieves an up to date version (fresh from the server) of the
                  SampleDistribution with the id supplied and then returns a clone of it
   * @param {string} id The ID of the SampleDistribution to be cloned
   * @returns Observable<SampleDistribution>
   */
  public clone(id: string): Observable<SampleModels.SampleDistribution> {
    return this.getByID(id).pipe(map((sampleDistribution: SampleModels.SampleDistribution) => {
      if (sampleDistribution) {
        let distribution = this.util.copyObj(sampleDistribution);
        delete distribution.id;
        distribution.name = "Copy of " + distribution.name;
        return distribution;
      }
      return sampleDistribution;
    }));
  }


  /**
   * @method SampleDistributionService.getForProject
   * @description Gets all and returns the subject which contains a filtered list of all with a sampleProjectID equal to the supplied id
   * @returns Observable<SampleDistribution[]>
   */
  public getByProject(sampleProjectID: string): Observable<SampleModels.SampleDistribution[]> {
    return this.api.get<SampleModels.SampleDistributionBase[]>('sampleProject/' + sampleProjectID + '/' + this.endpoint + 's', null, environment.samplingServicesUrl)
      .pipe(
        map((resp: any) => {
          // Store is implemented with effects
          const dists = resp.results.map(d => new SampleModels.SampleDistribution(d));
          this.store.dispatch(new SampleDistributionActions.GetAllSuccess(dists, sampleProjectID));
          return dists;
        })
      );
  }

  /**
   * @method SampleDistributionService.getAll
   * @description Retrieves a list of all SampleDistributions in AWS
   * @returns Observable<SampleDistribution[]>
   */
  public getAll(): Observable<SampleModels.SampleDistribution[]> {
    return this.api.get(this.endpoint, {}, environment.samplingServicesUrl)
      .pipe(map((resp: SampleModels.SamplingAPIResponse<SampleModels.SampleDistributionBase>) => {
        if (resp.results) {
          const dists = resp.results.map(d => new SampleModels.SampleDistribution(d));
          this.store.dispatch(new SampleDistributionActions.GetAllSuccess(dists));
          return dists;
        }
      }));
  }

  /**
   * @method SampleDistributionService.getByID
   * @description Retrieves a SampleDistribution for a given ID
   * @param {string} id The ID of the SampleDistribution to be retrieved
   * @returns Observable<SampleDistribution>
   */
  public getByID(id: string): Observable<SampleModels.SampleDistribution> {
    return this.api.get(this.endpoint + '/' + id, null, environment.samplingServicesUrl)
      .pipe(
        catchError((err: Error) => of(new SampleDistributionActions.GetError(id, err))),
        map((resp: SampleModels.SampleDistributionBase) => {
          if (resp) {
            const distribution = new SampleModels.SampleDistribution(resp);
            this.store.dispatch(new SampleDistributionActions.GetSuccess(distribution));
            return distribution;
          }
          return null;
        }));
  }

  /**
   * @method SampleDistributionService.update
   * @description Updates a SampleDistribution in AWS
   * @returns Observable<SampleDistribution>
   */
  public update(sampleDistribution: SampleModels.SampleDistributionBase): Observable<SampleModels.SampleDistribution> {
    return this.api.put(this.endpoint, sampleDistribution, true, environment.samplingServicesUrl, null, null, false)
      .pipe(map((resp: SampleModels.SampleDistributionBase) => {
        if (resp) {
          const sd = new SampleModels.SampleDistribution(resp);
          this.store.dispatch(new SampleDistributionActions.UpdateSuccess(sd));
          return sd;
        }
        return null;
      }), catchError((err: any) => {
        const messages = [];
        if (err && err.error && err.error.errors) {
          err.error.errors.forEach(e => {
            if (e.data) {
              messages.push(...this.unwrapError(e.data));
            }
            else if (e.message) {
              messages.push(e.message);
            }
          })
        }
        else if (err.message) {
          messages.push(err.message);
        }
        const error = new Error(messages.join('<br>'));
        this.store.dispatch(new SampleDistributionActions.UpdateError(sampleDistribution.id, error));
        return throwError(error);
      }));
  }

  /**
   * @method SampleDistributionService.delete
   * @description Deletes the given SampleDistribution on the server
   * @param {string} id The id of SampleDistribution to be deleted
   * @returns Observable<boolean>
   */
  public delete(id: string): Observable<boolean> {
    return this.api.delete(this.endpoint + '/' + id, null, environment.samplingServicesUrl)
      .pipe(map(() => {
        this.store.dispatch(new SampleDistributionActions.DeleteSuccess(id));
        return true;
      }))
      .pipe(catchError(() => of(false)));
  }
}
