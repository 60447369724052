import { Action } from '@ngrx/store';
import { BlacklistedEmail } from '../../components/community/community.models';

export enum Types {
  Get                      = '[CommunityBlacklist] Get',
  GetSuccess               = '[CommunityBlacklist] GetSuccess',
  GetError                 = '[CommunityBlacklist] GetError',
                           
  GetAll                   = '[CommunityBlacklist] GetAll',
  GetAllSuccess            = '[CommunityBlacklist] GetAllSuccess',
  GetAllError              = '[CommunityBlacklist] GetAllError',
                           
  Create                   = '[CommunityBlacklist] Create',
  CreateSuccess            = '[CommunityBlacklist] CreateSuccess',
  CreateError              = '[CommunityBlacklist] CreateError',
                           
  Delete                   = '[CommunityBlacklist] Delete',
  DeleteSuccess            = '[CommunityBlacklist] DeleteSuccess',
  DeleteError              = '[CommunityBlacklist] DeleteError',
                           
  Update                   = '[CommunityBlacklist] Update',
  UpdateSuccess            = '[CommunityBlacklist] UpdateSuccess',
  UpdateError              = '[CommunityBlacklist] UpdateError',
                           
  Clear                    = '[CommunityBlacklist] Clear',
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: BlacklistedEmail) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: BlacklistedEmail) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor() { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: BlacklistedEmail) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: number | string, public error: Error) { }
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() { }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: BlacklistedEmail[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: number | string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: number | string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: number | string, public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: number | string, public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: BlacklistedEmail) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: number | string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any =
    Get    | GetSuccess    | GetError
  | GetAll | GetAllSuccess | GetAllError
  | Delete | DeleteSuccess | DeleteError
  | Create | CreateSuccess | CreateError
  | Update | UpdateSuccess | UpdateError
  | Clear;
