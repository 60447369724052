import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/components/shared/shared.module';
import { DialogModule } from '../dialog/dialog.module';

import { AccountRoutingModule } from './account-routing.module';

import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeDetailsComponent } from './change-details/change-details.component';

import { AccountComponent, DeleteCustomerDialog, AddEditCustomerDialog, AddEditUserDialog, DeleteUserDialog } from './account.component';

@NgModule({
  imports: [
    SharedModule, DialogModule, AccountRoutingModule
  ],
  declarations: [
    AccountComponent,
    ManageUsersComponent, ChangePasswordComponent, ChangeDetailsComponent,
    DeleteCustomerDialog, AddEditCustomerDialog,
    AddEditUserDialog, DeleteUserDialog,
  ],
  entryComponents: [
    DeleteCustomerDialog,
    DeleteUserDialog,
    AddEditCustomerDialog,
    AddEditUserDialog,
  ],
})
export class AccountModule { }
