import { Action } from '@ngrx/store';
import { Source, SourceStatsBase } from 'src/app/components/recruit/recruit.models';

export enum Types {
  Get                 = '[RecruitSource] Get',
  GetSuccess          = '[RecruitSource] GetSuccess',
  GetError            = '[RecruitSource] GetError',
  //                      RecruitSource
  GetAll              = '[RecruitSource] GetAll',
  GetAllSuccess       = '[RecruitSource] GetAllSuccess',
  GetAllError         = '[RecruitSource] GetAllError',
  //                      RecruitSource
  GetStatsSuccess     = '[RecruitSource] GetStatsSuccess',
  GetStatsError       = '[RecruitSource] GetStatsError',
  //                      RecruitSource
  Create              = '[RecruitSource] Create',
  CreateSuccess       = '[RecruitSource] CreateSuccess',
  CreateError         = '[RecruitSource] CreateError',
  //                      RecruitSource
  Delete              = '[RecruitSource] Delete',
  DeleteSuccess       = '[RecruitSource] DeleteSuccess',
  DeleteError         = '[RecruitSource] DeleteError',
  //                      RecruitSource
  Update              = '[RecruitSource] Update',
  UpdateSuccess       = '[RecruitSource] UpdateSuccess',
  UpdateError         = '[RecruitSource] UpdateError',
  //                      RecruitSource
  Clear               = '[RecruitSource] Clear',
}

export class GetStatsSuccess implements Action {
  readonly type = Types.GetStatsSuccess;
  constructor(public id: string, public payload: SourceStatsBase) { }
}

export class GetStatsError implements Action {
  readonly type = Types.GetStatsError;
  constructor(public id: string, public error: Error) { }
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() {
    console.log("Initiate: " + this.type);
  }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: Source[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) {
    console.log("Initiate: " + this.type);
  }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: Source) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: Source) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: Source) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: string, public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: Source) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetAll | GetAllSuccess | GetAllError | GetStatsSuccess | GetStatsError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
