import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { from, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { AccessArea, User, UserBase } from 'src/app/data.models';
import { AppStateService } from './app-state.service';
import {
    AngularFireAuth,
    Auth, AuthCredential,
    confirmPasswordReset, EmailAuthProvider, FirebaseUser,
    Functions, getAuth,
    getFunctions, httpsCallable, HttpsCallableResult, reauthenticateWithCredential,
    sendPasswordResetEmail, signInWithEmailAndPassword,
    signOut, updateEmail, updatePassword,
    verifyPasswordResetCode
} from './firebase.service';
import { LoggerService } from './logger.service';
import { PanelAdminService } from './panel-admin/panel-admin.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private subs: Subscription[] = [];
  public functions: Functions = getFunctions();
  public auth: Auth = getAuth();
  public get isLoggedIn(): boolean {
    return !!this.userService.currentUser;
  }
  public redirectUrl: string = "";
  public user$: Observable<User>;

  /**
   * @property AuthService.currentUser
   * @explanation
   * Why pass through to the UserService to store the currentUser?
   * Because the UserService needs to know the currentUser, and the the AuthService needs to utilise some 
   * functions in the UserService, so they need access to each other, causing a circular reference and an 
   * error in Angular. Thus the UserService needs to be self-contained, detached from AuthService entirely.
   * As these services were originally developed as one (until it became too big and had to be split into 2)
   * the reference to currrentUser has been left in AuthService in case of anything still pointing to it.
   *
   * TODO: AdamA: Switch all references to AuthService.currentUser to look at UserService.currentUser
   * and remove this getter/setter pass-through.
   */
  public get currentUser(): User {
    return this.userService.currentUser;
  }

  public set currentUser(user: User) {
    this.userService.currentUser = user;
  }

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private logger: LoggerService,
    private appState: AppStateService,
    private panelAdminService: PanelAdminService
  ) {
    this.user$ = this.afAuth.authState.pipe(switchMap((user: FirebaseUser) => {
      if (user) {
        return this.getUser(user).pipe(catchError((err: Error) => {
          this.logger.warn(err.message);
          this.logout();
          return throwError(err);
        }));
      }
      return of(this.currentUser = null);
    })).pipe(share());
    this.subs.push(
      this.route.queryParams.subscribe(params => {
        if (params.redirectUrl) this.redirectUrl = params.redirectUrl;
      })
    );
  }

  /**
   * @method AuthService.login
   * @description Login using firebase Authentication (email/password)
   * @returns Promise<Observable<User>>
   */
  public login(email: string, password: string): Promise<Observable<User>> {
    // TODO: AdamA: Return an Observable here. Looks like firebase goes straight to the
    // AngularFireAuth.authState change Observable and never returns here when I try to use
    // the from() method. Returning the promise works in the interim but it's not ideal.
    this.logger.log("Login");
    return signInWithEmailAndPassword(this.auth, email, password).then(() => {
      return this.user$;
    }).catch((err: Error) => {
      this.logger.log(err);
      throw new Error(err.message);
    });
  }

  /**
   * @method AuthService.createUser
   * @description Register brand new user with the firebase.Auth
   * @returns Observable<User>
   */
  public createUser(base: UserBase): Observable<boolean> {
    let user: User = new User({
      ...base,
      created: new Date().getTime(),
      inactive: false,
    });
    let createUser = httpsCallable(this.functions, "createUser");

    return from(createUser(user.asDataObj)
      .then((resp: HttpsCallableResult) => {
        return true;
      }));
  }

  /**
   * @method AuthService.getUser
   * @description Retrieve the firebase IDTokenfor the user and populate the user object
   * @returns Observable<User>
   */

  private currentAuthUser: FirebaseUser;
  getUser(currentUser: FirebaseUser): Observable<User> {
    if (!currentUser) {
      return throwError(new Error('No currentUser in firebase.'));
    }
    this.currentAuthUser = currentUser;
    return this.afAuth.idTokenResult.pipe(map(idTokenResult => {
      //idTokenResult has a lot of firebase properties that we don't want so here we map it to only include required props
      if (!idTokenResult) {
        return null;
      }
      return {
        customerId: idTokenResult.claims.customerId,
        authId: this.currentAuthUser ? this.currentAuthUser.uid : "",
        panelServicesToken: idTokenResult.claims.panelServicesToken,
        panelServicesSurveyToken: idTokenResult.claims.panelServicesSurveyToken,
        expires: idTokenResult.claims.exp
      };
    }),
      switchMap((user: UserBase) => {
        if (user) {
          //Get the user object from Firestore
          return this.userService.getUser(user.authId)
            .pipe(
              catchError(() => {
                return this.userService.setUser(new User(user));
              }),
              map((u: User) => {
                if (u) {
                  //Extended the current user with the data retrieved from Firestore
                  this.currentUser = this.userService.fixUser(u.extend(user));
                  Sentry.configureScope(scope => scope.setUser({
                    id: this.currentUser.authId,
                    email: this.currentUser.email,
                    firstName: this.currentUser.firstName,
                    lastName: this.currentUser.lastName,
                    customerId: this.currentUser.customerId,
                  }));
                  this.subscribeToUserService();
                }
                return this.currentUser;
              })
            );
        }
        return of(null);
      })
    );
  }

  private subscribeToUserService(): void {
    this.userService.user$.subscribe(user => {
      this.logger.log(user)
    });
  }

  /**
   * @method AuthService.deleteUser
   * @description Delete a user from firebase.Auth, then trigger a delete from firebase.Firestore
   * @returns Observable<boolean>
   */
  public deleteUser(uid: string): Observable<boolean> {
    //TODO: AdamA: Not tested, as this feature is not yet required
    let deleteUser = httpsCallable(this.functions, "deleteUser");

    return from(<Promise<any>>deleteUser({ uid: uid })
      .then((resp: HttpsCallableResult) => {
        return resp.data;
      }).catch((error: any) => {
        if (error.details.code === "auth/user-not-found") {
          return true;
        }
        throw new Error(error.message);
      })).pipe(switchMap((data) => {
        return this.userService.deleteUser(uid);
      }));
  }

  /**
   * @method AuthService.changeEmail
   * @param password Their current password in order to re-authenticate them
   * @param email The new email address to update their account with
   * @description Re-authenticate a user, then change their email and update it in firestore
   * @returns Observable<boolean>
   */
  public changeEmail(password: string, email: string): Observable<boolean> {
    return this.reauthenticateUser(password).pipe(switchMap((user: FirebaseUser) => {
      return from(updateEmail(user, email).then(() => {
        this.logger.log("Email updated")
        this.userService.updateUser(this.currentAuthUser.uid, { email: email })
        return true;
      }));
    }));
  }

  /**
   * @method AuthService.changePassword
   * @param currentPassword Their current password in order to re-authenticate them
   * @param newPassword The new password to update their account with
   * @description Re-authenticate a user and then change their password
   * @returns Observable<boolean>
   */
  public changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    return this.reauthenticateUser(currentPassword).pipe(switchMap((user: FirebaseUser) => {
      return from(updatePassword(user, newPassword).then(() => {
        this.logger.log("Password updated")
        return true;
      }));
    }));
  }

  /**
   * @method AuthService.reauthenticateUser
   * @description Re-authenticate the currently logged in user in order to carry out a restricted action, like change-password
   * @returns Observable<FirebaseUser>
   */
  private reauthenticateUser(password: string): Observable<FirebaseUser> {
    const credentials: AuthCredential = EmailAuthProvider.credential(
      this.currentAuthUser.email,
      password
    );
    return from(reauthenticateWithCredential(this.currentAuthUser, credentials)).pipe(map(() => this.currentAuthUser));
  }

  /**
   * @method AuthService.triggerResetPassword
   * @description Request firebase to send a reset-password email to the email address supplied
   * @returns Observable<boolean>
   */
  public triggerResetPassword(email: string): Observable<boolean> {
    return from(sendPasswordResetEmail(this.auth, email).then(() => {
      return true;
    }).catch((error: Error) => {
      throw new Error(error.message);
    }));
  }

  /**
   * @method AuthService.resetPassword
   * @description Changes the user's password given a reset code provided via a link sent by email.
   * @returns Observable<string>
   */
  public resetPassword(newPassword: string, code: string): Observable<boolean> {
    return from(confirmPasswordReset(this.auth, code, newPassword).then(() => {
      return true;
    }).catch((error: Error) => {
      throw new Error(error.message);
    }));
  }

  /**
   * @method AuthService.verifyPasswordResetCode
   * @description Checks the validity of a reset code provided via a link sent by email.
   *              If successful the user's email address will be returned.
   * @returns Observable<string>
   */
  public verifyPasswordResetCode(code: string): Observable<string> {
    return from(verifyPasswordResetCode(this.auth, code));
  }

  /**
   * @method AuthService.checkAccess
   * @description Checks whether a user has access to the given area
   * @returns boolean
   */
  public checkAccess(area: AccessArea, user: User = this.currentUser): boolean {
    return !!user && user.access[area];
  }

  /**
   * @method AuthService.logout
   * @description Logs out a user from firebase, deleting the currentUser stored in memory and navigating home
   * @returns Promise<boolean>
   */
  public logout(): Observable<boolean> {
    const obs = this.panelAdminService.logout(false).pipe(catchError(() => of(null)), switchMap(resp => {
      this.userService.currentUser = null;
      this.currentAuthUser = null;
      Sentry.configureScope(scope => scope.setUser(null));
      return from(signOut(this.auth).then(() => {
        this.appState.clean();
        setTimeout(() => this.router.navigate(['']), 200);
        return true;
      }));
    }));
    obs.subscribe();
    return obs;
  }
}
