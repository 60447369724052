import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MiscPayout, MiscPayoutPagerFilters } from 'src/app/components/community/community.models';
import { PagedHttpResponse, PagerConfig } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Pager } from 'src/app/services/paging.service';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityMiscPayoutService {

  private endpoint: string = 'misc_payouts';

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method PanelAdminMiscPayoutService.getByID
   * @description Retrieves a MiscPayout
   * @returns Observable<MiscPayout>
   */
  public getByID(id?: number | string): Observable<MiscPayout> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + '/' + id, {}, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminMiscPayoutService.getAll
   * @description Retrieves a paged list of all MiscPayout, filtered by the params set
   * @returns Observable<PagedHttpResponse<MiscPayout>>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<MiscPayout>> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + (params || ''), {}, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: MiscPayoutPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }

}
