<form class="mx-auto">
  <div class="form-field-wrapper">
    <div class="d-flex flex-column flex-sm-row align-items-center">
      <div>
        <div class="avatar-preview"
             [class.hasImage]="selectedImage || image"
             [style.backgroundImage]="selectedImage ? 'url(' + selectedImage + ')' : ''">
          <div class="avatar-preview-inner"
               [style.backgroundImage]="!file && image ? 'url(' + image + ')' : ''">
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center ml-sm-2 ml-xl-4 mt-2 mt-sm-0 w-100">
        <label for="avatar-inputs" class="d-flex flex-column flex-sm-row align-items-sm-center">
          <ng-content></ng-content>
          <button mat-stroked-button class="my-2 ml-sm-2" type="button" (click)="fileInput.click()">
            Choose new image
          </button>
        </label>
        <input type="file"
               #fileInput
               hidden
               name="avatar-input"
               id="avatar-input"
               accept="image/png, image/jpeg, image/gif"
               (change)="selectImage($event.target.files)" />
        <mat-progress-bar class="mt-2" mode="determinate" [style.opacity]="uploadProgress ? 1 : 0" [value]="uploadProgress"></mat-progress-bar>
      </div>
    </div>
  </div>
</form>
