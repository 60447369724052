import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CommunityMemberPagerFilters, MemberLoyaltyBonus, PollfishSurveyPagerFilters } from 'src/app/components/community/community.models';
import { PagedHttpResponse, PagerConfig } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Pager } from 'src/app/services/paging.service';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityLoyaltyBonusService {

  private endpoint: string = 'loyalty_bonuses';

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method PanelAdminTestService.getAll
   * @description Retrieves a list of all MemberLoyaltyBonuss, filtered by the params set
   * @returns Observable<PagedHttpResponse<MemberLoyaltyBonus>>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<MemberLoyaltyBonus>> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + (params || ''), {}, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: PollfishSurveyPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }

}
