import { Injectable } from '@angular/core';
import { AreaChart, BarChart, Chart, ColumnChart, Dashboard, formatPercent, LineChart, PieChart, Sparkline } from 'src/app/data.models';
import { LoggerService } from './logger.service';


@Injectable({
  providedIn: 'root'
})
export class ChartService {

  primaryColor: string = '#60BD68';
  accentColor: string = '#ccc';
  colors: string[] = ['#60BD68','mediumvioletred','orangeyellow','green'];

  constructor(
    private logger: LoggerService
  ) { }

  setPrimaryColor(color:string) {
    //this.primaryColor = themeEmitter.primaryColor ? themeEmitter.primaryColor.hex.toString() : this.primaryColor;
    //this.accentColor =  themeEmitter.accentColor ? themeEmitter.accentColor.hex.toString() : this.accentColor;
    //this.logger.warn('Primary color: ' + this.primaryColor)
    this.colors[0] = color;
  }

  /**
   * @method ChartService.asPieChart
   * @description Builds a new PieChart data object with the supplied parameters
   * @returns PieChart
   */
  public asPieChart(data: any[], columnNames: any[], title?: string): PieChart {
    const chart: PieChart = new PieChart(data, columnNames);
    chart.title = title;
    return chart;
  }

  /**
   * @method ChartService.asSparkline
   * @description Builds a new PieChart data object with the supplied parameters
   * @returns PieChart
   */
  public asSparkline(data: any[], columnNames: any[], title?: string): Sparkline {
    const chart: Sparkline = new Sparkline(data, columnNames);
    chart.title = title;
    return chart;
  }

  /**
   * @method ChartService.asBarChart
   * @description Builds a new BarChart data object with the supplied parameters
   * @returns BarChart
   */
  public asBarChart(data: any[], columnNames: any[], title?: string): BarChart {
    const chart: BarChart = new BarChart(data, columnNames);
    chart.title = title;
    return chart;
  }

  /**
   * @method ChartService.asColumnChart
   * @description Builds a new ColumnChart data object with the supplied parameters
   * @returns ColumnChart
   */
  public asColumnChart(data: any[], columnNames: any[], title?: string): ColumnChart {
    const chart: ColumnChart = new ColumnChart(data, columnNames);
    chart.title = title;
    return chart;
  }

  /**
   * @method ChartService.asAreaChart
   * @description Builds a new AreaChart data object with the supplied parameters
   * @returns LineChart
   */
  public asAreaChart(data: any[], columnNames: any[], title?: string): AreaChart {
    const chart: AreaChart = new AreaChart(data, columnNames);
    chart.title = title;
    return chart;
  }

  /**
   * @method ChartService.asLineChart
   * @description Builds a new LineChart data object with the supplied parameters
   * @returns LineChart
   */
  public asLineChart(data: any[], columnNames: any[], title?: string): LineChart {
    const chart: LineChart = new LineChart(data, columnNames);
    chart.title = title;
    return chart;
  }

  /**
   * @method ChartService.createChartsForDashboard
   * @description Builds the standard BarCharts and PieCharts used by a Dashboard
   * @returns Chart[]
   */
  public createChartsForDashboard(dashboard: Dashboard): Chart[] {
    if (dashboard && dashboard.data) {
      const total = dashboard.data.sessions.total_count;
      const started = dashboard.data.sessions.started_count;
      const finished = dashboard.data.sessions.finished_count;
      const qualified = dashboard.data.sessions.qualified_count;
      return [
        this.asPieChart([
          ["Converted", dashboard.data.sessions.conversion_percent, ""],
          ["Unconverted", 1 - dashboard.data.sessions.conversion_percent, ""]
        ], ["type", "conversion rate", { role: 'annotation' }], "conversion rate").setOptions({
          title: '',
          pieHole: 0.7,
          pieSliceText: "none",
          tooltip: { text: 'percentage' },
          width: '30%',
          chartArea: { height: 150, },
          height: 150,
          legend: {
            position: 'none'
          },
          colors: [this.colors[0], this.accentColor]
        }),
        this.asBarChart([
          ["started", dashboard.data.sessions.started_percent * 100, formatPercent(dashboard.data.sessions.started_percent), "<p class='p-1' style='min-width:100px;'><b>" + started + " started</b> of " + total + " invited</p>"],
          ["finished", dashboard.data.sessions.finished_percent * 100, formatPercent(dashboard.data.sessions.finished_percent), "<p class='p-1' style='min-width:100px;'>   <b>" + finished + " finished</b> of " + started + " started</p>"],
          ["qualified", dashboard.data.sessions.qualified_percent * 100, formatPercent(dashboard.data.sessions.qualified_percent), "<p class='p-1' style='min-width:100px;'><b>" + qualified + " qualified</b> of " + finished + " finished</p>"],
        ], ["group", "count", { role: 'annotation' }, { type: 'string', role: 'tooltip', 'p': { 'html': true } }], 'conversion funnel').setOptions({
          title: '',
          width: '100%',
          height: 150,
          colors: this.colors,
          annotations: {
            textStyle: {
              color: 'black',
            }
          },
          chartArea: { height: 150 },
          vAxis: { minValue: 0 },
          hAxis: {
            minValue: 0,
            maxValue: 100,
            viewWindow: { min: 0, max: 100 },
            textPosition: 'none',
            gridlines: { color: 'transparent' },
          },
          tooltip: { isHtml: true },
          legend: {
            position: 'none'
          }
        })
      ];
    }
    return [];
  }
}
