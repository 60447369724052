import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import * as ProfilerActions from './profile-profiler.actions';
import { ProfilersService } from 'src/app/services/profilers.service'

@Injectable()
export class ProfileProfilerEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProfilerActions.Types.GetAll),
      mergeMap(() => this.profilersService.getActive()
        .pipe(
          map(profiles => (new ProfilerActions.GetAllSuccess(profiles))),
          catchError((err: Error) => of(new ProfilerActions.GetAllError(err)))
        ))
    ));

  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProfilerActions.Types.GetArchived),
      mergeMap(() => this.profilersService.getArchived()
        .pipe(
          map(profilers => (new ProfilerActions.GetAllSuccess(profilers, true))),
          catchError((err: Error) => of(new ProfilerActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProfilerActions.Types.Get),
      mergeMap((action: ProfilerActions.Get) => this.profilersService.getByID(action.id)
        .pipe(
          map(profiler => (new ProfilerActions.GetSuccess(profiler))),
          catchError((err: Error) => of(new ProfilerActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProfilerActions.Types.Create),
      mergeMap((action: ProfilerActions.Create) => this.profilersService.create(action.payload)
        .pipe(
          map((profiler) => (new ProfilerActions.CreateSuccess(profiler))),
          catchError((err: Error) => of(new ProfilerActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProfilerActions.Types.Update),
      mergeMap((action: ProfilerActions.Update) => this.profilersService.update(action.payload)
        .pipe(
          map((profiler) => (new ProfilerActions.UpdateSuccess(profiler))),
          catchError((err: Error) => of(new ProfilerActions.UpdateError(action.payload.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private profilersService: ProfilersService
  ) { }
}
