import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProductActions from './product-test-product.actions';
import { ProductTestProductService } from '../../services/panel-admin/product-test/product-test-product.service';

@Injectable()
export class ProductEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProductActions.Types.GetAll),
      mergeMap(() => this.service.getAll()
        .pipe(
          map(products => (new ProductActions.GetAllSuccess(products))),
          catchError((err: Error) => of(new ProductActions.GetAllError(err)))
        ))
    ));

  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProductActions.Types.GetArchived),
      mergeMap(() => this.service.getAll(true)
        .pipe(
          map(products => (new ProductActions.GetArchivedSuccess(products))),
          catchError((err: Error) => of(new ProductActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProductActions.Types.Get),
      mergeMap((action: ProductActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(product => (product ? new ProductActions.GetSuccess(product) : new ProductActions.GetError(action.id, new Error("Unknown Error")))),
          catchError((err: Error) => of(new ProductActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProductActions.Types.Create),
      mergeMap((action: ProductActions.Create) => this.service.create(action.payload)
        .pipe(
          map((product) => (new ProductActions.CreateSuccess(product))),
          catchError((err: Error) => of(new ProductActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProductActions.Types.Update),
      mergeMap((action: ProductActions.Update) => this.service.update(action.id, action.payload)
        .pipe(
          map((product) => (new ProductActions.UpdateSuccess(product))),
          catchError((err: Error) => of(new ProductActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProductActions.Types.Delete),
      mergeMap((action: ProductActions.Delete) => this.service.archive(action.id)
        .pipe(
          map(() => (new ProductActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new ProductActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: ProductTestProductService
  ) { }
}
