import { Action } from '@ngrx/store';
import { SiteImage } from 'src/app/components/recruit/recruit.models';

export enum Types {
  Get                   = '[RecruitSiteImage] Get',
  GetSuccess            = '[RecruitSiteImage] GetSuccess',
  GetError              = '[RecruitSiteImage] GetError',
  //                        RecruitSiteImage
  GetAll                = '[RecruitSiteImage] GetAll',
  GetAllSuccess         = '[RecruitSiteImage] GetAllSuccess',
  GetAllError           = '[RecruitSiteImage] GetAllError',
  //                        RecruitSiteImage
  Create                = '[RecruitSiteImage] Create',
  CreateSuccess         = '[RecruitSiteImage] CreateSuccess',
  CreateError           = '[RecruitSiteImage] CreateError',
  //                        RecruitSiteImage
  Delete                = '[RecruitSiteImage] Delete',
  DeleteSuccess         = '[RecruitSiteImage] DeleteSuccess',
  DeleteError           = '[RecruitSiteImage] DeleteError',
  //                        RecruitSiteImage
  Update                = '[RecruitSiteImage] Update',
  UpdateSuccess         = '[RecruitSiteImage] UpdateSuccess',
  UpdateError           = '[RecruitSiteImage] UpdateError',
  //                        RecruitSiteImage
  Clear                 = '[RecruitSiteImage] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() {
    console.log("Initiate: " + this.type);
  }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: SiteImage[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) {
    console.log("Initiate: " + this.type);
  }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: SiteImage) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: SiteImage) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: SiteImage) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: string, public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: SiteImage) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetAll | GetAllSuccess | GetAllError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
