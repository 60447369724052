import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { isDefined, MenuAction, UserRole } from 'src/app/data.models';
import { LoggerService, MenuService, UserService } from 'src/app/services';
import { act } from '@ngrx/effects';


@Component({
  selector: 'app-nav-action',
  templateUrl: './nav-action.component.html'
})
export class NavActionComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  @ViewChildren(MatMenuTrigger) triggers: QueryList<MatMenuTrigger>;

  public actions: MenuAction[];
  public showMore: boolean;

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map((result: any) => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private menuService: MenuService,
    private userService: UserService,
    private loggerService: LoggerService,
  ) { }

  getTemplateClass(action: MenuAction) {
    if (action.templateClass) {
      if (typeof action.templateClass === "string") {
        return action.templateClass;
      }
      else {
        try {
          return action.templateClass.call(action.context, action);
        } catch (e) {
          return "";
        }
      }
    }
  }

  public isVisible(action: MenuAction) {
    //this.loggerService.log(action.label + ' ' + action.hide.call(action.context))
    return typeof action.hide === "undefined" || action.hide.call(action.context);
  }

  public userHasPermission(role: UserRole) {
    return !isDefined(role) || this.userService.hasPermission(role);
  }

  public closeMenu() {
    this.loggerService.log(this.triggers);
    if (this.triggers && this.triggers.length) {
      this.triggers.forEach(trigger => {
        trigger.closeMenu();
      });
    }
  }
  
  public runAction(action: MenuAction) {
    this.menuService.callAction(action);
  }
  
  ngOnInit() {
    this.subs.push(
      this.menuService.actions.subscribe(actions => {
        this.actions = actions;
      }),
      this.menuService.close.subscribe(close => {
        if (close) this.closeMenu();
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
