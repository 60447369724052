import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { RecruitCampaignService } from 'src/app/services/panel-admin/recruit/recruit-campaign.service';

@Injectable()
export class RecruitCampaignEffects {
  constructor(
    private actions$: Actions,
    private service: RecruitCampaignService
  ) { }
}
