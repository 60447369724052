import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService, LoggerService } from 'src/app/services';

@Component({
  selector: 'upload-image',
  templateUrl: './upload-image.component.html'
})
export class UploadImageComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  loading: boolean;

  image: string;
  @Input("image") set _image(image: string) {
    this.image = image;
  }

  uploadProgress: number;
  @Input("uploadProgress") set _uploadProgress(uploadProgress: number) {
    this.uploadProgress = uploadProgress;
  }

  @Output() save: EventEmitter<File> = new EventEmitter();
  errorMsg: string;
  file: File;

  constructor(
    private logger: LoggerService,
  ) { }

  selectedImage: string;

  selectImage(files: FileList) {
    this.file = files[0];
    this.logger.log(this.file);
    if (this.file) {
      this.save.emit(this.file)
      var reader = new FileReader();

      reader.onload = (e: any) => {
        this.selectedImage = e.target.result as string;
      }
      reader.readAsDataURL(this.file);
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
