import { orderBy } from 'lodash';
import { Campaign, CampaignActivity, CampaignStats } from 'src/app/components/recruit/recruit.models';
import * as Reducers from '../reducers';
import * as CampaignActions from './recruit-campaign.actions';

export interface State extends Reducers.State<Campaign> {
  stats?: { [campaignId: string]: CampaignStats };
  statsErrors?: { [campaignId: string]: Error };
  activities?: CampaignActivity[];
  activitiesPending?: CampaignActivity[];
  activitiesMap?: { [id: string]: CampaignActivity };
  activitiesByCampaign?: { [campaign_id: string]: CampaignActivity[] };
  activitiesErrors?: { [id: string]: Error };
}

export const initialState: State = {
  data: null,
  archive: null,
  count: 0,
  map: {},
  errors: {},
  stats: {},
  statsErrors: {},
  activities: null,
  activitiesPending: null,
  activitiesMap: {},
  activitiesByCampaign: {},
  activitiesErrors: {},
};

export function reducerFn(state: State = initialState, action: CampaignActions.Any): State {

  const newState: State = { ...state };
  newState.map = Object.keys(newState.map).reduce((map, key) => {
    map[key] = new Campaign(newState.map[key]);
    return map;
  }, {});

  switch (action.type) {

    case CampaignActions.Types.GetSuccess:
    case CampaignActions.Types.UpdateSuccess:
      newState.errors = { ...newState.errors, ...(action.payload ? { [action.payload.id || ""]: null } : {}) };
    case CampaignActions.Types.CreateSuccess:
      newState.map = { ...newState.map, ...(action.payload ? { [action.payload.id || ""]: action.payload } : {}) };
      newState.errors = { ...newState.errors, create: null };
      break;

    case CampaignActions.Types.GetStatsSuccess:
      newState.stats = {
        ...newState.stats,
        [action.id]: new CampaignStats(action.payload)
      };
      if (newState.statsErrors[action.id]) {
        newState.statsErrors = {
          ...newState.statsErrors,
          [action.id]: null
        };
      }
      break;

    case CampaignActions.Types.GetStatsError:
      if (newState.map[action.id]) {
        newState.statsErrors = {
          ...newState.statsErrors,
          [action.id]: action.error
        };
      }
      break;

    case CampaignActions.Types.GetAllSuccess:
      newState.map = { ...newState.map, ...Reducers.map(action.payload) };
      newState.errors = { ...newState.errors, all: null };
      break;

    case CampaignActions.Types.DeleteSuccess:
      newState.map = {
        ...newState.map,
        [action.id]: null
      };
      break;

    case CampaignActions.Types.GetAllError:
      newState.map = {};
    case CampaignActions.Types.CreateError:
    case CampaignActions.Types.GetError:
    case CampaignActions.Types.DeleteError:
    case CampaignActions.Types.UpdateError:
      newState.errors = {
        ...state.errors,
        [action.type === CampaignActions.Types.GetAllError ? 'all'
          : action.type === CampaignActions.Types.CreateError ? 'create'
            : action.id]: action.error
      };
      break;

    case CampaignActions.Types.GetAllActivitySuccess:
      newState.activitiesMap = { ...newState.activitiesMap, ...Reducers.map(action.payload) };
    case CampaignActions.Types.GetActivitySuccess:
    case CampaignActions.Types.CreateActivitySuccess:
      if (action.type === CampaignActions.Types.GetActivitySuccess || action.type === CampaignActions.Types.CreateActivitySuccess) {
        newState.activitiesMap = { ...newState.activitiesMap, ...(action.payload ? { [action.payload.id || ""]: action.payload } : {}) };
      }
      newState.activities = orderBy(Object.values(newState.activitiesMap).filter(a => a.processed), "created_at", "desc");
      newState.activitiesPending = orderBy(Object.values(newState.activitiesMap).filter(a => !a.processed), "created_at", "desc");
      newState.activitiesErrors = {
        ...newState.activitiesErrors,
        [action.type === CampaignActions.Types.GetAllActivitySuccess ? 'all'
          : action.type === CampaignActions.Types.CreateActivitySuccess ? 'create'
            : action.payload.id || ""]: null
      }
      newState.activitiesByCampaign = newState.activities.reduce((prev, curr) => {
        prev[curr.campaign_id] = prev[curr.campaign_id] || [];
        prev[curr.campaign_id].push({ ...curr });
        return prev;
      }, {} as { [campaign_id: string]: CampaignActivity[] });
      break;

    case CampaignActions.Types.GetAllActivityError:
      newState.activitiesMap = {};
      newState.activities = [];
      newState.activitiesPending = [];
    case CampaignActions.Types.CreateActivityError:
    case CampaignActions.Types.GetActivityError:
      newState.activitiesErrors = {
        ...newState.activitiesErrors,
        [action.type === CampaignActions.Types.GetAllActivityError ? 'all'
          : action.type === CampaignActions.Types.CreateActivityError ? 'create'
            : action.id]: action.error
      };
      break;

    case CampaignActions.Types.GetStatsError:
      newState.statsErrors = {
        ...newState.statsErrors,
        [action.id]: action.error
      };
      break;

    case CampaignActions.Types.Clear:
      return initialState;

    default:
      return state;

  }
  newState.archive = Object.values(newState.map).filter(t => t && t.archived);
  newState.data = Object.values(newState.map).filter(t => t && !t.archived);
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  !!newState.data && newState.data.length || (newState.data = null);
  return newState;
}
