import { Pipe, PipeTransform } from '@angular/core';

import { Profiler } from 'src/app/data.models';
import { LoggerService } from 'src/app/services';

@Pipe({
  name: 'log'
})
export class LogPipe implements PipeTransform {

  constructor(private logger: LoggerService) {}

  transform(value: any): string {
    this.logger.log(value);
    return "";
  }
}
