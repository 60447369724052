import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Dashboard, EarningsData, formatCurrency, formatNumber, formatPercent, platformAliasMap, PlatformList, SurveyEntryData, UXTableData } from 'src/app/data.models';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';
import { LoggerService } from './logger.service';
import { SupplyOptimizerService } from './supply-optimizer.service';
import { UtilService } from './util.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardService extends SupplyOptimizerService {

  private platformAliasMap = platformAliasMap;

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private customerService: CustomerService,
    private snackBar: MatSnackBar,
    private util: UtilService,
  ) {
    super(customerService);
  }

  public mapDashboards(dashboards: Dashboard[]): Dashboard[] {
    // ToDo: AdamA: This method was to populate values that were not in the API yet and so needed to be hardcoded.
    // Need to remove this function now the API is complete
    return dashboards;
  }

  platformOrder: string[] = [];

  public getSurveyEntryData(dashboard: Dashboard, platformMap?: PlatformList): SurveyEntryData[] {
    //ToDo: AdamA: Need to check that each property exists on the supplied dashboard
    if (dashboard.data) {
      const platforms = [{ name: "total", value: "total" }];
      this.platformOrder = Object.keys(dashboard.data.survey_entries);
      this.platformOrder.filter(p => !platformMap || (!!p && platformMap[p])).forEach(platform => {
        if (this.platformAliasMap[platform] && dashboard.data.survey_entries[platform]) {
          platforms.push(this.platformAliasMap[platform]);
        }
      });
      
      return [
        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatCurrency(dashboard.data.survey_entries[curr.value].cpi_sum, '', '1.0-0');
          return prev;
        }, { name: 'Revenue' }),

        { name: '' },

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].started_count, '', '1.0-0');
          return prev;
        }, { name: 'Entries Started' }),
        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatCurrency(dashboard.data.survey_entries[curr.value].epc);
          return prev;
        }, { name: 'Average EPC' }),

        { name: '' },

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].qualified_count, '', '1.0-0');
          return prev;
        }, { name: 'Completes' }),
        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatCurrency(dashboard.data.survey_entries[curr.value].cpi_average);
          return prev;
        }, {
          name: 'Average CPI', tooltip: (platforms.reduce((prev: SurveyEntryData, curr) => {
            prev[curr.value] =
              formatCurrency(dashboard.data.survey_entries[curr.value].cpi_min)
              + ' / '
              + formatCurrency(dashboard.data.survey_entries[curr.value].cpi_max);
            return prev;
          }, { name: '' }))
        }),

        { name: '' },

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatPercent(dashboard.data.survey_entries[curr.value].conversion_percent, '', '1.1-1');
          return prev;
        }, {
          name: 'Conversion Rate', tooltip: (platforms.reduce((prev: SurveyEntryData, curr) => {
            prev[curr.value] = dashboard.data.survey_entries[curr.value].qualified_count + ' qualified';
            return prev;
          }, { name: '' }))
        }),

        { name: '' },

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatPercent(dashboard.data.survey_entries[curr.value].started_percent, '', '1.1-1');
          return prev;
        }, {
          name: 'Start Rate', tooltip: (platforms.reduce((prev: SurveyEntryData, curr) => {
            prev[curr.value] = dashboard.data.survey_entries[curr.value].started_count + ' started';
            return prev;
          }, { name: '' }))
        }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatPercent(dashboard.data.survey_entries[curr.value].finished_percent, '', '1.1-1');
          return prev;
        }, {
          name: 'Finish Rate', tooltip: (platforms.reduce((prev: SurveyEntryData, curr) => {
            prev[curr.value] = dashboard.data.survey_entries[curr.value].finished_count
              + ' finished, including '
              + dashboard.data.survey_entries[curr.value].buyer_finished_count
              + this.util.pluralise(' buyer', dashboard.data.survey_entries[curr.value].buyer_finished_count)
              + ' finished';
            return prev;
          }, { name: '' }))
        }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatPercent(dashboard.data.survey_entries[curr.value].qualified_percent, '', '1.1-1');
          return prev;
        }, {
          name: 'Qualify Rate', tooltip: (platforms.reduce((prev: SurveyEntryData, curr) => {
            prev[curr.value] = dashboard.data.survey_entries[curr.value].qualified_count + ' qualified';
            return prev;
          }, { name: '' }))
        }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatPercent(dashboard.data.survey_entries[curr.value].buyer_qualified_percent, '', '1.1-1');
          return prev;
        }, {
          name: 'Buyer Qualify Rate', tooltip: (platforms.reduce((prev: SurveyEntryData, curr) => {
            prev[curr.value] = dashboard.data.survey_entries[curr.value].qualified_count
              + this.util.pluralise(' buyer', dashboard.data.survey_entries[curr.value].qualified_count)
              + ' qualified';
            return prev;
          }, { name: '' }))
        }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatPercent(dashboard.data.survey_entries[curr.value].invalidated_percent, '', '1.1-1');
          return prev;
        }, {
          name: 'Quality Term Rate', tooltip: (platforms.reduce((prev: SurveyEntryData, curr) => {
            prev[curr.value] = dashboard.data.survey_entries[curr.value].invalidated_count + ' quality terms';
            return prev;
          }, { name: '' }))
        }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].failed_security_count, '', '1.0-0');
          return prev;
        }, { name: 'Failed Security Checks' }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].prescreen_failed_count, '', '1.0-0');
          return prev;
        }, { name: 'Failed Prescreen Checks' }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].prescreen_passed_count, '', '1.0-0');
          return prev;
        }, { name: 'Passed Prescreen Checks' }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].skipped_count, '', '1.0-0');
          return prev;
        }, { name: 'Skipped Entries' }),

        { name: '' },

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].loi_qualified_median / 60, '', '1.1-1');
          return prev;
        }, { name: 'Completes LOI | Median' }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].loi_qualified_75th / 60, '', '1.1-1');
          return prev;
        }, { name: 'Completes LOI | Top 25%' }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].loi_disqualified_median / 60, '', '1.1-1');
          return prev;
        }, { name: 'Terms LOI | Median' }),

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].loi_disqualified_75th / 60, '', '1.1-1');
          return prev;
        }, { name: 'Terms LOI | Top 25%' }),

        { name: '' },

        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].routed_count, '', '1.0-0');
          return prev;
        }, { name: 'Routed Entries' }),
        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].requested_count, '', '1.0-0');
          return prev;
        }, { name: 'Requested Entries' }),
        platforms.reduce((prev: SurveyEntryData, curr) => {
          prev[curr.value] = formatNumber(dashboard.data.survey_entries[curr.value].targeted_count, '', '1.0-0');
          return prev;
        }, { name: 'Targeted Entries' }),
      ];
    }
    return [];
  }

  public getEarningsByPlatform(dashboard: Dashboard, platformMap?: PlatformList): EarningsData[] {
    if (dashboard.data) {

      const columns = [
        {
          alias: "totalCompletes",
          value: "qualified_count_total",
          format: (value) => formatNumber(value, '', '1.0-0')
        },
        {
          alias: "totalRevenue",
          value: "cpi_sum_total",
          format: (value) => formatCurrency(value)
        }
      ];
      this.platformOrder = Object.keys(dashboard.data.survey_entries);
      const platforms = this.platformOrder.filter(p => {
        if (!platformMap || (!!p && !!platformMap[p])) {
          return !!this.platformAliasMap[p];
        }
        return false;
      }).map(p => this.platformAliasMap[p]);
      platforms.push({ name: "Total", value: "total" as any });

      return platforms.map(platform => {
        return columns.reduce((prev: EarningsData, curr) => {
          if (dashboard.data.survey_entries[platform.value]) {
            prev[curr.alias] = curr.format(dashboard.data.survey_entries[platform.value][curr.value]);
          }
          return prev;
        }, { name: platform.name });
      });
    }
    return [];
  }

  public getUXData(dashboard: Dashboard): UXTableData[] {
    if (dashboard.data) {
      return [
        {
          name: 'Session LOI | Median',
          abandons: formatNumber((dashboard.data.sessions.length_unexp_median / 60), '', '1.1-1'),
          terminates: formatNumber((dashboard.data.sessions.length_exp_dq_median / 60), '', '1.1-1'),
          completes: formatNumber((dashboard.data.sessions.length_exp_qual_median / 60), '', '1.1-1'),
        },
        {
          name: 'Session LOI | Top 25%',
          abandons: formatNumber((dashboard.data.sessions.length_unexp_75th / 60), '', '1.1-1'),
          terminates: formatNumber((dashboard.data.sessions.length_exp_dq_75th / 60), '', '1.1-1'),
          completes: formatNumber((dashboard.data.sessions.length_exp_qual_75th / 60), '', '1.1-1'),
        },
        { name: '' },
        {
          name: "Avg Survey Entries | Failed Prescreen",
          completes: formatNumber((dashboard.data.sessions.prescreen_failed_entries_exp_qual_average), '', '1.1-1'),
          terminates: formatNumber((dashboard.data.sessions.prescreen_failed_entries_exp_dq_average), '', '1.1-1'),
          abandons: formatNumber((dashboard.data.sessions.prescreen_failed_entries_unexp_average), '', '1.1-1'),
        },
        {
          name: "Avg Survey Entries | Skipped",
          completes: formatNumber((dashboard.data.sessions.skipped_entries_exp_qual_average), '', '1.1-1'),
          terminates: formatNumber((dashboard.data.sessions.skipped_entries_exp_dq_average), '', '1.1-1'),
          abandons: formatNumber((dashboard.data.sessions.skipped_entries_unexp_average), '', '1.1-1'),
        },
        {
          name: "Avg Survey Entries | Started",
          completes: formatNumber((dashboard.data.sessions.started_entries_exp_qual_average), '', '1.1-1'),
          terminates: formatNumber((dashboard.data.sessions.started_entries_exp_dq_average), '', '1.1-1'),
          abandons: formatNumber((dashboard.data.sessions.started_entries_unexp_average), '', '1.1-1'),
        },
        {
          name: 'Avg Survey Entries | Finished',
          abandons: formatNumber((dashboard.data.sessions.finished_entries_unexp_average), '', '1.1-1'),
          terminates: formatNumber((dashboard.data.sessions.finished_entries_exp_dq_average), '', '1.1-1'),
          completes: formatNumber((dashboard.data.sessions.finished_entries_exp_qual_average), '', '1.1-1'),
        },
        {
          name: 'Avg Survey Entries | Buyer Finished',
          abandons: formatNumber((dashboard.data.sessions.finished_buyer_entries_unexp_average), '', '1.1-1'),
          terminates: formatNumber((dashboard.data.sessions.finished_buyer_entries_exp_dq_average), '', '1.1-1'),
          completes: formatNumber((dashboard.data.sessions.finished_buyer_entries_exp_qual_average), '', '1.1-1')
        }
      ];
    }
    return [];
  }

  private today = new Date();
  public isNew(dashboard: Dashboard) {
    const created = new Date(dashboard.created_at).getTime();
    const maxAge = 1000 * 60 * 60 * 2; //2 hours
    const now = this.today.getTime();
    return now - created < maxAge;
  }

  private recentList: Dashboard[] = [];
  private recentList$: BehaviorSubject<Dashboard[]> = new BehaviorSubject(this.recentList);
  private setRecentList(list: Dashboard[] = this.recentList) {
    this.recentList$.next(this.recentList = this.mapDashboards(list));
  }

  public getRecent(): Observable<Dashboard[]> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'dashboards/recent' + this.password;
        return this.api.get(url, null, this.supplyOptimizerUrl)
          .pipe(switchMap((resp: Dashboard[]) => {
            this.setRecentList(resp && resp.length ? resp.reverse() : []);
            return this.recentList$.asObservable();
          }));
      }));
  }

  public getCurrent(): Observable<Dashboard[]> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'dashboards/current' + this.password;
        return this.api.get(url, null, this.supplyOptimizerUrl, 'all')
          .pipe(switchMap((resp: HttpResponse<any>) => {
            if (resp.status === 200) {
              return of(<Array<Dashboard>>resp.body.data);
            }
            if (resp.status === 204) {
              return throwError(new Error('No dashboards available'));
            }
            return throwError(new Error('Error fetching dashboards'));
          }))
      }));
  }

  public getByID(id: string): Observable<Dashboard> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'dashboard/' + id + this.password;
        return this.api.poll<Dashboard>(url, (resp: Dashboard) => resp && resp.populated, null, this.supplyOptimizerUrl)
          .pipe(map((dashboard: Dashboard) => {
            if (!dashboard) {
              return null;
            }
            const mappedDashboard = this.mapDashboards([dashboard])[0];
            if (this.recentList.length) {
              const index = this.recentList.findIndex((d => d.id === dashboard.id));
              if (index > -1) {
                this.recentList[index] = mappedDashboard;
              }
              else {
                this.recentList.unshift(mappedDashboard);
              }
              this.setRecentList();
            }
            return mappedDashboard;
          }));
      }));
  }

  private generating: boolean;
  public generateNew(newDashboard: Dashboard): Observable<Dashboard> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        //if (this.generating) {
        //  return of(null);
        //}
        this.generating = true;
        const url = 'dashboards/new' + this.password;
        return this.api.post(url, { start: newDashboard.start_time, end: newDashboard.end_time, panel_name: newDashboard.panel_name } as any, false, this.supplyOptimizerUrl)
          .pipe(map((dashboard: Dashboard) => {
            this.generating = false;
            if (dashboard && dashboard.id) {
              newDashboard.id = dashboard.id;
              this.snackBar.open("Dashboard generating... This might take a few minutes.");
              if (this.recentList.length) {
                this.recentList.unshift(newDashboard);
                this.setRecentList();
              }
              //return this.getByID(dashboard.id);
              return dashboard;
            }
            return null;
          }));
      }));
  }

  public cancelPoll(): void {
    this.api.cancelPoll();
  }
}
