  <app-dialog>
    <div dialog-title>Login</div>
    <div dialog-content>

      <app-login-form #loginForm [showErrors]="false" (success)="onSuccess($event)" (error)="onError($event)" (setBusy)="setBusy($event)"></app-login-form>
    
    </div>
    <div dialog-actions class="btn-row pb-2">
      <button type="button" id="loginComponent_cancelButton" mat-stroked-button color="warn" (click)="close()" class="mr-3">Cancel</button>
      <button type="submit" id="loginComponent_saveButton" mat-stroked-button color="primary" (click)="loginForm.login()">Login</button>
    </div>
  </app-dialog>
