import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService, AuthService, DialogService, LoggerService } from 'src/app/services';


@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user.component.html'
})
export class DeleteUserDialog implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public form: FormGroup;
  public errorMsg: string;
  public uid: string;
  public title: string = 'Are you sure you want to delete this user?';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private logger: LoggerService,
    private alert: AlertService,
    public dialogRef: MatDialogRef<DeleteUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public deleteUser() {
    this.dialogService.setBusy();
    this.subs.push(
      this.authService.deleteUser(this.uid).subscribe(resp => {
        this.alert.success("User successfully deleted.")
        this.dialogService.setBusy(false);
        this.close();
      }, err => {
        this.dialogService.setBusy(false);
        this.errorMsg = err.message;
      })
    );
  }
  
  public close(url: string = ''): void {
    this.dialogRef.close();
    this.dialogService.close();
  }
  
  ngOnInit() {
    this.dialogService.setBusy();
    this.dialogService.setCurrentDialog(this.dialogRef);
    
    this.subs.push(
      this.route.queryParams.subscribe(params => {
        if (params.id) {
          this.uid = params.id;
          this.dialogService.setBusy(false);
        }
        else if (!this.uid) {
          this.close();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
