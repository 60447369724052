import { Action } from '@ngrx/store';
import { PanelAdminSurvey, PanelAdminSurveyBase, PanelAdminSurveyFilters } from '../../components/product-tests/product-tests.models';

export enum Types {
  Get                 = '[ProductTestSurvey] Get',
  GetSuccess          = '[ProductTestSurvey] GetSuccess',
  GetError            = '[ProductTestSurvey] GetError',

  GetAll              = '[ProductTestSurvey] GetAll',
  GetAllSuccess       = '[ProductTestSurvey] GetAllSuccess',
  GetAllError         = '[ProductTestSurvey] GetAllError',

  GetArchived         = '[ProductTestSurvey] GetArchived',
  GetArchivedSuccess  = '[ProductTestSurvey] GetArchivedSuccess',

  Create              = '[ProductTestSurvey] Create',
  CreateSuccess       = '[ProductTestSurvey] CreateSuccess',
  CreateError         = '[ProductTestSurvey] CreateError',

  Delete              = '[ProductTestSurvey] Delete',
  DeleteSuccess       = '[ProductTestSurvey] DeleteSuccess',
  DeleteError         = '[ProductTestSurvey] DeleteError',

  Update              = '[ProductTestSurvey] Update',
  UpdateSuccess       = '[ProductTestSurvey] UpdateSuccess',
  UpdateError         = '[ProductTestSurvey] UpdateError',

  Clear               = '[ProductTestSurvey] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor(public filters?: string) {
    console.log("Initiate: " + this.type);
  }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: PanelAdminSurvey[], public filters?: string) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() {
    console.log("Initiate: " + this.type);
  }
}

export class GetArchivedSuccess implements Action {
  readonly type = Types.GetArchivedSuccess;
  constructor(public payload: PanelAdminSurvey[]) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) {
    console.log("Initiate: " + this.type);
  }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: PanelAdminSurvey) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: PanelAdminSurvey) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: PanelAdminSurvey) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: string, public payload: PanelAdminSurveyBase) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: PanelAdminSurvey) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetArchived | GetArchivedSuccess | GetAll | GetAllSuccess | GetAllError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
