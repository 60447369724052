import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { LoggerService } from './logger.service';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(
    private logger: LoggerService,
    private snackBar: MatSnackBar,
  ) {}

  public info(message: string) {
    this.logger.info('AlertService.info: ' + message);
    this.snackBarRef = this.snackBar.open(message, null, { verticalPosition: 'top', panelClass: 'snackbar-info' });
  }

  public success(message: string) {
    this.logger.log('AlertService.success: ' + message);
    this.snackBarRef = this.snackBar.open(message, null, { verticalPosition: 'top', panelClass: 'snackbar-success' });
  }

  public failure(message: string) {
    this.logger.warn('AlertService.failure: ' + message);
    this.snackBarRef = this.snackBar.open(message, null, { verticalPosition: 'top', panelClass: 'snackbar-error', duration: 5000 });
  }
}
