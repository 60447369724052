import * as BaseModels from '../../base.models';
import { SampleFilterBase } from '../../sample.models';
import { CommunityReward } from '../community/community.models';

export interface ProductTestProductBase extends BaseModels.APIBase {
  name?: string;
  main_image?: string;
  other_images?: string[];
  brand_id?: string | number;
  sku?: string;
  brand_name?: string;
  title_short?: string;
  title_long?: string;
  product_page?: string;
  open?: boolean;
  paused?: boolean;
  price?: string;
  bonus_reward?: number;
  nudge_rate_1?: number;
  nudge_rate_2?: number;
  nudge_rate_3?: number;
  nudge_rate_4?: number;
  nudge_rate_5?: number;
  created_at?: string;
  updated_at?: string;
  amazon?: boolean;
}

export class ProductTestProduct extends BaseModels.ApiDefaults implements ProductTestProductBase {
  name: string;
  main_image: string;
  other_images: string[];
  brand_id: string | number;
  brand_name: string;
  sku: string;
  title_short: string;
  title_long: string;
  product_page: string;
  open: boolean;
  paused: boolean;
  price: string;
  bonus_reward: number;
  nudge_rate_1: number;
  nudge_rate_2: number;
  nudge_rate_3: number;
  nudge_rate_4: number;
  nudge_rate_5: number;
  created_at: string;
  updated_at: string;
  amazon: boolean;

  constructor(data?: ProductTestProductBase) {
    super([
      'name',
      'main_image',
      'other_images',
      'brand_id',
      'brand_name',
      'sku',
      'title_short',
      'title_long',
      'product_page',
      'archived',
      'open',
      'paused',
      'price',
      'bonus_reward',
      'nudge_rate_1',
      'nudge_rate_2',
      'nudge_rate_3',
      'nudge_rate_4',
      'nudge_rate_5',
      'created_at',
      'updated_at',
      'amazon',
    ], data);
  }
}

export interface ProductTestBrandBase extends BaseModels.APIBase {
  name?: string;
  brand_seller_id?: number;
  created_at?: string;
  updated_at?: string;
}

export class ProductTestBrand extends BaseModels.ApiDefaults implements ProductTestBrandBase {
  name: string;
  brand_seller_id: number;
  created_at: string;
  updated_at: string;

  constructor(data?: ProductTestBrandBase) {
    super([
      'name',
      'brand_seller_id',
      'created_at',
      'updated_at',
    ], data);
  }
}

export interface ProductTestSellerBase extends BaseModels.APIBase {
  name?: string;
  created_at?: string;
  updated_at?: string;
}

export class ProductTestSeller extends BaseModels.ApiDefaults implements ProductTestSellerBase {
  name: string;
  created_at: string;
  updated_at: string;

  constructor(data?: ProductTestSellerBase) {
    super([
      'name',
      'created_at',
      'updated_at',
    ], data);
  }
}

export interface ProductTestTestBase extends BaseModels.APIBase {
  name?: string;
  created_at?: string;
  updated_at?: string;
  canceled?: boolean;
  finished?: boolean;
  invitation_id?: number;
  open?: boolean;
  order_num?: string;
  order_num_raw?: string;
  order_type?: CampaignOrderType;
  order_validation_status?: CampaignOrderStatus;
  ordered?: boolean;
  ordered_at?: string;
  price?: string;
  product_id?: number;
  product_test_campaign_id?: number;
  reward_id?: number;
  sfb_instructions?: string;
  survey_date?: string;
  test_days?: number;
  two_step_url?: string;
  two_step_url_num?: number;
  user_id?: number;
}

export class ProductTestTest extends BaseModels.ApiDefaults implements ProductTestTestBase {
  name: string;
  created_at: string;
  updated_at: string;

  canceled: boolean;
  finished: boolean;
  invitation_id: number;
  open: boolean;
  order_num: string;
  order_num_raw: string;
  order_type: CampaignOrderType;
  order_validation_status: CampaignOrderStatus;
  ordered: boolean;
  ordered_at: string;
  price: string;
  product_id: number;
  product_test_campaign_id: number;
  reward_id: number;
  sfb_instructions: string;
  survey_date: string;
  test_days: number;
  two_step_url: string;
  two_step_url_num: number;
  user_id: number;
  reward: CommunityReward;

  constructor(data?: ProductTestTestBase) {
    super([
      'name',
      'created_at',
      'updated_at',
      'canceled',
      'finished',
      'invitation_id',
      'open',
      'order_num',
      'order_num_raw',
      'order_type',
      'order_validation_status',
      'ordered',
      'ordered_at',
      'price',
      'product_id',
      'product_test_campaign_id',
      'reward_id',
      'sfb_instructions',
      'survey_date',
      'test_days',
      'two_step_url',
      'two_step_url_num',
      'user_id',
    ], data);
  }
}

export interface ProductTestPagerFilters {
  product_test_campaign_id?: string;
  canceled?: boolean;
  ordered?: boolean;
  finished?: boolean;
  order_validation_status?: CampaignOrderStatus;
  product_id?: string;
}

export interface PanelAdminSurveyBase extends BaseModels.APIBase {
  append_hashed_url?: boolean;
  auto_schedule_reminders?: boolean;
  cpi?: string;
  created_at?: string;
  credit_for_loyalty?: boolean;
  default_filter?: SampleFilterBase;
  default_length?: number;
  default_link?: string;
  default_link_params?: string[];
  default_reward_value?: string;
  default_topic?: string;
  dynamic_reward_value?: boolean;
  easy_exit_links?: boolean;
  exit_key_qualified?: string;
  exit_key_unqualified?: string;
  fa_id?: string;
  forthright?: boolean;
  invitation_template_id?: string;
  invite_by_email?: boolean;
  invite_by_sms?: boolean;
  mobile_friendly?: boolean;
  name?: string;
  notes?: string;
  notes_last_saved_at?: string;
  number?: number;
  open?: boolean;
  paused?: boolean;
  post_survey_id?: string;
  prescreener_filter?: any;
  prescreener_id?: string;
  preval?: boolean;
  profiler_id?: string;
  questions_to_pass?: string[];
  quota?: number;
  revenue?: string;
  salt?: any;
  skip_profilers?: boolean;
  special_note?: string;
  token?: string;
  topic_category?: string;
  unique_cookie?: boolean;
  unique_ip?: boolean;
  unique_ip_and_ua?: boolean;
  updated_at?: string;
}

export interface PanelAdminSurveyFilters {
  archived?: boolean;
  forthright?: boolean;
}

export class PanelAdminSurvey extends BaseModels.ApiDefaults implements PanelAdminSurveyBase {
  created_at: string;
  updated_at: string;
  name: string;
  default_link: string;
  default_link_params: string[];
  salt: any;
  open: boolean;
  default_length: number;
  default_topic: string;
  default_reward_value: string;
  post_survey_id: string;
  preval: boolean;
  cpi: string;
  mobile_friendly: boolean;
  auto_schedule_reminders: boolean;
  credit_for_loyalty: boolean;
  paused: boolean;
  quota: number;
  revenue: string;
  easy_exit_links: boolean;
  special_note: string;
  notes: string;
  notes_last_saved_at: string;
  prescreener_id: string;
  prescreener_filter: any;
  default_filter: SampleFilterBase;
  unique_cookie: boolean;
  unique_ip: boolean;
  unique_ip_and_ua: boolean;
  token: string;
  number: number;
  forthright: boolean;
  topic_category: string;
  invitation_template_id: string;
  profiler_id: string;
  skip_profilers: boolean;
  questions_to_pass: string[];
  invite_by_email: boolean;
  invite_by_sms: boolean;
  dynamic_reward_value: boolean;
  append_hashed_url: boolean;
  fa_id: string;
  exit_key_qualified: string;
  exit_key_unqualified: string;

  constructor(data?: PanelAdminSurveyBase) {
    super([
      'name',
      'created_at',
      'updated_at',
      'default_link',
      'default_link_params',
      'salt',
      'open',
      'default_length',
      'default_topic',
      'default_reward_value',
      'post_survey_id',
      'preval',
      'cpi',
      'mobile_friendly',
      'auto_schedule_reminders',
      'credit_for_loyalty',
      'paused',
      'quota',
      'revenue',
      'easy_exit_links',
      'special_note',
      'notes',
      'notes_last_saved_at',
      'prescreener_id',
      'prescreener_filter',
      'default_filter',
      'unique_cookie',
      'unique_ip',
      'unique_ip_and_ua',
      'token',
      'number',
      'forthright',
      'topic_category',
      'invitation_template_id',
      'profiler_id',
      'skip_profilers',
      'questions_to_pass',
      'invite_by_email',
      'invite_by_sms',
      'dynamic_reward_value',
      'append_hashed_url',
      'fa_id',
      'exit_key_qualified',
      'exit_key_unqualified',
    ], data);
  }
}

export type CampaignOrderStatus = '' | 'pending' | 'passed' | 'failed';
export const CampaignOrderStatuses = [{ value: 'pending', viewValue: "Pending" }, { value: 'passed', viewValue: "Passed" }, { value: 'failed', viewValue: "Failed" }];
export const CampaignOrderStatusMap = {
  pending: { value: 'pending', viewValue: "Pending" },
  passed: { value: 'passed', viewValue: "Passed" },
  failed: { value: 'failed', viewValue: "Failed" }
};

export type CampaignOrderType = '' | 'direct' | 'two_step' | 'sfb';
export interface CampaignFilters {
  includes?: any;
  excludes?: any;
}

export interface ProductTestCampaignBase extends BaseModels.APIBase {
  created_at?: string;
  updated_at?: string;
  active?: boolean;
  paused?: boolean;
  order_type?: CampaignOrderType;
  product_id?: string;
  start_date?: string;
  end_date?: string;
  max_orders?: number;
  max_orders_per_day?: number;
  profile_criteria?: CampaignFilters;
}

export class ProductTestCampaign extends BaseModels.ApiDefaults implements ProductTestCampaignBase {
  created_at: string;
  updated_at: string;
  active: boolean;
  paused: boolean;
  order_type: CampaignOrderType;
  product_id: string;
  start_date: string;
  end_date: string;
  max_orders: number;
  max_orders_per_day: number;
  profile_criteria: CampaignFilters;

  sfb_instructions: string;
  two_step_url_1: string;
  two_step_url_2: string;
  two_step_url_3: string;
  two_step_percent_1: string;
  two_step_percent_2: string;
  two_step_percent_3: string;

  survey_id: string;
  min_test_days: number;
  max_test_days: number;

  constructor(data?: ProductTestCampaignBase) {
    super([
      'name',
      'created_at',
      'updated_at',
      'active',
      'paused',
      'order_type',
      'product_id',
      'start_date',
      'end_date',
      'max_orders',
      'max_orders_per_day',
      'profile_criteria',

      'sfb_instructions',
      'two_step_url_1',
      'two_step_url_2',
      'two_step_url_3',
      'two_step_percent_1',
      'two_step_percent_2',
      'two_step_percent_3',

      'survey_id',
      'min_test_days',
      'max_test_days',
    ], data);
    this.profile_criteria = this.profile_criteria || {
      includes: {},
      excludes: {},
    };
  }
}
