import { Action } from '@ngrx/store';
import { CommunityMember } from '../../components/community/community.models';

export enum Types {
  Get                      = '[CommunityMember] Get',
  GetSuccess               = '[CommunityMember] GetSuccess',
  GetError                 = '[CommunityMember] GetError',
                           
  GetAll                   = '[CommunityMember] GetAll',
  GetAllSuccess            = '[CommunityMember] GetAllSuccess',
  GetAllError              = '[CommunityMember] GetAllError',
                           
  Create                   = '[CommunityMember] Create',
  CreateSuccess            = '[CommunityMember] CreateSuccess',
  CreateError              = '[CommunityMember] CreateError',
                           
  Delete                   = '[CommunityMember] Delete',
  DeleteSuccess            = '[CommunityMember] DeleteSuccess',
  DeleteError              = '[CommunityMember] DeleteError',
                           
  Update                   = '[CommunityMember] Update',
  UpdateSuccess            = '[CommunityMember] UpdateSuccess',
  UpdateError              = '[CommunityMember] UpdateError',
                           
  Clear                    = '[CommunityMember] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() {
    console.log("Initiate: " + this.type);
  }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: CommunityMember[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: CommunityMember) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: CommunityMember) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: CommunityMember) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: string, public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: CommunityMember) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any =
    Get    | GetSuccess    | GetError
  | GetAll | GetAllSuccess | GetAllError
  | Delete | DeleteSuccess | DeleteError
  | Create | CreateSuccess | CreateError
  | Update | UpdateSuccess | UpdateError
  | Clear;
