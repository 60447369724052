import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SellerActions from './product-test-seller.actions';
import { ProductTestSellerService } from '../../services/panel-admin/product-test/product-test-seller.service';

@Injectable()
export class SellerEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(SellerActions.Types.GetAll),
      mergeMap(() => this.service.getAll()
        .pipe(
          map(sellers => (new SellerActions.GetAllSuccess(sellers))),
          catchError((err: Error) => of(new SellerActions.GetAllError(err)))
        ))
    ));

  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(SellerActions.Types.GetArchived),
      mergeMap(() => this.service.getAll(true)
        .pipe(
          map(sellers => (new SellerActions.GetArchivedSuccess(sellers))),
          catchError((err: Error) => of(new SellerActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(SellerActions.Types.Get),
      mergeMap((action: SellerActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(seller => (seller ? new SellerActions.GetSuccess(seller) : new SellerActions.GetError(action.id, new Error("Unknown Error")))),
          catchError((err: Error) => of(new SellerActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(SellerActions.Types.Create),
      mergeMap((action: SellerActions.Create) => this.service.create(action.payload)
        .pipe(
          map((seller) => (new SellerActions.CreateSuccess(seller))),
          catchError((err: Error) => of(new SellerActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(SellerActions.Types.Update),
      mergeMap((action: SellerActions.Update) => this.service.update(action.id, action.payload)
        .pipe(
          map((seller) => (new SellerActions.UpdateSuccess(seller))),
          catchError((err: Error) => of(new SellerActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(SellerActions.Types.Delete),
      mergeMap((action: SellerActions.Delete) => this.service.archive(action.id)
        .pipe(
          map(() => (new SellerActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new SellerActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: ProductTestSellerService
  ) { }
}
