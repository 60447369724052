import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, share, skipWhile, take } from 'rxjs/operators';
import { Answer, AnswerOptions, AWSResponse, ErrorGroup, Question, RandomOrderResponseObjectType } from 'src/app/data.models';
import * as ProfileQuestionActions from 'src/app/store/profile/profile-question.actions';
import { RootStore } from 'src/app/store/store';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { flattenDeep, range, forEach, concat, flatMapDeep } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  private endpoint: string = 'question';

  public utilities = new QuestionUtilities();
    getRetryTimer: any;

  constructor(
    private api: ApiService,
    private store: Store<RootStore>,
    private logger: LoggerService
  ) { }

  private setDefaults(question: Question) {
    question.allowMultiple = !!question.allowMultiple;
    question.answerOptionOrder = question.answerOptionOrder || null;
    question.baseLogic = question.baseLogic || null;
    //question.regExValidation = question.regExValidation || "";
    return question;
  }

  /**
   * @method QuestionService.create
   * @description Creates a new question via POST
   * @param {Question} q The question to be created
   * @returns Observable<Question[]>
   */
  public create(q: Question): Observable<Question> {
    const errors = this.checkErrors(q);
    if (errors.length) {
      return throwError(errors);
    }
    q = this.setDefaults(q);
    return this.api.post(this.endpoint, q)
      .pipe(map((question: Question) => {
        this.logger.log('QuestionService.createQuestion: response:' + question)
        //this.getAllQuestions();
        if (question) {
          this.store.dispatch(new ProfileQuestionActions.CreateSuccess(question));
        }
        return question;
      }), catchError(error => {
        this.store.dispatch(new ProfileQuestionActions.CreateError(error));
        return throwError([{ error: error, field: 'all' }]);
      }));
  }

  /**
   * @method QuestionService.update
   * @description Updates an existing question for a given ID via PUT
   * @param {Question} q The question to be updated
   * @returns Observable<Question[]>
   */
  public update(q: Question): Observable<Question> {
    q = this.setDefaults(q);
    return this.api.put(this.endpoint, q)
      .pipe(map((resp: Question) => {
        const question = resp as Question;
        this.store.dispatch(new ProfileQuestionActions.UpdateSuccess(question));
        return question;
      }), catchError(errorGroup => {
        if (errorGroup.message) {
          this.store.dispatch(new ProfileQuestionActions.UpdateError(q.id, new Error(errorGroup.message)));
          return throwError([{ error: errorGroup.message, field: "all" }]);
        }
        this.store.dispatch(new ProfileQuestionActions.UpdateError(q.id, new Error("An error occurred.")));
        const errors = [];
        for (let err in errorGroup.error.errors) {
          errors.push({ error: errorGroup.error.errors[err], field: err });
        }
        return throwError(errors);
      }));
  }

  /**
   * @method QuestionService.getAllQuestions
   * @description Retrieves an array of all available questions from the API, and updates the local cache.
   * @param {string} categories A comma-separatedlist of categories to filter by
   * @returns Observable<Question[]>
   */
  public getAll(categories?: string, internal?: boolean): Observable<Question[]> {
    return this.api.get(this.endpoint, categories ? { categories: categories } : {})
      .pipe(
        map((resp: AWSResponse<Question>) => {
          const questions = resp.Items as Question[];
          if (questions && internal) {
            this.store.dispatch(new ProfileQuestionActions.GetAllSuccess(questions));
          }
          return questions;
        }),
        catchError((err: Error) => throwError(new Error(err.message || "An error occurred."))),
        share()
      );
  }

  /**
   * @method QuestionService.getByID
   * @description Retrieves a question for a given ID
   * @param {string} id The ID of the question to be retrieved
   * @returns Observable<Question>
   */
  public getByID(id: string): Observable<Question> {
    return this.api.get(this.endpoint, { id: id })
      .pipe(map((resp: Question) => {
        if (resp) {
          return resp;
        }
        return null;
      }));
  }

  questionObsMap: { [name: string]: Observable<Question> } = {};

  /**
   * @method QuestionService.getByName
   * @description Retrieves a question with a given name
   * @param {string} name The name of the question to be retrieved
   * @returns Observable<Question>
   */
  public getByName(name: string, retry?: boolean): Observable<Question> {
    if (!retry && this.questionObsMap[name]) {
      return this.questionObsMap[name];
    }
    return this.questionObsMap[name] = this.store.pipe(
      select((state) => state.questions.mapByName),
      map((map) => {
        const question = map[name];
        if (question) {
          return question;
        }
        this.logger.warn("No question found in store with name '" + name + "'. Force GET from server.");
        if (!retry && !this.getRetryTimer) {
          this.store.dispatch(new ProfileQuestionActions.GetAll());
          this.getRetryTimer = setTimeout(() => {
            if (this.getRetryTimer) {
              clearTimeout(this.getRetryTimer);
              this.getRetryTimer = null;
            }
          }, 100);
        }
        return null;
      }),
      catchError((err: Error) => {
        return of(null);
      })
    )
  }

  public getByCategory(categories?: string[]): Observable<Question[]> {
    return this.getAll(null, true).pipe(skipWhile((resp: Question[]) => !resp || !resp.length), take(1), map((questions: Question[]) => {
      if (questions && questions.length) {
        return questions.filter((q: Question) => {
          let include = true;
          for (let category of categories) {
            if (!q.categories.includes(category)) {
              include = false;
            }
          }
          return include;
        })
      }
      return [];
    }));
  }

  /**
   * @method QuestionService.checkErrors
   * @description Looks through a question for a list of known defects prior to serve submission and returns a list of errors if any are found
   * @param {Question} q The question to be checked
   * @returns ErrorGroup[]
   */
  public checkErrors(q: Question): ErrorGroup[] {
    const errors = [];
    if (q.name.includes(" ")) {
      errors.push({
        field: 'name',
        error: new Error("Name cannot contain spaces")
      });
    }
    return errors;
  }

  /**
   * @method QuestionService.delete
   * @description Retrieves a question for a given ID
   * @param {string} id The ID of the question to be retrieved
   * @returns Observable<Question[]>
   */
  public delete(id: string): Observable<Question> {
    return this.api.delete(this.endpoint, { id: id }).pipe(
      map((resp: AWSResponse) => {
        this.logger.log('QuestionService.deleteQuestion: response:' + resp);
        this.store.dispatch(new ProfileQuestionActions.DeleteSuccess(id));
        return null;
      }),
      catchError((err: Error) => {
        this.store.dispatch(new ProfileQuestionActions.DeleteError(id, new Error(err.message || "An error occurred.")));
        return throwError(err)
      })
    );
  }
}


export class QuestionUtilities {
  public calculateOrder(pattern, numOfOptions): Promise<number[]> {
    return new Promise<number[]>((resolve, reject) => {
      const firstCharacter = pattern.charAt(0);
      // forces all patterns to be surrounded by parentheses
      if (Number(firstCharacter)) {
        pattern = `(${pattern})`;
      }
      // for now the only possible operation is to randomize the order
      this.executeRandom(pattern, [], 0, null, (randomResponseObject: RandomOrderResponseObjectType) => {
        if (randomResponseObject.error) {
          return reject(randomResponseObject);
        }
        const array = flattenDeep(randomResponseObject.array) as number[];
        if (array.length !== numOfOptions) {
          const optionsPositions = range(1, (numOfOptions + 1));
          forEach(optionsPositions, (position) => {
            if (array.indexOf(position) === -1) {
              array.push(position);
            }
          });
        }
        return resolve(array);
      });
    });
  }

  public executeRandom(pattern: string, numbers: any[], index: number, sequenceType: any, callback: Function): any {

    // stop condition of the recursive function
    if (index >= pattern.length) {
      if (sequenceType && sequenceType !== pattern[index - 1]) {
        return callback({ error: 'Incorrect syntax', array: null });
      }
      return callback({ error: null, array: numbers });
    }
    const character = pattern.charAt(index);
    // detect the begining of a pattern
    if (character === '[' || character === '(') {
      const currentSequenceType = character;
      const obj = this.executeRandom(pattern, [], ++index, currentSequenceType, callback);
      if (obj.error) {
        return callback(obj);
      }
      const array = obj.array;
      let lastIndex = obj.lastIndex;
      numbers.push(array);

      return this.executeRandom(pattern, numbers, ++lastIndex, sequenceType, callback);
    }

    // detect the ending of a pattern for ]
    if (character === ']') {
      const afterChar = pattern.charAt(index - 1);
      if (sequenceType !== '[' || (afterChar === ',' || afterChar === '(' || afterChar === '[')) {
        return { error: 'Incorrect syntax', array: null };
      }
      numbers.sort((a, b) => { return 0.5 - Math.random(); });
      return { error: null, array: numbers, lastIndex: index };
    }

    // detect the ending of a pattern for )
    if (character === ')') {
      const afterChar = pattern.charAt(index - 1);
      if (sequenceType !== '(' || (afterChar === ',' || afterChar === '(' || afterChar === '[')) {
        return { error: 'Incorrect syntax', array: null };
      }
      return { error: null, array: numbers, lastIndex: index };
    }

    // detect sequences of numbers
    if (character === ',') {
      const afterChar = pattern.charAt(index - 1);
      if (afterChar === ',' || afterChar === '[' || afterChar === '(') {
        return callback({ error: 'Incorrect syntax', array: null });
      }
      return this.executeRandom(pattern, numbers, ++index, sequenceType, callback);
    }

    // detect ranges of numbers
    if (character === '.') {
      const isNextPoint = pattern.charAt(++index);
      const isEndRangeNumber = Number(pattern.charAt(++index));
      if (isNextPoint === '.' && isEndRangeNumber) {
        const numberAndIndex = this.getPossibleNumberFromExp(pattern, index);
        const { number, lastIndex } = numberAndIndex;
        index = lastIndex;
        const initRangeNumber = numbers[numbers.length - 1];
        if (!number) {
          return callback({ error: 'Incorrect syntax', array: null });
        }
        if (initRangeNumber >= number) {
          const error = 'For calculate a range, the second number should be greater than first number';
          return callback({ error, array: null });
        }
        const generatedRange = range((initRangeNumber + 1), (number + 1));
        numbers = concat(numbers, generatedRange);
        return this.executeRandom(pattern, numbers, ++index, sequenceType, callback);
      }
      return callback({ error: 'Incorrect syntax', array: null });
    }

    const numberPossible = Number(character);
    const afterChar = pattern.charAt(index - 1);
    if (numberPossible && (afterChar === ',' || afterChar === '[' || afterChar === '(')) {
      const numberAndIndex = this.getPossibleNumberFromExp(pattern, index);
      const { number, lastIndex } = numberAndIndex;
      index = lastIndex;
      numbers.push(number);
      return this.executeRandom(pattern, numbers, ++index, sequenceType, callback);
    }
    return callback({ error: 'Incorrect syntax', array: null });
  }

  getPossibleNumberFromExp(pattern: string, index: number) {
    let numberPossible = Number(pattern.charAt(index));
    let numberString = '';
    while (numberPossible || numberPossible === 0) {
      numberString += pattern.charAt(index);
      index += 1;
      if (index >= pattern.length) {
        break;
      }
      numberPossible = Number(pattern.charAt(index));
    }
    const number = Number(numberString);
    return { number, lastIndex: --index };
  }

  flattenAnswerOptions(options: AnswerOptions[]): Answer[] {
    return flatMapDeep<AnswerOptions, Answer>(options, (v, i) => {
      return flatMapDeep(v, (opt, id) => ({ text: opt, id: id, value: opt.toLowerCase() === 'true' ? true : opt.toLowerCase() === 'false' ? false : id }))
    });
  }
}


//let q = {
//  allowMultiple: false,
//  answerOptionOrder: null,
//  answerOptions: {},
//  categories: ["extended", "sample-filter"],
//  customerId: "56d344a0-18df-11e8-b6ef-e15b7d310e43",
//  derivationLogic: [],
//  id: "51034080-2eeb-11e8-91e5-bb7296eb3d6a",
//  name: "political_party_preference",
//  prompt: { "en-US": "Generally speaking, do you think of yourself as a..." },
//  type: "multi_choice",
//}
