import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, share, tap } from 'rxjs/operators';
import { SampleDistribution } from 'src/app/sample.models';
import { SampleDistributionService } from 'src/app/services/sample/sample-distribution.service';
import * as SampleDistributionActions from './sample-distribution.actions';

@Injectable()
export class SampleDistributionEffects {

  queue: { [key: string]: Observable<SampleDistribution[]> } = {};
  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleDistributionActions.Types.GetArchived),
      mergeMap(() => this.service.getAll()
        .pipe(
          map(distributions => (new SampleDistributionActions.GetAllSuccess(distributions))),
          catchError((err: Error) => of(new SampleDistributionActions.GetAllError(err)))
        ))
    ));

  
  constructor(
    private actions$: Actions,
    private service: SampleDistributionService
  ) { }
}
