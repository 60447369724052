import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Logger } from './logger.service';

export let isDebugMode = !environment.production;

const noop = (): any => undefined;

@Injectable()
export class ConsoleLoggerService implements Logger {

  private getLogFn(type) {
    const logFn: Function = (console)[type] || console.log || noop;
    if (isDebugMode) {
      return logFn.bind(console || this);
    } else {
      return noop;
    }
  }
  
  get log() {
    return this.getLogFn('log');
  }
  
  get groupEnd() {
    return this.getLogFn('groupEnd');
  }

  get groupCollapsed() {
    return this.getLogFn('groupCollapsed');
  }

  get group() {
    return this.getLogFn('group');
  }

  get info() {
    return this.getLogFn('info');
  }

  get warn() {
    return this.getLogFn('warn');
  }

  get error() {
    return this.getLogFn('error');
  }
  
  invokeConsoleMethod(type: string, args?: any): void {
    const logFn: Function = (console)[type] || console.log || noop;
    logFn.apply(console, [args]);
  }
}
