import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer } from 'src/app/data.models';
import { CustomerService, LoggerService, PanelAdminService, UtilService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class PanelAdminGuard implements CanActivate {

  constructor(
    private customerService: CustomerService,
    private panelAdminService: PanelAdminService,
    private router: Router,
    private util: UtilService,
    private logger: LoggerService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const rootPath = this.panelAdminService.rootPath;
    this.panelAdminService.rootPath = null;
    return this.checkStatus().pipe(map((allowed: boolean) => {
      if (!allowed) {
        this.logger.warn("AdminApiGuard: ACESSS DENIED");
        const redirectUrl = route.pathFromRoot.reduce((prev, curr) => {
          this.panelAdminService.rootPath = this.panelAdminService.rootPath || (curr.url[0] ? '/' + curr.url[0].path : '');
          return prev + curr.url.reduce((prev, curr) => {
            return prev + (curr.path ? '/' + curr.path : '');
          }, '');
        }, '');
        this.panelAdminService.redirectUrl = rootPath !== this.panelAdminService.rootPath || !this.panelAdminService.redirectUrl ? redirectUrl : this.panelAdminService.redirectUrl;
        this.router.navigate([this.panelAdminService.rootPath, 'login']);
      }
      return allowed;
    }));
  }

  checkStatus(): Observable<boolean> {
    //If we have a stored isLoggedIn status we have a user already so lets use that for our tests
    if (this.customerService.currentCustomer) {
      //Return an Observable with the result of whether the user has the correct access level
      return of(this.isLoggedIn(this.customerService.currentCustomer));
    }
    else {
      // Otherwise lets observer the authState in case a login is imminent
      return this.customerService.customer$.pipe(
        map((customer: Customer) => {
          if (!customer) {
            return false;
          }
          return this.isLoggedIn(customer);
        })
      );
    }
  }

  isLoggedIn(customer: Customer): boolean {
    return !!this.panelAdminService.getToken(customer.panelAdminUrl);
  }
}
