import { Component, Input, OnInit } from '@angular/core';
import { SkeletonRow, SkeletonColumn } from 'src/app/data.models';
import { SkeletonService, UtilService, LoggerService } from '../../../services';

@Component({
  selector: 'skeleton-loader',
  templateUrl: './skeleton-loader.component.html'
})
export class SkeletonLoaderComponent implements OnInit {

  viewBox: number;
  speed: number = 1;
  foregroundColor: string = this.skeletonService.foregroundColor;
  backgroundColor: string = this.skeletonService.backgroundColor;
  height: number = 90;
  rowTemplate: any;

  loading: boolean = true;
  @Input('loading') set _loading(loading: boolean) {
    this.loading = !!loading;
  }

  layout: SkeletonRow[] = [];
  @Input('layout') set _layout(layout: SkeletonRow[]) {
    this.height = 0;
    this.layout = layout.map(row => {
      return this.processRow(row);
    });
  }

  constructor(
    private skeletonService: SkeletonService,
    private logger: LoggerService,
    private util: UtilService
  ) { }

  isRow(element: SkeletonRow | SkeletonColumn): element is SkeletonRow {
    return this.skeletonService.isRow(element);
  }

  processRow(r: SkeletonRow, left: number = 0, height: number = this.height, nested?: boolean) {
    const row = new SkeletonRow(this.util.copyObj(true, r.asDataObj || r));
    height += row.top || 0;
    left += row.left || 0;
    let rowHeight = height;
    row.children = row.children.map(child => {
      if (this.skeletonService.isRow(child)) {
        let childRow = this.processRow(child, left, rowHeight, true);
        rowHeight += childRow.height || 0;
        rowHeight += childRow.bottom || 0;
        return childRow;
      }
      left += child.left || 0;
      const elem = this.util.copyObj(true, {
        ...row.asElement,
        ...child,
        height: child.height || row.height,
        top: height + (child.top || 0),
        left: (child.leftUnit === '%' ? left : child.left),
      });
      //this.logger.log(elem);
      left += (child.widthUnit === '%' && child.width ? child.width : 0) + (child.right || 0);
      elem.width = elem.width >= 0 ? elem.width : 0;
      return new SkeletonColumn(elem);
    });
    height += row.height || 0;
    height += row.bottom || 0;
    if (!nested) {
      this.height = height;
    }
    return row;
  }

  ngOnInit() {
  }

}
