import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import * as SampleActions from './sample.actions';
import { SampleDetailsService } from 'src/app/services/sample/sample-details.service'

@Injectable()
export class SampleEffects {

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleActions.Types.Get),
      mergeMap((action: SampleActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(sample => (new SampleActions.GetSuccess(sample))),
          catchError((err: Error) => of(new SampleActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleActions.Types.Create),
      mergeMap((action: SampleActions.Create) => this.service.create(action.payload)
        .pipe(
          map((sample) => (new SampleActions.CreateSuccess(sample))),
          catchError((err: Error) => of(new SampleActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleActions.Types.Update),
      mergeMap((action: SampleActions.Update) => this.service.update(action.payload)
        .pipe(
          map((sample) => (new SampleActions.UpdateSuccess(sample))),
          catchError((err: Error) => of(new SampleActions.UpdateError(action.payload.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: SampleDetailsService
  ) { }
}
