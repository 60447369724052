import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { map, mergeMap, catchError, switchMap, share, tap } from 'rxjs/operators';
import * as ProfileQuestionActions from './profile-question.actions';
import { QuestionService } from 'src/app/services/question.service';
import { Question } from 'src/app/data.models';

@Injectable()
export class ProfileQuestionEffects {

  private queue: { [key: string]: Observable<Question[]> } = {};

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileQuestionActions.Types.GetAll),
      mergeMap(() => (this.queue.all = this.queue.all || this.service.getAll())
        .pipe(
          tap(() => setTimeout(() => delete this.queue.all, 5000)),
          map(questions => (new ProfileQuestionActions.GetAllSuccess(questions))),
          catchError((err: Error) => {
            delete this.queue.all;
            return of(new ProfileQuestionActions.GetAllError(err))
          })
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileQuestionActions.Types.Get),
      mergeMap((action: ProfileQuestionActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(question => (new ProfileQuestionActions.GetSuccess(question))),
          catchError((err: Error) => of(new ProfileQuestionActions.GetError(action.id, err)))
        ))
    ));
  
  constructor(
    private actions$: Actions,
    private service: QuestionService
  ) { }
}
