import { RewardGiveaway } from 'src/app/components/reward/reward.models';
import { extendObj } from 'src/app/data.models';
import * as Reducers from '../reducers';
import * as Actions from './reward-giveaway.actions';

export interface State extends Reducers.State<RewardGiveaway> { }

export const initialState: State = {
  data: null, archive: null, count: 0, map: {}, errors: {}
};

export function reducer(state: State = initialState, action: Actions.Any): State {

  const newState: State = extendObj(true, {}, state);
  let id = "";
  switch (action.type) {

    case Actions.Types.GetSuccess:
    case Actions.Types.UpdateSuccess:
      newState.errors = { ...newState.errors, ...(action.payload ? { [action.payload.id || ""]: null } : {}) };
    case Actions.Types.CreateSuccess:
      if (!action.payload.rewardToken && action.payload.id && state.map[action.payload.id]) {
        action.payload.rewardToken = state.map[action.payload.id].rewardToken || "";
      }
      newState.map = { ...newState.map, ...(action.payload ? { [action.payload.id || ""]: action.payload } : {}) };
      newState.errors = { ...newState.errors, create: null };
      newState.archive = Object.values(newState.map).filter(g => g.archived);
      newState.data = Object.values(newState.map).filter(g => !g.archived);
      break;


    case Actions.Types.GetAllSuccess:
      newState.map = Reducers.map(action.payload);
      newState.data = Object.values(newState.map).filter(g => !g.archived);
      newState.archive = Object.values(newState.map).filter(g => g.archived);
      newState.errors = { ...newState.errors, all: null };
      break;


    case Actions.Types.DeleteSuccess:
      newState.data = (state.data || []).filter((g) => g.id !== action.id);
      newState.map = Reducers.map(newState.data);
      break;

      
    case Actions.Types.GetAllError:
      newState.map = {};
      newState.data = [];
    case Actions.Types.CreateError:
    case Actions.Types.GetError:
    case Actions.Types.DeleteError:
    case Actions.Types.UpdateError:
      console.log(action)
      newState.errors = {
        ...state.errors,
        [action.type === Actions.Types.GetAllError ? 'all'
         : action.type === Actions.Types.CreateError ? 'create'
         : action.id]: action.error
      };
      break;


    case Actions.Types.Clear:
      return initialState;

    default:
      return state;

  }
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  !!newState.data && newState.data.length || (newState.data = null);
  return newState;
}
