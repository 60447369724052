import { Injectable } from '@angular/core';
import { Range, SampleModels } from 'src/app/data.models';
import { ApiService } from '../api.service';
import { LoggerService } from '../logger.service';
import { SampleProjectService } from './sample-project.service';
import { UserQueryService } from '../user-query.service';
import { UtilService } from '../util.service';

@Injectable({
  providedIn: 'root'
})
export class SampleFilterService {

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private userQueryService: UserQueryService,
    private sampleProjectService: SampleProjectService,
    private util: UtilService,
  ) { }

  /**
   * @method SampleFilterService.unpack
   * @description Maps a SampleProjectFilter retrieved from the server into view-data to be draw into the view.
   */
  public unpack(filter: SampleModels.SampleFilterBase, questions: SampleModels.SampleProfileFilterData[], projects?: SampleModels.SampleProjectBase[]): SampleModels.SampleFilterBase {
    //let filter: any = this.util.extendObj(true, {}, f);
    questions.forEach((section) => {
      filter = this.unpackQuestion(filter, section, section.alias);
    });
    return filter;
  }

  public unpackQuestion(filter: SampleModels.SampleFilterBase, section: SampleModels.SampleProfileFilterData, alias: string) {
    if (!filter.excludes && !filter.includes) {
      filter.excludes = {};
      filter.includes = {};
      if (alias) {
        filter.includes[alias] = filter.includes[alias] || {};
        filter.excludes[alias] = filter.excludes[alias] || {};
      }
    }
    if (!filter.excludes) {
      filter.excludes = {};
    }
    if (!filter.includes) {
      filter.includes = {};
    }
    if (alias && !filter.excludes[alias]) {
      filter.excludes[alias] = {};
    }
    if (alias && !filter.includes[alias]) {
      filter.includes[alias] = {};
    }
    const excludes = (!alias ? filter.excludes : filter.excludes[alias]) || {};
    const includes = (!alias ? filter.includes : filter.includes[alias]) || {};
    section.questions.forEach((q) => {
      if (q.type === 'number') {
        q.range = this.setRange(filter, q.alias, alias ? alias : '');
      }
      else {
        q.selected = [];
        if (typeof includes[q.alias] !== "undefined" && includes[q.alias] !== null && includes[q.alias].length) {
          if (q.type === 'long_text' || q.type === 'short_text') {
            q.selected.push({
              text: includes[q.alias].join(', '),
              id: q.selected.length + '',
              included: true
            });
          }
          else {
            includes[q.alias].forEach((v: string) => {
              let find = null;
              v += "";
              if (typeof q.options !== "undefined") {
                find = q.options.find((opt) => (
                  opt.text.toLowerCase() === v.toLowerCase()
                  || opt.id.toLowerCase() === v.toLowerCase()
                  || opt.value === v
                )
                );
              }
              if (!find) {
                find = { text: v, id: v }
                q.options.push(find);
              }
              let existing = q.selected.find((selected) => selected.id === find.id);
              if (!existing) {
                q.selected.push({ ...find, included: true });
              }
            });
          }
        }
        if (typeof excludes[q.alias] !== "undefined" && excludes[q.alias] !== null && excludes[q.alias].length) {
          if (q.type === 'long_text' || q.type === 'short_text') {
            q.selected.push({
              text: excludes[q.alias].join(', '),
              id: excludes[q.alias].join(', '),
              included: false
            });
          }
          else {
            excludes[q.alias].forEach((v: string) => {
              let find = null;
              v += "";
              if (typeof q.options !== "undefined") {
                find = q.options.find((opt) => (
                  opt.text.toLowerCase() === v.toLowerCase()
                  || opt.id.toLowerCase() === v.toLowerCase()
                  || opt.value === v
                )
                );
              }
              if (!find) {
                find = { text: v, id: v }
                q.options.push(find);
              }
              let existing = q.selected.find((selected) => selected.id === find.id);
              if (!existing) {
                q.selected.push({ ...find, included: false });
              }
            });
            q.value = q.selected.map(opt => opt.id);
          }
        }
      }
    });
    if (section.sections) {
      section.sections.forEach((subSection) => {
        filter = this.unpackQuestion(filter, subSection, section.alias || alias);
      });
    }
    return filter;
  }

  public setRange(filter: SampleModels.SampleFilterBase, alias: string, nest?: string): Range {
    let range: Range;
    let excludes = (nest ? filter.excludes[nest] : filter.excludes) || {};
    let includes = (nest ? filter.includes[nest] : filter.includes) || {};
    if (includes[alias] && includes[alias].length) {
      return new Range(
        +includes[alias][0],
        +includes[alias][includes[alias].length - 1],
        true
      );
    }
    if (excludes[alias] && excludes[alias].length) {
      return new Range(
        +excludes[alias][0],
        +excludes[alias][excludes[alias].length - 1],
        false
      );
    }
    return new Range();
  }

  /**
   * @method SampleFilterService.pack
   * @description Maps the local view-data into a SampleProjectFilter to be sent to the server.
   */
  public pack(questions: SampleModels.SampleProfileFilterData[]): SampleModels.SampleFilterBase {
    let packedData: SampleModels.SampleFilterBase = { includes: {}, excludes: {} };
    questions.forEach((section) => {
      if (section.alias) {
        packedData.includes[section.alias] = {};
        packedData.excludes[section.alias] = {};
      }
      packedData = this.packQuestions(section.questions, packedData, section.alias);
      if (section.sections) {
        section.sections.forEach((subSection) => {
          packedData = this.packQuestions(subSection.questions, packedData, subSection.alias || section.alias);
        });
      }
    });
    return packedData;
  }

  /**
   * @method SampleFilterService.packQuestions
   * @description A Utility method implemented by FilterSampleProjectDialog.pack to help map questions into
   * a SampleModels.SampleFilterBase.
   */
  public packQuestions(questions: SampleModels.SampleProfileFilterQuestion[], filter: SampleModels.SampleFilterBase, nest?: string): SampleModels.SampleFilterBase {
    let excludes = nest ? filter.excludes[nest] : filter.excludes;
    let includes = nest ? filter.includes[nest] : filter.includes;

    questions.forEach((q) => {
      if (q.type === 'number') {
        const val = q.range.value;
        if (val.length) {
          if (q.range.include) {
            includes[q.alias] = val;
          }
          else {
            excludes[q.alias] = val;
          }
        }
      }
      else if (q.selected && q.selected.length) {
        q.selected.forEach((a) => {
          if (a.included) {
            includes[q.alias] = this.packQuestion(includes[q.alias] || [], a, q.type);
          }
          else {
            excludes[q.alias] = this.packQuestion(excludes[q.alias] || [], a, q.type);
          }
        });
      }
    });
    return filter;
  }
  public packQuestion(list: any[], a: SampleModels.SampleProfileFilterOption, type: string) {
    if (type === 'long_text' || type === 'short_text') {
      let texts = a.text.split('\n');
      texts.forEach(t => {
        list.push(...t.split(',').map((t: string) => t.trim()));
      })
    }
    else {
      if (typeof a.value !== "undefined") {
        list.push(a.value);
      }
      else {
        list.push(a.text);
      }
    };
    return list;
  }
}
