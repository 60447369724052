import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, catchError, flatMap, takeWhile, skipWhile, retry, takeLast, take } from 'rxjs/operators';

import { User } from 'src/app/data.models';

export class Cache<T = CacheSubject> {
  all: T[] = [];
  active: T[] = [];
  archived: T[] = [];
  all$: BehaviorSubject<T[]> = new BehaviorSubject(null);
  active$: BehaviorSubject<T[]> = new BehaviorSubject(null);
  archived$: BehaviorSubject<T[]> = new BehaviorSubject(null);
  subsets$: BehaviorSubject<CacheSubset<T>>[] = [];

  constructor(list?: T[]) {
    if (list) {
      this.update(list);
    }
  }

  /**
   * @method Cache.get
   * @description Searches for and returns a single item stored in the cache. Returns null if it is not found
   * @param id {string} The id of the item to be returned
   * @returns T | null
   */
  public get(id: string): T | null {
    if (this.all && this.all.length) {
      return this.all.find((v: T) => {
        return this.asSubject(v).id === id;
      });
    }
    return null;
  }

  /**
   * @method Cache.get$
   * @description Like `Cache.get`, but returns as an Observable and therefore refreshes when the item is updated or added. Returns `unndefined` if the item does not exist, and null if the list is uninitialised
   * @param id {string} The id of the item to be observed
   * @returns Observable<T|undefined|null>
   */
  public get$(id: string): Observable<T | undefined | null> {
    if (!id) {
      return of(undefined);
    }
    return this.all$.asObservable().pipe(map((list: T[]) => {
      if (list && list.length) {
        return list.find((v: T) => {
          return this.asSubject(v).id === id;
        })
      }
      return null;
    }));
  }

  /**
   * @method Cache.remove
   * @description Removes an item from the store
   * @param id {string} The id of the item to be removed
   * @returns void
   */
  public remove(id: string): void {
    let index = this.all.findIndex((item: T) => this.asSubject(item).id === id);
    if (index > -1) {
      this.all.splice(index, 1);
      this.refreshLists();
    }
  }

  /**
   * @method Cache.update
   * @description Adds new items to the store and registers an update with any observers
   * @param list {T[]} The items to be added to the store
   * @param merge {boolean} If items with the same ID as one in the new list are found, this flag determines whether they are combined or overwritten
   * @param force {boolean} If true, this will force all items in the new list to be updated in the store, regardless of whether they have changed
   * @returns void
   */
  public update(list: T[], merge?: boolean, force?: boolean): void {
    let hasChanged = false;
    if (this.all && this.all.length) {
      list.forEach((v: T, i: number) => {
        var existing = this.all.findIndex((p: T) => this.asSubject(p).id === this.asSubject(v).id);
        if (existing > -1) {
          if (force || this.isNew(this.asSubject(v), this.asSubject(this.all[existing]))) {
            if (merge) {
              this.all[existing] = Object.assign(this.all[existing], v);
            }
            else {
              this.all[existing] = v;
            }
            hasChanged = true;
          }
        }
        else {
          this.all.unshift(v);
          hasChanged = true;
        }
      })
    }
    else {
      this.all = list.reverse();
      hasChanged = true;
    }
    if (hasChanged || force) {
      this.updateSubsets(list);
      this.refreshLists();
    }
  }

  /**
   * @method Cache.error
   * @description Throws all the subjects into error, and then respawns them with their previous list so any subsequent subscribers get a non-error response
   * @param err {Error} The error to be thrown
   * @returns number
   */
  public error(err: Error) {
    this.all$.error(err);
    this.active$.error(err);
    this.archived$.error(err);

    this.all$ = new BehaviorSubject(this.all);
    this.active$ = new BehaviorSubject(this.active);
    this.archived$ = new BehaviorSubject(this.archived);
  }
  
  /**
   * @method Cache.createSubset
   * @description Creates a new subset of data from the store and returns the ID of the new subset
   * @param list {T[]} The items to be added to the subset
   * @returns number
   */
  public createSubset(list: T[]): number {
    let subset: any = {};
    subset.list = list;
    subset.id = this.subsets$.length - 1;
    subset.contains = list.map((item: any) => item.id);

    this.subsets$.push(new BehaviorSubject(subset));
    return this.subsets$.length - 1;
  }

  /**
   * @method Cache.getSubset
   * @description Returns a subset of items from the store. Returns undefined if it is not found
   * @param id {string} The id of the subset to be returned
   * @returns Observable<CacheSubset<T>> | Observable<undefined>
   */
  public getSubset(id: number): Observable<CacheSubset<T>> | Observable<undefined> {
    return this.subsets$[id] ? this.subsets$[id].asObservable() : of(undefined);
  }

  /**
   * @method Cache.updateSubsets
   * @description Adds new items to a subset and registers an update with any observers
   * @param list {T[]} The items to be added to the store
   * @returns void
   */
  public updateSubsets(list: T[]): void {
    //ToDo: AdamA: This method does not take into account if items are removed. Need to update the subset with removed items
    this.subsets$.forEach(subset => {
      const curr = subset.value;
      let isChanged = false;
      list.forEach((item: any) => {
        const index = curr.list.findIndex((v: any) => v.id === item.id);
        if (index > -1) {
          curr.list[index] = item;
          isChanged = true;
        }
      });
      if (isChanged) {
        subset.next(curr);
      }
    })
  }

  /**
   * @method Cache.clear
   * @description Empties the cache and notifies all observers
   * @returns void
   */
  public clear(): void {
    this.all = [];
    this.refreshLists();
  }

  /**
   * @method Cache.isNew
   * @description Checks whether 2 items are equivalent and if they have been changed, based on their `updated` property
   * @param newSub {CacheSubject} The new item to be checked
   * @param oldSub {CacheSubject} The previous version of the item to be checked against
   * @returns boolean
   */
  private isNew(newSub: CacheSubject, oldSub: CacheSubject): boolean {
    if (newSub.id === oldSub.id) {
      return !newSub.updatedAt || newSub.updatedAt !== oldSub.updatedAt;
    }
    return true;
  }

  /**
   * @method Cache.refreshLists
   * @description Updates the internal BehaviourSubjects, and thus any observers of thi store
   * @returns void
   */
  private refreshLists(): void {
    this.all$.next(this.all);
    this.archived$.next(this.archived = this.all.filter((p: T) => this.asSubject(p).archived))
    this.active$.next(this.active = this.all.filter((p: T) => !this.asSubject(p).archived));
  }

  /**
   * @method Cache.asSubject
   * @description Returns any item as a CacheSubject
   * @returns CacheSubject
   */
  private asSubject(s: any): CacheSubject {
    return s as CacheSubject;
  }
}

export interface CacheSubject {
  id: string;
  archived?: boolean;
  updatedAt?: string; //datetime
  createdAt?: string; //datetime
}


export interface CacheSubset<T = CacheSubject> {
  list: T[];
  id: number;
  contains: string[];
}
