import { Action } from '@ngrx/store';
import * as Models from 'src/app/data.models';

export enum Types {
  Get           = '[Reward] Get',
  GetSuccess    = '[Reward] GetSuccess',
  GetError      = '[Reward] GetError',

  GetAll        = '[Reward] GetAll',
  GetAllSuccess = '[Reward] GetAllSuccess',
  GetAllError   = '[Reward] GetAllError',

  GetArchived   = '[Reward] GetArchived',

  Create        = '[Reward] Create',
  CreateSuccess = '[Reward] CreateSuccess',
  CreateError   = '[Reward] CreateError',

  Delete        = '[Reward] Delete',
  DeleteSuccess = '[Reward] DeleteSuccess',
  DeleteError   = '[Reward] DeleteError',

  Update        = '[Reward] Update',
  UpdateSuccess = '[Reward] UpdateSuccess',
  UpdateError   = '[Reward] UpdateError',

  Clear         = '[Reward] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() { }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: Models.RewardModels.Reward[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: Models.RewardModels.Reward) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: Models.RewardModels.Reward) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: Models.RewardModels.Reward) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public payload: Models.RewardModels.Reward) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: Models.RewardModels.Reward) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetArchived | GetAll | GetAllSuccess | GetAllError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
