import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as CampaignActions from './product-test-campaign.actions';
import { ProductTestCampaignService } from '../../services/panel-admin/product-test/product-test-campaign.service';

@Injectable()
export class CampaignEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(CampaignActions.Types.GetAll),
      mergeMap(() => this.service.getAll()
        .pipe(
          map(campaigns => (new CampaignActions.GetAllSuccess(campaigns))),
          catchError((err: Error) => of(new CampaignActions.GetAllError(err)))
        ))
    ));

  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(CampaignActions.Types.GetArchived),
      mergeMap(() => this.service.getAll(true)
        .pipe(
          map(campaigns => (new CampaignActions.GetArchivedSuccess(campaigns))),
          catchError((err: Error) => of(new CampaignActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(CampaignActions.Types.Get),
      mergeMap((action: CampaignActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(campaign => (campaign ? new CampaignActions.GetSuccess(campaign) : new CampaignActions.GetError(action.id, new Error("Unknown Error")))),
          catchError((err: Error) => of(new CampaignActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(CampaignActions.Types.Create),
      mergeMap((action: CampaignActions.Create) => this.service.create(action.payload)
        .pipe(
          map((campaign) => (new CampaignActions.CreateSuccess(campaign))),
          catchError((err: Error) => of(new CampaignActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(CampaignActions.Types.Update),
      mergeMap((action: CampaignActions.Update) => this.service.update(action.id, action.payload)
        .pipe(
          map((campaign) => (new CampaignActions.UpdateSuccess(campaign))),
          catchError((err: Error) => of(new CampaignActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(CampaignActions.Types.Delete),
      mergeMap((action: CampaignActions.Delete) => this.service.archive(action.id)
        .pipe(
          map(() => (new CampaignActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new CampaignActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: ProductTestCampaignService
  ) { }
}
