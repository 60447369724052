import { Injectable } from '@angular/core';
import { DocumentData, QueryFn } from '@angular/fire/compat/firestore';
import { deleteField, getDocs, doc, query, collection, getFirestore, where } from "firebase/firestore";
import { isEqual } from 'lodash';
import { BehaviorSubject, from, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { AccessList, Customer, User, UserBase, UserRole } from 'src/app/data.models';
import { CustomerService } from './customer.service';
import { AngularFirestore, AngularFirestoreCollection } from './firebase.service';
import { LoggerService } from './logger.service';
import { getFunctions, Functions, httpsCallable } from 'firebase/functions';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private subs: Subscription[] = [];
  private _currentUser: User = null;
  private readonly collectionName = "users";
  private functions: Functions = getFunctions();

  public get currentUser(): User {
    return this._currentUser;
  }
  public set currentUser(user: User) {
    //Emit this new user data to any subscribers and update the stored currentUser
    this.customerService.setCurrent(!!user && user.customerId);
    this.user$.next(this._currentUser = user);
  }

  public user$: BehaviorSubject<User> = new BehaviorSubject(this._currentUser);

  constructor(
    private afStore: AngularFirestore,
    private logger: LoggerService,
    private customerService: CustomerService,
  ) {
    this.setUserAccess();
  }

  /**
   * @method UserService.setUserAccess
   * @description Listens to the current customer on the CustomerService for changes in global access levels.
   * @returns void
   */
  private setUserAccess(): void {
    this.subs.push(
      this.customerService.customer$.pipe(switchMap((customer: Customer) => {
        if (customer && customer.access && this.currentUser) {
          let access: AccessList = this.currentUser.access;
          if (this.currentUser.allowAllServices) {
            access = customer.access;
          }
          else {
            for (let value in customer.access) {
              if (!customer.access[value]) {
                access[value] = false;
              }
            }
          }
          if (!isEqual(access, this.currentUser.access)) {
            this.currentUser.access = access;
            return this.updateUser(this.currentUser.authId, {
              access: {
                ...access,
                optimize: deleteField()
              } as any
            });
          }
        }
        return of(null);
      })).subscribe()
    );
  }

  public hasPermission(role: UserRole, strict?: boolean): boolean {
    if (!this.currentUser) {
      return false;
    }
    if (strict) {
      return this.currentUser.role === role;
    }
    return this.currentUser.role <= role;
  }

  /**
   * @method UserService.users
   * @description Gets a list of users from Firestore
   */
  private collection(queryFn?: QueryFn<DocumentData>): AngularFirestoreCollection<UserBase> {
    return this.afStore.collection(this.collectionName, queryFn);
  }

  /**
   * @method UserService.setUser
   * @description Update or create the entry for a given user in firestore
   */
  public setUser(user: User): Observable<User> {
    return from(this.collection().doc(user.authId).set(user.asDataObj, { merge: true })
      .then((doc) => {
        this.logger.log("User successfully written!");
      })
      .catch(error => {
        this.logger.error("Error writing user: ", error);
        return throwError(new Error("Error writing user: " + error.message))
      })).pipe(map(() => user));
  }

  /**
   * @method UserService.updateUser
   * @description Simple update when only a few properties need to be changed on the user entity in firebase
   */
  public updateUser(authId: string, user: UserBase): Observable<User> {
    return from(this.collection().doc(authId).set(user, { merge: true })
      .then((doc) => {
        return authId;
      })).pipe(switchMap(id => {
        return this.getUser(authId, true);
      }));
  }

  /**
   * @method UserService.deleteUser
   * @description Delete a a user record from Firestore
   */
  public deleteUser(authId: string): Observable<boolean> {
    return from(this.collection().doc(authId).delete()
      .then(() => true)
      .catch(() => false)) as Observable<boolean>;
  }

  /**
   * @method UserService.fixUser
   * @description Helper method to clean up user objects with legacy field names.
   * TODO: AdamA: Disable for PROD
   */
  fixUser(user: User): User {
    return user;
  }

  /**
   * @method UserService.getUser
   * @description Retrieve the entry for a given user in firestore
   * @returns Observable<User>
   */
  public getUser(authId: string = this.currentUser.authId, update?: boolean): Observable<User> {
    if (!authId) {
      return throwError(new Error("Supplied parameter 'user' is invalid. Expected authId of type string, instead received '" + authId + "' of type '" + typeof authId + "'"));
    }
    return this.collection((ref) => ref.where("authId", "==", authId)).doc<UserBase>(authId).valueChanges().pipe(
      debounceTime(100),
      map((u: UserBase) => {
        if (!u) {
          throw new Error("User with ID " + authId + " not found");
        }
        let user = new User(u);
        if (update && this.currentUser && this.currentUser.authId === authId) {
          const tokens = {
            panelServicesToken: this.currentUser.panelServicesToken,
            panelServicesSurveyToken: this.currentUser.panelServicesSurveyToken
          }
          this.currentUser = new User({
            ...tokens,
            ...user.asDataObj
          });
        }
        return user;
      }),
      catchError((error: Error) => {
        this.logger.warn("Error getting user: ", error.message);
        return of(null);
      })
    );
  }

  /**
   * @method UserService.getUserList
   * @description Retrieve the full list of users available to the current user
   * @returns Observable<User[]>
   */
  public getUserList(user: User = this.currentUser): Observable<User[]> {
    if (!user) {
      return throwError(new Error('No user supplied. Are you logged in?'));
    }
    switch (user.role) {
      case UserRole.User:
        //For Users return error
        return throwError(new Error('You do not have permission to read/write other users'));
      case UserRole.SuperAdmin:
        //For SuperAdmins return all users
        return this.collection().valueChanges().pipe(
          map(us => us.map(u => new User(u)))
        );
      default:
        return this.getUserListForCustomer(user.customerId);
    }
  }

  /**
   * @method UserService.getUsersByCustomer
   * @description Retrieve the full list of users available to the current user, indexed by customer
   * @returns Observable<{ [key: string]: User[] }>
   */
  public getUserListByCustomer(): Observable<{ [customerId: string]: User[] }> {
    const customers: { [customerId: string]: User[] } = {};
    return this.getUserList()
      .pipe(map((users: User[]) => {
        users.forEach(u => {
          u = this.fixUser(u);
          customers[u.customerId] = customers[u.customerId] || [];
          customers[u.customerId].push(u);
        });
        return customers;
      }));
  }

  /**
   * @method UserService.getUserListForCustomer
   * @description Retrieve a list of users attached to a specified customer
   * @returns Observable<User[]>
   */
  public getUserListForCustomer(customerId: string): Observable<User[]> {
    if (customerId) {
      let getUserListForCustomer = httpsCallable(this.functions, "getUserListForCustomer");
      return from(<Promise<any>>getUserListForCustomer({ customerId }))
        .pipe(map(result => {
          const users: UserBase[] = result.data;
          if (users) {
            return users.map(u => this.fixUser(new User(u)));
          }
          return [];
        }), catchError((err) => {
          this.logger.error(err.message)
          return [];
        }));
      //this.collection(ref => ref.where('customerId', '==', customerId)).valueChanges()
    }
    return throwError(new Error("No customerId supplied. This could be a new customer, so try again in a few minutes."))
  }
}
