import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Answer } from 'src/app/data.models';
import { LoggerService } from 'src/app/services';


@Component({
  selector: 'app-question-check-list',
  templateUrl: './question-check-list.component.html'
})
export class QuestionCheckListComponent implements OnInit {

  @Input() value: any;
  @Output() valueChange: EventEmitter<string[]> = new EventEmitter();

  public selectedOptions: string[] = [];
  public options: Answer[];
  @Input("options") set _options(options: Answer[]) {
    this.options = options;
    this.options.map((opt: Answer) => {
      opt.exclusive = this.isExclusive(opt.id);
      return opt;
    });
  }
  public exclusiveOptions: string[];
  @Input("exclusiveOptions") set _exclusiveOptions(options: string[]) {
    this.exclusiveOptions = options;
    if (this.exclusiveOptions && this.options && this.exclusiveOptions.length && this.options.length) {
      this.exclusiveOptions.forEach((id: string) => {
        const option = this.options.find((opt: Answer) => {
          if (opt.id === id) {
            opt.exclusive = true;
            return true;
          }
        });
      })
    }
  }

  constructor(
    private loggerService:  LoggerService,
  ) { }

  public isExclusive(id: string) {
    return !!this.exclusiveOptions && !!this.exclusiveOptions.find(x => x === id);
  }

  isChecked(option: Answer) {
    return this.selectedOptions.indexOf(option.id) > -1;
  }

  public onChange($event: MatCheckboxChange, option: Answer) {
    let index = this.selectedOptions.indexOf(option.id);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    }
    if ($event.checked) {
      if (option.exclusive) {
        this.selectedOptions = [option.id];
      }
      else {
        this.selectedOptions.push(option.id);
      }
    }
    this.options = this.options.map((opt: Answer) => {
      opt.disabled = $event.checked && option.exclusive && opt.id !== option.id;
      return opt;
    });
    this.valueChange.emit(this.selectedOptions);
  }

  ngOnInit() {
  }

}
