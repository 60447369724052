import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService, LoggerService } from 'src/app/services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public form: FormGroup;
  public showCurrentPasswordHint: boolean;
  public showNewPasswordHint: boolean;
  public errorMsg: string;
  public loading: boolean;

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private logger: LoggerService
  ) { }

  changePassword() {
    this.errorMsg = "";
    this.loading = true;

    this.subs.push(
      this.authService.changePassword(this.form.get("currentPassword").value, this.form.get("newPassword").value).pipe(catchError(error => {
        this.logger.error(error.message);
        this.errorMsg = error.message;
        this.loading = false;
        return of(false);
      })).subscribe(success => {
        if (success) {
          this.loading = false;
          this.snackBar.open("Password successfully changed", "Dismiss", { verticalPosition: "top" });
          this.formGroupDirective.resetForm();
        }
      })
    );
  }

  ngOnInit() {
    this.form = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern('^(?=.*\d).{4,}$')]],
    });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
