import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { Dialog, DialogRoute, DialogRouteMap, AlertMessage } from 'src/app/data.models';
import { LoggerService } from './logger.service';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private data: any;
  private busyTimer: any;
  private prev$: BehaviorSubject<Dialog>;
  private prev: Dialog;
  public busy$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public beforeClose: BehaviorSubject<Dialog> = new BehaviorSubject(null);
  public get current(): Dialog {
    return this.stack.length ? this.stack[this.stack.length - 1].getValue() : null;
  }
  public current$: BehaviorSubject<MatDialogRef<any>> = new BehaviorSubject(null);
  private stack: BehaviorSubject<Dialog>[] = [];
  private routeSubs: Subscription[] = [];
  public queries: Params;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private logger: LoggerService
  ) { }

  alert(msg: AlertMessage): Observable<Dialog> {
    return this.open("alert", null, 'dialog', msg);
  }

  /**
   * @method DialogService.open
   * @description Generic method to open a Material dialog box. routes must be defined in app-router.module
   * @returns Promise<boolean>
   */
  public open(name: string, queries?: Params, type: 'dialog' | 'drawer' | string = 'dialog', data: any = null) {
    let existing = this.stack.find((dialog) => dialog.getValue() && dialog.getValue().name === name);
    const hasData = typeof data !== "undefined" && data !== null;
    if (hasData) {
      this.data = data;
    }
    if (!existing) {
      this.stack.push(new BehaviorSubject({ name, queries, type, action: 'open', data }));
      this.router.navigate(
        [{ outlets: { [type]: type + '/' + name } }],
        queries
          ? { queryParams: queries, queryParamsHandling: 'merge' }
          : { queryParamsHandling: 'preserve' }
      ).then(() => {
        if (this.ROUTE_MAP[name]) {
          this.openDialog(this.ROUTE_MAP[name], queries, type);
        }
        else {
          this.logger.log("DialogOpen: No configuration for '" + type + '/' + name + "'. Waiting for it to register...");
        }
        this.setBusy(false);
      });
    }
    return this.stack[this.stack.length - 1].asObservable();
  }

  /**
   * @method DialogService.close
   * @description Generic method to close a dialog box
   * @returns Promise<boolean>
   */
  public close(type?: string, data?: any): Promise<boolean> {
    this.setBusy(false);
    const hasData = typeof data !== "undefined";
    if (hasData) {
      this.data = data;
    }
    if (!type) {
      type = this.current ? this.current.type : 'dialog';
    }
    if (this.stack.length) {
      this.prev$ = this.stack.pop();
      this.prev = this.prev$.getValue();
      this.setCurrentDialog();
      if (this.prev && this.prev.ref) {
        if (hasData) {
          this.prev.data = data;
        }
        else {
          this.prev.data = null;
        }
        this.prev.action = 'close';
        this.beforeClose.next(this.prev);
        this.prev.ref.close();
        this.prev$.next(this.prev);
        return this.router.navigate([{ outlets: { [this.prev.type]: null } }], { queryParams: this.current ? this.current.queries : null }).then(() => {
          if (this.current) {
            //Commented this because the router does it automatically
            //return this.router.navigate([{ outlets: { [this.current.type]: this.current.type + '/' + this.current.name } }], { queryParams: this.current.queries });
          }
          else {
            //this.dialog.closeAll();
          }
          delete this.prev;
          delete this.prev$;
          return true;
        });
      }
    }
    this.dialog.closeAll();
    return this.router.navigate([{ outlets: { [type]: null } }]);
  }

  /**
   * @method DialogService.setBusy
   * @description Set the status of the dialogService to (not) busy
   * @returns void
   */
  setBusy(busy: boolean = true, delay: number = 0) {
    this.busyTimer && clearTimeout(this.busyTimer);
    this.busyTimer = setTimeout(() => {
      this.busy$.next(!!busy);
    }, delay);
  }

  /**
   * @method DialogService.setCurrentDialog
   * @description Set the current open dialog by adding it to the stack and updating the `current$` behaviour subject
   * @returns void
   */
  setCurrentDialog(dialogRef?: MatDialogRef<any>, type?: string, name?: string, queries?: Params) {
    if (dialogRef) {
      if (this.stack.length) {
        this.stack[this.stack.length - 1].getValue().ref = dialogRef;
      }
      else {
        this.stack.push(new BehaviorSubject({ name, type, queries, ref: dialogRef, action: 'open' }));
      }
    }
    this.current$.next(this.current ? this.current.ref : null);
  }

  setData(data: any) {
    this.data = data;
    setTimeout(() => (delete this.data), 100)
  }
  getData(share?: boolean) {
    const d = this.data;
    if (!share) {
      delete this.data;
    }
    return d;
  }

  /**
   *  DialogService.ROUTE_MAP made available as readonly property purely for test specs
   */
  get _routeMap() {
    return this.ROUTE_MAP;
  }

  private ROUTE_MAP: DialogRouteMap = {};

  registerDialog(route: string, component: any, dialog: MatDialog = this.dialog, data?: any) {
    if (!this.ROUTE_MAP[route]) {
      this.ROUTE_MAP[route] = {
        component: component,
        classes: data && data.classes ? data.classes : [],
        data: data || {},
        name: route,
        dialog: dialog
      }
      if (this.current && this.current.name === route) {
        this.logger.log("... DialogOpen: Configuration for '" + this.current.type + '/' + this.current.name + "' registered.");
        this.openDialog(this.ROUTE_MAP[route], this.current.queries, this.current.type);
      }
    }
    return this.ROUTE_MAP[route];
  }

  deregisterDialog(route: string) {
    if (this.ROUTE_MAP[route]) {
      delete this.ROUTE_MAP[route];
    }
  }

  openDialog(route: DialogRoute, queries: Params, type: string = "dialog", name: string = route.name): void {

    type = route.data && route.data.type ? route.data.type : type;

    this.logger.log("DialogOpen: Opening " + type + '/' + name);

    const dialogRef = route.dialog.open(route.component, {
      maxWidth: '800px',
      panelClass: ['app-dialog-wrapper', type, ...route.classes],
      backdropClass: type,
      data: {},
      disableClose: true,
    });

    this.setCurrentDialog(dialogRef, type, name, queries);
  }
}
