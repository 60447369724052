<ng-container *ngIf="!textOnly; else textTemplate">
  <div class="loader-wrapper" *ngIf="loading" [class.overlay]="overlay" [class.sm]="size === 'small'">
    <div class="loader">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>
</ng-container>
<ng-template #textTemplate>
  <p *ngIf="loading">Loading{{dot|async}}</p>
</ng-template>
