import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { LayoutModule as NgLayoutModule } from '@angular/cdk/layout';

import { NavGlobalComponent } from './nav-global/nav-global.component';
import { NavLocalComponent } from './nav-local/nav-local.component';
import { NavUserComponent } from './nav-user/nav-user.component';
import { NavActionComponent } from './nav-action/nav-action.component';
import { LayoutMainComponent } from './layout-main/layout-main.component';

@NgModule({
  imports: [
    SharedModule,
    NgLayoutModule,
  ],
  exports: [
    NavGlobalComponent,
    NavLocalComponent,
    NavUserComponent,
    NavActionComponent,
    LayoutMainComponent,
  ],
  declarations: [
    NavGlobalComponent,
    NavLocalComponent,
    NavUserComponent,
    NavActionComponent,
    LayoutMainComponent,
  ]
})
export class LayoutModule { }
