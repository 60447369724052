<form [formGroup]="form" (submit)="submitForm($event)" autocomplete="off" class="d-flex justify-content-start" id="changeDetailsForm">
  <div class="inner-content sm">
    <h2 class="d-md-none">Change Details</h2>

    <div class="d-md-flex">
      <!-- FirstName -->
      <mat-form-field class="mr-md-2">
        <mat-label>First Name</mat-label>
        <input matInput id="changeDetailsForm_firstName" formControlName="firstName" autocomplete="off" type="text" />
      </mat-form-field>

      <!-- LastName -->
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput id="changeDetailsForm_lastName" formControlName="lastName" autocomplete="off" type="text" />
      </mat-form-field>
    </div>
    <div class="d-md-flex">
      <!-- Email -->
      <mat-form-field class="mr-md-2">
        <mat-label>Email address</mat-label>
        <input matInput id="changeDetailsForm_newEmail" formControlName="newEmail" autocomplete="off" type="text" />
        <!--<mat-hint>Hint</mat-hint>-->
        <mat-error *ngIf="form.get('newEmail').hasError('email')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>

      <!-- Current Password -->
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput id="changeDetailsForm_password" formControlName="password" autocomplete="off" type="password" (focus)="showPasswordHint = true" (blur)="showPasswordHint = false" />
        <mat-hint *ngIf="showPasswordHint">For security, please re-enter your password.</mat-hint>
        <mat-error *ngIf="!showPasswordHint && form.get('password').hasError('required')">
          Current password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Error -->
    <mat-error *ngIf="errorMsg">
      {{errorMsg}}
    </mat-error>

    <div class="btn-row">
      <button type="submit" mat-flat-button id="changeDetailsForm_saveButton" color="primary" class="mr-3" [disabled]="loading">
        <ng-container *ngIf="!loading">Save</ng-container>
        <app-loader size="small" *ngIf="loading"></app-loader>
      </button>
      <button type="button" mat-stroked-button id="changeDetailsForm_resetButton" color="warn" (click)="reset()" [disabled]="loading">Reset</button>
    </div>

  </div>
</form>
