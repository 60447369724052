<div class="inner-content md">
  <mat-accordion class="mb-3 d-block" [hideToggle]="customerList?.length === 1">
    <mat-expansion-panel *ngFor="let customer of customerList; index as i"
                         [expanded]="customerList?.length === 1 || openList === i || user.role > 0"
                         (opened)="openList = i">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{customer.name || '--UNNAMED--'}}
        </mat-panel-title>
        <mat-panel-description style="max-width: 450px;" class="text-smaller">
          ID: {{customer.id}}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-list>
        <mat-list-item *ngFor="let u of customer.users$|async">
          <strong matLine>{{ u.firstName || u.lastName ? u.firstName + ' ' + u.lastName : 'Unknown' }}</strong>
          <p matLine>{{ u.email }}</p>
          <ng-container *ngIf="user.role === 0 || (user.role === 1 && user.customerId === customer.id && u.role > 0)">
            <button mat-icon-button id="manageUsersComponent_editUserButton" (click)="editUser(u)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button id="manageUsersComponent_deleteUserButton" (click)="deleteUser(u)">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="!(customer.users$|async)?.length">
          <ng-container *ngIf="!customer.loadingUsers; else loadingUsers">
            This customer has no users.
          </ng-container>
          <ng-template #loadingUsers>
            <div class="d-flex justify-content-center w-100">
              <app-loader size="small"></app-loader>
            </div>
          </ng-template>
        </mat-list-item>
      </mat-list>

      <mat-action-row *ngIf="user.role === 0" class="">
        <ng-container *ngIf="!(isHandset$|async); else mobileButtons">
          <button mat-stroked-button id="manageUsersComponent_addUserButton" color="primary" (click)="addUser(customer)" class="mr-auto ml-0">
            <mat-icon>person_add</mat-icon>
            Add User
          </button>
          <button type="button" mat-stroked-button id="manageUsersComponent_deleteCustomerButton" color="warn" (click)="deleteCustomer(customer.id)" *ngIf="user.role === 0" class="mr-3">
            <mat-icon>delete</mat-icon>
            Delete Customer
          </button>
          <button type="button" mat-stroked-button id="manageUsersComponent_editCustomerButton" color="primary" (click)="editCustomer(customer.id)" *ngIf="user.role === 0" class="mr-3">
            <mat-icon>edit</mat-icon>
            Edit Customer
          </button>
        </ng-container>
        <ng-template #mobileButtons>
          <button mat-mini-fab id="manageUsersComponent_fab_addUserButton" type="button" color="primary" (click)="addUser(customer)" class="mr-auto ml-0" matTooltip="Add User" matTooltipPosition="above">
            <mat-icon>person_add</mat-icon>
          </button>
          <button mat-mini-fab id="manageUsersComponent_fab_deleteCustomerButton" type="button" color="warn" (click)="deleteCustomer(customer.id)" *ngIf="user.role === 0" class="mr-3" matTooltip="Delete Customer" matTooltipPosition="above">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-mini-fab id="manageUsersComponent_fab_editCustomerButton" type="button" color="primary" (click)="editCustomer(customer.id)" *ngIf="user.role === 0" class="mr-3" matTooltip="Edit Customer" matTooltipPosition="above">
            <mat-icon>edit</mat-icon>
          </button>
        </ng-template>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
