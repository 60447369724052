import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from 'src/app/data.models';
import { AuthService, LoggerService, UserService } from 'src/app/services';

@Component({
  selector: 'app-change-details',
  templateUrl: './change-details.component.html'
})
export class ChangeDetailsComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public form: FormGroup;
  public showPasswordHint: boolean;
  public errorMsg: string;
  public loading: boolean;
  public user: User;

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private logger: LoggerService
  ) { }

  reset() {
    if (this.user) {
      this.form.get("newEmail").setValue(this.user.email);
      this.form.get("firstName").setValue(this.user.firstName);
      this.form.get("lastName").setValue(this.user.lastName);
    }
    else {
      this.form.get("newEmail").setValue('');
      this.form.get("firstName").setValue('');
      this.form.get("lastName").setValue('');
    }
  }

  changeEmail() {
    return this.authService.changeEmail(this.form.get("password").value, this.form.get("newEmail").value);
  }
  changeName() {
    return this.userService.updateUser(this.user.authId, {
      firstName: this.form.get("firstName").value,
      lastName: this.form.get("lastName").value
    });
  }
  formSub: Subscription;
  submitForm($event?: Event) {
    $event && $event.preventDefault();
    this.errorMsg = "";
    this.loading = true;

    if (this.formSub) {
      this.formSub.unsubscribe();
    }

    this.subs.push(
      this.formSub = of(false).pipe(
        switchMap(resp => {
          if (this.form.get("newEmail").value !== this.user.email)
            return this.changeEmail();
          else
            return of(resp);
        }),
        switchMap(resp => {
          if (this.form.get("firstName").value !== this.user.firstName || this.form.get("lastName").value !== this.user.lastName) {
            return this.changeName();
          }
          else
            return of(resp);
        })
      ).subscribe(
        updated => {
          if (updated) {
            this.loading = false;
            this.snackBar.open("Details successfully updated!", "Dismiss", { verticalPosition: "top" });
            this.reset();
            for (let ctrl in this.form.controls) {
              this.form.controls[ctrl].markAsPristine();
            }
            this.form.markAsPristine();
          }
          else {
            this.loading = false;
            this.snackBar.open("No details were changed!", "Dismiss", { verticalPosition: "top" });
          }
        },
        error => {
          this.snackBar.open("An error occurred. No details updated.", "Dismiss", { verticalPosition: "top" });
          this.errorMsg = error.message;
          this.loading = false;
        })
    );
  }

  ngOnInit() {
    this.form = this.fb.group({
      password: ['', [Validators.required]],
      newEmail: ['', [Validators.email]],
      firstName: '',
      lastName: ''
    });
    this.subs.push(
      this.authService.user$.subscribe(user => {
        this.user = user;
        if (this.user) {
          this.form.get("newEmail").setValue(user.email);
          this.form.get("firstName").setValue(user.firstName);
          this.form.get("lastName").setValue(user.lastName);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
