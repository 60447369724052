<mat-toolbar class="">
  <mat-toolbar-row class="px-0 header-bar">
    <skeleton-loader [loading]="loading" [layout]="skeletonHeaderBarLayout" class="w-100" [class.pb-2]="loading">
      <div class="d-flex justify-content-between align-items-center align-items-md-start header-bar">
        <button type="button"
                id="mainLayout_userMenuButton"
                class="menu-button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="onToggle.emit()"
                *ngIf="user && (isHandset$ | async)">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <h1 class="section-name" *ngIf="!loading && !(isHandset$ | async) && !(isTablet$ | async)">{{sectionTitle ? (sectionTitle|changeCase:'title') : 'Welcome ' + (user ? user.firstName : "")}}</h1>
        <div class="d-flex ml-sm-auto button-wrapper">
          <app-nav-user class="user-nav"></app-nav-user>
          <!--<feedback-button *ngIf="user && !(isHandset$ | async) && !(isTablet$ | async)"></feedback-button>
          <help-button *ngIf="user && !(isHandset$ | async) && !(isTablet$ | async)"></help-button>-->
        </div>
      </div>
    </skeleton-loader>
  </mat-toolbar-row>
  <mat-toolbar-row class="mat-elevation-z0 height-auto p-0 nav-bar" *ngIf="!loading" [ngClass]="navLinks.length ? 'd-block' : 'd-none'">
    <div class="d-flex justify-content-between align-items-center">
      <div class="local-nav mr-0 mr-md-auto ml-auto ml-md-0">
        <nav mat-tab-nav-bar [hidden]="((isHandset$ | async) || (isTablet$ | async))">
          <a mat-tab-link
             *ngFor="let link of navLinks"
             [routerLink]="link.path"
             routerLinkActive #rla="routerLinkActive"
             [active]="rla.isActive"
             [hidden]="(link.restricted|typeOf) !== 'undefined' && user?.role > link.restricted">
            {{link.label}}
          </a>
        </nav>

        <mat-menu #localNav="matMenu" [hidden]="!((isHandset$ | async) && (isTablet$ | async)) || !navLinks?.length">
          <button mat-menu-item
                  *ngFor="let link of navLinks"
                  [routerLink]="link.path"
                  routerLinkActive="active"
                  [hidden]="(link.restricted|typeOf) !== 'undefined' && user?.role > link.restricted">
            {{link.label}}
          </button>
        </mat-menu>

        <button mat-button
                class="local-nav-button"
                [matMenuTriggerFor]="localNav"
                aria-label="Local nav toggle icon"
                *ngIf="((isHandset$ | async) || (isTablet$ | async)) && navLinks?.length">
          <span class="inner-text">{{sectionTitle ? (sectionTitle|changeCase:'title') : 'Welcome ' + (user ? user.firstName : "")}}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </div>
      <app-nav-action></app-nav-action>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
