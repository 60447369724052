import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

let dialogShownTimeout: any;

if ((environment.production || environment.staging) && environment.sentry) {
  Sentry.init({
    environment: environment.production ? "production" : (environment.staging ? "staging" : "dev"),
    dsn: environment.sentry.key,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "npanel-staging.firebaseapp.com", environment.panelServicesUrl, environment.profilerWidgetUrl, environment.samplingServicesUrl, environment.firebaseConfig.authDomain, environment.firebaseConfig.databaseURL],
        routingInstrumentation: Sentry.routingInstrumentation,
        traceXHR: false,
        traceFetch: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentry.tracesSampleRate,

    beforeBreadcrumb(breadcrumb) {
      return breadcrumb.category === "console" || breadcrumb.category === "xhr" ? null : breadcrumb;
    },

    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        console.log(event.exception);
        // Timeout ensures only the last dialog is sent if multiple errors are received
        if (event.extra && !event.extra.handled) {
          if (dialogShownTimeout) {
            clearTimeout(dialogShownTimeout);
          }
          dialogShownTimeout = setTimeout(() => {
            Sentry.showReportDialog({ eventId: event.event_id });
          }, 250);
        }
      }
      return event;
    },
  });
}
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.production) {
  enableProdMode();
}

bootstrap()
  .catch(err => console.log(err));
