import * as RewardReducers from './reward.reducers';
import * as RewardProfileReducers from './reward-profile.reducers';
import * as RewardProviderReducers from './reward-provider.reducers';
import * as RewardGiveawayReducers from './reward-giveaway.reducers';
export { RewardReducers, RewardProfileReducers, RewardProviderReducers, RewardGiveawayReducers }

import * as RewardActions           from './reward.actions';
import * as RewardProfileActions    from './reward-profile.actions';
import * as RewardProviderActions   from './reward-provider.actions';
import * as RewardGiveawayActions from './reward-giveaway.actions';
export { RewardActions, RewardProfileActions, RewardProviderActions, RewardGiveawayActions }

export { RewardEffects }            from './reward.effects';
export { RewardProfileEffects }     from './reward-profile.effects';
export { RewardProviderEffects }    from './reward-provider.effects';

export interface RewardStore {
  rewards: any;
  profiles: any;
  providers: any;
  giveaways: any;
}

export const REWARD_STORE: RewardStore = {
  rewards: RewardReducers.rewardReducer,
  profiles: RewardProfileReducers.rewardProfileReducer,
  providers: RewardProviderReducers.rewardProviderReducer,
  giveaways: RewardGiveawayReducers.reducer
}

export interface IRewardStore extends RewardStore {
  rewards: RewardReducers.State;
  profiles: RewardProfileReducers.State;
  providers: RewardProviderReducers.State;
  giveaways: RewardGiveawayReducers.State;
}
