import * as Actions from './reward.actions';
import * as Reducers from '../reducers';
import { extendObj } from '../../data.models';
import { Reward } from '../../components/reward/reward.models';

export interface State extends Reducers.State<Reward> { }

export const initialState: State = {
  data: null, archive: null, count: 0, map: {}, errors: {}
};

export function rewardReducer(state: State = initialState, action: Actions.Any): State {

  const newState: State = extendObj(true, {}, state);
  let id = "";
  switch (action.type) {

    case Actions.Types.GetSuccess:
    case Actions.Types.UpdateSuccess:
      newState.errors = { ...newState.errors, ...(action.payload ? { [action.payload.id || ""]: null } : {}) };
    case Actions.Types.CreateSuccess:
      newState.map = { ...newState.map, ...(action.payload ? { [action.payload.id || ""]: action.payload } : {}) };
      newState.errors = { ...newState.errors, create: null };
      newState.archive = Object.values(newState.map).filter(p => p.archived);
      newState.data = Object.values(newState.map).filter(p => !p.archived);
      break;


    case Actions.Types.GetAllSuccess:
      newState.map = Reducers.map(action.payload);
      newState.data = Object.values(newState.map).filter(r => !r.archived);
      newState.archive = Object.values(newState.map).filter(r => r.archived);
      newState.errors = { ...newState.errors, all: null };
      break;


    case Actions.Types.DeleteSuccess:
      newState.data = (state.data || []).filter((r) => r.id !== action.id);
      newState.map = Reducers.map(newState.data);
      break;

      
    case Actions.Types.GetAllError:
      newState.map = {};
      newState.data = [];
    case Actions.Types.CreateError:
    case Actions.Types.GetError:
    case Actions.Types.DeleteError:
    case Actions.Types.UpdateError:
      console.log(action)
      newState.errors = {
        ...state.errors,
        [action.type === Actions.Types.GetAllError ? 'all'
         : action.type === Actions.Types.CreateError ? 'create'
         : action.id]: action.error
      };
      break;


    case Actions.Types.Clear:
      return initialState;

    default:
      return state;

  }
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  !!newState.data && newState.data.length || (newState.data = null);
  return newState;
}
