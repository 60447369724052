import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CommunityMemberPagerFilters, Invitation, InvitationStatus, InvitationPagerFilters } from 'src/app/components/community/community.models';
import { PagedHttpResponse, PagerConfig } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Pager } from 'src/app/services/paging.service';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityInvitationService {
  
  private endpoint: string = 'invitations';

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method PanelAdminTestService.send
   * @description Sends an invitation
   * @param {number} id The id of the invitation to send
   * @returns Observable<CommunityMember>
   */
  public send(id: number): Observable<boolean> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post(this.endpoint + '/send', { id }, false, panelAdminUrl, 'all', this.panelAdminService.options, false).pipe(
        map((resp: any) => {
          return resp.status === "success";
        }),
        catchError((resp: any) => {
          return this.panelAdminService.handleError(resp);
        })
      )
    ), catchError(() => of(false)));
  }

  /**
   * @method PanelAdminTestService.getByID
   * @description Retrieves an Invitation
   * @returns Observable<Invitation>
   */
  public getByID(id?: number|string): Observable<Invitation> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + '/' + id, {}, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminTestService.getAll
   * @description Retrieves a list of all Invitations, filtered by the params set
   * @returns Observable<PagedHttpResponse<Invitation>>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<Invitation>> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + (params || ''), {}, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminTestService.process
   * @description Process the given invitation
   * @returns Observable<CommunityMember>
   */
  public process(id: number, status: InvitationStatus): Observable<any> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/process/' + id, { status }, false, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        catchError((err: any) => {
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: InvitationPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }

}
