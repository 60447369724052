import { Injectable } from '@angular/core';

export abstract class Logger {
  log: any;
  info: any;
  warn: any;
  error: any;
  groupEnd: any;
  group: any;
  groupCollapsed: any;
}

@Injectable()
export class LoggerService implements Logger {
  log: any;
  info: any;
  warn: any;
  error: any;
  groupEnd: any;
  group: any;
  groupCollapsed: any;
  
  invokeConsoleMethod(type: string, args?: any): void {}  
}
