<div class="prop-list">
  <label [innerHTML]="label" class="no-bold"></label>

  <ng-container *ngIf="allowMultiple; else singleChoice">
    <app-question-check-list (valueChange)="valueChange.emit(value)" [exclusiveOptions]="exclusiveOptions" [value]="value" [options]="options"></app-question-check-list>
  </ng-container>

  <ng-template #singleChoice>
    <app-question-radio-list (valueChange)="valueChange.emit(value)" [value]="value" [options]="options"></app-question-radio-list>
  </ng-template>

  <div class="mt-3" *ngIf="answerOrder">
    <button mat-flat-button (click)="setOptionOrder()">
      Refresh order
    </button>
  </div>

</div>
