import { HttpResponse, HttpHeaders, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { of, throwError, Observable, timer } from 'rxjs';
import { catchError, map, switchMap, timeout, take } from 'rxjs/operators';
import { Customer, PANEL_ADMIN_TOKEN_NAME, PanelAdminApiToken } from 'src/app/data.models';
import { ApiService, HttpOptions } from '../api.service';
import { LoggerService } from '../logger.service';
import { UtilService } from '../util.service';
import { CustomerService } from '../customer.service';
import { DialogService } from '../dialog.service';
import { AlertService } from '../alert.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PanelAdminService {

  options: HttpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  redirectUrl: string;
  rootPath: string = "/community";

  constructor(
    private logger: LoggerService,
    private api: ApiService,
    private util: UtilService,
    private alert: AlertService,
    private dialogService: DialogService,
    private customerService: CustomerService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  _panelAdminUrl: string;
  getPanelAdminUrl() {
    if (this._panelAdminUrl) {
      return of(this._panelAdminUrl);
    }
    const customer = this.customerService.currentCustomer;
    let getAPIUrlTimer: any;
    let obs: Observable<Customer> = of(customer);
    if (!customer) {
      obs = this.customerService.customer$.asObservable().pipe(
        timeout(1000),
        catchError(() => of(null)));
    }
    return obs.pipe(
      switchMap((c) => {
        if (getAPIUrlTimer) {
          clearTimeout(getAPIUrlTimer);
        }
        if (c) {
          return of(this._panelAdminUrl = c.panelAdminUrl || this._panelAdminUrl || null);
        }
        else if (this._panelAdminUrl) {
          return of(this._panelAdminUrl);
        }
        else {
          this.logger.warn("No customer or panelAdminUrl available");
          return timer(1000).pipe(take(1), switchMap(() => throwError(new Error("You do not have access to the panel admin section"))));
        }
      })
    );
  }

  public login({ panelAdminEmail, panelAdminPassword }: { panelAdminEmail: string; panelAdminPassword: string; }) {
    return this.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<any>('admins/sign_in', JSON.stringify({ data: { email: panelAdminEmail, password: panelAdminPassword } }), false, panelAdminUrl, null, this.options, false).pipe(map((resp: any) => {
        this.logger.log(resp);
        const token = resp.jwt;
        if (token) {
          return this.setToken(panelAdminUrl, token);
        }
        return "";
      }), catchError(err => {
        try {
          this.logger.warn(err);
          if (err.error.error) {
            return throwError(new Error(err.error.error));
          }
        } catch (e) {
          return this.handleError(err);
        }
      }))
    ));
  }

  public register(user: { email: string, password: string, first_name: string, password_confirmation: string, phone: string }) {
    return this.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post('admins', JSON.stringify({ data: user }), false, panelAdminUrl, 'all', this.options, false).pipe(switchMap((resp: any) => {
        this.logger.log(resp);
        if (resp.ok) {
          return of(resp.body);
        }
        return this.handleError(resp);
      }), catchError(resp => {
        return this.handleError(resp);
      }))
    ), catchError(() => of(null)));
  }

  public logout(redirect: boolean = true) {
    return this.getPanelAdminUrl().pipe(switchMap(panelAdminUrl => {
      if (panelAdminUrl) {
        this._panelAdminUrl = null;
        return this.api.delete<any>('admins/sign_out', null, panelAdminUrl, null, this.options).pipe(map((resp: HttpResponse<any>) => {
          this.logger.log(resp);
          window.localStorage.removeItem(PANEL_ADMIN_TOKEN_NAME);
          if (redirect) {
            this.router.navigate([(this.rootPath || "/community"), 'login']).then(() => {
              this.dialogService.close();
            });
          }
          return null;
        }), catchError(err => {
          window.localStorage.removeItem(PANEL_ADMIN_TOKEN_NAME);
          return of(null);
        }));
      }
      window.localStorage.removeItem(PANEL_ADMIN_TOKEN_NAME);
      return of(null);
    }), catchError(() => {
      window.localStorage.removeItem(PANEL_ADMIN_TOKEN_NAME);
      return of(null);
    }));
  }

  public handleError(resp: HttpErrorResponse, loud?: boolean) {
    if (resp.status === 403) {
      this.dialogService.open('panel-admin-403');
      return of("");
    }
    else if (resp.status === 401) {
      if (loud) {
        this.alert.failure("User token expired");
      }
      if (window.location.pathname.indexOf("login") === -1) {
        this.redirectUrl = window.location.pathname;
      }
      return this.logout(true);
    }
    else {
      try {
        let msg: string;
        if (typeof resp === "string") {
          msg = resp;
        }
        else if (resp.error && resp.error.data && resp.error.data.errors) {
          msg = typeof resp.error.data.errors === "string" ? resp.error.data.errors : Object.keys(resp.error.data.errors).reduce((prev, key) => {
            const j = prev ? "<br />" : "";
            prev += j + key + " " + resp.error.data.errors[key][0];
            return prev;
          }, "");
        }
        else if (resp.error) {
          msg = typeof resp.error === "string" ? resp.error : resp.error.message;
        }
        else if (resp.message) {
          msg = resp.message;
        }

        msg = msg || "An unknown error occurred";

        if (loud) {
          this.alert.failure(msg);
        }
        return throwError(new Error(msg));
      }
      catch (e) {
        this.logger.log("PanelAdminTestService.handleError: ", resp);
        return throwError(new Error(resp.error ? resp.error : "An unknown error occurred"));
      }
    }
  }

  setToken(url: string, token: string) {
    const created = new Date().getTime();
    window.localStorage.setItem(PANEL_ADMIN_TOKEN_NAME, JSON.stringify({
      url,
      token,
      created
    } as PanelAdminApiToken));
    return token;
  }

  getToken(url: string) {
    const token = window.localStorage.getItem(PANEL_ADMIN_TOKEN_NAME);
    if (token) {
      const json: PanelAdminApiToken = JSON.parse(token);
      if (json.url === url && !this.util.isExpired(json.created, 60, 24)) {
        return json.token;
      }
      else {
        window.localStorage.removeItem(PANEL_ADMIN_TOKEN_NAME);
      }
    }
    return "";
  }
}
