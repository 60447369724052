import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Answer, AnswerOptions } from 'src/app/data.models';
import { LoggerService, QuestionService } from 'src/app/services';


@Component({
  selector: 'app-question-multi',
  templateUrl: './question-multi.component.html'
})
export class QuestionMultiComponent implements OnInit {

  @Input() allowMultiple: boolean;
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  public selectedOptions: string[];
  public options: Answer[];
  public answerOptions: Answer[];
  @Input("options") set _options(options: AnswerOptions[]) {
    //this.answerOptions = options;
    this.answerOptions = this.questionService.utilities.flattenAnswerOptions(options);
    this.setOptionOrder();
  }

  public answerOrder: string;
  @Input("answerOptionOrder") set _answerOrder(answerOrder: string) {
    this.answerOrder = answerOrder;
    this.setOptionOrder();
  }

  public exclusiveOptions: string[];
  @Input("exclusiveOptions") set _exclusiveOptions(options: string[]) {
    this.exclusiveOptions = options;
  }

  public label: string;
  @Input("label") set _label(label: string) {
    this.label = label;
  }

  constructor(
    private logger: LoggerService,
    private questionService: QuestionService,
  ) { }

  setOptionOrder() {
    if (this.answerOptions && this.answerOrder) {
      const order = this.questionService.utilities.calculateOrder(this.answerOrder, this.answerOptions.length).then((order: number[]) => {
        const opts = [];

        order.forEach((v) => {
          if (this.answerOptions[v-1]) {
            opts.push(this.answerOptions[v-1]);
          }
        });
        this.options = opts;
      })
    }
    else {
      this.options = this.answerOptions;
    }
  }

  ngOnInit() {
  }

}
