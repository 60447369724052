import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService, LoggerService } from 'src/app/services';


@Component({
  selector: 'app-dialog-open',
  template: ''
})
export class DialogOpenComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public queries: Params;

  constructor(
    private logger: LoggerService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.subs.push(
      this.route.queryParams.subscribe(params => {
        this.queries = params;
      }),
      this.route.params.subscribe(params => {
        for (let type in params) {
          let name = params[type];
          this.dialogService.open(name, this.queries, type)
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
