import { ActivityReport } from 'src/app/components/recruit/recruit.models';
import * as Reducers from '../reducers';
import * as Actions from './recruit-report.actions';

export interface State extends Reducers.State<ActivityReport> {
  mapBySource: { [filters: string]: ActivityReport[] };
  mapByCampaign: { [filters: string]: ActivityReport[] };
}

export const initialState: State = {
  data: null,
  archive: null,
  count: 0,
  map: {},
  mapBySource: {},
  mapByCampaign: {},
  errors: {},
};

export function reducerFn(state: State = initialState, action: Actions.Any): State {

  const newState: State = { ...state };
  switch (action.type) {

    case Actions.Types.GetSuccess:
    case Actions.Types.UpdateSuccess:
      newState.errors = { ...newState.errors, ...(action.payload ? { [action.payload.id || ""]: null } : {}) };
    case Actions.Types.CreateSuccess:
      newState.map = { ...newState.map, ...(action.payload ? { [action.payload.id || ""]: action.payload } : {}) };
      newState.errors = { ...newState.errors, create: null };
      newState.data = Object.values(newState.map);
      break;

    case Actions.Types.GetAllSuccess:
      newState.map = { ...newState.map, ...Reducers.map(action.payload) };
      newState.data = Object.values(newState.map);
      newState.errors = { ...newState.errors, all: null };
      break;

    case Actions.Types.DeleteSuccess:
      newState.data = (state.data || []).filter((t) => t.id !== action.id);
      newState.map = Reducers.map(newState.data);
      break;

    case Actions.Types.GetAllError:
      newState.map = {};
      newState.data = [];
    case Actions.Types.CreateError:
    case Actions.Types.GetError:
    case Actions.Types.DeleteError:
    case Actions.Types.UpdateError:
      newState.errors = {
        ...state.errors,
        [action.type === Actions.Types.GetAllError ? 'all'
          : action.type === Actions.Types.CreateError ? 'create'
            : action.id]: action.error
      };
      break;

    case Actions.Types.Clear:
      return initialState;

    default:
      return state;

  }
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  if (!!newState.data && newState.data.length) {
    newState.mapBySource = newState.data.reduce((map, report) => {
      if (report.source_id) {
        map[report.source_id] = [
          ...(map[report.source_id] || []),
          report
        ];
      }
      return map;
    }, {} as any);
    newState.mapByCampaign = newState.data.reduce((map, report) => {
      if (report.campaign_ids && report.campaign_ids.length) {
        report.campaign_ids.forEach(id => {
          map[id] = [
            ...(map[id] || []),
            report
          ];
        })
      }
      return map;
    }, {} as any);
  }
  else {
    newState.data = null;
    newState.map = {};
    newState.mapBySource = {};
    newState.mapByCampaign = {};
  }
  return newState;
}
