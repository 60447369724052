import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PagedHttpResponse, PagerConfig, SurveyPagerFilters } from 'src/app/data.models';
import { skip } from 'rxjs/operators';

export { PagedHttpResponse, PagerConfig }

export interface Pageable {
  getPager(config?: PagerConfig, filters?: any): Pager<any, any>;
}

export class Pager<T = any, F = any> {
  private updateSub: Subscription;

  public items: number = 20;
  public prevPage: number = 0;
  public currentPage: number = 1;
  public nextPage: number = 2;
  public isReversed: boolean = false;
  public allowPolling: boolean = false;
  public sortBy: string;
  public pages: number;
  public count: number;
  public filters: F;

  public get defaults(): PagerConfig {
    return { page: this.currentPage, reverse: this.isReversed, items: this.items, sort: this.sortBy };
  }
  public currentPageData: BehaviorSubject<T[]> = new BehaviorSubject(null);
  public pageCache: Array<BehaviorSubject<T[]>> = [];

  constructor(
    private getter: (params: string, ...args: any[]) => Observable<PagedHttpResponse<T, F>>,
    private context: Pageable,
    initialFilters?: F,
    configOverrides?: PagerConfig,
    private getterArgs?: any[]
  ) {
    this.pageCache[0] = new BehaviorSubject(null);
    if (initialFilters) {
      this.filters = initialFilters;
    }
    if (configOverrides) {
      if (configOverrides.items) {
        this.items = configOverrides.items;
      }
      if (configOverrides.reverse) {
        this.isReversed = configOverrides.reverse;
      }
      if (configOverrides.sort) {
        this.sortBy = configOverrides.sort;
      }
      if (configOverrides.page) {
        this.currentPage = configOverrides.page;
      }
      if (configOverrides.allowPolling) {
        this.allowPolling = configOverrides.allowPolling;
      }

    }
    this.update();
  }

  private handleResponse(response: PagedHttpResponse<T, F>): void {
    if (response) {
      this.isReversed = !!response.reverse;
      //this.items = response.items;
      this.filters = response.filters;
      this.count = response.count;
      this.pages = response.pages || 0;
      this.sortBy = response.sort;
      this.setPage(response.page);
      this.currentPageData.next(response.data);
      this.pageCache[this.currentPage] && this.pageCache[this.currentPage].next(response.data);
      this.pageCache.length = (this.pages = this.pages) + 1;
      if (!this.allowPolling) {
        this.clearQueue();
      }
    }
  }

  private setPage(page: number): void {
    this.currentPage = page;
    this.nextPage = page + 1;
    this.prevPage = page - 1;
    if (this.nextPage > this.pages) {
      this.nextPage = null;
    }
    if (this.prevPage < 1) {
      this.prevPage = null;
    }
  }

  private clearCache(): void {
    this.pageCache = [];
    this.pageCache.length = (this.pages = this.pages || 0) + 1; //add one because we won't use [0]
    this.pageCache[0] = new BehaviorSubject(null); // set the null responder into the 0 position because we won't need it for anything else
    this.currentPage = 1;
  }

  private buildQueryString(options: PagerConfig, filters: SurveyPagerFilters = this.filters) {
    let queryString = '';
    let j = '?';
    for (let opt in options) {
      if (options[opt]) {
        queryString += j + `${opt}=${options[opt]}`
        j = '&';
      }
    }
    //let f = j + "filter={";
    //j = '&';
    //let c = "";
    //for (let filter in filters) {
    //  f += c + `${filter}:${JSON.stringify(filters[filter])}`
    //  c = ",";
    //}
    //f += "}";
    //queryString += f;
    if (filters && Object.keys(filters).length) {
      queryString += j + `filters=${JSON.stringify(filters)}`
    }
    return queryString;
  }

  public next(): Observable<T[]> {
    return this.get(this.nextPage);
  }
  public prev(): Observable<T[]> {
    return this.get(this.prevPage);
  }

  public get(page: number, force: boolean = false): Observable<T[]> {
    if (page) {
      if (this.pageCache[page] && !force) {
        this.currentPageData.next(this.pageCache[page].getValue());
        return this.pageCache[page].asObservable();
      }
      return this.update({ page: page, });
    }
    this.currentPageData.next(this.pageCache[0].getValue());
    return this.pageCache[0].asObservable();
  }

  public reverse(reverse: boolean = !this.isReversed) {
    this.clearCache();
    this.update({ reverse: this.isReversed = reverse });
  }

  public filter(filters: F) {
    this.clearCache();
    this.filters = filters;
    this.update();
  }

  public sort(sortBy: string) {
    this.clearCache();
    this.update({ reverse: !(this.sortBy !== sortBy || this.isReversed), sort: this.sortBy = sortBy });
  }

  public changeSize(size: number) {
    this.items = size;
  }

  public clearQueue() {
    if (this.updateSub) {
      this.updateSub.unsubscribe();
      this.updateSub = null;
    }
  }

  public update(options?: PagerConfig): Observable<any> {
    this.clearQueue();
    const config = { ... this.defaults, ...(options || {}) };
    this.setPage(config.page);
    this.isReversed = config.reverse;
    this.pageCache[config.page] = this.pageCache[config.page] || new BehaviorSubject(null);

    const queryString = this.buildQueryString(config);
    this.updateSub = this.getter.call(this.context, queryString, ...(this.getterArgs || [])).subscribe(
      (resp: PagedHttpResponse<T, F>) => this.handleResponse(resp),
      (error: any) => this.handleResponse({ data: [] })
    );

    return this.currentPageData.pipe(skip(1));
  }

}
