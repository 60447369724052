import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as BrandActions from './product-test-brand.actions';
import { ProductTestBrandService } from '../../services/panel-admin/product-test/product-test-brand.service';

@Injectable()
export class BrandEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(BrandActions.Types.GetAll),
      mergeMap(() => this.service.getAll()
        .pipe(
          map(brands => (new BrandActions.GetAllSuccess(brands))),
          catchError((err: Error) => of(new BrandActions.GetAllError(err)))
        ))
    ));

  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(BrandActions.Types.GetArchived),
      mergeMap(() => this.service.getAll(true)
        .pipe(
          map(brands => (new BrandActions.GetArchivedSuccess(brands))),
          catchError((err: Error) => of(new BrandActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(BrandActions.Types.Get),
      mergeMap((action: BrandActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(brand => (brand ? new BrandActions.GetSuccess(brand) : new BrandActions.GetError(action.id, new Error("Unknown Error")))),
          catchError((err: Error) => of(new BrandActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(BrandActions.Types.Create),
      mergeMap((action: BrandActions.Create) => this.service.create(action.payload)
        .pipe(
          map((brand) => (new BrandActions.CreateSuccess(brand))),
          catchError((err: Error) => of(new BrandActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(BrandActions.Types.Update),
      mergeMap((action: BrandActions.Update) => this.service.update(action.id, action.payload)
        .pipe(
          map((brand) => (new BrandActions.UpdateSuccess(brand))),
          catchError((err: Error) => of(new BrandActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(BrandActions.Types.Delete),
      mergeMap((action: BrandActions.Delete) => this.service.archive(action.id)
        .pipe(
          map(() => (new BrandActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new BrandActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: ProductTestBrandService
  ) { }
}
