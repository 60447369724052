import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event as NavigationEvent, NavigationStart, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { filter } from "rxjs/operators";
import { AlertDialog } from './components/dialog/dialog.module';
import { FeedbackDialog } from './components/dialog/feedback-dialog/feedback-dialog.component';
import { LoginDialog } from './components/dialog/login/login.component';
import { ResetPasswordDialog } from './components/dialog/reset-password/reset-password.component';
import { DialogService, LoggerService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private verbose: boolean;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private logger: LoggerService,
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.dialogService.registerDialog("login", LoginDialog);
    this.dialogService.registerDialog("reset-password", ResetPasswordDialog);
    this.dialogService.registerDialog("feedback", FeedbackDialog);
    this.dialogService.registerDialog("alert", AlertDialog, this.dialog, { classes: ["alert-dialog", "short"] });


    if (this.verbose) {
      this.subs.push(
        this.router.events.pipe(filter((event: NavigationEvent) => {
          return (event instanceof NavigationStart);
        })).subscribe((event: NavigationStart) => {
          this.logger.groupCollapsed("NavigationStart Event");
          this.logger.log("navigation id:", event.id);
          this.logger.log("route:", event.url);
          this.logger.log("trigger:", event.navigationTrigger);
          this.logger.groupEnd();
        })
      );
    }

  }

  ngOnDestroy() {
    this.dialogService.deregisterDialog("view-survey");
    this.dialogService.deregisterDialog("view-respondent");
    this.dialogService.deregisterDialog("view-dashboard");
    this.dialogService.deregisterDialog("alert");
    this.subs.forEach(s => s.unsubscribe());
  }
}
