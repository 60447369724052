import { BlacklistedEmail } from '../../components/community/community.models';
import { extendObj } from '../../data.models';
import * as Reducers from '../reducers';
import * as BlacklistActions from './community-blacklist.actions';

export interface State extends Reducers.State<BlacklistedEmail> { }

export const initialState: State = {
  data: null, archive: null, count: 0, map: {}, errors: {},
};

export function reducerFn(state: State = initialState, action: BlacklistActions.Any): State {

  const newState: State = extendObj(true, {}, state);

  switch (action.type) {

    case BlacklistActions.Types.GetSuccess:
    case BlacklistActions.Types.UpdateSuccess:
      newState.errors = { ...newState.errors, ...(action.payload ? { [action.payload.id || ""]: null } : {}) };
    case BlacklistActions.Types.CreateSuccess:
      newState.data = Object.values(newState.map = {
        ...newState.map,
        [action.payload.id]: action.payload
      });
      break;

    case BlacklistActions.Types.GetAllSuccess:
      newState.map = Reducers.map(newState.data = action.payload);
      break;

    case BlacklistActions.Types.DeleteSuccess:
      newState.data = (state.data || []).filter((t) => t.id !== action.id);
      newState.map = Reducers.map(newState.data);
      break;


    case BlacklistActions.Types.GetAllError:
      newState.map = {};
      newState.data = [];
    case BlacklistActions.Types.CreateError:
    case BlacklistActions.Types.GetError:
    case BlacklistActions.Types.DeleteError:
    case BlacklistActions.Types.UpdateError:
      newState.errors = {
        ...state.errors,
        [action.type === BlacklistActions.Types.GetAllError ? 'all'
          : action.type === BlacklistActions.Types.CreateError ? 'create'
            : action.id]: action.error
      };  
      break;


    case BlacklistActions.Types.Clear:
      return initialState;

    default:
      return state;

  }
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  !!newState.data && newState.data.length || (newState.data = null);
  return newState;
}
