import { Action } from '@ngrx/store';
import * as Models from 'src/app/data.models';

export enum Types {
    Get = '[SampleProject] Get',
    GetStats = '[SampleProject] GetStats',
    GetSuccess = '[SampleProject] GetSuccess',
    GetError = '[SampleProject] GetError',
    GetStatsSuccess = '[SampleProject] GetStatsSuccess',
    GetStatsError = '[SampleProject] GetStatsError',
    GetAll = '[SampleProject] GetAll',
    GetAllSuccess = '[SampleProject] GetAllSuccess',
    GetAllError = '[SampleProject] GetAllError',
    GetArchived = '[SampleProject] GetArchived',
    Create = '[SampleProject] Create',
    CreateSuccess = '[SampleProject] CreateSuccess',
    CreateError = '[SampleProject] CreateError',
    Delete = '[SampleProject] Delete',
    DeleteSuccess = '[SampleProject] DeleteSuccess',
    DeleteError = '[SampleProject] DeleteError',
    Update = '[SampleProject] Update',
    UpdateSuccess = '[SampleProject] UpdateSuccess',
    UpdateError = '[SampleProject] UpdateError',
    Clear = '[SampleProject] Clear',
    ClearCurrent = '[SampleProject] ClearCurrent'
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() { }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: Models.SampleModels.SampleProject[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id?: string) { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: Models.SampleModels.SampleProject) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class GetStats implements Action {
  readonly type = Types.GetStats;
  constructor(public id: string) { }
}

export class GetStatsSuccess implements Action {
  readonly type = Types.GetStatsSuccess;
  constructor(public id: string, public payload: Models.SampleModels.SampleStatsBase) { }
}

export class GetStatsError implements Action {
  readonly type = Types.GetStatsError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: Models.SampleModels.SampleProjectBase) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: Models.SampleModels.SampleProject) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public payload: Models.SampleModels.SampleProjectBase) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: Models.SampleModels.SampleProject) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class ClearCurrent implements Action {
  readonly type = Types.ClearCurrent;
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetStats | GetSuccess | GetError | GetArchived | GetAll | GetAllSuccess | GetAllError | GetStatsSuccess | GetStatsError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear | ClearCurrent;
