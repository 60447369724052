<form [formGroup]="form" (submit)="changePassword()" autocomplete="off" class="d-flex justify-content-start" id="changePasswordForm">
  <div class="inner-content sm">
    <h2 class="d-md-none">
      Change Password
    </h2>

    <div class="d-md-flex">
      <!-- Current Password -->
      <mat-form-field class="mr-md-2">
        <mat-label>Old Password</mat-label>
        <input matInput id="changePasswordForm_currentPassword" formControlName="currentPassword" autocomplete="off" type="password" (focus)="showCurrentPasswordHint = true" (blur)="showCurrentPasswordHint = false" />
        <mat-hint *ngIf="showCurrentPasswordHint">For security, please re-enter your password.</mat-hint>
        <mat-error *ngIf="!showCurrentPasswordHint && form.get('currentPassword').hasError('required')">
          Current password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <!-- New Password -->
      <mat-form-field>
        <mat-label>New Password</mat-label>
        <input matInput id="changePasswordForm_newPassword" formControlName="newPassword" autocomplete="off" type="password" (focus)="showNewPasswordHint = true" (blur)="showNewPasswordHint = false" />
        <mat-hint *ngIf="showNewPasswordHint">
          Password must be longer than 4 characters and contain at least one number
        </mat-hint>
        <mat-error *ngIf="!showNewPasswordHint && form.get('newPassword').hasError('pattern') && !form.get('newPassword').hasError('required')">
          Password is invalid
        </mat-error>
        <mat-error *ngIf="!showNewPasswordHint && form.get('newPassword').hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Error -->
    <mat-error *ngIf="errorMsg">
      {{errorMsg}}
    </mat-error>

    <div class="btn-row">
      <button type="submit" id="changePasswordForm_saveButton" mat-flat-button color="primary" [disabled]="loading">
        <ng-container *ngIf="!loading">Change Password</ng-container>
        <app-loader size="small" *ngIf="loading"></app-loader>
      </button>
    </div>

  </div>
</form>
