import { ISampleStore } from './sample/sample.store';
import { IRewardStore } from './reward/reward.store';
import { IProfileStore } from './profile/profile.store';
import { IStore as IProductTestStore } from './product-test/product-test.store';
import { ICommunityStore } from './community';
import { IRecruitStore } from './recruit/recruit.store';
import * as ProfileQuestionReducers from './profile/profile-question.reducers';

export interface RootStore {
  sample: ISampleStore;
  reward: IRewardStore;
  profile: IProfileStore;
  questions: ProfileQuestionReducers.State;
  productTests: IProductTestStore;
  community: ICommunityStore;
  recruit: IRecruitStore;
}

export const ROOT_STORE = {
  questions: ProfileQuestionReducers.profileQuestionReducer,
}
