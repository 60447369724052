import { FirebaseConfiguration, Environment } from './configuration.models'

export const firebaseStagingConfig: FirebaseConfiguration = {
  apiKey: 'AIzaSyDBQOHT1OOEQpIk0idQbDQm0j2rn37k_XE',
  authDomain: 'npanel-staging.firebaseapp.com',
  databaseURL: 'https://npanel-staging.firebaseio.com',
  projectId: 'npanel-staging',
  storageBucket: "npanel-staging.appspot.com",
  messagingSenderId: "1001214536081",
  appId: "1:1001214536081:web:98d4fcf6a0986f329b1230"
}

export const firebaseDevConfig: FirebaseConfiguration = {
  apiKey: "AIzaSyABZ4BvJ-OzGtK8lubtI5sE7IkSJHO7xwo",
  authDomain: "npanel-dev.firebaseapp.com",
  databaseURL: "https://npanel-dev.firebaseio.com",
  projectId: "npanel-dev",
  storageBucket: "npanel-dev.appspot.com",
  messagingSenderId: "1069387663438",
  appId: "1:1069387663438:web:aaf57e9e91d08964"
}

export const firebaseProdConfig: FirebaseConfiguration = {
  apiKey: 'AIzaSyCXe5JzZyJpV_wSgL6gg0NDnq4HkK-1lp4',
  authDomain: 'npanel-prod.firebaseapp.com',
  databaseURL: 'https://npanel-prod.firebaseio.com',
  projectId: 'npanel-prod',
  storageBucket: "npanel-prod.appspot.com",
  messagingSenderId: "734148993043",
  appId: "1:734148993043:web:1e21eaeea10f3022049fe6"
}
