import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuAction, MenuLink, UserRole } from 'src/app/data.models';
import { LoggerService } from './logger.service';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  //Keep the actual Subject obfuscated as a private variable, but allow public visibility as an Observable
  private close$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public get close(): Observable<boolean> {
    return this.close$.asObservable();
  }

  //Keep the actual Subject obfuscated as a private variable, but allow public visibility as an Observable
  private _actions: MenuAction[] = [];
  private actions$: BehaviorSubject<MenuAction[]> = new BehaviorSubject([]);
  public get actions(): Observable<MenuAction[]> {
    return this.actions$.asObservable();
  }

  private location$: BehaviorSubject<string> = new BehaviorSubject('');
  public get location(): Observable<string> {
    return this.location$.asObservable();
  }
  public currLocation: string;

  private subLocation$: BehaviorSubject<string> = new BehaviorSubject('');
  public get subLocation(): Observable<string> {
    return this.subLocation$.asObservable();
  }
  public currSubLocation: string;

  public menuData: { [key: string]: MenuLink[], defaultMenu: MenuLink[] } = {
    sample: [
      {
        path: '/sample/projects',
        label: 'Projects'
      },
      {
        path: '/sample/distributions',
        label: 'Distributions',
        restrict: UserRole.Admin
      },
      {
        path: '/sample/batch-updates',
        label: 'Batch Updates'
      },
      {
        path: '/sample/settings',
        label: 'Settings',
        restrict: UserRole.SuperAdmin
      }
    ],
    profile: [
      {
        path: '/profile/profilers',
        label: 'Profilers'
      },
      {
        path: '/profile/questions',
        label: 'Questions'
      }
    ],
    reward: [
      {
        path: '/reward/providers',
        label: 'Providers'
      },
      {
        path: '/reward/profiles',
        label: 'Profiles'
      },
      {
        path: '/reward/rewards',
        label: 'Rewards'
      },
      {
        path: '/reward/batch-deliver',
        label: 'Batch Deliver'
      },
      {
        path: '/reward/giveaways',
        label: 'Giveaways'
      },
      //{
      //  path: '/reward/export',
      //  label: 'Export'
      //},
    ],
    monetize: [
      {
        path: '/monetize/dashboard',
        label: 'Dashboard'
      },
      {
        path: '/monetize/respondents',
        label: 'Respondents'
      },
      {
        path: '/monetize/surveys',
        label: 'Surveys'
      },
      {
        path: '/monetize/logs',
        label: 'Logs'
      },
      {
        path: '/monetize/settings',
        label: 'Settings',
        restrict: UserRole.Admin
      },
    ],
    account: [
      {
        path: '/account/manage-users',
        label: 'Manage Users',
        restrict: UserRole.Admin
      },
      {
        path: '/account/change-details',
        label: 'Change Details'
      },
      {
        path: '/account/change-password',
        label: 'Change Password'
      }
    ],
    community: [
      {
        path: '/community/members',
        label: 'Members'
      },
      {
        path: '/community/blacklists',
        label: 'Blacklists'
      },
      {
        path: '/community/tests',
        label: 'Tests'
      }
    ],
    "product-tests": [
      {
        path: '/product-tests/campaigns',
        label: 'Campaigns'
      },
      {
        path: '/product-tests/products',
        label: 'Products'
      },
      {
        path: '/product-tests/brands',
        label: 'Brands'
      },
      {
        path: '/product-tests/sellers',
        label: 'Sellers'
      },
      {
        path: '/product-tests/tests',
        label: 'Tests'
      },
    ],
    "recruit": [
      {
        path: '/recruit/campaigns',
        label: 'Campaigns'
      },
      {
        path: '/recruit/sources',
        label: 'Sources'
      },
      {
        path: '/recruit/site-images',
        label: 'Site Images'
      },
      {
        path: '/recruit/blog-posts',
        label: 'Blog Posts'
      },
    ],
    defaultMenu: []
  };

  constructor(
    private router: Router,
    private logger: LoggerService
  ) {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects || event.url;
        const loc: string = this.getLoc(url);
        if (loc) {
          this.menuData[loc] = this.menuData[loc] || this.menuData.defaultMenu;
        }
        if (loc !== this.currLocation) {
          this.location$.next(this.currLocation = loc);
        }
        const subLoc: string = this.getSubLoc(url);
        if (subLoc !== this.currSubLocation) {
          this.subLocation$.next(this.currSubLocation = subLoc);
        }

      }
    });
  }

  /**
   * @method MenuService.getMenuData
   * @description Returns the set of MenuLinks relating to the given location and filtered by user access
   */
  getMenuData(loc: string, role: UserRole = UserRole.All): MenuLink[] {
    const menu = this.menuData[loc];

    return menu.filter(item => {
      return typeof item.restrict === 'undefined' || item.restrict >= role;
    });
  }

  /**
   * @method MenuService.getLoc
   * @description Returns the current top-level location in the site, used for authorisation
   */
  getLoc(url: string): string {
    let loc = url;
    if (url.charAt(0) === '/') {
      loc = url.substring(1);
    }
    const end: number = loc.search(/[\(\/\?\#]/);
    return loc.substring(0, end > -1 ? end : loc.length);
  }

  /**
   * @method MenuService.getSubLoc
   * @description Returns the current second-level location in the site
   */
  getSubLoc(url: string): string {
    let loc = url;
    if (url.charAt(0) === '/') {
      loc = url.substring(1);
    }

    const start: number = loc.indexOf('/') + 1;
    if (start < 2) {
      return null;
    }
    loc = loc.substring(start, loc.length);
    if (loc.length > 1) {
      const end: number = loc.search(/[\(\/\?\#]/);
      return loc.substring(0, end > -1 ? end : loc.length);
    }
    return null;
  }

  /**
   * @method MenuService.search
   * @description Search the router tree for localNav data
   */
  search(route: ActivatedRouteSnapshot): any {
    let data = route.data;
    if (!data.area && route.parent) {
      return this.search(route.parent);
    }
    return data.area;
  }

  /**
   * @method MenuService.registerActions
   * @description A method to add action buttons to the header navigation
   */
  registerActions(actions: MenuAction[]): void {
    //Removed: we do this in the component in case User.role changes throughout the session
    //let allowed = actions.filter(a => !isDefined(a.restricted) || this.userService.hasPermission(a.restricted));
    this._actions.push(...actions)
    this.actions$.next(this._actions);
  }

  /**
   * @method MenuService.clearActions
   * @description Remove any action buttons in the header navigation area
   */
  clearActions(): void {
    this.actions$.next(this._actions = []);
  }

  /**
   * @method MenuService.closeMenu
   * @description Emits an event which tells subscribers to close any open menus
   */
  closeMenu(): void {
    this.close$.next(true);
  }

  /**
   * @method MenuService.callAction
   * @description Call the method assigned to a given action with context if it was requested by the action registrar
   */
  callAction(action: MenuAction): void {
    if (action.action) {
      action.action.call(action.context || this);
    }
  }

}
