<form [formGroup]="loginForm" (submit)="login()" id="loginForm">

  <!-- Email -->
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput
           id="loginForm_email"
           formControlName="email"
           [errorStateMatcher]="matcher"
           type="email" />
    <!--<mat-hint>Hint</mat-hint>-->
    <mat-error *ngIf="showErrors && loginForm.get('email').dirty && loginForm.get('email').hasError('email') && !loginForm.get('email').hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="showErrors && loginForm.get('email').dirty && loginForm.get('email').hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <!-- Password -->
  <mat-form-field class="pb-2">
    <mat-label>Password</mat-label>
    <input matInput
           id="loginForm_password"
           formControlName="password"
           [errorStateMatcher]="matcher"
           type="password"
           (focus)="showPasswordHint = true"
           (blur)="showPasswordHint = false" />
    <mat-error *ngIf="showErrors && loginForm.get('password').dirty && loginForm.get('password').hasError('pattern') && !loginForm.get('password').hasError('required')">
      Password must be longer than 4 characters and contain at least one number
    </mat-error>
    <mat-error *ngIf="showErrors && loginForm.get('password').dirty && loginForm.get('password').hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <!-- Error Message -->
  <mat-error *ngIf="errorMsg" class="py-2">
    {{errorMsg}}
  </mat-error>

  <!-- Submit -->
  <div class="btn-row" [hidden]="hideButtons" [class.d-flex]="!hideButtons">
    <div class="d-flex align-items-center justify-content-start">
      <button type="submit" mat-stroked-button id="loginForm_loginButton" color="primary">Login</button>
      <a class="text-primary text-link ml-3" (click)="forgotPassword()">Forgot password?</a>
    </div>
  </div>

</form>
