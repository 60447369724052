import { Action } from '@ngrx/store';
import * as Models from 'src/app/data.models';

export enum Types {
  Get           = '[SampleBatch] Get',
  GetSuccess    = '[SampleBatch] GetSuccess',
  GetError      = '[SampleBatch] GetError',
  GetAll        = '[SampleBatch] GetAll',
  GetAllSuccess = '[SampleBatch] GetAllSuccess',
  GetAllError   = '[SampleBatch] GetAllError',
  GetArchived   = '[SampleBatch] GetArchived',
  Create        = '[SampleBatch] Create',
  CreateSuccess = '[SampleBatch] CreateSuccess',
  CreateError   = '[SampleBatch] CreateError',
  Delete        = '[SampleBatch] Delete',
  DeleteSuccess = '[SampleBatch] DeleteSuccess',
  DeleteError   = '[SampleBatch] DeleteError',
  Update        = '[SampleBatch] Update',
  UpdateSuccess = '[SampleBatch] UpdateSuccess',
  UpdateError   = '[SampleBatch] UpdateError',
  Clear         = '[SampleBatch] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() { }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: Models.SampleModels.SampleBatch[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: Models.SampleModels.SampleBatch) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: Models.SampleModels.SampleBatch) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: Models.SampleModels.SampleBatch) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public payload: Models.SampleModels.SampleBatch) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: Models.SampleModels.SampleBatch) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetArchived | GetAll | GetAllSuccess | GetAllError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
