<app-dialog>
  <div dialog-title>
    {{data?.title || 'Alert!'}}
  </div>
  <div dialog-content class="w-100">
    <form (ngSubmit)="confirm()">
      <div class="inner text-center" [innerHtml]="data?.body|sanitize:'html':true"></div>
      <div class="btn-row justify-content-center" *ngIf="!data?.ctas || !data?.ctas.length">
        <button mat-stroked-button type="button" (click)="close()">{{data?.closeText || 'Cancel'}}</button>
        <button mat-flat-button color="primary" type="submit">Continue</button>
      </div>
      <div class="btn-row justify-content-center" *ngIf="data?.ctas && data?.ctas.length">
        <button *ngFor="let cta of data?.ctas"
                class="mx-1"
                mat-flat-button
                [color]="cta.color || 'primary'"
                [ngClass]="cta.cssClasses ? cta.cssClasses : ''"
                type="button"
                (click)="confirm(cta.data)">
          {{cta.text}}
        </button>
      </div>
    </form>
  </div>
  <div dialog-actions>
  </div>
</app-dialog>
