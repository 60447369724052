<mat-nav-list color="primary" class="p-0">
  <ng-container *ngFor="let link of menuLinks">
    <a *ngIf="userHasAccess(link)"
       mat-list-item [routerLink]="link.path"
       routerLinkActive="active"
       [ngClass]="link.area"
       (click)="closeSideNav()">
      <mat-icon>{{link.icon}}</mat-icon>
      <span>{{link.label}}</span>
    </a>
  </ng-container>
</mat-nav-list>
