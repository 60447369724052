import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService, DialogService, UserService, LoggerService } from 'src/app/services';
import { User } from 'src/app/data.models';

@Component({
  selector: 'app-nav-user',
  templateUrl: './nav-user.component.html'
})
export class NavUserComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall])
    .pipe(map((result: BreakpointState) => {
      return result.matches;
    }));
  public user: User;
  public get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private userService: UserService,
    private dialogService: DialogService,
    private router: Router,
    private logger: LoggerService
  ) { }

  public login() {
    this.dialogService.open('login');
  }
  public signup() {
    this.dialogService.open('signup');
  }

  public logout() {
    this.authService.logout();
  }

  ngOnInit() {
    this.subs.push(this.userService.user$.subscribe(user => {
      if (user) {
        this.user = user;
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
