import * as Reducers from '../reducers';
import * as Actions from './sample-batch.actions';
import { SampleBatch } from '../../sample.models';
import { extendObj } from '../../data.models';

export interface State extends Reducers.State<SampleBatch> {
  requestedAllAt?: number;
}

export const initialState: State = {
  data: null, archive: null, count: 0, map: {}, errors: {},
  requestedAllAt: 0,
};

export function sampleBatchReducer(state: State = initialState, action: Actions.Any): State {

  const newState: State = extendObj(true, {}, state);

  newState.map = Object.keys(newState.map).reduce((map, key) => {
    map[key] = new SampleBatch(newState.map[key]);
    return map;
  }, {});

  let id = "";
  switch (action.type) {

    case Actions.Types.GetSuccess:
    case Actions.Types.UpdateSuccess:
      newState.errors = { ...newState.errors, ...(action.payload ? { [action.payload.id || ""]: null } : {}) };
    case Actions.Types.CreateSuccess:
      newState.map = { ...newState.map, ...(action.payload ? { [action.payload.id || ""]: action.payload } : {}) };
      newState.errors = { ...newState.errors, create: null };
      break;


    case Actions.Types.GetAllSuccess:
      newState.requestedAllAt = new Date().getTime();
      newState.map = Reducers.map(action.payload);
      newState.errors = { ...newState.errors, all: null };
      break;


    case Actions.Types.DeleteSuccess:
      newState.map = {
        ...newState.map,
        [action.id]: new SampleBatch({
          ...newState.map[action.id],
          queuedForDeletion: true
        })
      };
      break;

      
    case Actions.Types.GetAllError:
      newState.map = {};
      newState.data = [];
    case Actions.Types.CreateError:
    case Actions.Types.GetError:
    case Actions.Types.DeleteError:
    case Actions.Types.UpdateError:
      newState.errors = {
        ...state.errors,
        [action.type === Actions.Types.GetAllError ? 'all'
         : action.type === Actions.Types.CreateError ? 'create'
         : action.id]: action.error
      };
      break;


    case Actions.Types.Clear:
      return initialState;

    default:
      return state;
  }
  newState.data = Object.values(newState.map).filter(b => !b.archived);
  newState.archive = Object.values(newState.map).filter(b => b.archived);
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  !!newState.data && newState.data.length || (newState.data = null);
  return newState;
}
