<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer
               class="sidenav mat-elevation-z1"
               fixedInViewport="true"
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="!initialLoading && (!user || (isHandset$ | async)) ? 'push' : 'side'"
               [opened]="initialLoading || (!!user && !(isHandset$ | async))">
    <skeleton-loader [loading]="initialLoading" [layout]="skeletonLayout">
      <mat-toolbar class="logo">
        <a routerLink="/" class="mat-primary" (click)="closeSideNav()">
          <!--{{siteName}}-->
          <ng-container *ngIf="customer?.logoPath?.downloadURL; else defaultLogo">
            <div class="avatar-preview sm mx-auto">
              <div class="avatar-preview-inner" [style.backgroundImage]="'url(' + customer?.logoPath?.downloadURL + ')'"></div>
            </div>
          </ng-container>
          <ng-template #defaultLogo>
            <mat-icon [class.loaded]="!loading">track_changes</mat-icon>
          </ng-template>
        </a>
      </mat-toolbar>

      <app-nav-global (close)="closeSideNav()"></app-nav-global>
    </skeleton-loader>

  </mat-sidenav>
  <mat-sidenav-content [class.m-0]="!initialLoading && !user">

    <app-nav-local #localNav [loading]="loading" [user]="user" (toggle)="drawer.toggle()"></app-nav-local>
    
    <div class="main-content" *ngIf="!loading">
      <ng-content></ng-content>
      <!--<app-loader class="main-loader" [loading]="busy" [overlay]="true"></app-loader>-->
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
