import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/data.models';
import { AuthService, UserService } from 'src/app/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public user: User;
  public busy: boolean;
  public loaded: boolean;
  public loadingTimer: any;

  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) { }

  public setBusy(busy) {
    this.busy = busy;
  }
  public onError($event) {
    this.busy = false;
  }
  public onSuccess($event) {
    this.busy = false;
  }
  private startTimer(clearOnly?: boolean) {
    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer);
    }
    if (!clearOnly) {
      this.loadingTimer = setTimeout(() => this.loaded = true, 3000);
    }
  }
  ngOnInit() {
    this.subs.push(
      this.authService.user$.subscribe(user => {
        this.user = user;
        if (user) {
          this.loaded = true;
          this.startTimer(true);
        }
        else {
          this.startTimer();
        }
      }),
      this.userService.user$.subscribe(user => {
        this.user = user;
        if (user) {
          this.loaded = true;
          this.startTimer(true);
        }
        else {
          this.startTimer();
        }
      })
    );
    this.startTimer();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
