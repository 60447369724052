import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap, startWith } from 'rxjs/operators';
import * as RewardProviderActions from './reward-provider.actions';
import { RewardProviderService } from 'src/app/services/reward/reward-provider.service';

@Injectable()
export class RewardProviderEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProviderActions.Types.GetAll),
      mergeMap(() => this.rewardProviderService.getAll()
        .pipe(
          map(providers => (new RewardProviderActions.GetAllSuccess(providers))),
          catchError((err: Error) => of(new RewardProviderActions.GetAllError(err)))
        ))
    ));

  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProviderActions.Types.GetArchived),
      mergeMap(() => this.rewardProviderService.getAll(true)
        .pipe(
          map(providers => (new RewardProviderActions.GetAllSuccess(providers))),
          catchError((err: Error) => of(new RewardProviderActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProviderActions.Types.Get),
      mergeMap((action: RewardProviderActions.Get) => this.rewardProviderService.getByID(action.id)
        .pipe(
          map(provider => (new RewardProviderActions.GetSuccess(provider))),
          catchError((err: Error) => of(new RewardProviderActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProviderActions.Types.Create),
      mergeMap((action: RewardProviderActions.Create) => this.rewardProviderService.create(action.payload)
        .pipe(
          map((provider) => (new RewardProviderActions.CreateSuccess(provider))),
          catchError((err: Error) => of(new RewardProviderActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProviderActions.Types.Update),
      mergeMap((action: RewardProviderActions.Update) => this.rewardProviderService.update(action.payload)
        .pipe(
          map((provider) => (new RewardProviderActions.UpdateSuccess(provider))),
          catchError((err: Error) => of(new RewardProviderActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProviderActions.Types.Delete),
      mergeMap((action: RewardProviderActions.Delete) => this.rewardProviderService.remove(action.id)
        .pipe(
          map(() => (new RewardProviderActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new RewardProviderActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private rewardProviderService: RewardProviderService
  ) { }
}
