<mat-card *ngIf="!user; else welcomeUser" class="inner-content xs mr-auto ml-auto mt-sm-2">
  <ng-container *ngIf="loaded; else loading">
    <mat-card-title>
      Login
    </mat-card-title>
    <app-login-form #loginForm [showErrors]="false" [hideButtons]="false" (success)="onSuccess($event)" (error)="onError($event)" (setBusy)="setBusy($event)"></app-login-form>
    <app-loader [loading]="busy" [overlay]="true"></app-loader>
  </ng-container>
  <ng-template #loading>
    <app-loader></app-loader>
  </ng-template>
</mat-card>

<ng-template #welcomeUser>
  <div class="inner-content md mr-auto ml-auto">
    <app-nav-global class="home-nav"></app-nav-global>
  </div>
</ng-template>

<router-outlet></router-outlet>
