<app-dialog [closeOnOverlayClick]="true">
  <div dialog-title>Question Preview</div>
  <div dialog-content>
    <div class="inner-content md pt-0">
      <div class="d-md-flex justify-content-between align-items-end">
        <mat-form-field class="w-md-auto">
          <mat-label>Language</mat-label>
          <mat-select [id]="'questionDetailsPreviewDialog_' + question?.name + '_language'" [(value)]="selectedLanguage">
            <mat-option *ngFor="let language of languages" [value]="language">
              {{language}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="question-preview">
        <app-loader [loading]="loading"></app-loader>
        <app-question-picker [hidden]="loading" [question]="question" [language]="selectedLanguage"></app-question-picker>
      </div>
    </div>
  </div>
  <div dialog-actions class="d-flex justify-content-center align-items-center w-100">
      <button mat-stroked-button color="primary" (click)="close()">Done</button>
  </div>
</app-dialog>
