import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Customer, CustomerBase, FormSelectData, platformAliasMap, PlatformAliasMap, PlatformList, PlatformType, platformTypes, SampleModels, User } from 'src/app/data.models';
import { AlertService, CustomerService, DialogService, LoggerService, UserService, UtilService } from 'src/app/services';

@Component({
  selector: 'app-add-edit-customer-dialog',
  templateUrl: './add-edit-customer.component.html'
})
export class AddEditCustomerDialog implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private uploadLogoSub: Subscription;
  private loadingTimer: any;
  public form: FormGroup;
  public errorMsg: string;
  public title: string = 'Add Customer';
  public editMode: boolean;
  public showPasswordHint: boolean;
  public user: User;
  public customer: Customer;
  public platforms: PlatformType[] = platformTypes;
  public platformMap: PlatformAliasMap = platformAliasMap;
  public availableRoles: FormSelectData[] = [];
  public selectedPlatforms: PlatformList = {};

  public distributions: SampleModels.SampleDistributionBase[] = [];
  public allDistributions: SampleModels.SampleDistributionBase[] = [];
  public selectedDistributions: SampleModels.SampleDistributionBase[] = [];
  markLogoForRemoval: boolean;
  refreshingTokens: boolean;
  refreshPanelServicesTokensSub: Subscription;

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private logger: LoggerService,
    private fb: FormBuilder,
    private util: UtilService,
    private alert: AlertService,
    public dialogRef: MatDialogRef<AddEditCustomerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public refreshPanelServicesTokens() {
    this.refreshingTokens = true;
    if (this.refreshPanelServicesTokensSub) {
      this.refreshPanelServicesTokensSub.unsubscribe();
    }
    if (this.customer && this.customer.id) {
      this.subs.push(
        this.refreshPanelServicesTokensSub = this.customerService
          .update(this.customer.id, {
            panelAdminUrl: this.form.get("panelAdminUrl").value || this.customer.panelAdminUrl,
            panelServicesEmail: this.form.get("panelServicesEmail").value || this.customer.panelServicesEmail,
            panelServicesPassword: this.form.get("panelServicesPassword").value || this.customer.panelServicesPassword,
          })
          .pipe(take(1), switchMap(() => this.customerService.refreshPanelServicesTokens(this.customer.id)), catchError((error: Error) => {
            this.logger.log(error);
            this.refreshingTokens = false;
            return of(false);
          }))
          .subscribe((success: boolean) => {
            if (success) {
              this.alert.success('Customer tokens refreshed!');
            }
            this.refreshingTokens = false;
          })
      );
    }
  }

  public createCustomer() {
    this.errorMsg = '';
    this.dialogService.setBusy();
    this.subs.push(
      this.customerService.create(
        this.form.value as CustomerBase
      ).pipe(map(resp => true), catchError((error: Error) => {
        this.logger.log(error);
        this.errorMsg = error.message;
        return of(false);
      })).subscribe((success: boolean) => {
        if (success) {
          this.alert.success('Customer successfully created!');
          this.dialogService.setBusy(false);
          this.close();
        }
        else {
          this.dialogService.setBusy(false);
        }
      })
    );
  }

  public close(): void {
    this.dialogService.close();
  }

  public saveCustomer() {
    this.errorMsg = '';
    this.dialogService.setBusy();

    if (this.form.valid) {
      if (this.uploadLogoSub) {
        this.uploadLogoSub.unsubscribe();
      }
      const updates: CustomerBase = this.form.value;
      for (var prop in updates) {
        updates[prop] = updates[prop] || null;
      }
      let obs: Observable<boolean>;
      if (this.logo) {
        obs = this.customerService.setLogo(this.logo, this.customer.id).pipe(switchMap(task => {
          this.uploadProgress = task.complete ? 0 : task.progress > this.uploadProgress ? task.progress : (this.uploadProgress < 90 ? this.uploadProgress + 3 : this.uploadProgress);
          if (!task.complete) {
            return of(false);
          }
          return of(true);
        }));
      }
      else if (this.markLogoForRemoval && this.customer.logoPath) {
        obs = this.customerService.removeLogo(this.customer.logoPath.path, this.customer.id).pipe(map(() => true));
      }
      else {
        obs = of(true);
      }
      this.subs.push(
        this.uploadLogoSub = obs.pipe(switchMap((resp) => {
          if (resp) {
            return this.customerService.update(this.customer.id, updates).pipe(map(resp => true));
          }
          return of(null);
        }), catchError((error: Error) => {
          this.logger.log(error);
          this.errorMsg = error.message;
          return of(false);
        }))
          .subscribe((done: boolean) => {
            if (done) {
              this.alert.success('Customer successfully updated!');
              this.close();
            }
            else if (done === false) {
              this.dialogService.setBusy(false);
            }
          })
      );
    }
    else {
      this.errorMsg = "Please check the form and try again.";
      this.util.markFormGroupTouched(this.form);
      this.dialogService.setBusy(false);
    }
  }

  public delete() {
    if (this.editMode) {
      this.dialogService.setBusy(true);
      this.subs.push(
        this.customerService.deleteCustomer(this.customer.id).subscribe(resp => {
          this.dialogService.setBusy(false);
          if (resp) {
            this.close();
          }
          else {
            this.errorMsg = "There was an error deleting this customer.";
          }
        }, err => {
          this.dialogService.setBusy(false);
          this.errorMsg = err.message;
        })
      );
    }
  }

  public generatePassword() {
    const rnd = Math.round(Math.random() * new Date().getTime())
    this.form.get('password').setValue('P_' + rnd);
  }

  uploadProgress: number = 0;
  logo: File;
  public updateLogo(logo: File) {
    this.logo = logo;
    this.markLogoForRemoval = false;
  }

  public removeLogo() {
    this.logo = null;
    this.markLogoForRemoval = true;
  }

  ngOnInit() {
    this.dialogService.setBusy();
    this.dialogService.setCurrentDialog(this.dialogRef);
    this.loadingTimer = setTimeout(() => this.dialogService.setBusy(false), 1000);

    for (let platform of this.platforms) {
      this.selectedPlatforms[this.platformMap[platform].value] = true;
    }

    this.form = this.fb.group({
      name: ['', [Validators.required]],
      panelAdminUrl: ['', [Validators.required]],
      panelServicesEmail: ['', [Validators.required, Validators.email]],
      panelServicesPassword: ['', [Validators.required]],
      supplyOptimizerUrl: ['', [Validators.required]],
      supplyOptimizerPassword: ['', [Validators.required]],
      monetizePlatforms: this.fb.group(this.selectedPlatforms),
      access: this.fb.group({
        monetize: false,
        profile: false,
        reward: false,
        sample: false,
        invite: false,
        media: false,
        community: false,
        productTests: false,
        recruit: false
      })
    });
    this.customer = new Customer();
    this.subs.push(
      this.form.get('access').valueChanges.subscribe(access => {
        this.logger.log(access);
        if (access.community || access.productTests) {
          this.form.get('panelAdminUrl').setValidators(Validators.required);
        }
        else {
          this.form.get('panelAdminUrl').clearValidators();
        }
        this.form.get('panelAdminUrl').updateValueAndValidity();
      }),
      this.dialogRef.beforeClosed().subscribe(resp => {
        this.close();
      }),
      this.route.queryParams.subscribe(params => {
        const id = params["customerId"];
        if (id) {
          this.loadingTimer && clearTimeout(this.loadingTimer);
          this.subs.push(this.customerService.getByID(id).subscribe(
            (customer: Customer) => {
              this.editMode = true;
              this.title = "Edit Customer: " + customer.name;
              this.customer = customer;
              this.form.patchValue(customer);
              this.selectedPlatforms = {};
              for (let platform of this.platforms) {
                this.selectedPlatforms[platform] = !this.customer.monetizePlatforms || typeof this.customer.monetizePlatforms[platform] === 'undefined' || this.customer.monetizePlatforms[platform];
              }
              this.form.get('monetizePlatforms').patchValue(this.selectedPlatforms);
              this.dialogService.setBusy(false, 300);
            },
            err => {
              this.close();
              this.logger.log(err.message);
              this.dialogService.setBusy(false);
            }));
        }
      }),
      this.userService.user$.subscribe(user => {
        this.user = user;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
