import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse, HttpResponse, HttpEventType, HttpParams } from '@angular/common/http';
import * as Sentry from "@sentry/angular";

import { Observable, of, from, throwError, BehaviorSubject, Subscription, interval, ReplaySubject } from 'rxjs';
import { map, switchMap, catchError, share, retry, skipWhile, mergeMap, tap } from 'rxjs/operators';

import { LoggerService } from './logger.service';

import { environment } from 'src/environments/environment';

export type ReturnObjectOptions = 'all' | 'body';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  /**
    * @property ApiService.httpOptions
    * @description A set of standardised HttpRequest options
    */
  private httpOptions: HttpOptions = {
    responseType: 'json'
  }
  public currentPoll: Poll = {};
  private verbose: boolean;

  constructor(
    private http: HttpClient,
    private logger: LoggerService
  ) { }

  public cancelPoll() {
    if (this.currentPoll) {
      this.currentPoll.subject && this.currentPoll.subject.complete();
      this.currentPoll.subscription && this.currentPoll.subscription.unsubscribe();
    }
    this.currentPoll = {};
  }

  public poll<T>(
    url: string, testFn: (resp: T) => boolean,
    params?: { [param: string]: string }, baseUrl?: string, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors?: boolean,
    int: number = 1000, infinite?: boolean
  ): Observable<T> {
    this.cancelPoll();
    this.currentPoll.subject = this.currentPoll.subject || new ReplaySubject<T>(1);

    this.currentPoll.subscription = this.runPoll<T>(url, testFn, params, baseUrl, returnObj, options, handleErrors, infinite)
      .pipe(switchMap(() => {
        return interval(int)
          .pipe(switchMap(() => {
            return this.runPoll<T>(url, testFn, params, baseUrl, returnObj, options, handleErrors, infinite);
          }));
      }))
      .subscribe();
    return this.currentPoll.subject.asObservable();
  }

  private runPoll<T>(
    url: string, testFn: (resp: T) => boolean,
    params?: { [param: string]: string }, baseUrl?: string, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors?: boolean, infinite?: boolean
  ): Observable<T> {
    return this.get<T>(url, params, baseUrl, returnObj, options, handleErrors)
      .pipe(
        tap((resp) => {
          if (resp && (!testFn || testFn(resp))) {
            this.currentPoll.subject.next(resp);
            if (!infinite) {
              this.currentPoll.subscription.unsubscribe();
            }
          }
        }),
        catchError((err) => {
          this.logger.log(err);
          this.currentPoll.subscription.unsubscribe();
          if (handleErrors) {
            return of(null);
          }
          return throwError(err);
        }),
        share()
      );
  }

  public get<T = any>(url: string, params?: { [param: string]: string }, baseUrl?: string, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors: boolean = true): Observable<T> {
    let opts: HttpOptions = { ...this.httpOptions, ...(options || {}) };
    if (params) {
      opts.params = new HttpParams({ fromObject: params });
    }
    let req = new HttpRequest<T>('GET', url, opts);
    return this.sendRequest<T>(req, baseUrl, returnObj, handleErrors);
  }

  public post<T>(url: string, data: T, nestAsData: boolean = true, baseUrl?: string, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors: boolean = true): Observable<T> {
    let postData: any = data;
    if (nestAsData) {
      postData = { data: data };
    }
    let req = new HttpRequest<T>('POST', url, postData, { ...this.httpOptions, ...(options || {}) });
    return this.sendRequest<T>(req, baseUrl, returnObj, handleErrors);
  }

  public patch<T>(url: string, data: T, nestAsData: boolean = true, baseUrl?: string, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors: boolean = true): Observable<T> {
    let putData: any = data;
    if (nestAsData) {
      putData = { data: data };
    }
    const req = new HttpRequest<T>('PATCH', url, putData, { ...this.httpOptions, ...(options || {}) });
    return this.sendRequest<T>(req, baseUrl, returnObj, handleErrors);
  }

  public put<T>(url: string, data: T, nestAsData: boolean = true, baseUrl?: string, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors: boolean = true): Observable<T> {
    let putData: any = data;
    if (nestAsData) {
      putData = { data: data };
    }
    const req = new HttpRequest<T>('PUT', url, putData, { ...this.httpOptions, ...(options || {}) });
    return this.sendRequest<T>(req, baseUrl, returnObj, handleErrors);
  }

  public delete<T>(url: string, params?: { [param: string]: string }, baseUrl?: string, returnObj: ReturnObjectOptions = "all", options?: HttpOptions, handleErrors: boolean = true): Observable<T> {
    let opts: HttpOptions = { ...this.httpOptions, ...(options || {}) };
    if (params) {
      opts.params = new HttpParams({ fromObject: params });
    }
    let req = new HttpRequest<T>('DELETE', url, opts);
    return this.sendRequest<T>(req, baseUrl, returnObj, handleErrors);
  }

  /**
   * @method ApiService.sendRequest
   * @description A standardised call to the server which adds any generic information to the request
   * @param req The request to be issued
   * @param T The type of object to be returned
   * @param baseUrl (optional) The base API url. These are mainly stored in the environment object and the default is environment.panelServicesUrl
   */
  private sendRequest<T>(req: HttpRequest<T>, baseUrl?: string, returnObj?: ReturnObjectOptions, handleErrors?: boolean): Observable<T> {

    //Add the environment specific server URL to the front of the request URL
    const r = req.clone<T>({
      url: (baseUrl || environment.panelServicesUrl) + req.url,
    });

    const allowSentry = true;
    let transaction;
    let span;
    if (allowSentry) {
      transaction = Sentry.startTransaction({
        name: "API Request",
        description: `${r.method} ${r.url}`,
        tags: { type: r.method },
      });

      span = transaction.startChild({
        tags: { phase: "request" },
        data: {
          request: {
            params: r.params,
            url: r.url,
            body: r.body,
            method: r.method,
          }
        },
        op: r.method.toUpperCase(),
        description: `Send Request`,
      });
    }

    if (this.verbose) {
      this.logger.log('send HttpResponse');
    }
    return this.http.request<T>(r)
      .pipe(
        share(),
        //Skip HttpClient Event notifications
        skipWhile((resp: HttpResponse<T>) => {
          return resp.type < HttpEventType.Response;
        }),
        map((resp: any) => {
          if (this.verbose) {
            this.logger.log('map HttpResponse');
          }
          if (allowSentry) {
            span.finish();
            const span2 = transaction.startChild({
              tags: { phase: "response" },
              data: {
                response: resp
              },
              op: r.method.toUpperCase(),
              description: `Handle Response`,
            });
            span2.finish();
            transaction.finish();
          }
          if (returnObj === 'all') {
            return resp as any;
          }
          if (returnObj === 'body') {
            return resp.body;
          }
          return typeof resp.body.data !== "undefined" ? resp.body.data : resp.body || resp;
        }),
        catchError((resp: HttpErrorResponse) => {
          if (allowSentry) {
            span.finish();
            const span2 = transaction.startChild({
              tags: { phase: "response" },
              data: {
                response: resp
              },
              op: r.method.toUpperCase(),
              description: `Catch Error Response`,
            });
            span2.finish();
          }
          if (handleErrors) {
            return this.handleError(resp);
          }
          return throwError(resp);
        }),
        share()
      );
  }

  /**
   * @method ApiService.handleError
   * @description A standardised error handler for failed API calls
   * @param error The error to be handled
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    let resp;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      this.logger.error('An error occurred:', error.error.message);
      resp = (error && error.error || error) as HttpErrorResponse;
      if (!resp.status || (resp.status !== 401 && resp.status !== 422)) {
        Sentry.captureException(resp, { extra: { handled: true, request_url: error.url }, tags: { "api_response": true, "api_origin": error.url ? new URL(error.url).origin : "unknown" } });
      }
      return throwError(error.error);
    }
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    this.logger.warn(
      `Server returned error code ${error.status}, ` +
      `body was: ${error.error}`);
    try {
      resp = error && error.error || error;
      let e: any = new Error(resp || "An error occurred");
      if (resp.error) {
        e = resp.error;
      }
      if ((resp.errors && resp.errors.length) || (resp.data)) {
        let msg = resp.message ? resp.message + ": " : "";
        let connector = "";

        (<any[]>resp.errors || Object.keys(resp.data)).forEach((e) => {
          if (e) {
            if (e.data) {
              msg += connector + Object.values(e.data).join(connector = ". ");
            }
            else if (e.message || typeof e === "string") {
              msg += connector + (e.message || resp.data[e]);
              connector = ". ";
            }
          }
        });
        e = new Error(msg || "An error occurred");
      }
      if (!resp.status || (resp.status !== 401 && resp.status !== 422)) {
        Sentry.captureException(e, { extra: { handled: true, request_url: error.url }, tags: { "api_response": true, "api_origin": error.url ? new URL(error.url).origin : "unknown" } });
      }
      return throwError(e);
    }
    catch (e) {
      this.logger.warn(e)
      Sentry.captureException(error && error.error || error, { extra: { handled: true } });
      return throwError(error && error.error || error);
    }
  }
}

export interface HttpOptions {
  headers?: HttpHeaders;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

export interface Poll {
  subject?: ReplaySubject<any>;
  subscription?: Subscription;
}
