import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { PartnerSurveyAttempt, PartnerSurveyPagerFilters, PartnerSurveyStatus } from 'src/app/components/community/community.models';
import { PagedHttpResponse, PagerConfig } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Pager } from 'src/app/services/paging.service';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityPartnerService {

  private endpoint: string = 'partner_survey_attempts';

  private cacheMap: { [id: string]: PartnerSurveyAttempt } = {};

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  process(id: number, status: PartnerSurveyStatus) {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/process/' + id, { status }, false, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        catchError((err: any) => {
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method CommunityPartnerService.getByID
   * @description Retrieves a PartnerSurveyAttempt
   * @returns Observable<PartnerSurveyAttempt>
   */
  public getByID(id?: number | string): Observable<PartnerSurveyAttempt> {
    return of(this.cacheMap[id + ""] || null);
  }

  /**
   * @method CommunityPartnerService.getAll
   * @description Retrieves a list of all PartnerSurveyAttempt, filtered by the params set
   * @returns Observable<PagedHttpResponse<PartnerSurveyAttempt>>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<PartnerSurveyAttempt>> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + (params || ''), {}, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        tap((resp: PagedHttpResponse<PartnerSurveyAttempt>) => {
          this.logger.log(resp);
          if (resp.data && resp.data.length) {
            resp.data.forEach(d => {
              this.cacheMap[d.id + ""] = d;
            });
          }
        }),
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: PartnerSurveyPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }

}
