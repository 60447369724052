import { Action } from '@ngrx/store';
import { ActivityReport } from 'src/app/components/recruit/recruit.models';

export enum Types {
  Get                   = '[RecruitActivityReport] Get',
  GetSuccess            = '[RecruitActivityReport] GetSuccess',
  GetError              = '[RecruitActivityReport] GetError',
  //                        RecruitActivityReport
  GetAll                = '[RecruitActivityReport] GetAll',
  GetAllSuccess         = '[RecruitActivityReport] GetAllSuccess',
  GetAllError           = '[RecruitActivityReport] GetAllError',
  //                        RecruitActivityReport
  Create                = '[RecruitActivityReport] Create',
  CreateSuccess         = '[RecruitActivityReport] CreateSuccess',
  CreateError           = '[RecruitActivityReport] CreateError',
  //                        RecruitActivityReport
  Delete                = '[RecruitActivityReport] Delete',
  DeleteSuccess         = '[RecruitActivityReport] DeleteSuccess',
  DeleteError           = '[RecruitActivityReport] DeleteError',
  //                        RecruitActivityReport
  Update                = '[RecruitActivityReport] Update',
  UpdateSuccess         = '[RecruitActivityReport] UpdateSuccess',
  UpdateError           = '[RecruitActivityReport] UpdateError',
  //                        RecruitActivityReport
  Clear                 = '[RecruitActivityReport] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() {
    console.log("Initiate: " + this.type);
  }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: ActivityReport[], public filters?: any) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) {
    console.log("Initiate: " + this.type);
  }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: ActivityReport) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: ActivityReport) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: ActivityReport) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: string, public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: ActivityReport) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetAll | GetAllSuccess | GetAllError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
