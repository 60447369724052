import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer, FormSelectData, User, UserRole } from 'src/app/data.models';
import { AlertService, AuthService, CustomerService, DialogService, LoggerService, UserService, UtilService } from 'src/app/services';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-add-edit-user-dialog',
  templateUrl: './add-edit-user.component.html'
})
export class AddEditUserDialog implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    role: 2,
    customerId: '',
    lastName: '',
    firstName: '',
    allowAllServices: false,
    access: this.fb.group({
      monetize: false,
      profile: false,
      reward: false,
      sample: false,
      invite: false,
      media: false,
      community: false,
      productTests: false,
      recruit: false
    }),
    panelAdminEmail: '',
    panelAdminPassword: '',
  });
  errorMsg: string;
  title: string = 'Sign Up';
  availableRoles: FormSelectData[] = [];
  customer: Customer;
  currentUser: User;
  user: User;
  editor: User;
  editMode: boolean;
  sendingResetPasswordEmail: boolean;
  showPanelAdminFields: boolean;
  panelSections: string[] = ["invite", "community", "productTests", "recruit"];

  get isCurrentUser() {
    return !!this.currentUser && !!this.user && this.currentUser.authId === this.user.authId;
  }

  get allowedServicesCount() {
    if (this.customer) {
      return Object.values(this.customer.access).filter(a => a).length;
    }
    return 0;
  }

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private logger: LoggerService,
    private fb: FormBuilder,
    private util: UtilService,
    private alert: AlertService,
  ) { }

  public sendResetPasswordEmail() {
    if (this.user) {
      this.sendingResetPasswordEmail = true;
      this.subs.push(
        this.authService.triggerResetPassword(this.user.email).subscribe(resp => {
          this.sendingResetPasswordEmail = false;
          if (resp) {
            this.alert.info('A reset password email has been sent to ' + this.user.email);
          }
        }, err => {
          this.alert.failure('Reset password email failed to send. ' + err.message);
          this.sendingResetPasswordEmail = false;
        })
      );
    }
  }

  public setRoles(user: User) {
    if (user) {
      this.currentUser = user;
      //If we haven't already set the customerId from the query params, set it from the current user
      if (!this.form.get('customerId').value) {
        this.form.get('customerId').setValue(user.customerId);
      }

      if (user.role === UserRole.User) {
        this.availableRoles = [];
      }
      else if (user.role < UserRole.User) {
        this.availableRoles = [
          {
            value: UserRole.User,
            viewValue: "User"
          },
          {
            value: UserRole.Admin,
            viewValue: "Admin"
          }
        ];
        if (user.role === UserRole.SuperAdmin) {
          this.availableRoles.push({
            value: UserRole.SuperAdmin,
            viewValue: "Super Admin"
          });
        }
      }
    }
  }

  public saveUser() {
    this.dialogService.setBusy(true);
    if (this.currentUser.canEditCustomer(this.form.get('customerId').value)) {
      this.setAccess();
      let user = this.util.copyObj(true, this.form.value);
      if (!this.isCurrentUser) {
        delete user.email;
      }
      this.subs.push(
        this.userService.updateUser(this.userId, user).subscribe(updated => {
          if (updated) {
            this.alert.success('User successfully updated!');
            this.close();
          }
          else {
            this.dialogService.setBusy(false);
          }
        }, (err) => {
          this.logger.log(err);
          this.alert.failure(err && err.message || "An error occurred, please check the form and try again");
          this.dialogService.setBusy(false);
        })
      );
    }
    else {
      this.dialogService.setBusy(false);
      this.errorMsg = "You do not have permission to edit this user."
    }
  }

  public createUser() {
    this.errorMsg = '';
    this.dialogService.setBusy();
    this.setAccess();
    if (this.form.valid) {
      this.subs.push(
        this.authService.createUser(this.form.value).subscribe((success: boolean) => {
          if (success) {
            this.alert.success('User successfully created!');
            this.close();
          }
          this.dialogService.setBusy(false);
        }, (err) => {
          this.logger.log(err);
          this.alert.failure(err && err.message || "An error occurred, please check the form and try again");
          this.dialogService.setBusy(false);
        })
      );
    }
    else {
      this.dialogService.setBusy(false);
      this.errorMsg = "Please check the form and try again";
    }
  }

  userId: string;
  getUser(id: string) {
    this.userId = id;
    this.errorMsg = '';
    this.dialogService.setBusy();
    this.subs.push(
      this.userService.getUser(this.userId).pipe(take(1)).subscribe(userData => {
        this.user = userData;
        this.editMode = true;
        this.dialogService.setBusy(false);
        this.form.patchValue(userData);
        this.setVisibility();
      }, err => {
        this.dialogService.setBusy(false);
        this.dialogService.close();
      })
    );
  }

  public close(): void {
    this.dialogService.close();
  }

  public setVisibility() {
    const accessList = this.getAccessList();
    const hasPASections = this.panelSections.findIndex((section) => !!accessList[section]) > -1;
    if (hasPASections) {
      this.showPanelAdminFields = true;
    }
    else {
      this.showPanelAdminFields = false;
    }
  }

  getAccessList() {
    if (this.form.get('allowAllServices').value) {
      return this.customer.access;
    }
    else {
      const accessList = {};
      for (let value in this.customer.access) {
        accessList[value] = this.customer.access[value] && this.form.get('access.' + [value]) ? this.form.get('access.' + [value]).value : this.customer.access[value];
      }
      return accessList;
    }
  }

  public setAccess() {
    if (this.customer) {
      const accessList = this.getAccessList();
      this.form.get('access').patchValue(accessList);
      this.setVisibility();
    }
  }

  private getCustomerSub: number;
  private loadingTimer: any;

  ngOnInit() {
    this.dialogService.setBusy();
    this.subs.push(
      this.form.get("customerId").valueChanges.subscribe((customerId: string) => {
        if (!this.customer || this.customer.id !== customerId) {
          this.getCustomerSub = typeof this.getCustomerSub !== 'undefined' ? this.getCustomerSub : this.subs.length;
          this.subs[this.getCustomerSub] = this.customerService.getByID(customerId).subscribe((customer: Customer) => {
            this.customer = customer;
            this.setAccess();
          });
        }
      }),
      this.form.get("allowAllServices").valueChanges.subscribe((allowAll: boolean) => {
        if (allowAll) {
          this.form.get("access").disable();
        }
        else {
          this.form.get("access").enable();
        }
        this.setVisibility();
      }),
      this.form.get("access").valueChanges.subscribe(() => {
        this.setVisibility();
      }),
      this.route.queryParams.subscribe(params => {
        if (params.customerId) {
          this.form.get("customerId").setValue(params.customerId);
        }
        if (params.id) {
          this.title = "Edit User";
          this.getUser(params.id);
        }
      }),
      this.userService.user$.subscribe((user: User) => {
        if (user) {
          this.title = "Create User";
          this.setRoles(user);
          if (!this.editor) {
            this.dialogService.setBusy(false);
            this.editor = user;
            if (this.loadingTimer) {
              clearTimeout(this.loadingTimer);
            }
          }
        }
      })
    );
    this.loadingTimer = setTimeout(() => this.dialogService.setBusy(false), 2000);
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
