import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageCode, Question } from 'src/app/data.models';
import { DialogService, LoggerService, QuestionService } from 'src/app/services';


@Component({
  selector: 'app-question-preview-dialog',
  templateUrl: './question-preview-dialog.component.html'
})
export class QuestionPreviewDialog implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  private closeTimer: any;
  private id: string;

  public errorMsg: string;
  public question: Question;
  public loading: boolean;
  public selectedLanguage: string;
  public languages: LanguageCode[] = [];

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private questionService: QuestionService,
    private logger: LoggerService,
  ) {}

  public close() {
    this.dialogService.close();
  }

  public getQuestion(id: string) {
    this.loading = true;
    this.subs.push(
      this.questionService.getByID(id).subscribe(
        question => {
          if (question) {
            this.question = question;
            this.loading = false;
            this.languages = [];
            for (let lang in this.question.prompt) {
              this.languages.push(lang as LanguageCode);
            }
            this.selectedLanguage = this.languages[0];
            this.logger.log(question);
          }
        },
        err => {
          this.loading = false;
          this.logger.error(err.message);
        }
      )
    );
  }
  ngOnInit() {
    this.subs.push(
      this.route.queryParams.subscribe(params => {
        this.id = params['qid'] || this.id;
        if (this.closeTimer) clearTimeout(this.closeTimer);
        if (this.id) {
          this.getQuestion(this.id);
        }
        else {
          this.closeTimer = setTimeout(() => this.close(), 100);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
