import { Observable, of, from, throwError, BehaviorSubject, interval, Subscription, Subject } from 'rxjs';
import { map, switchMap, catchError, flatMap, takeWhile, skipWhile, retry, takeLast, take } from 'rxjs/operators';

import { CustomerService } from './customer.service';

import {  } from 'src/app/data.models';

export class SupplyOptimizerService {

  public supplyOptimizerPassword: string;
  public supplyOptimizerUrl: string;

  public get password(): string {
    return '?pw=' + this.supplyOptimizerPassword;
  }

  constructor(
    private _customerService: CustomerService,
    //private endpoint: string = 'sampleProject'
  ) {
    this._customerService.customer$.subscribe(customer => {
      if (customer) {
        this.supplyOptimizerPassword = customer.supplyOptimizerPassword;
        this.supplyOptimizerUrl = customer.supplyOptimizerUrl;
      }
    });
  }

  public waitForPassword() {
    return interval(100)
      .pipe(map(() => this.supplyOptimizerPassword))
      .pipe(skipWhile((pw) => !pw))
      .pipe(take(1));
  }
}
