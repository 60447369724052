interface SampleServiceBase<T> {
  endpoint: string;
}

export class SampleService<T> implements SampleServiceBase<T> {

  constructor(
    public endpoint: string
  ) {}
}
