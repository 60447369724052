import { Pipe, PipeTransform } from '@angular/core';

import { TranslatableTextObject, LanguageCode } from 'src/app/data.models';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  transform(value: TranslatableTextObject, lang: LanguageCode = "en-US"): any {
    if (!value) return '';

    return value[lang];
  }
}
