import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import * as RewardActions from './reward.actions';
import { RewardService } from 'src/app/services/reward/reward.service'

@Injectable()
export class RewardEffects {

  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardActions.Types.Get),
      mergeMap((action: RewardActions.Get) => this.rewardService.getByID(action.id)
        .pipe(
          map(reward => (new RewardActions.GetSuccess(reward))),
          catchError((err: Error) => of(new RewardActions.GetError(action.id, err)))
        ))
    ));

  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardActions.Types.Update),
      mergeMap((action: RewardActions.Update) => this.rewardService.update(action.payload)
        .pipe(
          map((reward) => (new RewardActions.UpdateSuccess(reward))),
          catchError((err: Error) => of(new RewardActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardActions.Types.Delete),
      mergeMap((action: RewardActions.Delete) => this.rewardService.archive(action.id)
        .pipe(
          map(() => (new RewardActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new RewardActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private rewardService: RewardService
  ) { }
}
