import { Action } from '@ngrx/store';
import * as Models from 'src/app/data.models';

export enum Types {
  Get           = '[SampleDistribution] Get',
  GetSuccess    = '[SampleDistribution] GetSuccess',
  GetError      = '[SampleDistribution] GetError',
  GetAll        = '[SampleDistribution] GetAll',
  GetAllSuccess = '[SampleDistribution] GetAllSuccess',
  GetAllError   = '[SampleDistribution] GetAllError',
  GetArchived   = '[SampleDistribution] GetArchived',
  GetForProject = '[SampleDistribution] GetForProject',
  GetStats        = '[SampleDistribution] GetStats',
  GetStatsSuccess = '[SampleDistribution] GetStatsSuccess',
  GetStatsError   = '[SampleDistribution] GetStatsError',
  CreateSuccess = '[SampleDistribution] CreateSuccess',
  CreateError   = '[SampleDistribution] CreateError',
  DeleteSuccess = '[SampleDistribution] DeleteSuccess',
  DeleteError   = '[SampleDistribution] DeleteError',
  UpdateSuccess = '[SampleDistribution] UpdateSuccess',
  UpdateError   = '[SampleDistribution] UpdateError',
  Clear         = '[SampleDistribution] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() { }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: Models.SampleModels.SampleDistribution[], public projectID?: string) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error, public projectID?: string) { }
}

export class GetForProject implements Action {
  readonly type = Types.GetForProject;
  constructor(public projectID: string) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: Models.SampleModels.SampleDistribution) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class GetStats implements Action {
  readonly type = Types.GetStats;
  constructor(public payload: Models.SampleModels.SampleProfile) { }
}

export class GetStatsSuccess implements Action {
  readonly type = Types.GetStatsSuccess;
  constructor(public id: string, public payload: Models.SampleModels.SampleStatsBase) { }
}

export class GetStatsError implements Action {
  readonly type = Types.GetStatsError;
  constructor(public id: string, public error: Error) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: Models.SampleModels.SampleDistribution) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: Models.SampleModels.SampleDistribution) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetArchived | GetAll | GetAllSuccess | GetAllError | GetStats | GetStatsSuccess | GetStatsError | DeleteSuccess | DeleteError | CreateSuccess | CreateError | UpdateSuccess | UpdateError | Clear;
