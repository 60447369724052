import {
  formatPercent as _formatPercent,
  formatDate as _formatDate,
  formatCurrency as _formatCurrency,
  formatNumber as _formatNumber,
} from '@angular/common';
import { TemplateRef } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { isNaN } from 'lodash';
import { Observable } from 'rxjs';
import * as SampleModels from 'src/app/sample.models';
export * from './skeleton.models';
import { DataObj, LanguageCode, QuestionType } from './base.models';
import * as RewardModels from './components/reward/reward.models';

export { Answer, APIBase, ApiDefaults, Base, currencies, Currency, CurrencyType, DataObj, DataSpec, LanguageCode, Languages, QuestionType, QuestionTypes, Range } from './base.models';
export { RewardModels };
export { SampleModels };


export enum UserRole {
  SuperAdmin = 0,
  Admin = 1,
  User = 2,
  All = 3 //This should always be the last entry; new roles replace this entry and move this one to the end of the enum
}

export enum FavoritesTypes {
  ProductTestsProduct = 'product-tests-product',
  ProductTestsCampaign = 'product-tests-campaign',
  ProductTestsTest = 'product-tests-test',
  RewardProfiles = 'reward-profiles',
  RewardGiveaways = 'reward-giveaways',
  SampleProjects = 'sample-projects',
  Profilers = 'profilers',
  RecruitCampaigns = 'recruit-campaigns',
  RecruitSources = 'recruit-sources',
}

export type AccessArea = "monetize" | "profile" | "reward" | "sample" | "community" | "invite" | "media" | "productTests" | "recruit";
export interface AccessList {
  monetize?: boolean;
  profile?: boolean;
  reward?: boolean;
  sample?: boolean;
  community?: boolean;
  invite?: boolean;
  media?: boolean;
  productTests?: boolean;
  recruit?: boolean;
}
export interface UserBase {
  authId?: string;             //Firebase user ID
  email?: string;
  firstName?: string;
  lastName?: string;
  created?: number;            //Date stamp
  inactive?: boolean;
  expires?: number;            //Date stamp
  updated?: number;             //Date stamp
  role?: UserRole;
  customerId?: string;         //Firebase customer ID
  panelServicesToken?: string;
  panelServicesSurveyToken?: string;
  access?: AccessList;
  allowAllServices?: boolean;
  panelAdminEmail?: string;
  panelAdminPassword?: string;
}
export class User extends DataObj implements UserBase {
  role: UserRole;
  customerId: string;               //Firebase customer ID
  authId: string;                   //Firebase user ID
  panelServicesToken: string;
  panelServicesSurveyToken: string; //Used for survey preview url
  email: string;
  firstName: string;
  lastName: string;
  created: number;            //Date stamp
  updated: number;            //Date stamp
  inactive: boolean;
  allowAllServices: boolean;
  access: AccessList;
  panelAdminEmail: string;
  panelAdminPassword: string;

  get getRole() {
    return isDefined(this.role) ? this.role : UserRole.User;
  }

  canEditCustomer(cid: string) {
    return this.role === UserRole.SuperAdmin || (this.role === UserRole.Admin && this.customerId === cid);
  }

  constructor(data?: UserBase) {
    super([
      { name: 'role', defaultValue: UserRole.User },
      'customerId',
      'authId',
      'email',
      'firstName',
      'lastName',
      'inactive',
      'access',
      { name: 'allowAllServices', defaultValue: false },
      { name: 'created', defaultValue: new Date().getTime() },
      { name: 'updated', defaultValue: new Date().getTime() },
      'panelAdminEmail',
      'panelAdminPassword',
    ], data);
    if (!this.access) {
      this.access = {
        monetize: false,
        profile: false,
        reward: false,
        sample: false,
        community: false,
        invite: false,
        media: false,
        productTests: false,
        recruit: false,
      };
    }
  }

  extend(data) {
    this["__patch__"](data);
    return this;
  }
}

export interface DataItem<T = any> {
  value: string | number;
  viewValue: string;
  data?: T;
  id?: string;
}
export interface FormSelectData<T = any> extends DataItem { }
export interface SortableListItem<T = any> extends DataItem { }
export interface SortableListAction<T = any> extends MenuAction {
  action: (item?: T, index?: number) => any;
}

export interface AutocompleteInputSelectionEvent {
  option: string;
  list: string[];
}

export interface Form {
  save: () => any;
  form: FormGroup | NgForm;
}


export interface CustomerBase {
  id?: string;                     // Firebase generated ID
  panelServicesPassword?: string;  // Provided by AWS
  panelServicesEmail?: string;
  name?: string;
  created?: number;                // Date stamp
  updated?: number;                // Date stamp
  inactive?: boolean;
  monetizePlatforms?: PlatformList;
  supplyOptimizerUrl?: string;
  supplyOptimizerPassword?: string;
  sampleProfileSettings?: SampleModels.SampleProfileSettings;
  sampleFilterOptions?: SampleModels.SampleProfileFilterData[]; //legacy - to be deleted
  defaultSampleProfileIncludes?: SampleModels.SampleFilterOptionsList;  //legacy - to be deleted
  defaultSampleProfileExcludes?: SampleModels.SampleFilterOptionsList;  //legacy - to be deleted
  access?: AccessList;
  logoPath?: UploadTask;
  panelAdminUrl?: string;
}

export class Customer extends DataObj implements CustomerBase {
  id: string;
  panelServicesPassword: string;
  panelServicesEmail: string;
  name: string;
  created: number;
  updated: number;
  inactive: boolean;
  monetizePlatforms: PlatformList;
  supplyOptimizerUrl: string;
  supplyOptimizerPassword: string;
  sampleProfileSettings: SampleModels.SampleProfileSettings;
  access: AccessList;
  logoPath: UploadTask;
  panelAdminUrl: string;

  constructor(data?: any) {
    super([
      'id',
      'name',
      'panelServicesEmail',
      'panelServicesPassword',
      'supplyOptimizerUrl',
      'supplyOptimizerPassword',
      { name: 'sampleProfileSettings' },
      { name: 'inactive', defaultValue: false },
      { name: 'monetizePlatforms', defaultValue: {} },
      { name: 'access', defaultValue: null },
      { name: 'created', defaultValue: new Date().getTime() },
      { name: 'updated', defaultValue: new Date().getTime() },
      { name: 'logoPath', defaultValue: null },
      'panelAdminUrl',
    ], data);
    if (!this.access) {
      this.access = {
        monetize: false,
        profile: false,
        reward: false,
        sample: false,
        community: false,
        invite: false,
        media: false,
        productTests: false,
        recruit: false
      };
    }
    // ToDo: AdamA: This is a legacy update since we renamed automate => monetize. Can remove this in the future
    //if (typeof this.access["automate"] !== "undefined") {
    //  this.access["monetize"] = this.access["automate"];
    //  delete this.access["automate"];
    //}
  }
}

export interface UploadedFile {
  projectId?: string;
  organizationId?: string;
  uploadedAt?: number;
  uploadedBy?: string;
  downloadURL?: string;
  name?: string;
  filetype?: string;
  path?: string;
}

export interface UploadTask extends UploadedFile {
  progress?: number;
  complete?: boolean;
  failed?: boolean;
}

export interface Profiler {
  listQuestionsId?: string[];
  questions?: Observable<Question>[];
  codeSnippet?: string;
  callbackType?: "async" | "sync";
  hideAnsweredQuestions?: boolean;
  questionOrder?: null;
  introMessage?: TranslatableTextObject;
  id?: string;
  name?: string;
  archived?: boolean;
  exitMessage?: TranslatableTextObject;
  customerId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export type Side = 'top' | 'bottom' | 'left' | 'right' | 'none' | 'labeled';
export type Alignment = 'start' | 'center' | 'end';

export interface TextStyle {
  color?: string;
  fontSize?: number;
  fontName?: string;
  bold?: boolean;
  italic?: boolean;
}
export interface ChartLegend {
  position?: Side;
  textStyle?: TextStyle;
  alignnment?: Alignment;
  maxLines?: number;
}
export interface ChartAxis {
  direction?: number;
  title?: string;
  titleTextStyle?: TextStyle;
  textPosition?: 'out' | 'in' | 'none';
  textStyle?: TextStyle;
  baseline?: number;
  baselineColor?: string;
  gridlines?: {
    color?: string;
    count?: number;
  };
  minorGridlines?: {
    color?: string;
    count?: number;
  };
  minValue?: number;
  maxValue?: number;
  viewWindow?: {
    min?: number;
    max?: number;
  }
}
export interface ChartOptions {
  aggregationTarget?: 'category' | 'series' | 'auto' | 'none';
  annotations?: { textStyle?: TextStyle; alwaysOutside?: boolean; alwaysInside?: boolean; highContrast?: boolean; };
  curveType?: string;
  colors?: string[];
  focusTarget?: 'datum' | 'category';
  width?: number | string;
  height?: number;
  title?: string;
  enableInteractivity?: boolean;
  hAxes?: any;
  vAxes?: any;
  hAxis?: ChartAxis;
  vAxis?: ChartAxis;
  is3D?: boolean;
  legend?: ChartLegend;
  pieHole?: number;       //0.0 - 1.0
  pieSliceText?: 'percentage' | 'value' | 'label' | 'none';
  pieSliceTextStyle?: TextStyle;
  pieStartAngle?: number; //0 - 365
  reverseCategories?: boolean;
  pieResidueSliceColor?: string;
  backgroundColor?: string;
  chartArea?: { left?: number | string; top?: number | string; width?: number | string; height?: number | string; backgroundColor?: string; }; //string must be a percentage, e.g. "75%"
  pieResidueSliceLabel?: string;
  slices?: { color?: string; offset?: number; textStyle?: TextStyle; tooltip?: string; }[];
  sliceVisibilityThreshold?: number;
  tooltip?: {
    ignoreBounds?: boolean;
    isHtml?: boolean;
    showColorCode?: boolean;
    text?: 'both' | 'value' | 'percentage';
    textStyle?: TextStyle;
    trigger?: 'focus' | 'selection' | 'none';
  }
}

export interface ChartData {
  [rowName: string]: {
    [colName: string]: any;
  }
}

export interface ChartBase {
  title?: string;
  data: Array<Array<any>>;
  columnNames: any[];
  type: ChartType;
  options?: ChartOptions;
  loaded: boolean;
}

const charts = [];

export class Chart implements ChartBase {
  title: string;
  options: ChartOptions = {};
  type: ChartType;
  loaded: boolean;

  private id: number;

  constructor(public data: any[], public columnNames: any[]) {
    this.id = charts.push(this) - 1;
  }

  setOptions(options: ChartOptions) {
    for (let opt in options) {
      this.options[opt] = options[opt];
    }
    return this;
  }

  setData(data: ChartData) {
    const map = this.convertToChartData(data);
    this.data = map.data;
    this.columnNames = map.columnNames;
  }

  convertToChartData(table: ChartData) {
    const rows: string[] = [];
    const columns: string[] = [];
    const data = [];
    let template;
    for (let row in table) {
      template = table[row];
      rows.push(row);
    }
    for (let row of rows) {
      const dataRow = [row];
      for (let col in template) {
        columns.push(col);
        dataRow.push(table[row][col]);
      }
      data.push(dataRow);
    }
    return { columnNames: columns, rowNames: rows, data: data };
  }

  destroy() {
    delete charts[this.id];
  }
}

export class PieChart extends Chart {
  constructor(public data: any[], public columnNames: any[]) {
    super(data, columnNames);
    this.type = ChartType.Pie;
    this.options.chartArea = { top: '5%', height: '90%', left: '5%', width: '90%' };
  }
}

export class Sparkline extends Chart {
  constructor(public data: any[], public columnNames: any[]) {
    super(data, columnNames);
    this.type = ChartType.Sparkline;
    this.options.chartArea = { top: '5%', height: '90%', left: '5%', width: '90%' };
  }
}

export class BarChart extends Chart {
  constructor(public data: any[], public columnNames: any[]) {
    super(data, columnNames);
    this.type = ChartType.Bar;
    this.options.chartArea = { width: '70%' };
  }
}

export class ColumnChart extends Chart {
  constructor(public data: any[], public columnNames: any[]) {
    super(data, columnNames);
    this.type = ChartType.Column;
    this.options.chartArea = { width: '70%' };
  }
}

export class LineChart extends Chart {
  constructor(public data: any[], public columnNames: any[]) {
    super(data, columnNames);
    this.type = ChartType.Line;
    this.options.curveType = 'function';
    this.options.width = "100%"

  }
}

export class AreaChart extends Chart {
  constructor(public data: any[], public columnNames: any[]) {
    super(data, columnNames);
    this.type = ChartType.Area;
    this.options.curveType = 'function';
    this.options.width = "100%"
  }
}

export interface Dashboard {
  id?: string;
  created_at?: string;
  start_time?: string;
  end_time?: string;
  panel_name?: string;
  timezone?: string; // Internal property
  data?: DashboardData;
  tag?: string;
  populated?: boolean;
  loaded?: boolean;
  errorMsg?: string;
  charts?: Chart[];
}

export interface DashboardDataSessions {
  conversion_percent?: number;
  cpi_average?: number;
  cpi_sum?: number;
  entries_average?: number;
  entries_max?: number;
  entries_unexp_average?: number;
  entries_exp_dq_average?: number;
  entries_exp_qual_average?: number;
  epc?: number;
  expired_count?: number;
  expired_percent?: number;
  finished_buyer_entries_exp_dq_average?: number;
  finished_buyer_entries_exp_qual_average?: number;
  finished_buyer_entries_unexp_average?: number;
  finished_buyer_entries_average?: number;
  finished_buyer_entries_max?: number;
  finished_count?: number;
  finished_entries_exp_dq_average?: number;
  finished_entries_unexp_average?: number;
  finished_entries_exp_qual_average?: number;
  finished_entries_average?: number;
  finished_entries_max?: number;
  finished_percent?: number;
  length_exp_dq_75th?: number;
  length_exp_dq_average?: number;
  length_exp_dq_max?: number;
  length_exp_dq_median?: number;
  length_exp_dq_min?: number;
  length_exp_qual_75th?: number;
  length_exp_qual_average?: number;
  length_exp_qual_max?: number;
  length_exp_qual_median?: number;
  length_exp_qual_min?: number;
  length_unexp_75th?: number;
  length_unexp_average?: number;
  length_unexp_max?: number;
  length_unexp_median?: number;
  length_unexp_min?: number;
  started_count?: number;
  started_percent?: number;
  qualified_count?: number;
  qualified_percent?: number;
  total_count?: number;
  prescreen_failed_entries_exp_qual_average?: number;
  prescreen_failed_entries_exp_dq_average?: number;
  prescreen_failed_entries_unexp_average?: number;
  skipped_entries_exp_qual_average?: number;
  skipped_entries_exp_dq_average?: number;
  skipped_entries_unexp_average?: number;
  started_entries_exp_qual_average?: number;
  started_entries_exp_dq_average?: number;
  started_entries_unexp_average?: number;
}

export interface DashboardData {
  fulcrum_statuses?: any[];
  fulcrum_term_quals?: any[];
  spectrum_statuses?: any[];
  survey_entries?: DashboardSurveyEntries;
  sessions?: DashboardDataSessions;
  timestamp?: number;
}

export interface DashboardSurveyEntries {
  total: DashboardSurveyEntry;
  fulcrum?: DashboardSurveyEntry;
  rfg?: DashboardSurveyEntry;
  spectrum?: DashboardSurveyEntry;
  innovate?: DashboardSurveyEntry;
  peanut_labs?: DashboardSurveyEntry;
  your_surveys?: DashboardSurveyEntry;
  precision_sample?: DashboardSurveyEntry;
  sample_cube?: DashboardSurveyEntry;
  dynata?: DashboardSurveyEntry;
}

export interface DashboardSurveyEntry {
  buyer_started_count: number;
  buyer_finished_count: number;
  buyer_qualified_percent: number;
  conversion_percent: number;
  cpi_average: number;
  cpi_max: number;
  gross_profit: number;
  cpi_min: number;
  cpi_sum: number;
  cpi_sum_total: number;
  epc: number;
  failed_security_count: number;
  started_count: number;
  started_percent: number;
  finished_count: number;
  finished_percent: number;
  invalidated_count: number;
  invalidated_percent: number;
  loi_disqualified_average: number;
  loi_disqualified_max: number;
  loi_disqualified_median: number;
  loi_disqualified_75th: number;
  loi_disqualified_min: number;
  loi_qualified_average: number;
  loi_qualified_max: number;
  loi_qualified_median: number;
  loi_qualified_75th: number;
  loi_qualified_min: number;
  platform_fees: number;
  qualified_count: number;
  qualified_count_total: number;
  qualified_percent: number;
  requested_count: number;
  routed_count: number;
  targeted_count: number;
  total_count: number;
  prescreen_failed_count: number;
  prescreen_passed_count: number;
  skipped_count: number;
}

export interface UXTableData {
  name: string;
  completes?: string;
  terminates?: string;
  abandons?: string
}

export interface EarningsData {
  name: string;
  totalRevenue?: string;
  totalCompletes?: string;
}

export interface SurveyEntryData {
  name?: string | number;
  total?: string | number;
  fulcrum?: string | number;
  spectrum?: string | number;
  innovate?: string | number;
  peanut_labs?: string | number;
  your_surveys?: string | number;
  precision_sample?: string | number;
  dynata?: string | number;
  rfg?: string | number;
  sample_cube?: string | number;
  tooltip?: SurveyEntryData;
}

export interface ComplexFilterValue<T = string> {

  // For numbers matches only
  $gt?: number;
  $lt?: number;

  // For any matches
  $eq?: T;
  $in?: T[];
  $ex?: T[];

  // For string matches only
  $regex?: string;
  $options?: 'i'|'g'|'ig';
}

export interface SurveyPagerFilters {
  open?: boolean;
  blocked?: boolean;
  rank?: number | ComplexFilterValue<number>;
  platform?: string | ComplexFilterValue<string>;
  identifier?: string | ComplexFilterValue<string>;
}

export interface RespondentListsPagerFilters {
  ranked?: boolean;
  open?: boolean;
  blocked?: boolean;
  platform?: string; //PlatformType
  name?: string | ComplexFilterValue<string>;
  identifier?: string;
}

export interface LogsPagerFilters {
  type?: string | ComplexFilterValue<string>;
  message?: string | ComplexFilterValue<string>;
}

export interface PagerConfig {
  items?: number;
  page?: number;
  pages?: number;
  count?: number;
  sort?: string;
  reverse?: boolean;
  allowPolling?: boolean;
}

export interface PagedHttpResponse<T = any, F = any> extends PagerConfig {
  data?: T[];
  filters?: F;
}

export interface SurveyCache {
  id: string;
  created_at: string;
  surveys: any;
  populated: boolean;
}

export interface Survey {
  rank: number;
  identifier: string;
  open: boolean;
  blocked: boolean;
  platform: string;
  account: string;
  finish_rate: number;
  name: string;
  sessions: number;
  cpi: number;
  conversion: number;
  revenue: number;
  epc: number;
  properties: {
    AccountName?: string;
  };
  created_at: string;
  finishes: number;
  last_updated_at: string;
  p12h_respondents: number;
  qualifies: number;
  starts: number;
  filter_specs: {
    CPI: any;
  }
}

export const RouteTypes: RouteType[] = ["Routed Survey", "Targeted Survey", "Requested Survey", "No Survey"];
export type RouteType = "Routed Survey" | "Targeted Survey" | "Requested Survey" | "No Survey";

export type PlatformType = "fulcrum" | "spectrum" | "innovate" | "rfg" | "peanut_labs" | "your_surveys" | "precision_sample" | "dynata" | "sample_cube" | "prodege";
export const platformTypes: PlatformType[] = ["fulcrum", "spectrum", "innovate", "rfg", "peanut_labs", "your_surveys", "precision_sample", "dynata", "sample_cube", "prodege"];
export interface PlatformTypeMap {
  value: PlatformType;
  name: string;
}
export type PlatformAliasMap = {
  [key in PlatformType]: PlatformTypeMap;
};
export const platformAliasMap: PlatformAliasMap = {
  fulcrum: {
    value: "fulcrum",
    name: "Fulcrum",
  },
  spectrum: {
    value: "spectrum",
    name: "Pure Spectrum",
  },
  innovate: {
    value: "innovate",
    name: "Innovate",
  },
  rfg: {
    value: "rfg",
    name: "RFG",
  },
  peanut_labs: {
    value: "peanut_labs",
    name: "Peanut Labs",
  },
  your_surveys: {
    value: "your_surveys",
    name: "Your Surveys",
  },
  precision_sample: {
    value: "precision_sample",
    name: "Precision Sample",
  },
  dynata: {
    value: "dynata",
    name: "Dynata",
  },
  sample_cube: {
    value: "sample_cube",
    name: "Sample Cube",
  },
  prodege: {
    value: "prodege",
    name: "Prodege"
  }
}
export interface PlatformList<T = boolean> {
  fulcrum?: T;
  spectrum?: T;
  innovate?: T;
  rfg?: T;
  peanut_labs?: T;
  your_surveys?: T;
  precision_sample?: T;
  dynata?: T;
  sample_cube?: T;
  prodege?: T;
  total?: T;
}

export interface Log {
  created_at: string;
  type: string;
  message: string;
  survey_platform?: string;
  survey_identifier?: string;
  outcome: 'success' | 'fail';
}

export interface RespondentSurveyEntry {
  buyer: boolean;
  case_id: string;
  check_security: boolean;
  created_at: string; //date
  failed_speed_check: boolean;
  finish_params: { [key: number]: string };
  finish_time: number;
  finished: boolean;
  finished_at: string; //date
  invalidated: boolean;
  qualified: boolean;
  route_type: RouteType;
  security_check_status: string; //"Pending"|...
  started: boolean;
  survey_cpi: number;
  survey_group_ids: string[];
  survey_identifier: string;
  survey_platform: string;
  updated_at: string; //date
}

export interface Respondent {
  sessid: string;
  properties: any;
  expired: boolean;
  created_at: Date | string;
  length: number;
  pid: string;
  survey_entries_count: number;
  survey_identifiers: string[];
  route_types: RouteType[];
  qualified: boolean;
}

//export type ChartType = 'BarChart' | 'PieChart' | 'ColumnChart' | 'AreaChart' | 'Bubblechart' | 'LineChart' | 'Sparkline';
export enum ChartType {
  Bar = 'BarChart',
  Pie = 'PieChart',
  Column = 'ColumnChart',
  Area = 'AreaChart',
  Bubble = 'BubbleChart',
  Line = 'LineChart',
  Sparkline = 'Sparkline'
}


export interface RespondentList {
  id: string;
  created_at: string;
  name: string;
  file_url: string;
}

export interface Question {
  type?: QuestionType;
  allowMultiple?: boolean;
  categories?: string[];
  prompt?: TranslatableTextObject;
  id?: string;
  answerOptions?: TranslatableTextObject<AnswerOptions[]>;
  exclusiveAnswerOptions?: string[];
  name?: string;
  regExValidation?: string | null;
  regExValidationMessage?: TranslatableTextObject;
  customerId?: string;
  baseLogic?: string;
  answeOptionOrder?: string;      //TODO: AdamA: Check if this is this a typo in the backend
  answerOptionOrder?: string;
  derived?: boolean;
  derivationLogic?: AnswerOptions[];
}

export interface RandomOrderResponseObjectType {
  error: string;
  array: number[][] | number[];
  (randomOrderResponseObject: RandomOrderResponseObjectType): any;
}

export interface TranslatableInputItem<T = any> {
  language: LanguageCode | '';
  value: T;
  viewValue?: string;
}

export interface TranslatableInputOption {
  alias: any;
  label: string;
  type?: 'checkbox' | 'textstring';
}

export interface Dialog {
  name: string;
  queries?: Params;
  type: string;
  data?: any;
  action?: "open" | "close" | string;
  ref?: MatDialogRef<any, any>;
}

export interface FilterInputChangeEvent<T = any> {
  list: T[];
  value: string;
}

/**
 * @example
 * {
 *  "en-US": "<bold>In what year were you born?</bold>",
 *  "es-CO": "<bold>En que año nacio?</bold>"
 * }
 */
export interface TranslatableTextObject<T = string> {
  [key: string]: T;
}
export interface AnswerOptions {
  [key: string]: string;
}

export interface MenuLink<T = any> {
  path?: string;
  label: string;
  icon?: string;
  iconClass?: string;
  area?: AccessArea;
  hide?: (item?: T) => boolean;
  isHidden?: boolean;
  restrict?: UserRole;
  uncancelable?: boolean;
}


export interface MenuAction<T = any> extends MenuLink<T> {
  action: (item?: T) => any;
  onClose?: (item?: T) => any;
  context?: any;
  template?: TemplateRef<any>;
  templateClass?: string | Function;
}

export interface AWSResponse<T = any> {
  Items?: T[];
  Count?: number;
  Attributes?: T;
  data?: T[];
}

export type ListViewType = 'table' | 'grid' | 'none';

export interface TableColumn<T = any> {
  name: string;
  alias?: string;
  case?: "lower" | "upper" | "sentence" | "capitalise";
  cssClass?: string;
  iconClass?: string;
  compute?: (row?: T, column?: TableColumn) => any;
  action?: (row?: T, column?: TableColumn) => any;
  context?: any;
  defaultValue?: string | number;
  layout?: 'template' | 'boolean' | 'chips' | 'date' | 'string' | 'number' | 'bar' | 'bold' | 'html' | 'icon';
  sortable?: boolean;
  hide?: (row?: T, column?: TableColumn) => boolean;
  flag?: (row?: T, column?: TableColumn) => boolean;
  skeletonWidth?: number;
}

export interface TableAction<T = any> extends MenuAction<T> {
  action: (item?: T) => any;
}

export interface ErrorGroup {
  field?: string;
  error: Error;
}

export interface DialogRouteMap {
  [key: string]: DialogRoute;
}

export interface DialogRoute {
  component: any;
  dialog?: MatDialog;
  classes?: string[];
  name?: string;
  data?: any;
}

export interface AlertMessage {
  title?: string;
  body?: string;
  type?: string;
  ctas?: CTA[];
  closeText?: string; // default: "Cancel"
}

export interface CTA {
  text: string;
  cssClasses?: string;
  color?: "primary" | "accent" | "success" | "warn" | "dark" | "light";
  data?: any;
}

export interface PanelAdminApiToken {
  token: string;
  url: string;
  created: number;
}

export function isDefined(item: any) {
  return typeof item !== 'undefined';
}

export function trackByIndexFn(index, item) {
  return index;
}

export const PANEL_ADMIN_TOKEN_NAME = "panel_admin_jwt";

export const FRIENDLY_DATETIME_FORMAT = 'E, MMM d, yyyy hh:mma';
export const    SHORT_DATETIME_FORMAT = 'M/d/yy h:mma';
export const     LONG_DATETIME_FORMAT = 'MMM d, hh:mma';
export const     FRIENDLY_DATE_FORMAT = 'E, MMM d, yyyy';
export const        SHORT_DATE_FORMAT = 'M/d/yy';
export const         LONG_DATE_FORMAT = 'E, MMM d, yyyy';
export const        MONTH_DATE_FORMAT = 'MMMM yyyy';

export function extendObj<T = any>(...args: any[]): T;
export function extendObj<T = any>(deep: boolean, ...args: any[]): T;
export function extendObj<T = any>(...args: any[]): T {

  // Variables
  var extended = {};
  var deep = false;
  var i = 0;
  var length = arguments.length;

  // Check if a deep merge
  if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
    deep = arguments[0];
    i++;
  }

  // Merge the object into the extended object
  var merge = (obj) => {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        // If deep merge and property is an object, merge properties
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          extended[prop] = extendObj<T>(true, {}, obj[prop]);
        }
        else if (deep && Object.prototype.toString.call(obj[prop]) === '[object Array]') {
          extended[prop] = copyArray(obj[prop]);
        }
        else {
          extended[prop] = obj[prop];
        }
      }
    }
  };

  // Loop through each object and conduct a merge
  for (; i < length; i++) {
    var obj = arguments[i];
    merge(obj);
  }

  return extended as T;
}


export function copyArray(arr: any[]) {
  let copy = [];
  for (let i in arr) {

    if (Object.prototype.toString.call(arr[i]) === '[object Object]') {
      copy[i] = extendObj(true, {}, arr[i]);
    }
    else if (Object.prototype.toString.call(arr[i]) === '[object Array]') {
      copy[i] = copyArray(arr[i]);
    }
    else {
      copy[i] = arr[i];
    }
  }
  return copy;
}

export function formatDate(value: string | number | Date, defaultValue?: string, format?: string, timezone?: string, locale?: string): string {
  if (value) {
    return _formatDate(value, (format || SHORT_DATETIME_FORMAT), (locale || 'en-US'), timezone);
  }
  return defaultValue || "";
}

export function formatCurrency(value: number | string, defaultValue?: string, digitsInfo?: string, currency?: string, currencyCode?: string, locale?: string): string {
  if (isDefined(value)) {
    const num_value = +value;
    if (!isNaN(num_value)) {
      return _formatCurrency(num_value, (locale || 'en-US'), (currency || '$'), (currencyCode || 'USD'), (digitsInfo || '1.2-2'));
    }
  }
  return defaultValue || "";
}

export function formatPercent(value: number | string, defaultValue?: string, digitsInfo?: string, locale?: string): string {
  if (isDefined(value)) {
    const num_value = +value;
    if (!isNaN(num_value)) {
      return _formatPercent(num_value, (locale || 'en-US'), (digitsInfo || '1.0-1'));
    }
  }
  return defaultValue || "";
}

export function formatNumber(value: number | string, defaultValue?: string, digitsInfo?: string, locale?: string): string {
  if (isDefined(value)) {
    const num_value = +value;
    if (!isNaN(num_value)) {
      return _formatNumber(num_value, (locale || 'en-US'), (digitsInfo || '1.0-1'));
    }
  }
  return defaultValue || "";
}

