import { Action } from '@ngrx/store';
import * as Models from 'src/app/data.models';

export enum Types {
  Get           = '[ProfileProfiler] Get',
  GetSuccess    = '[ProfileProfiler] GetSuccess',
  GetError      = '[ProfileProfiler] GetError',

  GetAll        = '[ProfileProfiler] GetAll',
  GetAllSuccess = '[ProfileProfiler] GetAllSuccess',
  GetAllError   = '[ProfileProfiler] GetAllError',

  GetArchived   = '[ProfileProfiler] GetArchived',

  Create        = '[ProfileProfiler] Create',
  CreateSuccess = '[ProfileProfiler] CreateSuccess',
  CreateError   = '[ProfileProfiler] CreateError',
  
  Update        = '[ProfileProfiler] Update',
  UpdateSuccess = '[ProfileProfiler] UpdateSuccess',
  UpdateError   = '[ProfileProfiler] UpdateError',

  Clear         = '[ProfileProfiler] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() { }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: Models.Profiler[], public archive?: boolean) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: Models.Profiler) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: Models.Profiler) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: Models.Profiler) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public payload: Models.Profiler) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: Models.Profiler) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetArchived | GetAll | GetAllSuccess | GetAllError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
