import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessArea, User } from 'src/app/data.models';
import { AuthService, DialogService, LoggerService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class AuthAreaGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private snackBar: MatSnackBar,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    const area: AccessArea = route.data.area;

    return this.checkUser(area, url).pipe(map((hasAccess: boolean) => {
      if (!hasAccess) {
        this.logger.warn("AuthAreaGuard: ACESSS DENIED");
        this.dialogService.open('not-allowed');
      }
      return hasAccess;
    }));
  }

  checkUser(area: AccessArea, url: string): Observable<boolean> {
    if (this.authService.isLoggedIn) {
      //Return an Observable with the result of whether the user has the correct access level
      return of(this.hasAccess(area));
    }
    else {
      // Otherwise lets observer the authState in case a login is imminent
      return this.authService.user$.pipe(
        map((user: User) => {
          if (!user) {
            let queries;
            if (url) {
              //If the user was trying to access a url, store the attempted URL for redirecting later
              this.authService.redirectUrl = url;

              //Add the attempted url to the actual URL as a query string in case the page gets refreshed
              queries = { queryParams: { redirectUrl: url }, queryParamsHandling: 'merge' };
            }
            //Navigate to the login
            this.router.navigate(['/login'], queries);

            //Return false - the user is not logged in and has no access
            return false;
          }
          //Return the result of whether the user has the correct access level
          return this.hasAccess(area);
        })
      );
    }
  }

  hasAccess(area: AccessArea) {
    return this.authService.checkAccess(area)
  }

}
