import * as ProfileProfilerReducers from './profile-profiler.reducers';
import * as ProfileProfilerActions           from './profile-profiler.actions';
export { ProfileProfilerEffects }            from './profile-profiler.effects';

import * as ProfileQuestionReducers          from './profile-question.reducers';
import * as ProfileQuestionActions           from './profile-question.actions';
export { ProfileQuestionEffects }            from './profile-question.effects';

export { ProfileProfilerActions, ProfileProfilerReducers, ProfileQuestionReducers, ProfileQuestionActions }

export interface ProfileStore {
  profilers: any;
}

export const PROFILE_STORE: ProfileStore = {
  profilers: ProfileProfilerReducers.profileProfilerReducer,
}

export interface IProfileStore extends ProfileStore {
  profilers: ProfileProfilerReducers.State;
}
