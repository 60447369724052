import { Action } from '@ngrx/store';
import { BlogPost } from 'src/app/components/recruit/recruit.models';

export enum Types {
  Get                   = '[RecruitBlogPost] Get',
  GetSuccess            = '[RecruitBlogPost] GetSuccess',
  GetError              = '[RecruitBlogPost] GetError',
  //                        RecruitBlogPost
  GetAll                = '[RecruitBlogPost] GetAll',
  GetAllSuccess         = '[RecruitBlogPost] GetAllSuccess',
  GetAllError           = '[RecruitBlogPost] GetAllError',
  //                        RecruitBlogPost
  Create                = '[RecruitBlogPost] Create',
  CreateSuccess         = '[RecruitBlogPost] CreateSuccess',
  CreateError           = '[RecruitBlogPost] CreateError',
  //                        RecruitBlogPost
  Delete                = '[RecruitBlogPost] Delete',
  DeleteSuccess         = '[RecruitBlogPost] DeleteSuccess',
  DeleteError           = '[RecruitBlogPost] DeleteError',
  //                        RecruitBlogPost
  Update                = '[RecruitBlogPost] Update',
  UpdateSuccess         = '[RecruitBlogPost] UpdateSuccess',
  UpdateError           = '[RecruitBlogPost] UpdateError',
  //                        RecruitBlogPost
  Clear                 = '[RecruitBlogPost] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() {
    console.log("Initiate: " + this.type);
  }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: BlogPost[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) {
    console.log("Initiate: " + this.type);
  }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: BlogPost) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: BlogPost) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: BlogPost) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: string, public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: BlogPost) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetAll | GetAllSuccess | GetAllError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
