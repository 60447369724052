import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/data.models';
import { MenuService, UserService, SkeletonService } from 'src/app/services';
import { SkeletonRow, SkeletonColumn } from 'src/app/skeleton.models';


@Component({
  selector: 'app-nav-local',
  templateUrl: './nav-local.component.html'
})
export class NavLocalComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  @Output() show: EventEmitter<boolean> = new EventEmitter();

  @Output("toggle") onToggle: EventEmitter<boolean> = new EventEmitter();

  loading: boolean;
  @Input("loading") set _loading(loading: boolean) {
    this.loading = !!loading;
  }

  public user: User;
  @Input("user") set _user(user: User) {
    this.user = user;
    this.setLinks();
  }

  public showTabs: boolean;
  public sectionTitle: string = '';
  public location: string = '';
  public subLocation: string = '';

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall])
    .pipe(map((result: BreakpointState) => result.matches));
  public isTablet$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small])
    .pipe(map((result: any) => result.matches));

  public navLinks: any[] = [];

  public skeletonHeaderBarLayout: SkeletonRow[] = (function () {
    const height = 18;
    const width = 10;
    const right = 2;
    const left = 2;
    const top = 15;

    return [
      this.skeletonService.getSubtitle({ left, top }),
      new SkeletonRow(new SkeletonRow({
        left, height,
        children: [
          new SkeletonColumn({ width, right }),
          new SkeletonColumn({ width, right }),
          new SkeletonColumn({ width, right }),
          this.skeletonService.getButtons(1).children[0].amend({ left: (100 - ((width + right) * 3) - left) - width, width: width - right }),
        ]
      }))
    ]
  }.call(this));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private menuService: MenuService,
    private skeletonService: SkeletonService,
  ) {
    this.subs.push(
      this.menuService.location.subscribe(loc => {
        this.location = loc;
        this.sectionTitle = this.location.replace("-", " ");
        if (this.sectionTitle === "community") {
          this.sectionTitle = "community members";
        }
        this.setLinks();
      }),
      this.menuService.subLocation.subscribe(loc => {
        this.subLocation = loc;
      })
    );
  }

  public setLinks() {
    if (this.location && this.user) {
      this.navLinks = this.menuService.getMenuData(this.location, this.user.getRole);
      this.show.emit(true);
    }
    else {
      this.navLinks = [];
      this.show.emit(false);
    }
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
