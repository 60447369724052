import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService, LoggerService } from 'src/app/services';


@Component({
  selector: 'app-panel-admin-403',
  template: ''
})
export class PanelAdmin403Component {

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.openDialog()
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PanelAdmin403Dialog, {
      data: {},
      disableClose: true,
      panelClass: 'alert-dialog'
    });
  }
}

@Component({
  selector: 'app-panel-admin-403-dialog',
  templateUrl: './panel-admin-403.dialog.html'
})
export class PanelAdmin403Dialog implements OnInit {

  private subs: Subscription[] = [];

  public message: string = "An error occurred.";

  constructor(
    private dialogService: DialogService,
    private logger: LoggerService,
    private router: Router,
    public dialogRef: MatDialogRef<PanelAdmin403Dialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.afterClosed().subscribe(resp => {
      this.close();
    });
    if (data.message) {
      this.message = data.message;
    }
  }

  public login() {
    this.close().then(() => {
      setTimeout(() => {
        this.router.navigate(['/product-tests', 'login']);
      }, 10);
    })
  }

  public close(url: string = '') {
    this.dialogRef.close();
    return this.dialogService.close();
  }

  ngOnInit() {
  }
}
