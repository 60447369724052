import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProductTestCampaign, ProductTestCampaignBase } from 'src/app/components/product-tests/product-tests.models';
import { FavoritesTypes } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as CampaignActions from 'src/app/store/product-test/product-test-campaign.actions';
import { IStore } from 'src/app/store/product-test/product-test.store';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTestCampaignService {

  private endpoint: string = 'product_test_campaigns';

  private _tempCampaign: ProductTestCampaign;
  private tempCampaign$: ReplaySubject<ProductTestCampaign> = new ReplaySubject(1);
  tempCampaign: Observable<ProductTestCampaign> = this.tempCampaign$.asObservable();

  constructor(
    private store: Store<{ productTests: IStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
    private panelAdminService: PanelAdminService,
  ) { }

  setTempCampaign(campaign: ProductTestCampaign) {
    this.tempCampaign$.next(this._tempCampaign = campaign);
  }

  /**
   * @method PanelAdminCampaignService.create
   * @description Saves a new ProductTestsProduct to the server
   * @param {ProductTestCampaign} product The new ProductTestsProduct to be saved
   * @returns Observable<ProductTestsProduct>
   */
  public create(product: ProductTestCampaign): Observable<ProductTestCampaign> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl => {
      if (panelAdminUrl) {
        return this.api.post<ProductTestCampaignBase>(this.endpoint, product.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
          map((p) => {
            const storedProduct = new ProductTestCampaign(p)
            this.store.dispatch(new CampaignActions.CreateSuccess(storedProduct));
            return storedProduct;
          }),
          switchMap((p) => {
            return this.favoritesService.addFavorite(FavoritesTypes.ProductTestsCampaign, p.id).pipe(map(() => p));
          }),
          catchError((resp: any) => {
            this.store.dispatch(new CampaignActions.CreateError(resp));
            return this.panelAdminService.handleError(resp);
          })
        )
      }
      return of(null);
    }));
  }

  /**
   * @method PanelAdminCampaignService.getAll
   * @description Retrieves a list of all ProductTestsProducts in the Sample API
   * @returns Observable<ProductTestsProduct[]>
   */
  public getAll(archive?: boolean): Observable<ProductTestCampaign[]> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ProductTestCampaignBase[]>(this.endpoint, archive ? { archived: "true" } : {}, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => resp.map((p: ProductTestCampaignBase) => new ProductTestCampaign(p))),
        catchError((resp: any) => this.panelAdminService.handleError(resp))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminCampaignService.getByID
   * @description Retrieves a ProductTestsProduct for a given ID
   * @param {string} id The ID of the ProductTestsProduct to be retrieved
   * @returns Observable<ProductTestsProduct>
   */
  public getByID(id: string): Observable<ProductTestCampaign> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ProductTestCampaignBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((p) => {
          return new ProductTestCampaign(p);
        }),
        catchError((resp: any) => this.panelAdminService.handleError(resp))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminCampaignService.update
   * @description Updates a ProductTestsProduct on the server
   * @returns Observable<ProductTestsProduct>
   */
  public update(id: string, product: ProductTestCampaignBase): Observable<ProductTestCampaign> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/' + id, JSON.stringify({ data: product }), false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((p: ProductTestCampaignBase) => {
          const storedProduct = new ProductTestCampaign(p);
          this.store.dispatch(new CampaignActions.UpdateSuccess(storedProduct));
          return storedProduct;
        }),
        catchError((err: any) => {
          this.store.dispatch(new CampaignActions.UpdateError(product.id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminCampaignService.archive
   * @description Archives a ProductTestsProduct from the server, by setting it's 'archived' property to 'true'
   * @returns Observable<ProductTestsProduct>
   */
  public archive(id: string, archive?: boolean): Observable<ProductTestCampaign> {
    return this.update(id, { archived: !!archive });
  }

}
