import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProductTestSeller, ProductTestSellerBase } from 'src/app/components/product-tests/product-tests.models';
import { FavoritesTypes } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as SellerActions from 'src/app/store/product-test/product-test-seller.actions';
import { IStore } from 'src/app/store/product-test/product-test.store';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTestSellerService {

  private endpoint: string = 'brand_sellers';

  constructor(
    private store: Store<{ productTests: IStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method PanelAdminProductsService.create
   * @description Saves a new RewardProfile to the server
   * @param {ProductTestSeller} profile The new RewardProfile to be saved
   * @returns Observable<ProductTestsBrandSeller>
   */
  public create(profile: ProductTestSeller): Observable<ProductTestSeller> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<ProductTestSellerBase>(this.endpoint, profile.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((p) => {
          const profile = new ProductTestSeller(p)
          this.store.dispatch(new SellerActions.CreateSuccess(profile));
          return profile;
        }),
        switchMap((profile) => {
          return this.favoritesService.addFavorite(FavoritesTypes.RewardProfiles, profile.id).pipe(map(() => profile));
        }),
        catchError((resp: any) => {
          this.store.dispatch(new SellerActions.CreateError(resp));
          return this.panelAdminService.handleError(resp);
        }))
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminProductsService.getAll
   * @description Retrieves a list of all RewardProfiles in the Sample API
   * @returns Observable<ProductTestsBrandSeller[]>
   */
  public getAll(archive?: boolean): Observable<ProductTestSeller[]> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ProductTestSellerBase[]>(this.endpoint, archive ? { archived: "true" } : { archived: "false" }, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => resp.map((p: ProductTestSellerBase) => new ProductTestSeller(p))),
        catchError((resp: any) => this.panelAdminService.handleError(resp)
      ))
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminProductsService.getByID
   * @description Retrieves a RewardProfile for a given ID
   * @param {string} id The ID of the RewardProfile to be retrieved
   * @returns Observable<ProductTestsBrandSeller>
   */
  public getByID(id: string): Observable<ProductTestSeller> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ProductTestSellerBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((p) => {
          return new ProductTestSeller(p);
        }),
        catchError((resp: any) => this.panelAdminService.handleError(resp)
        ))
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminProductsService.update
   * @description Updates a RewardProfile on the server
   * @returns Observable<ProductTestsBrandSeller>
   */
  public update(id: string, brand: ProductTestSellerBase): Observable<ProductTestSeller> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.put<ProductTestSellerBase>(this.endpoint + '/' + id, brand, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((b) => {
          const storedBrandSeller = new ProductTestSeller(b);
          this.store.dispatch(new SellerActions.UpdateSuccess(storedBrandSeller));
          return storedBrandSeller;
        }),
        catchError((err: any) => {
          this.store.dispatch(new SellerActions.UpdateError(brand.id, err));
          return this.panelAdminService.handleError(err);
        }))
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminProductsService.archive
   * @description Archives a RewardProfile from the server, by setting it's 'archived' property to 'true'
   * @returns Observable<ProductTestsBrandSeller>
   */
  public archive(id: string, archive?: boolean): Observable<ProductTestSeller> {
    return this.update(id, { archived: !!archive });
  }

}
