import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BlogPost, BlogPostBase } from 'src/app/components/recruit/recruit.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as Actions from 'src/app/store/recruit/recruit-blog.actions';
import { IRecruitStore } from 'src/app/store/recruit/recruit.store';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class RecruitBlogService {
  
  private endpoint: string = 'posts';
  private blog_post_requested: { [id: string]: boolean; } = {};

  constructor(
    private store: Store<{ recruit: IRecruitStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method RecruitBlogService.create
   * @description Saves a new BlogPost to the server
   * @param {BlogPost} post The new BlogPost to be saved
   * @returns Observable<BlogPost>
   */
  public create(post: BlogPost): Observable<BlogPost> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<BlogPostBase>(this.endpoint, post.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((c) => {
          const post = new BlogPost(c);
          this.store.dispatch(new Actions.CreateSuccess(post));
          return post;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.CreateError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method RecruitBlogService.getAll
   * @description Retrieves a list of all BlogPosts in the Sample API
   * @returns Observable<BlogPost[]>
   */
  public getAll(): Observable<BlogPost[]> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<BlogPostBase[]>(this.endpoint, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        switchMap((resp: any) => {
          const posts = resp.map((p: BlogPostBase) => new BlogPost(p));
          this.store.dispatch(new Actions.GetAllSuccess(posts));
          return this.store.pipe(select(state => state.recruit.blogPosts.data));
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.GetAllError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method RecruitBlogService.getByID
   * @description Retrieves a BlogPost for a given ID
   * @param {string} id The ID of the BlogPost to be retrieved
   * @returns Observable<BlogPost>
   */
  public getByID(id: string | number, force?: boolean): Observable<BlogPost> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return state.recruit.blogPosts.map[id];
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      switchMap((post) => {
        if (!this.blog_post_requested[id] && (force || !post)) {
          this.blog_post_requested[id] = true;
          return this.panelAdminService.getPanelAdminUrl().pipe(
            switchMap(panelAdminUrl =>
              this.api.get<BlogPostBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
                map((p) => {
                  const post = new BlogPost(p);
                  this.store.dispatch(new Actions.GetSuccess(post));
                  this.blog_post_requested[id] = false;
                  return post;
                }),
                catchError((err: any) => {
                  this.store.dispatch(new Actions.GetError(id + "", err));
                  this.blog_post_requested[id] = false;
                  return this.panelAdminService.handleError(err);
                })
              )
            ),
            catchError(() => of(null))
          );
        }
        return of(post);
      })
    );
  }

  /**
   * @method RecruitBlogService.update
   * @description Updates a BlogPost on the server
   * @returns Observable<BlogPost>
   */
  public update(id: string, post: BlogPostBase, action?: string): Observable<BlogPost> {
    const url = this.endpoint + '/' + (action ? action + '/' : '') + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(url, JSON.stringify({ data: post }), false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: BlogPostBase) => {
          const post = new BlogPost(resp);
          this.store.dispatch(new Actions.UpdateSuccess(post));
          return post;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.UpdateError(post.id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }


  /**
   * @method RecruitBlogService.delete
   * @description Deletes a BlogPost on the server
   * @returns Observable<boolean>
   */
  public delete(id: string): Observable<boolean> {
    const url = this.endpoint + '/' + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.delete<any>(url, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => {
          this.store.dispatch(new Actions.DeleteSuccess(id));
          return true;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.DeleteError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

}
