import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, of, from, throwError, BehaviorSubject, interval, Subscription, Subject, timer, ReplaySubject } from 'rxjs';
import { map, switchMap, catchError, flatMap, takeWhile, skipWhile, retry, takeLast, take, merge, concat, concatMap } from 'rxjs/operators';

import { LoggerService } from './logger.service';
import { ApiService } from './api.service';
import { UtilService } from './util.service';
import { Cache } from './cache.service';

import { SampleModels } from 'src/app/data.models';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserQueryService {

  private endpoint: string = 'sampleProfile/stats';
  private cache: Cache<SampleModels.SampleStatsBase> = new Cache<SampleModels.SampleProjectBase>();

  public get placeholderStats(): SampleModels.SampleStatsBase {
    return {
      "total": 25000,
      "available": 500,
      "selected": 24500,
      "invited": 24500,
      "started": 5000,
      "finished": 4000,
      "qualified": 1000,
      "invalidated": 20,
      "invalidatedQualified": 15,
      "invalidatedDisqualified": 5,
      "feasible": 10000.5
    };
  };

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private util: UtilService,
  ) { }

  /**
   * @method UserQueryService.getSampleProfileStats
   * @description Retrieves the stats for a sample profile
   * @returns Observable<SampleProfileStats>
   */
  getSampleProfileStatsSubs: {
    [key: string]: {
      obs: Subject<SampleModels.SampleStatsBase>;
      key: string;
      sub: Subscription;
    }
  } = {};

  public getSampleProfileStats(profile: SampleModels.SampleProfileBase): Observable<SampleModels.SampleStatsBase> {
    const countQuery: any = {
      sampleProjectId: profile.sampleProjectId,
      includes: [profile.includes ? profile.includes : {}],
      excludes: [profile.excludes ? profile.excludes : {}]
    };
    const key = JSON.stringify(countQuery);
    if (!this.getSampleProfileStatsSubs[key]) {
      this.getSampleProfileStatsSubs[key] = {
        key,
        obs: new Subject(),
        sub: this.api.post<SampleModels.SampleStatsBase>('', countQuery, true, environment.userStatsUrl)
          .pipe(
            catchError((err: Error) => {
              this.logger.log(err.message);
              return of(null);
            })
          ).subscribe(resp => {
            this.getSampleProfileStatsSubs[key].obs.next(resp);
            this.getSampleProfileStatsSubs[key].obs.complete();
            this.getSampleProfileStatsSubs[key].sub.unsubscribe();
            delete this.getSampleProfileStatsSubs[key];
          })
      }
    }
    return this.getSampleProfileStatsSubs[key].obs.asObservable();

  }

  /**
   * @method UserQueryService.getSampleProjectStats
   * @description Retrieves the counts for a sample profile
   * @returns Observable<SampleProfileStats>
   */
  public getSampleProjectStats(projectID: string): Observable<SampleModels.SampleStatsBase> {

    const countQuery: any = {
      sampleProjectId: projectID,
      projectStatsOnly: true,
      includes: [{}],
      excludes: [{}]
    };

    return this.api.post<SampleModels.SampleStatsBase>('', countQuery, true, environment.userStatsUrl)
      .pipe(
        catchError((err: Error) => {
          this.logger.log(err.message);
          return of(null);
        })
      );
  }

  /**
   * @method UserQueryService.getSampleDistributionCellStats
   * @description Retrieves the stats for a sample distribution cell
   * @returns Observable<any>
   */
  public getSampleDistributionCellStats(cell: SampleModels.SampleDistributionCellBase, profile?: SampleModels.SampleProfileBase): Observable<SampleModels.SampleStatsBase> {

    let countQuery: any = {};
    if (profile) {
      countQuery = {
        sampleProjectId: profile.sampleProjectId,
        includes: [profile.includes ? profile.includes : {}, cell.includes || {}],
        excludes: [profile.excludes ? profile.excludes : {}, cell.excludes || {}],
      };
    }
    else {
      countQuery = {
        includes: [cell.includes || {}],
        excludes: [cell.excludes || {}],
      };
    }

    return this.api.post<SampleModels.SampleStatsBase>('', countQuery, true, environment.userStatsUrl)
      .pipe(
        catchError((err: Error) => {
          this.logger.log(err.message);
          return of(null);
        })
      );
  }


  /**
   * @method UserQueryService.getNestedSampleDistributionCellStats
   * @description Retrieves the stats for a sample distribution cells in a nested form
   * @returns Observable<any>
   */
  public getNestedSampleDistributionStats(distributions: SampleModels.SampleDistributionBase[], profile?: SampleModels.SampleProfileBase): Observable<SampleModels.SampleDistributionCellBase | number> {

    const queries = this.util.combineArrays(distributions.map((d: SampleModels.SampleDistributionBase) => d.cells));
    let obs: Observable<any> = of(queries.length);

    this.logger.log(queries);

    queries.forEach((query) => {
      let countQuery = {
        name: "",
        target: 1,
        sampleProjectId: profile ? profile.sampleProjectId : "",
        includes: profile && profile.includes ? [profile.includes] : [],
        excludes: profile && profile.excludes ? [profile.excludes] : [],
      };
      query.forEach(cell => {
        countQuery.name += countQuery.name ? " | " : "";
        countQuery.name += cell.name;
        countQuery.target = cell.target * countQuery.target;
        countQuery.includes.push(cell.includes || {});
        countQuery.excludes.push(cell.excludes || {});
      })
      obs = obs.pipe(merge(this.api.post('', countQuery as any, true, environment.userStatsUrl).pipe(map((resp: string) => {
        let cell: SampleModels.SampleDistributionCellBase = {};
        cell.stats = resp as SampleModels.SampleStatsBase;
        cell.target = countQuery.target;
        cell.name = countQuery.name;
        return cell;
      }))));
    })

    return obs;
  }
}
