<div>
  <ng-container [ngSwitch]="question?.type">
    <app-question-text  [value]="value" (valueChange)="updateValue($event)" [label]="questionText" *ngSwitchCase="'number'" type="number"></app-question-text>
    <app-question-multi [value]="value" (valueChange)="updateValue($event)"
                        [answerOptionOrder]="question?.answerOptionOrder"
                        [exclusiveOptions]="question?.exclusiveAnswerOptions"
                        [options]="question?.answerOptions[language]"
                        [label]="questionText"
                        [allowMultiple]="question?.allowMultiple"
                        *ngSwitchCase="'multi_choice'"></app-question-multi>
    <app-question-text [value]="value" (valueChange)="updateValue($event)" [label]="questionText" *ngSwitchCase="'long_text'" [long]="true"></app-question-text>
    <app-question-text [value]="value" (valueChange)="updateValue($event)" [label]="questionText" *ngSwitchDefault></app-question-text>
  </ng-container>
  <mat-error *ngIf="!valid">{{question.regExValidationMessage[language]}}</mat-error>

</div>
