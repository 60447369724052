import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Profiler, AWSResponse } from 'src/app/data.models';
import * as ProfileProfilerActions from 'src/app/store/profile/profile-profiler.actions';
import { RootStore } from 'src/app/store/store';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class ProfilersService {

  private subs: Subscription[] = [];
  private endpoint: string = 'profiler';

  constructor(
    private api: ApiService,
    private userService: UserService,
    private store: Store<RootStore>,
    private logger: LoggerService
  ) { }

  private clean(profiler: Profiler) {
    delete profiler.questions;
    delete profiler.codeSnippet;
    return profiler;
  }

  /**
   * @method ProfilersService.create
   * @description Creates a new profiler in AWS
   * @param profiler The profiler to be created
   * @returns Observable<Profiler>
   */
  create(profiler: Profiler) {
    return this.api.post(this.endpoint, this.clean(profiler))
      .pipe(map((resp: Profiler) => {
        this.logger.log(resp);
        if (resp) {
          this.store.dispatch(new ProfileProfilerActions.CreateSuccess(resp));
          return resp;
        }
        return null;
      }), catchError((err: any) => {
        this.store.dispatch(new ProfileProfilerActions.CreateError(err));
        return throwError(new Error(err && err.errors ? err.errors[0].message : "An error occurred"));
      }));
  }

  /**
   * @method ProfilersService.getAll
   * @description Retrieves a list of all profilers (archived and active)
   * @returns Observable<Profiler[]>
   */
  public getAll(): Observable<Profiler[]> {
    return this.api.get(this.endpoint, { filter: 'all' }).pipe(
      map((resp: AWSResponse<Profiler>) => resp.Items),
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'An error occurred')))
    );
  }

  /**
   * @method ProfilersService.getActive
   * @description Retrieves a list of active profilers only
   * @returns Observable<Profiler[]>
   */
  public getActive(): Observable<Profiler[]> {
    return this.api.get(this.endpoint, { filter: 'all' }).pipe(
      map((resp: AWSResponse<Profiler>) => resp.Items),
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'An error occurred')))
    );
  }

  /**
   * @method ProfilersService.getArchived
   * @description Retrieves a list of archived profilers only
   * @returns Observable<Profiler[]>
   */
  public getArchived(): Observable<Profiler[]> {
    return this.api.get(this.endpoint, { filter: 'archived' }).pipe(
      map((resp: AWSResponse<Profiler>) => {
        this.logger.log("ProfilersService.getArchived: ", resp)
        return resp.Items;
      }),
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'An error occurred')))
    );
  }

  /**
   * @method ProfilersService.getByID
   * @description Retrieves a profiler for a given ID
   * @param {string} id The ID of the profiler to be retrieved
   * @returns Observable<{data: Profiler, embedCode: string}>
   */
  public getByID(id: string): Observable<Profiler> {
    return this.api.get(this.endpoint, { id: id }, null, "body").pipe(
      map((resp: { data: Profiler, embedCode: string }) => {
        let profiler = resp.data;
        profiler.codeSnippet = resp.embedCode;
        return profiler;
      }),
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'An error occurred')))
    );
  }

  /**
   * @method ProfilersService.update
   * @description Updates the properties of a profiler
   * @param profiler The profiler to be updated
   * @returns Observable<Profiler>
   */
  public update(profiler: Profiler): Observable<Profiler> {
    return this.store.pipe(
      select(state => state.profile.profilers.map[profiler.id]),
      take(1),
      switchMap((existing: Profiler) => {
        return this.api.put(this.endpoint, this.clean(profiler))
          .pipe(map((resp: Profiler) => {
            if (resp) {
              const p = resp; // ToDo: AdamA: Update this to resp.data once available from the API
              if (existing) {
                p.codeSnippet = existing.codeSnippet;
              }
              this.store.dispatch(new ProfileProfilerActions.UpdateSuccess(p));
            }
            return resp;
          }), catchError((err: any) => {
            this.store.dispatch(new ProfileProfilerActions.UpdateError(profiler.id, err));
            return throwError(new Error(err && err.errors ? err.errors[0].message : "An error occurred"));
          }))
      })
    );
  }

  /**
   * @method ProfilersService.unarchiveProfiler
   * @description Updates the 'archived' property on a profiler
   * @param profiler The profiler to be unarchived
   * @returns Observable<Profiler>
   */
  public unarchive(id: string): Observable<Profiler> {
    const profiler = { id: id, archived: false };
    return this.update({ ...profiler });
  }

  /**
   * @method ProfilersService.archiveProfiler
   * @description Updates the 'archived' property on a profiler
   * @param profiler The profiler to be archived
   * @returns Observable<Profiler>
   */
  public archive(id: string): Observable<Profiler> {
    const profiler = { id: id, archived: true };
    return this.update({ ...profiler });
    //return this.api.put(`${this.endpoint}/${id}/archive`, {})
    //  .pipe(map((resp: AWSResponse) => {
    //    this.logger.log(resp);
    //    if (resp) {
    //      const p = (resp.Attributes || resp.data) as Profiler; // ToDo: AdamA: Update this to resp.data once available from the API
    //      this.store.dispatch(new ProfileProfilerActions.UpdateSuccess(p));
    //      return p;
    //    }
    //    return null;
    //  }), catchError((err: any) => {
    //    this.store.dispatch(new ProfileProfilerActions.UpdateError(id, err));
    //    return throwError(new Error(err && err.errors ? err.errors[0].message : "An error occurred"));
    //  }));
  }

  /**
   * @method ProfilersService.getPreviewUrl
   * @description Builds and returns a url to preview a given profiler survey
   * @param {string} id The ID of the profiler to be previewed
   * @returns {string} The url address at which the profiler can be previewed
   */
  public getPreviewUrl(profiler: Profiler): string {
    const rnd_no = Math.round(Math.random() * 100000);
    return `${environment.profilerWidgetUrl}`
      + `?userId=preview${rnd_no}`
      + `&profilerId=${profiler.id}`
      + `&locale=en-US`
      + `&surveyToken=${this.userService.currentUser.panelServicesSurveyToken}`;
  }
}
