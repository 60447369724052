import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer, UserRole } from 'src/app/data.models';
import { AlertService, CustomerService, DialogService, LoggerService, UserService } from 'src/app/services';


@Component({
  selector: 'app-delete-customer-dialog',
  templateUrl: './delete-customer.component.html'
})
export class DeleteCustomerDialog implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private loadingTimer: any;
  public errorMsg: string;
  public title: string = 'Are you sure you want to delete this customer?';
  public customer: Customer;

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private logger: LoggerService,
    private alert: AlertService,
    public dialogRef: MatDialogRef<DeleteCustomerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public delete() {
    this.dialogService.setBusy(true);
    this.subs.push(
      this.customerService.deleteCustomer(this.customer.id).subscribe(resp => {
        this.dialogService.setBusy(false);
        if (resp) {
          this.alert.success("Customer '" + this.customer.name + "' was successfully deleted.")
          this.close();
        }
        else {
          this.errorMsg = "There was an error deleting this customer.";
        }
      }, err => {
        this.dialogService.setBusy(false);
        this.errorMsg = err.message;
      })
    );
  }

  public close(): void {
    this.dialogService.close();
  }

  ngOnInit() {
    this.dialogService.setBusy();
    this.dialogService.setCurrentDialog(this.dialogRef);
    this.loadingTimer = setTimeout(() => this.dialogService.setBusy(false), 1000);
    
    this.customer = new Customer();
    this.subs.push(
      this.dialogRef.beforeClosed().subscribe(resp => {
        this.close();
      }),
      this.route.queryParams.subscribe(params => {
        const id = params["customerId"];
        if (id) {
          if (this.loadingTimer) {
            clearTimeout(this.loadingTimer);
            this.loadingTimer = null;
          }
          this.subs.push(this.customerService.getByID(id).subscribe(
            (customer: Customer) => {
              this.title = "Are you sure you want to delete customer: " + customer.name + "?";
              this.customer = customer;
              this.dialogService.setBusy(false, 300);
            },
            err => {
              this.close();
              this.logger.log(err.message);
              this.dialogService.setBusy(false);
            }));
        }
      }),
      this.userService.user$.subscribe(user => {
        if (user && user.role < UserRole.SuperAdmin) {
          this.close();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    this.loadingTimer && clearTimeout(this.loadingTimer);
  }
}
