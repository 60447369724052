<ul class="d-md-flex action-nav">
  <li *ngIf="(isHandset$|async) && actions.length > 2 && !showMore">
    <button mat-fab
            color="primary"
            class="ml-md-2 mt-2 mt-md-0"
            matTooltip="Show actions"
            matTooltipPosition="left"
            id="navActionLayout_showMoreButton"
            (click)="showMore = true">
      <mat-icon>more_horiz</mat-icon>
    </button>
  </li>
  <li *ngFor="let action of actions">
    <ng-container *ngIf="isVisible(action)">
      <ng-container *ngIf="!action.template; else actionTemplate">
        <button mat-flat-button
                [id]="'navActionLayout_actionButton_' + action.label.replace(' ', '')"
                color="light"
                class="ml-md-2 mt-2 mt-md-0"
                (click)="runAction(action)"
                *ngIf="!(isHandset$|async) && userHasPermission(action.restrict)">
          <mat-icon>{{action.icon}}</mat-icon>
          {{action.label}}
        </button>
        <button mat-fab
                [id]="'navActionLayout_actionButton_fab_' + action.label.replace(' ', '')"
                color="primary"
                [hidden]="actions.length > 2 && !showMore"
                class="ml-md-2 mt-2 mt-md-0"
                [matTooltip]="action.label"
                matTooltipPosition="left"
                *ngIf="(isHandset$|async) && userHasPermission(action.restrict)"
                (click)="runAction(action)">
          <mat-icon>{{action.icon}}</mat-icon>
        </button>
      </ng-container>
      <ng-template #actionTemplate>
        <button mat-flat-button
                [id]="'navActionLayout_actionButton_template_' + action.label.replace(' ', '')"
                color="light"
                class="ml-md-2 mt-2 mt-md-0"
                [matMenuTriggerFor]="appMenu"
                *ngIf="!(isHandset$|async) && userHasPermission(action.restrict)">
          <mat-icon>{{action.icon}}</mat-icon>
          {{action.label}}
        </button>
        <button mat-fab
                [id]="'navActionLayout_actionButton_template_fab_' + action.label.replace(' ', '')"
                color="primary"
                class="ml-md-2 mt-2 mt-md-0"
                [matTooltip]="action.label"
                matTooltipPosition="left"
                *ngIf="(isHandset$|async) && userHasPermission(action.restrict)"
                [matMenuTriggerFor]="appMenu">
          <mat-icon>{{action.icon}}</mat-icon>
        </button>
        <mat-menu #appMenu="matMenu" [class]="getTemplateClass(action)"
                  (closed)="action.onClose ? action.onClose() : null">
          <div (click)="$event.stopPropagation()" class="py-1 px-2">
            <ng-template matMenuContent>
              <ng-container *ngTemplateOutlet="action.template; context: { $implicit: action }"></ng-container>
              <button mat-stroked-button *ngIf="!action.uncancelable" [id]="'navActionLayout_actionButton_template_fab_' + action.label.replace(' ', '') + '_cancelButton'" color="warning" (click)="closeMenu()">Cancel</button>
            </ng-template>
          </div>
        </mat-menu>
      </ng-template>
    </ng-container>
  </li>
  <li *ngIf="(isHandset$|async) && actions.length > 2 && showMore">
    <button mat-fab
            id="navActionLayout_showLessButton"
            color="primary"
            class="ml-md-2 mt-2 mt-md-0"
            matTooltip="Hide actions"
            matTooltipPosition="left"
            (click)="showMore = false">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </li>
</ul>
