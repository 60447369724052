import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeCase'
})
export class ChangeCasePipe implements PipeTransform {

  transform(value: string, changeTo?: string): string {
    if (!value) return '';
    switch (changeTo) {
      case 'lower':
      case 'lowercase':
        return value.toLowerCase();
      case 'upper':
      case 'uppercase':
        return value.toUpperCase();
      case 'sentence':
        let sentences = value.split('.');
        let newVal = "";
        sentences.forEach((s, index) => {
          newVal += s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
        });
        return newVal;
      case 'capitalise':
      case 'title':
        return value.replace(/\w\S*/g, function (word) {
          return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
        });
      default:
        return value;
    }
  }
}
