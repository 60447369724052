
/**
 * @class Base
 * @description A simple class for other classes in the project to inherit common methods and properties from.
 * Not designed to be used on its own or to include any complex logic.
 */
export class Base {

  /**
   * @property Base.__patchLookups__
   * @type { key: value }
   *
   * @description
   * A meta list of parameters on the class which need to be instantiated using another
   * custom class, with the parameter name as the key and the custom class as the value.
   * Used in the Base.__patch__ method to build the class object from a data input.
   * 
   * @example { property: MyClass }
   */
  private __patchLookups__: { [key: string]: any };

  constructor(private base?: any) {
    if (base) {
      this.__patch__(base);
    }
  }

  /**
   * @method Base.__patch__
   * @param data: any
   * @description Meta function to build the initial class object from a data input.
   */
  private __patch__(data: any) {
    for (let prop in data) {
      if (typeof data[prop] !== 'undefined' && (!this["__lookupGetter__"](prop) || this["__lookupSetter__"](prop))) {
        if (this.__patchLookups__ && typeof this.__patchLookups__[prop] !== 'undefined') {
          this[prop] = new this.__patchLookups__[prop](data[prop]);
        }
        else {
          this[prop] = data[prop];
        }
      }
    }
    return this;
  }
}

export class DataObj extends Base {

  /**
   * @property Base.__flatDataModel__
   * @type Array<any>
   *
   * @description
   * A meta list of parameters on the class which need to be included in the output object when this class is flattened into a JSON object.
   * 
   * @example ['prop1', 'prop2', ['prop3', ['prop3a', 'prop3b']], 'prop4']
   */
  constructor(private __flatDataModel__: (DataSpec | string)[], private data?: any) {
    super(data)
  }

  /**
   * @property DataObj.asDataObj
   * @description Gets the class as a flat object, according to its __flatDataModel__ property
   */
  public get asDataObj() {
    return this.__package__(this.__flatDataModel__);
  }

  /**
   * @method DataObj.__package__
   * @param dataModel: any[]
   * @description Outputs a flattened object of the class
   * 
   * @example { property: MyClass }
   */
  private __package__(dataModel: (DataSpec | string)[]) {
    let data: any = {};

    for (let prop of dataModel as DataSpec[]) {
      if (typeof prop === 'string') {
        if (typeof (this[<string>prop]) !== 'undefined') {
          data[<string>prop] = this[<string>prop];
        }
      }
      else {
        if (prop.children) {
          data[prop.name] = this.__package__(prop.children);
        }
        else if (prop.transform) {
          data[prop.name] = prop.transform.call(this, this[prop.name], prop);
        }
        else {
          if (typeof this[prop.name] !== 'undefined') {
            data[prop.name] = this[prop.name];
          }
          else if (prop.defaultValue) {
            data[prop.name] = prop.defaultValue;
          }
        }
      }
    }
    return data;
  }
}

export interface DataSpec {
  name: string;
  defaultValue?: any;
  children?: DataSpec[] | string[];
  transform?: (value: any, spec: DataSpec) => any;
}

export interface APIBase {
  id?: string;
  archived?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export class ApiDefaults extends DataObj implements APIBase {
  id: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(specs?: (DataSpec | string)[], data?: any) {
    if (specs) {
      specs.unshift(
        'createdAt',
        'updatedAt',
        'id',
        'archived',
      );
    }
    super(specs, data);
  }
}

export type CurrencyType = 'USD' | 'GBP';

export interface Currency {
  code: CurrencyType;
  name: string;
}
export const currencies: Currency[] = [
  {
    code: 'USD',
    name: 'United States Dollars'
  }
];

export interface Answer {
  id?: string;
  text: string;
  value?: any;
  exclusive?: boolean;
  disabled?: boolean;
  logic?: string;
}

export const QuestionTypes: QuestionType[] = ['multi_choice', 'number', 'short_text', 'long_text'];
export type QuestionType = 'multi_choice' | 'number' | 'short_text' | 'long_text';
export type LanguageCode = 'en-US' | 'es-CO' | 'es-US' | 'es-MX' | 'en-CA' | 'fr-CA';
export const Languages: LanguageCode[] = ['en-US', 'es-CO', 'es-US', 'es-MX', 'en-CA', 'fr-CA'];

export class Range {
  constructor(public from?: number, public to?: number, public include: boolean = true) { }

  clear() {
    delete this.to;
    delete this.from;
  }

  get value() {
    if (typeof this.to !== 'number' || typeof this.from !== 'number') {
      return [];
    }
    let arr = [];
    for (var i = +this.from; i <= +this.to; i++) {
      arr.push(i);
    }
    return arr;
  }

  get length() {
    if (typeof this.to !== 'undefined' && typeof this.from !== 'undefined') {
      return this.to - this.from + 1;
    }
    return 0;
  }
}

export const COOKIE_NAMES = {
  history: "ps-history-storage"
}
