import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PagedHttpResponse, PagerConfig, Survey, SurveyPagerFilters } from 'src/app/data.models';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';
import { LoggerService } from './logger.service';
import { Pageable, Pager } from './paging.service';
import { SupplyOptimizerService } from './supply-optimizer.service';
import { UtilService } from './util.service';



@Injectable({
  providedIn: 'root'
})
export class SurveyService extends SupplyOptimizerService implements Pageable {

  private surveys: { [key: string]: BehaviorSubject<Survey> } = {};

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private snackBar: MatSnackBar,
    private customerService: CustomerService,
    private utilService: UtilService,
  ) {
    super(customerService);
  }

  public toggleBlockedStatus(identifier: string): Observable<Survey> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'survey/' + identifier + '/blocked' + this.password;
        return this.api.post(url, {}, false, this.supplyOptimizerUrl, "all")
          .pipe(switchMap((resp: HttpResponse<any>) => {
            if (resp.status === 200) {
              return this.getByIdentifier(identifier);
            }
            else {
              return throwError("Update failed");
            }
          }));
      }));
  }

  public toggleOpenStatus(identifier: string): Observable<Survey> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'survey/' + identifier + '/open' + this.password;
        return this.api.post(url, {}, false, this.supplyOptimizerUrl, "all")
          .pipe(switchMap((resp: HttpResponse<any>) => {
            if (resp.status === 200) {
              return this.getByIdentifier(identifier);
            }
            else {
              return throwError("Update failed");
            }
          }));
      }));
  }

  public getByIdentifier(identifier: string): Observable<Survey> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'survey/' + identifier;
        return this.api.get<Survey>(url, { pw: this.supplyOptimizerPassword }, this.supplyOptimizerUrl)
          .pipe(catchError(err => throwError(new Error('Error fetching survey: ' + identifier))));
      }));
  }

  public getAll(params: string): Observable<PagedHttpResponse<Survey>> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'v1/surveys' + params;
        return this.api.get<PagedHttpResponse<Survey>>(url, { pw: this.supplyOptimizerPassword }, this.supplyOptimizerUrl, "body")
          .pipe(catchError(err => throwError(new Error('Error fetching surveys'))));
      }));
  }

  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: SurveyPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters);
  }
}
