import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/data.models';
import { AuthService, DialogService, LoggerService } from 'src/app/services';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html'
})
export class LoginFormComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public loginForm: FormGroup;
  public errorMsg: string;
  public showPasswordHint: boolean;
  public matcher: ShowOnDirtyErrorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  @Input() hideButtons: boolean = true;
  @Input() showErrors: boolean = true;
  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Output() setBusy: EventEmitter<boolean> = new EventEmitter();
  @Output() error: EventEmitter<string> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private logger: LoggerService
  ) { }

  public forgotPassword() {
    this.dialogService.open('reset-password');
  }

  public login() {
    if (this.errorMsg) {
      this.errorMsg = '';
      this.error.emit(this.errorMsg);
    }
    this.setBusy.emit(true);
    this.authService.login(this.loginForm.get("email").value, this.loginForm.get("password").value).then((user$: Observable<User>) => {
      this.logger.log(user$);
      this.subs.push(user$.subscribe(user => {
        this.setBusy.emit(false);
        if (!user) {
          this.error.emit(this.errorMsg = "Login failed");
        }
        else {
          if (this.authService.redirectUrl) {
            this.router.navigateByUrl(this.authService.redirectUrl).then(fulfilled => {
              if (fulfilled) {
                this.success.emit(true);
                this.authService.redirectUrl = null;
              }
            }).catch(() => this.success.emit(true));
          }
          else {
            this.success.emit(true);
          }
          const snackBarRef = this.snackBar.open('Welcome ' + user.firstName, null, { verticalPosition: 'top' });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }
      }, err => {
        this.setBusy.emit(false);
        this.error.emit(this.errorMsg = err.message);
      }));

    }).catch((error: Error) => {
      this.logger.warn(error);
      this.errorMsg = error.message;
      this.setBusy.emit(false);
      this.error.emit(this.errorMsg);
    });
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*\d).{4,}$')]],
    });
  }

  ngOnDestroy() {
    this.setBusy.emit(false);
    this.subs.forEach(s => s.unsubscribe());
  }
}
