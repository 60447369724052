import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap, map } from 'rxjs/operators';
import { CampaignActivity } from 'src/app/components/recruit/recruit.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as Actions from 'src/app/store/recruit/recruit-campaign.actions';
import { IRecruitStore } from 'src/app/store/recruit/recruit.store';
import { PanelAdminService } from '../panel-admin.service';


@Injectable({
  providedIn: 'root'
})
export class RecruitCampaignActivityService {

  private endpoint: string = 'user_activity_export_lists';
  private activities_requested: { [id: string]: boolean; } = {};

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private store: Store<{ recruit: IRecruitStore }>,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method RecruitCampaignActivityService.getAll
   * @description Retrieves a list of all CampaignActivity
   * @returns Observable<CampaignActivity[]>
   */
  public getAll(campaign_id?: string, force?: boolean): Observable<CampaignActivity[]> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return campaign_id ? state.recruit.campaigns.activitiesByCampaign[campaign_id] : state.recruit.campaigns.activities;
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    const id = campaign_id || "all";
    return store_ref.pipe(
      switchMap((activities) => {
        if (!this.activities_requested[id] && (force || !activities)) {
          this.activities_requested[id] = true;
          return this.panelAdminService.getPanelAdminUrl().pipe(
            switchMap(panelAdminUrl =>
              this.api.get<CampaignActivity[]>(this.endpoint, campaign_id ? { filters: JSON.stringify({ campaign_id }) } : null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
                tap((list: CampaignActivity[]) => {
                  this.logger.log(list);
                  if (list && list.length) {
                    this.store.dispatch(new Actions.GetAllActivitySuccess(list));
                  }
                }),
                catchError((err: any) => {
                  this.store.dispatch(new Actions.GetAllActivityError(err));
                  return this.panelAdminService.handleError(err);
                })
              )
            ),
            catchError(() => of(null))
          );
        }
        return of(activities);
      })
    );
  }

  /**
   * @method RecruitCampaignActivityService.getByID
   * @description Retrieves a CampaignActivity for a given ID
   * @param {string} id The ID of the CampaignActivity to be retrieved
   * @returns Observable<CampaignActivity>
   */
  public getByID(id: string): Observable<CampaignActivity> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<CampaignActivity>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        tap((activity: CampaignActivity) => {
          this.logger.log(activity);
          if (activity) {
            this.store.dispatch(new Actions.GetActivitySuccess(activity));
          }
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.GetActivityError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method RecruitCampaignActivityService.poll
   * @description Polls for a CampaignActivity for a given ID until the activity is processed
   * @param {string} id The ID of the CampaignActivity to be polled
   * @returns Observable<CampaignActivity>
   */
  public poll(id: string): Observable<CampaignActivity> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.poll<CampaignActivity>(this.endpoint + '/' + id, (activity) => !!activity && activity.processed, null, panelAdminUrl, null, this.panelAdminService.options, true).pipe(
        tap((activity: CampaignActivity) => {
          this.logger.log(activity);
          if (activity) {
            this.store.dispatch(new Actions.GetActivitySuccess(activity));
          }
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.GetActivityError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method RecruitCampaignActivityService.create
   * @description Creates a new CampaignActivity export
   * @param {string} campaign_id The id of the Campaign for which the CampaignActivity export relates
   * @returns Observable<CampaignActivity>
   */
  create(campaign_id: string): Observable<CampaignActivity> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<any>(this.endpoint, { campaign_id }, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        tap((activity: CampaignActivity) => {
          this.logger.log(activity);
          if (activity) {
            this.store.dispatch(new Actions.CreateActivitySuccess(activity));
          }
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.CreateActivityError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method RecruitCampaignActivityService.delete
   * @description Deletes a CampaignActivity on the server
   * @returns Observable<boolean>
   */
  public delete(id: string): Observable<boolean> {
    const url = this.endpoint + '/' + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.delete<any>(url, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => {
          this.store.dispatch(new Actions.DeleteSuccess(id));
          return true;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.DeleteError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

}
