import { Action } from '@ngrx/store';
import { ProductTestSeller, ProductTestSellerBase } from '../../components/product-tests/product-tests.models';

export enum Types {
  Get                 = '[ProductTestSeller] Get',
  GetSuccess          = '[ProductTestSeller] GetSuccess',
  GetError            = '[ProductTestSeller] GetError',

  GetAll              = '[ProductTestSeller] GetAll',
  GetAllSuccess       = '[ProductTestSeller] GetAllSuccess',
  GetAllError         = '[ProductTestSeller] GetAllError',

  GetArchived         = '[ProductTestSeller] GetArchived',
  GetArchivedSuccess  = '[ProductTestSeller] GetArchivedSuccess',

  Create              = '[ProductTestSeller] Create',
  CreateSuccess       = '[ProductTestSeller] CreateSuccess',
  CreateError         = '[ProductTestSeller] CreateError',

  Delete              = '[ProductTestSeller] Delete',
  DeleteSuccess       = '[ProductTestSeller] DeleteSuccess',
  DeleteError         = '[ProductTestSeller] DeleteError',

  Update              = '[ProductTestSeller] Update',
  UpdateSuccess       = '[ProductTestSeller] UpdateSuccess',
  UpdateError         = '[ProductTestSeller] UpdateError',

  Clear               = '[ProductTestSeller] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() {
    console.log("Initiate: " + this.type);
  }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: ProductTestSeller[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() {
    console.log("Initiate: " + this.type);
  }
}

export class GetArchivedSuccess implements Action {
  readonly type = Types.GetArchivedSuccess;
  constructor(public payload: ProductTestSeller[]) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) {
    console.log("Initiate: " + this.type);
  }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: ProductTestSeller) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: ProductTestSeller) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: ProductTestSeller) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: string, public payload: ProductTestSellerBase) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: ProductTestSeller) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetArchived | GetArchivedSuccess | GetAll | GetAllSuccess | GetAllError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
