import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { SharedFormsModule } from 'src/app/components/shared-forms/shared-forms.module';
import { LayoutModule } from '../layout/layout.module';

import { HomeComponent } from './home.component'
import { NotFoundComponent } from './not-found/not-found.component';


@NgModule({
  imports: [
    SharedModule,
    SharedFormsModule,
    LayoutModule,
  ],
  declarations: [HomeComponent, NotFoundComponent]
})
export class HomeModule { }
