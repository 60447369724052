import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, Customer } from 'src/app/data.models';
import { AppStateService, UserService, CustomerService, SkeletonService } from 'src/app/services';
import { SkeletonRow, SkeletonColumn } from 'src/app/skeleton.models';
import { NavLocalComponent } from '../nav-local/nav-local.component';


@Component({
  selector: 'app-layout-main',
  templateUrl: './layout-main.component.html'
})
export class LayoutMainComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private getCustomerSub: Subscription;
  public loading: boolean = true;
  public initialLoading: boolean = true;

  @ViewChild(NavLocalComponent, { static: false }) set _localNav(localNav: NavLocalComponent) {
    if (localNav) {
      this.localNav = localNav;
    }
  }

  localNav: NavLocalComponent;

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall])
    .pipe(map((result: BreakpointState) => result.matches));
  public isTablet$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small])
    .pipe(map((result: any) => result.matches));

  public user: User;
  public customer: Customer;
  public siteName: string = "Bovitz Panel Tools";

  @ViewChild(MatSidenav) drawer: MatSidenav;

  public skeletonLayout: SkeletonRow[] = [
    this.skeletonService.getCircle(50, 1, { top: 30, left: 20 }),
    this.skeletonService.getCircle(30, 1, { top: 60, left: 30 }),
    this.skeletonService.getCircle(30, 1, { top: 60, left: 30 }),
    this.skeletonService.getCircle(30, 1, { top: 60, left: 30 }),
    this.skeletonService.getCircle(30, 1, { top: 60, left: 30 }),
  ];

  initialLoadingTimer: any;

  constructor(
    private skeletonService: SkeletonService,
    private breakpointObserver: BreakpointObserver,
    private appState: AppStateService,
    private userService: UserService,
    private customerService: CustomerService,
  ) { }

  public closeSideNav() {
    this.subs.push(this.isHandset$.subscribe(isHandset => {
      if (isHandset) {
        this.drawer.close();
      }
    }));
  }

  ngOnInit() {
    this.subs.push(
      this.userService.user$.subscribe((user: User) => {
        if (this.getCustomerSub) {
          this.getCustomerSub.unsubscribe()
        }
        this.user = user;
        if (user && user.customerId) {
          this.subs.push(
            this.getCustomerSub = this.customerService.getByID(user.customerId).subscribe(customer => {
              this.customer = customer;
            })
          );
        }
      }),
      this.appState.busy$.subscribe(busy => {
        this.loading = busy;
        if (this.initialLoadingTimer) {
          clearTimeout(this.initialLoadingTimer);
        }
        if (this.initialLoading && !busy) {
          this.initialLoadingTimer = setTimeout(() => {
            this.initialLoading = false;
          }, 100);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
