import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuLink } from 'src/app/data.models';
import { AuthService } from 'src/app/services';


@Component({
  selector: 'app-nav-global',
  templateUrl: './nav-global.component.html'
})
export class NavGlobalComponent implements OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter();
  
  private subs: Subscription[] = [];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.XSmall)
    .pipe(map((result: any) => result.matches));

  public menuLinks: MenuLink[] = [
    {
      path: '/community',
      label: 'Members',
      area: 'community',
      icon: 'account_box'
    },
    {
      path: '/recruit',
      label: 'Recruit',
      area: 'recruit',
      icon: 'person_search'
    },
    {
      path: '/profile',
      label: 'Profile',
      area: 'profile',
      icon: 'supervised_user_circle'
    },
    {
      path: '/sample',
      label: 'Sample',
      area: 'sample',
      icon: 'colorize'
    },
    {
      path: '/reward',
      label: 'Reward',
      area: 'reward',
      icon: 'star'
    },
    {
      path: '/monetize',
      label: 'Monetize',
      area: 'monetize',
      icon: 'monetization_on'
    },
    {
      path: '/product-tests',
      label: 'Products',
      area: 'productTests',
      icon: 'sell'
    },
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
  ) { }

  public userHasAccess(link: MenuLink) {
    return this.authService.checkAccess(link.area);
  }

  public closeSideNav() {
    this.subs.push(this.isHandset$.subscribe(isHandset => {
      if (isHandset) {
        this.close.emit();
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
