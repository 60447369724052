import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as RewardActions from 'src/app/store/reward/reward.actions';
import { IRewardStore, Reward, RewardBase, RewardPagerFilters } from 'src/app/components/reward/reward.models';
import { ApiService } from '../api.service';
import { LoggerService } from '../logger.service';
import { PagedHttpResponse, Pager, PagerConfig } from '../paging.service';

@Injectable({
  providedIn: 'root'
})
export class RewardService {

  private endpoint: string = 'reward';

  constructor(
    private store: Store<{ reward: IRewardStore }>,
    private api: ApiService,
    private logger: LoggerService,
  ) { }

  /**
   * @method RewardService.create
   * @description Saves a new Reward to the server
   * @param {Reward} reward The new Reward to be saved
   * @returns Observable<Reward>
   */
  public create(reward: Reward): Observable<Reward> {
    const url = this.endpoint;
    const base = reward.asDataObj;
    const r = Object.keys(base).reduce((r, key) => {
      if (base[key]) {
        r[key] = base[key];
      }
      return r;
    }, {});
    return this.api.post<RewardBase>(url, r).pipe(
      map((p) => {
        const profile = new Reward(p)
        this.store.dispatch(new RewardActions.CreateSuccess(profile));
        return profile;
      }),
      catchError((err: Error) => {
        this.store.dispatch(new RewardActions.CreateError(err));
        return throwError(new Error(err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
      })
    );
  }

  /**
   * @method RewardService.getAll
   * @description Retrieves a list of all Rewards in the Sample API
   * @returns Observable<Reward[]>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<Reward>> {
    const url = this.endpoint + (params || '');
    return this.api.get(url, {}, null, 'body').pipe(
      map((resp: PagedHttpResponse<RewardBase>) => {
        const data = resp.data.map((m: RewardBase) => new Reward(m));
        this.store.dispatch(new RewardActions.GetAllSuccess(data));
        return {
          ...resp,
          data
        };
      }),
      catchError(() => of(null))
    );
  }

  /**
   * @method RewardService.getByID
   * @description Retrieves a Reward for a given ID
   * @param {string} id The ID of the Reward to be retrieved
   * @returns Observable<Reward>
   */
  public getByID(id: string): Observable<Reward> {
    const url = this.endpoint;
    return this.api.get(url, { id }, null, "body").pipe(
      map((resp) => {
        const reward = new Reward(resp.data);
        reward.claimLink = resp.claimLink;
        return reward;
      }),
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardService.update
   * @description Updates a Reward on the server
   * @returns Observable<Reward>
   */
  public update(reward: Reward): Observable<Reward> {
    const url = this.endpoint;
    return this.api.put<RewardBase>(url, reward.asDataObj).pipe(
      map((r) => {
        const reward = new Reward(r)
        this.store.dispatch(new RewardActions.UpdateSuccess(reward));
        return reward;
      }),
      catchError((err: Error) => {
        this.store.dispatch(new RewardActions.UpdateError(reward.id, err));
        return throwError(new Error(err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
      })
    );
  }

  /**
   * @method RewardService.archive
   * @description Archives a Reward from the server, by setting it's 'archived' property to 'true'
   * @returns Observable<Reward>
   */
  public archive(id: string): Observable<Reward> {
    const url = this.endpoint;
    return this.api.delete(url, { id }).pipe(
      map((p) => new Reward(p)),
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardService.queueRewardDelivery
   * @description Delivers a batch of Rewards
   * @returns Observable<any>
   */
  public queueRewardDelivery(id: string): Observable<any> {
    const url = `${this.endpoint}/${id}/queueDelivery`;
    return this.api.put(url, {}).pipe(
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardService.batchQueueRewardDelivery
   * @description Delivers a batch of Rewards
   * @returns Observable<any>
   */
  public batchQueueRewardDelivery(ids: string[]): Observable<any> {
    const url = this.endpoint + '/batchQueueRewardDelivery';
    return this.api.post(url, ids).pipe(
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: RewardPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }

  /**
   * @method RewardService.getPayoutStatus
   * @description Retrieves the PayPal payout status for a given Reward ID
   * @param {string} id The ID of the Reward to get the payout status for
   * @returns Observable<string>
   */
  public getPayoutStatus(id: string): Observable<string> {
    const url = this.endpoint + '/' + id + '/payout';
    return this.api.get(url, null, null, "body").pipe(
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardService.getPayoutStatus
   * @description Retrieves the PayPal payout status for a given Reward ID
   * @param {string} id The ID of the Reward to get the payout status for
   * @returns Observable<string>
   */
  public cancelPayout(id: string): Observable<string> {
    const url = this.endpoint + '/' + id + '/cancelPayoutItem';
    return this.api.get(url, null, null, "body").pipe(
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

}
