import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProductTestPagerFilters, ProductTestTest, ProductTestTestBase } from 'src/app/components/product-tests/product-tests.models';
import { FavoritesTypes, PagedHttpResponse, PagerConfig } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Pager } from 'src/app/services/paging.service';
import * as TestActions from 'src/app/store/product-test/product-test-test.actions';
import { IStore } from 'src/app/store/product-test/product-test.store';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTestService {

  private endpoint: string = 'product_tests';

  constructor(
    private store: Store<{ productTests: IStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method PanelAdminTestService.create
   * @description Saves a new ProductTestTest to the server
   * @param {ProductTestTest} test The new ProductTestTest to be saved
   * @returns Observable<ProductTestTest>
   */
  public create(test: ProductTestTest): Observable<ProductTestTest> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<ProductTestTestBase>(this.endpoint, test.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t) => {
          const storedTest = new ProductTestTest(t)
          this.store.dispatch(new TestActions.CreateSuccess(storedTest));
          return storedTest;
        }),
        switchMap((t) => {
          return this.favoritesService.addFavorite(FavoritesTypes.ProductTestsTest, t.id).pipe(map(() => t));
        }),
        catchError((resp: any) => {
          this.store.dispatch(new TestActions.CreateError(resp));
          return this.panelAdminService.handleError(resp);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminTestService.getAll
   * @description Retrieves a list of all ProductTestTests in the Sample API
   * @returns Observable<ProductTestTest[]>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<ProductTestTest>> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + (params || ''), {}, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        map((resp: PagedHttpResponse<ProductTestTestBase>) => {
          resp.data = resp.data.map((p: ProductTestTestBase) => new ProductTestTest(p));
          return resp;
        }),
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminTestService.getByID
   * @description Retrieves a ProductTestTest for a given ID
   * @param {string} id The ID of the ProductTestTest to be retrieved
   * @returns Observable<ProductTestTest>
   */
  public getByID(id: string): Observable<ProductTestTest> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ProductTestTestBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t) => {
          return new ProductTestTest(t);
        }),
        catchError((resp: any) => this.panelAdminService.handleError(resp))
      )
    ));
  }

  /**
   * @method PanelAdminTestService.update
   * @description Updates a ProductTestTest on the server
   * @returns Observable<ProductTestTest>
   */
  public update(id: string, test: ProductTestTestBase): Observable<ProductTestTest> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/' + id, JSON.stringify({ data: test }), false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t: ProductTestTestBase) => {
          const storedTest = new ProductTestTest(t);
          this.store.dispatch(new TestActions.UpdateSuccess(storedTest));
          return storedTest;
        }),
        catchError((err: any) => {
          this.store.dispatch(new TestActions.UpdateError(test.id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminTestService.validate
   * @description Validates a ProductTestTest on the server
   * @returns Observable<ProductTestTest>
   */
  public validate(id: string): Observable<ProductTestTest> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/validate_order/' + id, null, false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t: ProductTestTestBase) => {
          const test = new ProductTestTest(t);
          this.store.dispatch(new TestActions.UpdateSuccess(test));
          return test;
        }),
        catchError((err: any) => {
          this.store.dispatch(new TestActions.UpdateError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method PanelAdminTestService.invalidate
   * @description Invalidate a ProductTestTest on the server
   * @returns Observable<ProductTestTest>
   */
  public invalidate(id: string): Observable<ProductTestTest> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/invalidate_order/' + id, null, false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t: ProductTestTestBase) => {
          const test = new ProductTestTest(t);
          this.store.dispatch(new TestActions.UpdateSuccess(test));
          return test;
        }),
        catchError((err: any) => {
          this.store.dispatch(new TestActions.UpdateError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method PanelAdminTestService.cancel
   * @description Cancel a ProductTestTest on the server
   * @returns Observable<ProductTestTest>
   */
  public cancel(id: string): Observable<ProductTestTest> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/cancel/' + id, null, false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t: ProductTestTestBase) => {
          const test = new ProductTestTest(t);
          this.store.dispatch(new TestActions.UpdateSuccess(test));
          return test;
        }),
        catchError((err: any) => {
          this.store.dispatch(new TestActions.UpdateError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method PanelAdminTestService.uncancel
   * @description Uncancel a ProductTestTest on the server
   * @returns Observable<ProductTestTest>
   */
  public uncancel(id: string): Observable<ProductTestTest> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/uncancel/' + id, null, false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t: ProductTestTestBase) => {
          const test = new ProductTestTest(t);
          this.store.dispatch(new TestActions.UpdateSuccess(test));
          return test;
        }),
        catchError((err: any) => {
          this.store.dispatch(new TestActions.UpdateError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method PanelAdminTestService.archive
   * @description Archives a ProductTestTest from the server, by setting it's 'archived' property to 'true'
   * @returns Observable<ProductTestTest>
   */
  public archive(id: string, archive?: boolean): Observable<ProductTestTest> {
    return this.update(id, { archived: !!archive });
  }

  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: ProductTestPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }
}
