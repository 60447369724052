import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { LoggerService, QuestionService } from 'src/app/services';
import { Question } from 'src/app/data.models';

@Component({
  selector: 'app-question-text',
  templateUrl: './question-text.component.html'
})
export class QuestionTextComponent implements OnInit {

  @Input() long: boolean;
  @Input() type: 'text' | 'number' = 'text';
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  public label: string;
  public id: string = "questionTextComponent_input"
  @Input("label") set _label(label: string) {
    this.label = label;
    this.id = "questionTextComponent_input_" + (label || "").replace(/ /gi, "_");
  }
  
  constructor(
    private questionService:  QuestionService,
  ) { }

  ngOnInit() {
  }

}
