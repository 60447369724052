import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { LoggerService, QuestionService } from 'src/app/services';
import { Question } from 'src/app/data.models';

@Component({
  selector: 'app-question-picker',
  templateUrl: './question-picker.component.html'
})
export class QuestionPickerComponent implements OnInit {

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  public question: Question;
  @Input("question") set _question(q: Question) {
    this.question = q;
    if (this.language) {
      this.questionText = this.question.prompt[this.language];
    }
  }

  public language: string;
  public questionText: string;
  @Input("language") set _language(lang: string) {
    this.language = lang;
    if (this.question) {
      this.questionText = this.question.prompt[this.language];
    }
  }
  
  constructor(
    private loggerService:  LoggerService,
  ) { }

  updateValue(value) {
    this.value = value;
    this.isValid();
    this.valueChange.emit();
  }

  public valid: boolean = true;
  isValid() {
    if (this.question) {
      if (typeof this.value === 'string') {
        this.valid = (new RegExp(this.question.regExValidation).test(this.value));
      }
      else if (this.value && this.value.forEach) {
        const error = this.value.find((str: string) => !(new RegExp(this.question.regExValidation).test(str)));
        this.valid = !error;
      }
    }
    return this.valid;
  }

  ngOnInit() {
  }

}
