import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BankRedemption, CommunityReward, CommunityRewardPagerFilters } from 'src/app/components/community/community.models';
import { PagedHttpResponse, PagerConfig } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Pager } from 'src/app/services/paging.service';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityRewardService {

  private endpoint: string = 'rewards';

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method PanelAdminRewardService.getByID
   * @description Retrieves a CommunityReward
   * @returns Observable<CommunityReward>
   */
  public getByID(id?: number | string): Observable<CommunityReward> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + '/' + id, {}, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminRewardService.getAll
   * @description Retrieves a paged list of all CommunityRewards, filtered by the params set
   * @returns Observable<PagedHttpResponse<CommunityReward>>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<CommunityReward>> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + (params || ''), {}, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }


  /**
   * @method PanelAdminRewardService.bankRedemption
   * @description Creates a new Bank Redemption
   * @returns Observable<any>
   */
  public bankRedemption(id: string, remdemption: BankRedemption): Observable<any> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post(this.endpoint + '/redeem/' + id, remdemption, false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }



  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: CommunityRewardPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }

}
