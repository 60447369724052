import * as ProductReducer from  './product-test-product.reducers';
import * as BrandReducer from './product-test-brand.reducers';
import * as SellerReducer from './product-test-seller.reducers';
import * as CampaignReducer from './product-test-campaign.reducers';
import * as SurveyReducer from './product-test-survey.reducers';
import * as TestReducer from './product-test-test.reducers';
export { ProductReducer, BrandReducer, SellerReducer, CampaignReducer, SurveyReducer, TestReducer }

import * as ProductActions    from './product-test-product.actions';
import * as BrandActions from   './product-test-brand.actions';
import * as SellerActions from './product-test-seller.actions';
import * as CampaignActions from './product-test-campaign.actions';
import * as SurveyActions from './product-test-survey.actions';
import * as TestActions from './product-test-test.actions';
export { ProductActions, BrandActions, SellerActions, CampaignActions, SurveyActions, TestActions }

export { ProductEffects }     from './product-test-product.effects';
export { BrandEffects } from './product-test-brand.effects';
export { SellerEffects } from './product-test-seller.effects';
export { CampaignEffects } from './product-test-campaign.effects';
export { SurveyEffects } from './product-test-survey.effects';
export { TestEffects } from './product-test-test.effects';

export interface Store {
  products: any;
  brands: any;
  sellers: any;
  campaigns: any;
  surveys: any;
  tests: any;
}

export const PRODUCT_TEST_STORE: Store = {
  products: ProductReducer.reducerFn,
  brands: BrandReducer.reducerFn,
  sellers: SellerReducer.reducerFn,
  campaigns: CampaignReducer.reducerFn,
  surveys: SurveyReducer.reducerFn,
  tests: TestReducer.reducerFn,
}

export interface IStore extends Store {
  products: ProductReducer.State;
  brands: BrandReducer.State;
  sellers: SellerReducer.State;
  campaigns: CampaignReducer.State;
  surveys: SurveyReducer.State;
  tests: TestReducer.State;
}
