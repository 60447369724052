import { Pipe, PipeTransform } from '@angular/core';

import { Profiler } from 'src/app/data.models';
import { LoggerService, FavoritesService, UtilService } from 'src/app/services';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

  constructor(
    private logger: LoggerService,
    private utilService: UtilService
  ) {

  }

  transform(list: any[] = [], limit: number = 1): any {
    if (!list || !list.length) return [];

    const newList: LimitedList = { isLimited: false, limitedList: list, fullList: list, overflowList: [], limit: limit };
    if (list.length > limit) {
      newList.isLimited = true;
      newList.limitedList = list.slice(0, limit);
      newList.overflowList = list.slice(limit);
    }
    return newList;
  }
}

interface LimitedList {
  isLimited: boolean;
  limit: number;
  limitedList: any[];
  fullList: any[];
  overflowList: any[];
};
