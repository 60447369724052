import { Injectable } from '@angular/core';
import { QueryFn } from '@angular/fire/compat/firestore';
import { DocumentData, Firestore, getFirestore, doc, setDoc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { from, Observable, of, throwError, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { FavoritesTypes } from 'src/app/data.models';
import { AngularFirestore, AngularFirestoreCollection, FirestoreSnapshotResponse } from './firebase.service';
import { LoggerService } from './logger.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  private firestore: Firestore = getFirestore();
  private collectionName: string = "favorites";

  get authId(): string {
    return this.userService.currentUser ? this.userService.currentUser.authId : "";
  }

  constructor(
    private afStore: AngularFirestore,
    private userService: UserService,
    private logger: LoggerService
  ) {}

  private collection(queryFn?: QueryFn<DocumentData>): AngularFirestoreCollection<{ [key: string]: string[] }> {
    return this.afStore.collection(this.collectionName, queryFn);
  }

  /**
   * @method FavoritesService.setFavorites
   * @description Update the favorites of a given type in firestore
   * @param type {FavoritesTypes} The type of favourites to be updated
   * @param favorites {string[]} A list of id to add to favourites
   * @returns Observable<boolean>
   */
  public setFavorites(type: FavoritesTypes, favorites: string[]): Observable<boolean> {
    const ref = doc(this.firestore, this.collectionName, this.authId);

    return from(setDoc(ref, { [type]: favorites }, { merge: true })
      .then(() => {
        this.logger.log("Favorites successfully saved");
        return true;
      }));
  }

  /**
   * @method FavoritesService.addFavorite
   * @description Adds a single ID to a given type of favourite list
   * @param type {FavoritesTypes} The type of favourite to update
   * @param id {string} The id to add to the list of favourites
   * @returns Observable<boolean>
   */
  public addFavorite(type: FavoritesTypes, id: string): Observable<boolean> {
    return this.getFavorites(type).pipe(take(1), switchMap((resp: FirestoreSnapshotResponse<string[]>) => {
      const favorites = resp.value;
      if (favorites) {
        resp.unsubscribe();
        const find = favorites.indexOf(id);
        if (find > -1) {
          return of(true);
        }
        favorites.push(id);
        return this.setFavorites(type, favorites);
      }
      return of(null);
    }));
  }

  /**
   * @method FavoritesService.getFavorites
   * @description Retrieves a list of favorites for the currentUser
   * @param type {FavoritesTypes} The type of favourite to get
   * @returns Observable<string[]>
   */
  public getFavorites(type: FavoritesTypes): Observable<FirestoreSnapshotResponse<string[]>> {
    if (this.authId) {
      const ref = doc(this.firestore, this.collectionName, this.authId);
      const sub = new Subject<FirestoreSnapshotResponse<string[]>>();
      const unsub = onSnapshot(ref, doc => {
        const favorites = doc.data();
        sub.next({
          value: favorites && favorites[type] || [],
          unsubscribe: unsub
        });
      });
      return sub.asObservable();
    }
    return throwError(new Error("Can't get favorites! No currentUser set in UserService."));
  }
  
}

//Data structure for Favorites
//const firebaseDataStructure = {
//  userId1: {
//    profilers: ['id1', 'id2'],
//    questions: ['id1', 'id2'],
//  },
//  userId2: { ...},
//  ...
//}
