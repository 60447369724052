import * as SampleReducers      from './sample.reducers';
import * as SampleProjectReducers      from './sample-project.reducers';
import * as SampleProfileReducers      from './sample-profile.reducers';
import * as SampleBatchReducers        from './sample-batch.reducers';
import * as SampleDistributionReducers from './sample-distribution.reducers';

import * as SampleActions             from './sample.actions';
import * as SampleProjectActions      from './sample-project.actions';
import * as SampleProfileActions      from './sample-profile.actions';
import * as SampleBatchActions        from './sample-batch.actions';
import * as SampleDistributionActions from './sample-distribution.actions';

export { SampleActions, SampleProjectActions, SampleProfileActions, SampleBatchActions, SampleDistributionActions }

export { SampleEffects              } from './sample.effects';
export { SampleProjectEffects       } from './sample-project.effects';
export { SampleProfileEffects       } from './sample-profile.effects';
export { SampleBatchEffects         } from './sample-batch.effects';
export { SampleDistributionEffects  } from './sample-distribution.effects';

export interface SampleStore {
  details: any;
  projects: any;
  profiles: any;
  distributions: any;
  batchLists: any;
}

export const SAMPLE_STORE: SampleStore = {
  details: SampleReducers.sampleReducer,
  projects: SampleProjectReducers.sampleProjectReducer,
  profiles: SampleProfileReducers.sampleProfileReducer,
  distributions: SampleDistributionReducers.sampleDistributionReducer,
  batchLists: SampleBatchReducers.sampleBatchReducer,
}

export interface ISampleStore extends SampleStore {
  details: SampleReducers.State;
  projects: SampleProjectReducers.State;
  profiles: SampleProfileReducers.State;
  distributions: SampleDistributionReducers.State;
  batchLists: SampleBatchReducers.State;
}

export interface RootSampleStore {
  sample: ISampleStore;
}

export { SampleReducers, SampleProjectReducers, SampleProfileReducers, SampleDistributionReducers, SampleBatchReducers }
