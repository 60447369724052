import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as TestActions from './product-test-test.actions';
import { ProductTestService } from '../../services/panel-admin/product-test/product-test.service';

@Injectable()
export class TestEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(TestActions.Types.GetAll),
      mergeMap(() => this.service.getAll()
        .pipe(
          map(resp => (new TestActions.GetAllSuccess(resp.data))),
          catchError((err: Error) => of(new TestActions.GetAllError(err)))
        ))
    ));

  //@Effect()
  //getArchive$ = this.actions$
  //  .pipe(
  //    ofType(TestActions.Types.GetArchived),
  //    mergeMap(() => this.service.getAll(true)
  //      .pipe(
  //        map(tests => (new TestActions.GetArchivedSuccess(tests))),
  //        catchError((err: Error) => of(new TestActions.GetAllError(err)))
  //      ))
  //  );

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(TestActions.Types.Get),
      mergeMap((action: TestActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(test => (test ? new TestActions.GetSuccess(test) : new TestActions.GetError(action.id, new Error("Unknown Error")))),
          catchError((err: Error) => of(new TestActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(TestActions.Types.Create),
      mergeMap((action: TestActions.Create) => this.service.create(action.payload)
        .pipe(
          map((test) => (new TestActions.CreateSuccess(test))),
          catchError((err: Error) => of(new TestActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(TestActions.Types.Update),
      mergeMap((action: TestActions.Update) => this.service.update(action.id, action.payload)
        .pipe(
          map((test) => (new TestActions.UpdateSuccess(test))),
          catchError((err: Error) => of(new TestActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(TestActions.Types.Delete),
      mergeMap((action: TestActions.Delete) => this.service.archive(action.id)
        .pipe(
          map(() => (new TestActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new TestActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: ProductTestService
  ) { }
}
