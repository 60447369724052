import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Source, SourceBase, SourceStats, SourceStatsBase } from 'src/app/components/recruit/recruit.models';
import { FavoritesTypes } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as Actions from 'src/app/store/recruit/recruit-source.actions';
import { IRecruitStore } from 'src/app/store/recruit/recruit.store';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class RecruitSourceService {

  private endpoint: string = 'sources';
  private sources_requested: { [id: string]: boolean; } = {};
  private source_stats_queue: { [id: string]: Subscription; } = {};

  constructor(
    private store: Store<{ recruit: IRecruitStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
    private panelAdminService: PanelAdminService,
  ) { }


  /**
   * @method RecruitSourceService.getStats
   * @description Saves a new Source to the server
   * @param {Source} source The new Source to be saved
   * @returns Observable<Source>
   */
  public getStats(id: string, force?: boolean): Observable<SourceStats> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return state.recruit.sources.stats[id];
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      tap((stats) => {
        if (!this.source_stats_queue[id] && (force || !stats || stats.isExpired)) {
          force = false;
          const obs = this.panelAdminService.getPanelAdminUrl().pipe(
            switchMap(panelAdminUrl =>
              this.api.get<SourceStatsBase>(this.endpoint + '/stats/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
                switchMap((stats) => {
                  if (stats) {
                    stats.id = id;
                    this.store.dispatch(new Actions.GetStatsSuccess(id, stats));
                  }
                  return store_ref;
                }),
                catchError((err: any) => {
                  this.store.dispatch(new Actions.GetStatsError(id, err));
                  return of(null);
                })
              )
            )
          );
          this.source_stats_queue[id] = obs.subscribe(
            () => {
              if (this.source_stats_queue[id]) {
                this.source_stats_queue[id].unsubscribe();
                this.source_stats_queue[id] = null;
              }
            }
          );
        }
      }),
      catchError(() => of(null))
    );
  }

  /**
   * @method RecruitSourceService.create
   * @description Saves a new Source to the server
   * @param {Source} source The new Source to be saved
   * @returns Observable<Source>
   */
  public create(source: Source): Observable<Source> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<SourceBase>(this.endpoint, source.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((p) => {
          const storedProduct = new Source(p);
          this.store.dispatch(new Actions.CreateSuccess(storedProduct));
          return storedProduct;
        }),
        switchMap((p) => {
          return this.favoritesService.addFavorite(FavoritesTypes.RecruitSources, p.id).pipe(map(() => p));
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.CreateError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method RecruitSourceService.getAll
   * @description Retrieves a list of all Sources in the Sample API
   * @returns Observable<Source[]>
   */
  public getAll(archive?: boolean, force?: boolean): Observable<Source[]> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return state.recruit.sources.data;
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      switchMap((stored_sources) => {
        const id = "all";
        if (!this.sources_requested[id] && (force || !stored_sources || stored_sources.length < 5)) {
          force = false;
          this.sources_requested[id] = true;
          return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
            this.api.get<SourceBase[]>(this.endpoint, archive ? { archived: "true" } : {}, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
              switchMap((resp: any) => {
                const sources = resp.map((p: SourceBase) => new Source(p));
                this.store.dispatch(new Actions.GetAllSuccess(sources));
                this.sources_requested[id] = false;
                return store_ref;
              }),
              catchError((err: any) => {
                this.store.dispatch(new Actions.GetAllError(err));
                this.sources_requested[id] = false;
                return this.panelAdminService.handleError(err);
              })
            )
          ), catchError(() => of(null)));
        }
        return of(stored_sources);
      })
    );
  }

  /**
   * @method RecruitSourceService.getByID
   * @description Retrieves a Source for a given ID
   * @param {string} id The ID of the Source to be retrieved
   * @returns Observable<Source>
   */
  public getByID(id: string | number, force?: boolean): Observable<Source> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return state.recruit.sources.map[id];
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      switchMap((stored_source) => {
        if (!this.sources_requested[id] && (force || !stored_source)) {
          force = false;
          this.sources_requested[id] = true;
          return this.panelAdminService.getPanelAdminUrl().pipe(
            switchMap(panelAdminUrl =>
              this.api.get<SourceBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
                switchMap((p) => {
                  const source = new Source(p);
                  this.store.dispatch(new Actions.GetSuccess(source));
                  this.sources_requested[id] = false;
                  return store_ref;
                }),
                catchError((err: any) => {
                  this.store.dispatch(new Actions.GetError(id + "", err));
                  this.sources_requested[id] = false;
                  return this.panelAdminService.handleError(err);
                })
              )
            ),
            catchError(() => of(null))
          );
        }
        return of(stored_source);
      })
    );
  }

  /**
   * @method RecruitSourceService.update
   * @description Updates a Source on the server
   * @returns Observable<Source>
   */
  public update(id: string, data: SourceBase): Observable<Source> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/' + id, JSON.stringify({ data }), false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: SourceBase) => {
          const source = new Source(resp);
          this.store.dispatch(new Actions.UpdateSuccess(source));
          return source;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.UpdateError(data.id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

}
