import { orderBy } from "lodash";

export interface State<T = any> {
  data: T[];
  archive: T[];
  map: { [id: string]: T };
  count: number;
  errors: { [id: string]: Error };
}

export const initialState: State = {
  data: null, archive: null, count: 0, map: {}, errors: {}
};

export function map<T = any>(items: T[], prop: string = "id"): { [id: string]: T } {
  if (items) {
    return items.reduce((prev: { [key: string]: T }, curr: any) => {
      prev[curr[prop]] = curr;
      return prev;
    }, {});
  }
  return {};
}

export function orderData<T = any>(state: State<T>, iteratees: Function[]|string[], filterFn?: (item?: T) => boolean, orders: ('asc'|'desc')[] = ['desc']): T[] {
  return (<any>orderBy(Object.values(state.map).filter(filterFn || (() => true)), iteratees, orders)) as T[];
 //return _.sortBy(Object.values(state.map).filter(filterFn || (() => true)), orderBy);
}

export function replaceOrAdd<T = any>(arr: T[], newItem: T) {
  const idx = arr.findIndex((item: any) => item.id === (<any>newItem).id);
  if (idx > -1) {
    arr[idx] = newItem;
  }
  else {
    arr.unshift(newItem);
  }
  return arr;
}
