<form [formGroup]="form" (submit)="editMode ? saveCustomer() : createCustomer()" autocomplete="off" id="addEditCustomerForm">
  <app-dialog>
    <div dialog-title>{{title}}</div>
    <div dialog-content *ngIf="!user || user?.role !== 0">
      <h5>You do not have permission to edit this customer</h5>
    </div>
    <div dialog-content *ngIf="user && user?.role === 0" class="">

      <!-- Name -->
      <div class="my-4 my-md-1">
        <mat-form-field [class.no-labels]="form.get('name').untouched || form.get('name').valid">
          <mat-label>Customer Name</mat-label>
          <input matInput id="addEditCustomerForm_name" formControlName="name" type="text" />
          <mat-error *ngIf="form.get('name').hasError('required')">
            Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="d-sm-flex align-items-center my-4 my-md-1">
        <!-- Panel Services Email -->
        <mat-form-field class="mr-sm-1 mb-1 mb-sm-0" style="min-width:200px" [class.no-labels]="(form.get('panelServicesEmail').untouched || form.get('panelServicesEmail').valid) && (form.get('panelServicesPassword').untouched || form.get('panelServicesPassword').valid)">
          <mat-label>Panel Services Email</mat-label>
          <input matInput id="addEditCustomerForm_panelServicesEmail" formControlName="panelServicesEmail" autocomplete="off" type="email" />
          <mat-error *ngIf="form.get('panelServicesEmail').hasError('email') && !form.get('panelServicesEmail').hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="form.get('panelServicesEmail').hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <!-- Panel Services Password -->
        <mat-form-field class="ml-sm-1" [class.no-labels]="(form.get('panelServicesEmail').untouched || form.get('panelServicesEmail').valid) && (form.get('panelServicesPassword').untouched || form.get('panelServicesPassword').valid)">
          <mat-label>Panel Services Password</mat-label>
          <input matInput id="addEditCustomerForm_panelServicesPassword" formControlName="panelServicesPassword" autocomplete="off" type="password" (focus)="showPasswordHint = true" (blur)="showPasswordHint = false" />
          <mat-hint *ngIf="showPasswordHint">
            <ng-template #passwordHint>Password should be supplied by AWS panel services.</ng-template>
          </mat-hint>
          <mat-error *ngIf="form.get('panelServicesPassword').hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <div *ngIf="editMode" class="pl-sm-1 mt-1 mt-sm-0">
          <button type="button" mat-stroked-button id="addEditCustomerForm_refreshPanelServicesTokens"
                  (click)="refreshPanelServicesTokens()"
                  [disabled]="refreshingTokens" class="mx-auto d-block w-100">
            Save &amp; Refresh Tokens
          </button>
        </div>
      </div>

      <div class="d-sm-flex my-4 my-md-1">
        <!-- Supply Optimizer Url -->
        <mat-form-field class="mr-sm-1 mb-1 mb-md-0" [class.no-labels]="(form.get('supplyOptimizerUrl').untouched || form.get('supplyOptimizerUrl').valid) && (form.get('supplyOptimizerPassword').untouched || form.get('supplyOptimizerPassword').valid)">
          <mat-label>Supply Optimizer URL</mat-label>
          <input matInput id="addEditCustomerForm_supplyOptimizerUrl" formControlName="supplyOptimizerUrl" autocomplete="off" type="text" />
          <mat-error *ngIf="form.get('panelServicesEmail').hasError('required')">
            Supply Optimizer URL is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <!-- Supply Optimizer Password -->
        <mat-form-field class="ml-sm-1" [class.no-labels]="(form.get('supplyOptimizerUrl').untouched || form.get('supplyOptimizerUrl').valid) && (form.get('supplyOptimizerPassword').untouched || form.get('supplyOptimizerPassword').valid)">
          <mat-label>Supply Optimizer Password</mat-label>
          <input matInput id="addEditCustomerForm_supplyOptimizerPassword" formControlName="supplyOptimizerPassword" autocomplete="off" type="text" />
          <mat-error *ngIf="form.get('supplyOptimizerPassword').hasError('required')">
            Supply Optimizer Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="my-4 my-md-1">
        <!-- Panel Admin Url -->
        <mat-form-field [class.no-labels]="form.get('supplyOptimizerPassword').untouched || form.get('supplyOptimizerPassword').valid">
          <mat-label>Panel Admin URL</mat-label>
          <input matInput id="addEditCustomerForm_panelAdminUrl" formControlName="panelAdminUrl" autocomplete="off" type="text" />
          <mat-error *ngIf="form.get('panelAdminUrl').hasError('required')">
            Panel Admin URL url is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="d-sm-flex">
        <!-- MonetizePlatforms -->
        <div class="mr-sm-1 w-sm-40">
          <div class="d-flex flex-column pt-2" formGroupName="monetizePlatforms">
            <h6 class="text-medium-grey">Monetize Platforms</h6>
            <mat-checkbox *ngFor="let platform of platforms" class="box-sizing-content pb-1" [id]="'addEditCustomerForm_monetizePlatforms_' + platform" [formControlName]="platform" [disableRipple]="!0">{{platformMap[platform]?.name}}</mat-checkbox>
          </div>
        </div>

        <!-- AccessList - IF Super Admin/Admin -->
        <div class="ml-sm-1 w-sm-50" *ngIf="user && user?.role < 2">
          <div formGroupName="access" class="d-flex flex-column pt-2">
            <h6 class="text-medium-grey">Allowed Services</h6>
            <div class="d-flex">
              <div class="d-flex flex-column mr-4">
                <mat-slide-toggle id="addEditCustomerForm_access_profile" class="box-sizing-content pb-1" formControlName="profile" [disableRipple]="!0">Profile</mat-slide-toggle>
                <mat-slide-toggle id="addEditCustomerForm_access_sample" class="box-sizing-content pb-1" formControlName="sample" [disableRipple]="!0">Sample</mat-slide-toggle>
                <mat-slide-toggle id="addEditCustomerForm_access_reward" class="box-sizing-content pb-1" formControlName="reward" [disableRipple]="!0">Reward</mat-slide-toggle>
                <mat-slide-toggle id="addEditCustomerForm_access_monetize" class="box-sizing-content pb-1" formControlName="monetize" [disableRipple]="!0">Monetize</mat-slide-toggle>
                <mat-slide-toggle id="addEditCustomerForm_access_invite" class="box-sizing-content pb-1" formControlName="invite" [disableRipple]="!0">Invite</mat-slide-toggle>
              </div>
              <div class="d-flex flex-column">
                <mat-slide-toggle id="addEditCustomerForm_access_media" class="box-sizing-content pb-1" formControlName="media" [disableRipple]="!0">Media</mat-slide-toggle>
                <mat-slide-toggle id="addEditCustomerForm_access_community" class="box-sizing-content pb-1" formControlName="community" [disableRipple]="!0">Community</mat-slide-toggle>
                <mat-slide-toggle id="addEditCustomerForm_access_productTests" class="box-sizing-content pb-1" formControlName="productTests" [disableRipple]="!0">Product Tests</mat-slide-toggle>
                <mat-slide-toggle id="addEditCustomerForm_access_recruit" class="box-sizing-content pb-1" formControlName="recruit" [disableRipple]="!0">Recruit</mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Logo -->
      <div class="pt-2" *ngIf="user && user?.role < 2">
        <h6 class="text-medium-grey">Site Logo</h6>
        <upload-image [image]="markLogoForRemoval ? null : customer?.logoPath?.downloadURL" [uploadProgress]="uploadProgress" (save)="updateLogo($event)">
          <button mat-flat-button id="addEditCustomerForm_removeLogoButton" color="warn" type="button" (click)="removeLogo()"
                  *ngIf="logo || (!markLogoForRemoval && customer?.logoPath?.downloadURL)">
            Remove image
          </button>
        </upload-image>
      </div>

    </div>
    <div dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button type="button" mat-stroked-button id="addEditCustomerForm_cancelButton" (click)="close()" class="mr-3">Cancel</button>
        <!--<button type="button" mat-flat-button color="warn" (click)="delete()" *ngIf="user.role === 0 && editMode" class="mr-3">Delete</button>-->
        <button type="submit" mat-flat-button id="addEditCustomerForm_saveButton" class="bg-success">{{ editMode ? 'Save' : 'Create Customer'}}</button>
      </div>
    </div>
  </app-dialog>
</form>
