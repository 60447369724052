import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserRole } from 'src/app/data.models'
import { AuthRoleGuard } from '../../guards/auth-role.guard';

import { AccountComponent } from './account.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeDetailsComponent } from './change-details/change-details.component';

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    children: [
      {
        path: 'manage-users',
        canActivate: [AuthRoleGuard],
        data: {
          role: UserRole.Admin
        },
        component: ManageUsersComponent
      },
      {
        path: 'change-details',
        component: ChangeDetailsComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'change-details'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
