import { CommunityMember } from 'src/app/components/community/community.models';
import { extendObj } from 'src/app/data.models';
import * as Reducers from '../reducers';
import * as Actions from './community-test-user.actions';

export interface State extends Reducers.State<CommunityMember> { }

export const initialState: State = {
  data: null, archive: null, count: 0, map: {}, errors: {}
};

export function reducerFn(state: State = initialState, action: Actions.Any): State {

  const newState: State = extendObj(true, {}, state);
  newState.map = Object.keys(newState.map).reduce((map, key) => {
    map[key] = new CommunityMember(newState.map[key]);
    return map;
  }, {});

  switch (action.type) {

    case Actions.Types.GetSuccess: newState.errors = { ...newState.errors, ...(action.payload ? { [action.payload.id || ""]: null } : {}) };
    case Actions.Types.CreateSuccess:
      newState.map = { ...newState.map, ...(action.payload ? { [action.payload.id || ""]: action.payload } : {}) };
      newState.errors = { ...newState.errors, create: null };
      break;


    case Actions.Types.GetAllSuccess:
      newState.map = { ...newState.map, ...Reducers.map(action.payload) };
      newState.errors = { ...newState.errors, all: null };
      break;


    case Actions.Types.DeleteSuccess:
      newState.map = {
        ...newState.map,
        [action.id]: null
      };
      break;


    case Actions.Types.GetAllError:
      newState.map = {};
      newState.data = [];
    case Actions.Types.CreateError:
    case Actions.Types.GetError:
    case Actions.Types.DeleteError:
      newState.errors = {
        ...state.errors,
        [action.type === Actions.Types.GetAllError ? 'all'
          : action.type === Actions.Types.CreateError ? 'create'
            : action.id]: action.error
      };
      break;


    case Actions.Types.Clear:
      return initialState;

    default:
      return state;
  }

  newState.archive = Object.values(newState.map).filter(t => !!t && t.archived);
  newState.data = Object.values(newState.map).filter(t => !!t && !t.archived);
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  !!newState.data && newState.data.length || (newState.data = null);
  return newState;
}
