import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService, LoggerService } from 'src/app/services';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public busy: boolean;
  @Input() closeOnOverlayClick: boolean = false;

  constructor(
    private router: Router,
    private logger: LoggerService,
    private dialogService: DialogService,
  ) {}

  close() {
    this.dialogService.close();
  }

  ngOnInit() {
    this.subs.push(
      this.dialogService.busy$.subscribe(busy => {
        this.busy = busy;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
