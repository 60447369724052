<app-dialog>
  <div dialog-title class="text-tiny">{{title}}</div>
  <div dialog-content>
    <p class="text-center">Once you confirm, this action cannot be undone.</p>
  </div>
  <div dialog-actions>
    <button type="button" mat-stroked-button id="deleteCustomerComponent_cancelButton" (click)="close()" class="mr-3">Cancel</button>
    <button type="button" mat-flat-button id="deleteCustomerComponent_saveButton" (click)="delete()" color="warn">Delete Customer</button>
  </div>
</app-dialog>
