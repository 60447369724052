import { Injectable } from '@angular/core';
import { SkeletonElement, SkeletonColumn, SkeletonRow } from 'src/app/data.models';

@Injectable({
  providedIn: 'root'
})
export class SkeletonService {
  foregroundColor: string = "#fbfbfb";
  backgroundColor: string = "#ecebeb";

  constructor(
  ) { }

  private getTableCell(width: number, base: SkeletonElement = {}) {
    return new SkeletonColumn({ ...base, width });
  }

  isRow(element): element is SkeletonRow {
    return element.type === "row";
  }
  isElement(element): element is SkeletonColumn {
    return element.type === "column";
  }
  private getTableRow(columns: number[], headerRow?: boolean, compact?: boolean, horzSpacer = 0.1, vertSpacer = 1) {
    const rowHeight = (compact ? 50 : 60);
    return new SkeletonRow({
      height: rowHeight - (headerRow ? (compact ? 10 : 0) : 30),
      bottom: vertSpacer,
      radius: 0,
      children: [
        ...(columns.map((width, i) => {
          return this.getTableCell(width - (i > 0 ? horzSpacer : 0), { ...(i > 0 ? { left: horzSpacer } : {}), radius: 0 })
        }))
      ]
    })
  }

  getTable(columns: number[] = [50, 25, 25], rows = 5, compact?: boolean, horzSpacer = 0.1, vertSpacer = 1, skipHeader?: boolean) {
    const table: SkeletonRow[] = [];
    if (!skipHeader) {
      table.push(this.getTableRow(columns, true, compact, horzSpacer, vertSpacer));
    }
    while (rows > 0) {
      table.push(this.getTableRow(columns, false, compact, horzSpacer, vertSpacer))
      --rows;
    }

    return table;
  }

  getList(count: number = 10): SkeletonRow[] {
    const list = [this.getRectangles(1, 100, 40).set("bottom", 10).set("top", 20)];

    while (count > 1) {
      list.push(this.getRectangles(1, 100, 40).set("bottom", 10));
      count--;
    }
    return list;
  }

  getRectangles(count: number = 1, width: number = 33, height: number = 150, base: SkeletonElement = {}, horzSpacer: number = 2): SkeletonRow {
    let rectangles: SkeletonColumn[] = [];
    while (count > 0) {
      rectangles.push(new SkeletonColumn({
        width: width - (rectangles.length ? horzSpacer : 0),
        left: rectangles.length ? horzSpacer : 0,
        height
      }));
      count--;
    }
    return new SkeletonRow({
      ...base,
      height,
      children: rectangles
    });
  }

  getButtons(count: number = 1, base: SkeletonElement = {}): SkeletonRow {
    let buttons: SkeletonColumn[] = [];
    while (count > 0) {
      buttons.push(new SkeletonColumn({ left: buttons.length ? 1 : 0, width: 25 }));
      count--;
    }
    return new SkeletonRow({
      ...base,
      height: 50,
      children: buttons
    });
  }

  getInputs(count: number = 1, base: SkeletonElement = {}, availableWidth = 100) {
    let inputs: SkeletonColumn[] = [];
    const height = 44;
    const getWidth = () => Math.floor(availableWidth / count);
    while (count > 0) {
      const space = (inputs.length ? 1 : 0);
      const width = availableWidth <= 0 ? 0 : (count === 1 ? availableWidth : getWidth());
      availableWidth -= width;
      inputs.push(new SkeletonColumn({ left: space, width: width - space, height }));
      count--;
    }
    return new SkeletonRow({
      ...base,
      height: height,
      children: inputs
    });
  }

  getCircle(size: number = 45, count: number = 1, base: SkeletonElement = {}) {
    let circles: SkeletonColumn[] = [];
    while (count > 0) {
      circles.push(new SkeletonColumn({ width: size, widthUnit: "px", radius: size }));
      count--;
    }
    return new SkeletonRow({
      ...base,
      height: size,
      children: circles
    });
  }

  getTitle(base: SkeletonElement = {}) {
    return new SkeletonRow({
      ...base,
      height: 45,
      bottom: 20,
      children: [new SkeletonColumn({ width: 170, widthUnit: "px", height: 45, })]
    });
  }

  getSubtitle(base: SkeletonElement = {}) {
    return new SkeletonRow({
      ...base,
      height: 30,
      bottom: 20,
      children: [new SkeletonColumn({ width: 170, widthUnit: "px", height: 30, })]
    });
  }

  getLinks(count: number = 1, base: SkeletonElement = {}) {
    let links: SkeletonColumn[] = [];
    while (count > 0) {
      links.push(new SkeletonColumn({ left: links.length ? 5 : 0, width: 20, height: 10 }));
      count--;
    }
    return new SkeletonRow({
      ...base,
      height: 10,
      children: links
    });
  }

  getTextBlock(count: number = 2, base: SkeletonElement = {}) {
    let lines: SkeletonRow[] = [];
    const height = 18;
    const total = count;
    while (count > 0) {
      lines.push(new SkeletonRow({
        ...base,
        height,
        bottom: 8,
        children: [
          new SkeletonColumn({ width: count === 1 ? (total === 1 ? 100 : 60) : 110, height, })
        ]
      }));
      count--;
    }
    return lines;
  }

  getCheckboxList(count: number = 2, base: SkeletonElement = {}, availableWidth = 97): SkeletonRow {
    const size = 20;
    const adjustLeft = 2;
    let checkboxList: SkeletonRow = new SkeletonRow({
      ...(base || {}),
      children: []
    });
    while (count > 0) {
      checkboxList.children.push(new SkeletonRow({
        height: size,
        bottom: 8,
        children: [
          new SkeletonColumn({ width: size, height: size, widthUnit: 'px' }),
          new SkeletonColumn({ width: (base && (base.width - (base.left ? base.left + adjustLeft : adjustLeft)) || availableWidth), left: adjustLeft, height: size - 4, top: 2 })
        ]
      }));
      count--;
    }
    return checkboxList;
  }
}
