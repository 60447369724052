import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, share, skipWhile, tap } from 'rxjs/operators';
import { SampleModels } from 'src/app/data.models';
import { SampleProjectService } from 'src/app/services/sample/sample-project.service';
import { UserQueryService } from 'src/app/services/user-query.service';
import * as SampleProjectActions from './sample-project.actions';

@Injectable()
export class SampleProjectEffects {

  private currentID: string;
  private queue: { [key: string]: Observable<SampleModels.SampleProject[]> } = {};
  private requests: { [key: string]: Observable<SampleModels.SampleProject> } = {};

  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProjectActions.Types.GetArchived),
      mergeMap(() => this.service.getArchived()
        .pipe(
          map(projects => (new SampleProjectActions.GetAllSuccess(projects))),
          catchError((err: Error) => of(new SampleProjectActions.GetAllError(err)))
        ))
    ));

  
  getStats$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProjectActions.Types.GetStats),
      mergeMap((action: SampleProjectActions.GetStats) => this.userQueryService.getSampleProjectStats(action.id)
        .pipe(
          skipWhile(stats => !stats),
          map(stats => {
            return new SampleProjectActions.GetStatsSuccess(action.id, stats);
          }),
          catchError((err: Error) => of(new SampleProjectActions.UpdateError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProjectActions.Types.Create),
      mergeMap((action: SampleProjectActions.Create) => this.service.create(action.payload)
        .pipe(
          map((project) => (new SampleProjectActions.CreateSuccess(project))),
          catchError((err: Error) => of(new SampleProjectActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProjectActions.Types.Update),
      mergeMap((action: SampleProjectActions.Update) => this.service.update(action.payload)
        .pipe(
          map((project) => (new SampleProjectActions.UpdateSuccess(project))),
          catchError((err: Error) => of(new SampleProjectActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProjectActions.Types.Delete),
      mergeMap((action: SampleProjectActions.Delete) => this.service.archive(action.id, true)
        .pipe(
          map(() => (new SampleProjectActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new SampleProjectActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: SampleProjectService,
    private userQueryService: UserQueryService,
  ) { }
}
