import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SurveyActions from './product-test-survey.actions';
import { ProductTestSurveyService } from '../../services/panel-admin/product-test/product-test-survey.service';

@Injectable()
export class SurveyEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(SurveyActions.Types.GetAll),
      mergeMap((action: SurveyActions.GetAll) => {
        return this.service.getAll(action.filters)
          .pipe(
            map(surveys => (new SurveyActions.GetAllSuccess(surveys, action.filters))),
            catchError((err: Error) => of(new SurveyActions.GetAllError(err)))
          );
      })
    ));

  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(SurveyActions.Types.GetArchived),
      mergeMap(() => this.service.getAll("{archived:true}")
        .pipe(
          map(surveys => (new SurveyActions.GetArchivedSuccess(surveys))),
          catchError((err: Error) => of(new SurveyActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(SurveyActions.Types.Get),
      mergeMap((action: SurveyActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(survey => (survey ? new SurveyActions.GetSuccess(survey) : new SurveyActions.GetError(action.id, new Error("Unknown Error")))),
          catchError((err: Error) => of(new SurveyActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(SurveyActions.Types.Create),
      mergeMap((action: SurveyActions.Create) => this.service.create(action.payload)
        .pipe(
          map((survey) => (new SurveyActions.CreateSuccess(survey))),
          catchError((err: Error) => of(new SurveyActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(SurveyActions.Types.Update),
      mergeMap((action: SurveyActions.Update) => this.service.update(action.id, action.payload)
        .pipe(
          map((survey) => (new SurveyActions.UpdateSuccess(survey))),
          catchError((err: Error) => of(new SurveyActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(SurveyActions.Types.Delete),
      mergeMap((action: SurveyActions.Delete) => this.service.archive(action.id)
        .pipe(
          map(() => (new SurveyActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new SurveyActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: ProductTestSurveyService
  ) { }
}
