import { HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SiteImage, SiteImageBase } from 'src/app/components/recruit/recruit.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as Actions from 'src/app/store/recruit/recruit-image.actions';
import { IRecruitStore } from 'src/app/store/recruit/recruit.store';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class RecruitImageService {
  
  private endpoint: string = 'site_images';
  private site_images_requested: { [id: string]: boolean; } = {};

  constructor(
    private store: Store<{ recruit: IRecruitStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method RecruitSiteImageService.create
   * @description Saves a new SiteImage to the server
   * @param {SiteImage} formData The new SiteImage to be saved
   * @returns Observable<SiteImage>
   */
  public create(formData: FormData): Observable<SiteImage> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<SiteImageBase>(this.endpoint, formData as any, false, panelAdminUrl, null, {}, false).pipe(
        map((resp) => {
          const image = new SiteImage(resp);
          this.store.dispatch(new Actions.CreateSuccess(image));
          return image;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.CreateError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method RecruitSiteImageService.getAll
   * @description Retrieves a list of all SiteImages in the Sample API
   * @returns Observable<SiteImage[]>
   */
  public getAll(): Observable<SiteImage[]> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<SiteImageBase[]>(this.endpoint, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        switchMap((resp: any) => {
          const images = resp.map((p: SiteImageBase) => new SiteImage(p));
          this.store.dispatch(new Actions.GetAllSuccess(images));
          return this.store.pipe(select(state => state.recruit.siteImages.data));
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.GetAllError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method RecruitSiteImageService.getByID
   * @description Retrieves a SiteImage for a given ID
   * @param {string} id The ID of the SiteImage to be retrieved
   * @returns Observable<SiteImage>
   */
  public getByID(id: string | number, force?: boolean): Observable<SiteImage> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return state.recruit.siteImages.map[id];
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      switchMap((image) => {
        if (!this.site_images_requested[id] && (force || !image)) {
          this.site_images_requested[id] = true;
          return this.panelAdminService.getPanelAdminUrl().pipe(
            switchMap(panelAdminUrl =>
              this.api.get<SiteImageBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
                map((p) => {
                  const image = new SiteImage(p);
                  this.store.dispatch(new Actions.GetSuccess(image));
                  this.site_images_requested[id] = false;
                  return image;
                }),
                catchError((err: any) => {
                  this.store.dispatch(new Actions.GetError(id + "", err));
                  this.site_images_requested[id] = false;
                  return this.panelAdminService.handleError(err);
                })
              )
            ),
            catchError(() => of(null))
          );
        }
        return of(image);
      })
    );
  }

  /**
   * @method RecruitSiteImageService.delete
   * @description Deletes a SiteImage on the server
   * @returns Observable<boolean>
   */
  public delete(id: string): Observable<boolean> {
    const url = this.endpoint + '/' + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.delete<any>(url, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => {
          this.store.dispatch(new Actions.DeleteSuccess(id));
          return true;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.DeleteError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

}
