import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from 'src/app/services/logger.service';
import { CustomerService } from '../services/customer.service';
import { PanelAdminService } from '../services/panel-admin/panel-admin.service';

@Injectable()
export class PanelAdminInterceptor implements HttpInterceptor {

  private tokenName: string = 'Authorization';
  private verbose: boolean;

  constructor(
    private customerService: CustomerService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) {
  }

  /**
   * @method AuthInterceptor.intercept
   * @description Intercepts all API calls and adds the authorisation token to it
   * @param req The request being issued
   * @param next The next Http handler in the queue
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    //Check that a customer exists
    const customer = this.customerService.currentCustomer;

    if (customer && customer.panelAdminUrl) {

      const isPanelAdminCall = req.url.indexOf(customer.panelAdminUrl) > -1;

      if (isPanelAdminCall) {

        //Check that the request is to the Panel Admin server
        //this.logger.log("PanelAdminInterceptor: Intercepting http " + req.method + " req to '" + req.url + "'");

        // Get the panelServicesToken from the AuthService.
        const authToken = this.panelAdminService.getToken(customer.panelAdminUrl);
        if (authToken) {
          // Add the new Authorization header
          const authReq = req.clone({
            headers: req.headers.set(this.tokenName, "Bearer " + authToken)
          });

          return next.handle(authReq);
        }
        else {
          this.logger.warn("PanelAdminInterceptor: User has no authToken");
        }

        // send cloned request with header to the next handler.
      }
      else if (this.verbose) {
        this.logger.log("PanelAdminInterceptor: Not a panel admin request");
      }
    }
    else if (this.verbose) {
      this.logger.log("PanelAdminInterceptor: No customer or customer has no panel-admin URL");
    }
    //By default, pass the req onto the next handler
    return next.handle(req);

  }
}
