import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PanelAdminSurvey, PanelAdminSurveyBase, PanelAdminSurveyFilters } from 'src/app/components/product-tests/product-tests.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as SellerActions from 'src/app/store/product-test/product-test-seller.actions';
import { IStore } from 'src/app/store/product-test/product-test.store';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTestSurveyService {

  private endpoint: string = 'surveys';

  filters: { [key: string]: PanelAdminSurveyFilters; } = {};

  getFilterKey(filters: any) {
    const key = JSON.stringify(filters);
    this.filters[key] = this.filters[key] || filters;
    return key;
  }

  constructor(
    private store: Store<{ productTests: IStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method PanelAdminSurveyService.create
   * @description Saves a new ProductTestSurvey to the server
   * @param {PanelAdminSurvey} survey The new ProductTestSurvey to be saved
   * @returns Observable<ProductTestSurvey>
   */
  public create(profile: PanelAdminSurvey): Observable<PanelAdminSurvey> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<PanelAdminSurveyBase>(this.endpoint, profile.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((p) => {
          const survey = new PanelAdminSurvey(p)
          this.store.dispatch(new SellerActions.CreateSuccess(survey));
          return survey;
        }),
        //switchMap((survey) => {
        //  return this.favoritesService.addFavorite(FavoritesTypes.ProductTestSurveys, survey.id).pipe(map(() => survey));
        //}),
        catchError((resp: any) => {
          this.store.dispatch(new SellerActions.CreateError(resp));
          return this.panelAdminService.handleError(resp);
        }))
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminSurveyService.getAll
   * @description Retrieves a list of all ProductTestSurveys in the Sample API
   * @returns Observable<ProductTestSurvey[]>
   */
  public getAll(filters?: string): Observable<PanelAdminSurvey[]> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl => {
      const params: any = {};
      if (filters) {
        params.filters = filters;
      }
      else {
        params.filters = `{archived:"false"}`;
      }
      return this.api.get<PanelAdminSurveyBase[]>(this.endpoint, params, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => resp.map((s: PanelAdminSurveyBase) => new PanelAdminSurvey(s))),
        catchError((resp: any) => this.panelAdminService.handleError(resp)
        ))
    }), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminSurveyService.getByID
   * @description Retrieves a ProductTestSurvey for a given ID
   * @param {string} id The ID of the ProductTestSurvey to be retrieved
   * @returns Observable<ProductTestSurvey>
   */
  public getByID(id: string): Observable<PanelAdminSurvey> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<PanelAdminSurveyBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((s) => {
          return new PanelAdminSurvey(s);
        }),
        catchError((resp: any) => this.panelAdminService.handleError(resp)
        ))
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminSurveyService.update
   * @description Updates a ProductTestSurvey on the server
   * @returns Observable<ProductTestSurvey>
   */
  public update(id: string, survey: PanelAdminSurveyBase): Observable<PanelAdminSurvey> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.put<PanelAdminSurveyBase>(this.endpoint + '/' + id, survey, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((b) => {
          const storedBrandSeller = new PanelAdminSurvey(b);
          this.store.dispatch(new SellerActions.UpdateSuccess(storedBrandSeller));
          return storedBrandSeller;
        }),
        catchError((err: any) => {
          this.store.dispatch(new SellerActions.UpdateError(survey.id, err));
          return this.panelAdminService.handleError(err);
        }))
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminSurveyService.archive
   * @description Archives a ProductTestSurvey from the server, by setting it's 'archived' property to 'true'
   * @returns Observable<ProductTestSurvey>
   */
  public archive(id: string, archive?: boolean): Observable<PanelAdminSurvey> {
    return this.update(id, { archived: !!archive });
  }

}
