import { Action } from '@ngrx/store';
import { ProductTestCampaign } from '../../components/product-tests/product-tests.models';

export enum Types {
  Get                 = '[ProductTestCampaign] Get',
  GetSuccess          = '[ProductTestCampaign] GetSuccess',
  GetError            = '[ProductTestCampaign] GetError',
  GetAll              = '[ProductTestCampaign] GetAll',
  GetAllSuccess       = '[ProductTestCampaign] GetAllSuccess',
  GetAllError         = '[ProductTestCampaign] GetAllError',
  GetArchived         = '[ProductTestCampaign] GetArchived',
  GetArchivedSuccess  = '[ProductTestCampaign] GetArchivedSuccess',
  Create              = '[ProductTestCampaign] Create',
  CreateSuccess       = '[ProductTestCampaign] CreateSuccess',
  CreateError         = '[ProductTestCampaign] CreateError',
  Delete              = '[ProductTestCampaign] Delete',
  DeleteSuccess       = '[ProductTestCampaign] DeleteSuccess',
  DeleteError         = '[ProductTestCampaign] DeleteError',
  Update              = '[ProductTestCampaign] Update',
  UpdateSuccess       = '[ProductTestCampaign] UpdateSuccess',
  UpdateError         = '[ProductTestCampaign] UpdateError',
  Clear               = '[ProductTestCampaign] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() { }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: ProductTestCampaign[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() { }
}

export class GetArchivedSuccess implements Action {
  readonly type = Types.GetArchivedSuccess;
  constructor(public payload: ProductTestCampaign[]) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: ProductTestCampaign) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: ProductTestCampaign) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: ProductTestCampaign) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: string, public payload: ProductTestCampaign) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: ProductTestCampaign) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetArchived | GetArchivedSuccess | GetAll | GetAllSuccess | GetAllError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
