import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as MemberActions from './community-member.actions';
import { CommunityMemberService } from '../../services/panel-admin/community/community-member.service';

@Injectable()
export class MemberEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(MemberActions.Types.GetAll),
      mergeMap(() => this.service.getAll()
        .pipe(
          map(resp => (new MemberActions.GetAllSuccess(resp.data))),
          catchError((err: Error) => of(new MemberActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(MemberActions.Types.Get),
      mergeMap((action: MemberActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(test => (test ? new MemberActions.GetSuccess(test) : new MemberActions.GetError(action.id, new Error("Member not found")))),
          catchError((err: Error) => of(new MemberActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(MemberActions.Types.Create),
      mergeMap((action: MemberActions.Create) => this.service.create(action.payload)
        .pipe(
          map((test) => (new MemberActions.CreateSuccess(test))),
          catchError((err: Error) => of(new MemberActions.CreateError(err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: CommunityMemberService
  ) { }
}
