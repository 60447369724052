import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProductTestProduct, ProductTestProductBase } from 'src/app/components/product-tests/product-tests.models';
import { FavoritesTypes } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as ProductActions from 'src/app/store/product-test/product-test-product.actions';
import { IStore } from 'src/app/store/product-test/product-test.store';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTestProductService {

  private endpoint: string = 'products';

  constructor(
    private store: Store<{ productTests: IStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method PanelAdminProductsService.create
   * @description Saves a new ProductTestsProduct to the server
   * @param {ProductTestProduct} product The new ProductTestsProduct to be saved
   * @returns Observable<ProductTestsProduct>
   */
  public create(product: ProductTestProduct): Observable<ProductTestProduct> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<ProductTestProductBase>(this.endpoint, product.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((p) => {
          const storedProduct = new ProductTestProduct(p)
          this.store.dispatch(new ProductActions.CreateSuccess(storedProduct));
          return storedProduct;
        }),
        switchMap((p) => {
          return this.favoritesService.addFavorite(FavoritesTypes.ProductTestsProduct, p.id).pipe(map(() => p));
        }),
        catchError((resp: any) => {
          this.store.dispatch(new ProductActions.CreateError(resp));
          return this.panelAdminService.handleError(resp);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminProductsService.getAll
   * @description Retrieves a list of all ProductTestsProducts in the Sample API
   * @returns Observable<ProductTestsProduct[]>
   */
  public getAll(archive?: boolean): Observable<ProductTestProduct[]> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ProductTestProductBase[]>(this.endpoint, archive ? { archived: "true" } : {}, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => resp.map((p: ProductTestProductBase) => new ProductTestProduct(p))),
        catchError((resp: any) => this.panelAdminService.handleError(resp))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminProductsService.getByID
   * @description Retrieves a ProductTestsProduct for a given ID
   * @param {string} id The ID of the ProductTestsProduct to be retrieved
   * @returns Observable<ProductTestsProduct>
   */
  public getByID(id: string): Observable<ProductTestProduct> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ProductTestProductBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((p) => {
          return new ProductTestProduct(p);
        }),
        catchError((resp: any) => this.panelAdminService.handleError(resp))
      )
    ));
  }

  /**
   * @method PanelAdminProductsService.update
   * @description Updates a ProductTestsProduct on the server
   * @returns Observable<ProductTestsProduct>
   */
  public update(id: string, product: ProductTestProductBase): Observable<ProductTestProduct> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/' + id, JSON.stringify({ data: product }), false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((p: ProductTestProductBase) => {
          const storedProduct = new ProductTestProduct(p);
          this.store.dispatch(new ProductActions.UpdateSuccess(storedProduct));
          return storedProduct;
        }),
        catchError((err: any) => {
          this.store.dispatch(new ProductActions.UpdateError(product.id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminProductsService.archive
   * @description Archives a ProductTestsProduct from the server, by setting it's 'archived' property to 'true'
   * @returns Observable<ProductTestsProduct>
   */
  public archive(id: string, archive?: boolean): Observable<ProductTestProduct> {
    return this.update(id, { archived: !!archive });
  }

}
