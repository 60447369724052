<ng-container *ngIf="isLoggedIn">
  <button mat-button [matMenuTriggerFor]="menu">
    <div class="d-flex justify-content-center align-items-center">
      <span *ngIf="!(isHandset$|async)">{{user?.firstName}}</span>
      <mat-icon class="pl-sm-1">account_circle</mat-icon>
    </div>
  </button>
  <mat-menu #menu="matMenu">

    <span class="section-header" *ngIf="(isHandset$|async)"><mat-icon>account_circle</mat-icon>{{user?.firstName}}</span>
    <mat-divider></mat-divider>

    <ng-container *ngIf="user?.role < 2">
      <span class="section-header" *ngIf="!(isHandset$|async)"><mat-icon>lock</mat-icon>Admin</span>
      <mat-divider *ngIf="!(isHandset$|async)"></mat-divider>
      <button mat-menu-item routerLink="/account/manage-users">Manage Users</button>
      <mat-divider *ngIf="!(isHandset$|async)"></mat-divider>
      <span class="section-header" *ngIf="!(isHandset$|async)"><mat-icon>account_circle</mat-icon>Account</span>
      <mat-divider></mat-divider>
    </ng-container>

    <button mat-menu-item routerLink="/account/change-email">Change email</button>
    <button mat-menu-item routerLink="/account/change-password">Change password</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()">Logout</button>

  </mat-menu>
</ng-container>
