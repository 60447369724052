<form [formGroup]="form" (submit)="editMode ? saveUser() : createUser()" autocomplete="off" id="addEditUserForm">
  <app-dialog>
    <div dialog-title>Add User for {{customer?.name}}</div>
    <div dialog-content>
      <div class="d-flex">
        <!-- First Name -->
        <mat-form-field class="mr-1">
          <mat-label>First Name</mat-label>
          <input matInput id="addEditUserForm_firstName" formControlName="firstName" type="text" />
        </mat-form-field>

        <!-- Last Name -->
        <mat-form-field class="ml-1">
          <mat-label>Last Name</mat-label>
          <input matInput id="addEditUserForm_lastName" formControlName="lastName" type="text" />
        </mat-form-field>
      </div>

      <div class="d-flex align-items-center">
        <!-- Email -->
        <mat-form-field [class.mr-1]="editMode && !isCurrentUser"
                        [class.disabled]="editMode && !isCurrentUser">
          <mat-label>Email</mat-label>
          <input matInput id="addEditUserForm_email" [readonly]="editMode && !isCurrentUser" formControlName="email" type="email" />
          <mat-error *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="form.get('email').hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <button *ngIf="editMode && user?.email" type="button" id="addEditUserForm_resetPasswordButton" color="primary"
                mat-stroked-button (click)="sendResetPasswordEmail()"
                class="ml-1"
                style="min-width: 160px;">
          <ng-container *ngIf="!sendingResetPasswordEmail">Reset Password</ng-container>
          <app-loader *ngIf="sendingResetPasswordEmail" size="small"></app-loader>
        </button>
      </div>

      <!-- Role - IF Super Admin/Admin -->
      <mat-form-field *ngIf="currentUser && currentUser?.role < 2">
        <mat-label>Role</mat-label>
        <mat-select id="addEditUserForm_role" formControlName="role">
          <mat-option *ngFor="let role of availableRoles" [value]="role.value">
            {{role.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Customer UID - IF Super Admin -->
      <input id="addEditUserForm_customerId" formControlName="customerId" type="hidden" />

      <!-- AllowAllServices - IF Super Admin/Admin -->
      <div *ngIf="currentUser && currentUser?.role < 2" class="d-flex flex-column align-items-start">
        <h6 class="text-medium-grey">Allow all Services</h6>
        <mat-slide-toggle id="addEditUserForm_allowAllServices" class="box-sizing-content pb-3" formControlName="allowAllServices" [disableRipple]="!0">Allow all</mat-slide-toggle>
      </div>

      <!-- AccessList - IF Super Admin/Admin -->
      <div *ngIf="currentUser && currentUser?.role < 2" formGroupName="access" class="d-flex flex-column" [hidden]="form?.get('allowAllServices').value">
        <h6 class="text-medium-grey">Allowed Services</h6>
        <mat-error *ngIf="!allowedServicesCount">There are no allowed services for members of this customer. Edit the customer to allow them there first.</mat-error>
        <div class="d-flex">
          <div class="d-flex flex-column mr-4">
            <mat-slide-toggle id="addEditUserForm_allowedServicesCount_profile" class="box-sizing-content pb-1" *ngIf="customer?.access?.profile" formControlName="profile" [disableRipple]="!0">Profile</mat-slide-toggle>
            <mat-slide-toggle id="addEditUserForm_allowedServicesCount_sample" class="box-sizing-content pb-1" *ngIf="customer?.access?.sample" formControlName="sample" [disableRipple]="!0">Sample</mat-slide-toggle>
            <mat-slide-toggle id="addEditUserForm_allowedServicesCount_reward" class="box-sizing-content pb-1" *ngIf="customer?.access?.reward" formControlName="reward" [disableRipple]="!0">Reward</mat-slide-toggle>
            <mat-slide-toggle id="addEditUserForm_allowedServicesCount_monetize" class="box-sizing-content pb-1" *ngIf="customer?.access?.monetize" formControlName="monetize" [disableRipple]="!0">Monetize</mat-slide-toggle>
            <mat-slide-toggle id="addEditUserForm_allowedServicesCount_invite" class="box-sizing-content pb-1" *ngIf="customer?.access?.invite" formControlName="invite" [disableRipple]="!0">Invite</mat-slide-toggle>
          </div>
          <div class="d-flex flex-column">
            <mat-slide-toggle id="addEditUserForm_allowedServicesCount_media" class="box-sizing-content pb-1" *ngIf="customer?.access?.media" formControlName="media" [disableRipple]="!0">Media</mat-slide-toggle>
            <mat-slide-toggle id="addEditUserForm_allowedServicesCount_community" class="box-sizing-content pb-1" *ngIf="customer?.access?.community" formControlName="community" [disableRipple]="!0">Community</mat-slide-toggle>
            <mat-slide-toggle id="addEditUserForm_allowedServicesCount_productTests" class="box-sizing-content pb-1" *ngIf="customer?.access?.productTests" formControlName="productTests" [disableRipple]="!0">Product Tests</mat-slide-toggle>
            <mat-slide-toggle id="addEditUserForm_allowedServicesCount_recruit" class="box-sizing-content pb-1" *ngIf="customer?.access?.recruit" formControlName="recruit" [disableRipple]="!0">Recruit</mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="d-flex" *ngIf="showPanelAdminFields">
        <!-- Panel Admin Email -->
        <mat-form-field class="mr-1">
          <mat-label>Panel Admin Email</mat-label>
          <input matInput id="addEditUserForm_panelAdminEmail" formControlName="panelAdminEmail" type="email" />
        </mat-form-field>

        <!-- Panel Admin Password -->
        <mat-form-field class="ml-1">
          <mat-label>Panel Admin Password</mat-label>
          <input matInput id="addEditUserForm_panelAdminPassword" formControlName="panelAdminPassword" type="text" />
        </mat-form-field>
      </div>

      <!-- Error -->
      <div *ngIf="errorMsg" class="p-2 text-center">
        <mat-error>{{errorMsg}}</mat-error>
      </div>

    </div>
    <div dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button type="button" id="addEditUserForm_cancelButton" mat-stroked-button (click)="close()" class="mr-3">Cancel</button>
        <button type="submit" id="addEditUserForm_saveButton" mat-flat-button class="bg-success">{{editMode ? 'Save' : 'Create Account'}}</button>
      </div>
    </div>
  </app-dialog>
</form>
