import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { InbrainSurveyAttempt, PollfishSurveyPagerFilters } from 'src/app/components/community/community.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { PanelAdminService } from '../panel-admin.service';
import { Pager, PagedHttpResponse, PagerConfig } from '../../paging.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityInbrainService {

  private endpoint: string = 'inbrain_survey_attempts';

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method PanelAdminInbrainService.getByID
   * @description Retrieves a InbrainSurveyAttempt
   * @returns Observable<InbrainSurveyAttempt>
   */
  public getByID(id?: number | string): Observable<InbrainSurveyAttempt> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + '/' + id, {}, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method PanelAdminInbrainService.getAll
   * @description Retrieves a list of all InbrainSurveyAttempt, filtered by the params set
   * @returns Observable<PagedHttpResponse<InbrainSurveyAttempt>>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<InbrainSurveyAttempt>> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + (params || ''), {}, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: PollfishSurveyPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }

}
