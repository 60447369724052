import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { UploadTask } from '../data.models';
import { LoggerService } from './logger.service';
import { FirebaseStorage, getStorage, StorageReference, listAll, getDownloadURL, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  storage: FirebaseStorage = getStorage();

  constructor(
    private logger: LoggerService
  ) { }

  public getURL(path: string, filter?: string): Observable<string>;
  public getURL(ref: StorageReference, filter?: string): Observable<string>
  public getURL(...args): Observable<string> {
    let fileRef: StorageReference;
    let filter: string;
    if (typeof args[0] === "string") {
      fileRef = ref(this.storage, args[0]);
    }
    else {
      fileRef = args[0];
    }
    if (args[1]) {
      filter = args[1];
    }
    return from(listAll(fileRef)
      .then((res) => {
        const item = (filter ? res.items.filter((itemRef) => itemRef.name.indexOf(filter) > -1) : res.items)[0];
        if (item) {
          return getDownloadURL(fileRef);
        }
        return null;
      })
      .catch((error: Error) => {
        this.logger.warn("Error finding user avatar: ", error.message);
        return null;
      }));
  }

  public upload(file: Blob | File, path: string, name: string, filetype: string = "", overwrite?: boolean, attempt: number = 0, sub?: BehaviorSubject<UploadTask>): Observable<UploadTask> {
    let fullname = name;
    if (attempt) {
      const dotIdx = fullname.lastIndexOf(".");
      if (dotIdx === -1 || dotIdx < fullname.length - 6) {
        // There is no type on the file name
        fullname += " (" + attempt + ")";
      }
      else {
        // There is a type on the file name, lets append the number before the type
        fullname = fullname.substr(0, dotIdx) + " (" + attempt + ")" + fullname.substr(dotIdx);
      }
    }
    let fullpath = path + '/' + fullname;
    let update: UploadTask = {
      filetype,
      name: fullname,
      path: fullpath,
      progress: 20,
      uploadedAt: new Date().getTime()
    };
    if (sub) {
      sub.next(update);
    }
    else {
      sub = new BehaviorSubject(update);
    }
    let item = ref(this.storage, fullpath);
    getDownloadURL(item).then(() => {
      if (!overwrite && attempt < 100) {
        this.upload(file, path, name, filetype, false, ++attempt, sub);
      }
      else if (overwrite) {
        throw { code: "storage/object-not-found" };
      }
      else {
        throw { code: "storage/too-many-attempts" };
      }
    }).catch((error) => {
      switch (error.code) {
        case 'storage/object-not-found':
          const item = ref(this.storage, fullpath)
          const uploadTask = uploadBytesResumable(item, file);
          uploadTask.on('state_changed', (snapshot) => {
            update = {
              ...update,
              progress: (snapshot.bytesTransferred / snapshot.totalBytes) * 90 // Multiply by 90 here rather than 100, as this task's completion does not signify 100% completion of our internal task
            };
            sub.next(update);
          }, (error) => {
            update = {
              ...update,
              failed: true
            };
            sub.next(update);
            sub.error(update);
          }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
              update = {
                ...update,
                downloadURL: downloadURL,
                progress: 100,
                complete: true
              };
              sub.next(update);
              sub.complete();
            });
          });
          break;

        case 'storage/unauthorized': // User doesn't have permission to access the object
        case 'storage/canceled': // User canceled the upload
        case 'storage/too-many-attempts': // This function looped too many times
        case 'storage/unknown': // Unknown error occurred
        default:
          update = {
            ...update,
            failed: true
          };
          sub.next(update);
          sub.error(update);
          break;
      }
    })
    return sub.asObservable();
  }


  public delete(path: string) {
    const fileRef = ref(this.storage, path);
    return from(deleteObject(fileRef)
      .then(() => true)
      .catch(() => false)
    );
  }
}
