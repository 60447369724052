import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CommunityMember, CommunityMemberBase, CommunityMemberPagerFilters } from 'src/app/components/community/community.models';
import { FavoritesTypes, PagedHttpResponse, PagerConfig } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Pager } from 'src/app/services/paging.service';
import { ICommunityStore, TestUserActions } from 'src/app/store/community';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityTestUserService {

  private endpoint: string = 'test_users';

  constructor(
    private store: Store<{ community: ICommunityStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method CommunityTestUserService.create
   * @description Saves a new TestUser to the server
   * @param {CommunityMemberBase} member The new TestUser to be saved
   * @returns Observable<CommunityMember>
   */
  public create(member: CommunityMemberBase): Observable<CommunityMember> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<CommunityMemberBase>(this.endpoint, member, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t) => {
          const storedProduct = new CommunityMember(t)
          this.store.dispatch(new TestUserActions.CreateSuccess(storedProduct));
          return storedProduct;
        }),
        catchError((resp: any) => {
          this.store.dispatch(new TestUserActions.CreateError(resp));
          return this.panelAdminService.handleError(resp);
        })
      )
    ));
  }

  /**
   * @method CommunityTestUserService.getAll
   * @description Retrieves a list of all TestUsers in the Sample API
   * @returns Observable<CommunityMember[]>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<CommunityMember>> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + (params || ''), {}, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        map((resp: PagedHttpResponse<CommunityMemberBase>) => {
          const data = resp.data.map((m: CommunityMemberBase) => new CommunityMember(m));
          this.store.dispatch(new TestUserActions.GetAllSuccess(data));
          resp.data = data;
          return resp;
        }),
        catchError((err: any) => {
          this.store.dispatch(new TestUserActions.GetAllError(err));
          return this.panelAdminService.handleError(err, true);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityTestUserService.getByID
   * @description Retrieves a TestUser for a given ID
   * @param {string} id The ID of the TestUser to be retrieved
   * @returns Observable<CommunityMember>
   */
  public getByID(id: string, force?: boolean): Observable<CommunityMember> {
    const store_ref = this.store.pipe(select(state => state.community.test_users.map[id]));
    return store_ref.pipe(
      switchMap((member) => {
        if (force || !member) {
          const params = `?filters={ "id": ${id} }`;
          return this.getAll(params).pipe(map((resp: PagedHttpResponse<CommunityMember>) => resp.data && resp.data[0]));
        }
        return of(member);
      })
    );
  }

  /**
   * @method CommunityTestUserService.delete
   * @description Deletes a TestUser on the server
   * @returns Observable<boolean>
   */
  public delete(id: number | string): Observable<boolean> {
    const url = this.endpoint + '/' + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.delete<any>(url, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => {
          this.store.dispatch(new TestUserActions.DeleteSuccess(id));
          return true;
        }),
        catchError((err: any) => {
          this.store.dispatch(new TestUserActions.DeleteError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: CommunityMemberPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }

}
