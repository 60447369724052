import { Action } from '@ngrx/store';
import { Campaign, CampaignStatsBase, CampaignActivity } from 'src/app/components/recruit/recruit.models';

export enum Types {
  Get                   = '[RecruitCampaign] Get',
  GetSuccess            = '[RecruitCampaign] GetSuccess',
  GetError              = '[RecruitCampaign] GetError',
  //                        RecruitCampaign
  GetStatsSuccess       = '[RecruitCampaign] GetStatsSuccess',
  GetStatsError         = '[RecruitCampaign] GetStatsError',
  //                        RecruitCampaign
  GetAllActivitySuccess = '[RecruitCampaign] GetAllActivitySuccess',
  GetAllActivityError   = '[RecruitCampaign] GetAllActivityError',
  GetActivitySuccess    = '[RecruitCampaign] GetActivitySuccess',
  GetActivityError      = '[RecruitCampaign] GetActivityError',
  CreateActivitySuccess = '[RecruitCampaign] CreateActivitySuccess',
  CreateActivityError   = '[RecruitCampaign] CreateActivityError',
  //                        RecruitCampaign
  GetAll                = '[RecruitCampaign] GetAll',
  GetAllSuccess         = '[RecruitCampaign] GetAllSuccess',
  GetAllError           = '[RecruitCampaign] GetAllError',
  //                        RecruitCampaign
  Create                = '[RecruitCampaign] Create',
  CreateSuccess         = '[RecruitCampaign] CreateSuccess',
  CreateError           = '[RecruitCampaign] CreateError',
  //                        RecruitCampaign
  Delete                = '[RecruitCampaign] Delete',
  DeleteSuccess         = '[RecruitCampaign] DeleteSuccess',
  DeleteError           = '[RecruitCampaign] DeleteError',
  //                        RecruitCampaign
  Update                = '[RecruitCampaign] Update',
  UpdateSuccess         = '[RecruitCampaign] UpdateSuccess',
  UpdateError           = '[RecruitCampaign] UpdateError',
  //                        RecruitCampaign
  Clear                 = '[RecruitCampaign] Clear',
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor() {
    console.log("Initiate: " + this.type);
  }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: Campaign[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) {
    console.log("Initiate: " + this.type);
  }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: Campaign) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class GetStatsSuccess implements Action {
  readonly type = Types.GetStatsSuccess;
  constructor(public id: string, public payload: CampaignStatsBase) { }
}

export class GetStatsError implements Action {
  readonly type = Types.GetStatsError;
  constructor(public id: string, public error: Error) { }
}

export class CreateActivitySuccess implements Action {
  readonly type = Types.CreateActivitySuccess;
  constructor(public payload: CampaignActivity) { }
}

export class CreateActivityError implements Action {
  readonly type = Types.CreateActivityError;
  constructor(public error: Error) { }
}

export class GetActivitySuccess implements Action {
  readonly type = Types.GetActivitySuccess;
  constructor(public payload: CampaignActivity) { }
}

export class GetActivityError implements Action {
  readonly type = Types.GetActivityError;
  constructor(public id: string, public error: Error) { }
}

export class GetAllActivitySuccess implements Action {
  readonly type = Types.GetAllActivitySuccess;
  constructor(public payload: CampaignActivity[]) { }
}

export class GetAllActivityError implements Action {
  readonly type = Types.GetAllActivityError;
  constructor(public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: Campaign) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: Campaign) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public id: string, public payload: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: Campaign) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetAll | GetAllSuccess | GetAllError | GetStatsSuccess | GetStatsError | CreateActivitySuccess | CreateActivityError | GetActivitySuccess | GetActivityError | GetAllActivitySuccess | GetAllActivityError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear;
