import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ActivityReport, ActivityReportBase } from 'src/app/components/recruit/recruit.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as Actions from 'src/app/store/recruit/recruit-report.actions';
import { IRecruitStore } from 'src/app/store/recruit/recruit.store';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class RecruitReportService {

  private endpoint: string = 'campaign_and_source_activity_reports';
  private reports_requested: { [id: string]: boolean; } = {};

  constructor(
    private store: Store<{ recruit: IRecruitStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method RecruitReportService.create
   * @description Saves a new ActivityReport to the server
   * @param {ActivityReport} report The new ActivityReport to be saved
   * @returns Observable<ActivityReport>
   */
  public create(report: ActivityReport): Observable<ActivityReport> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<ActivityReportBase>(this.endpoint, report.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((c) => {
          if (c) {
            const report = new ActivityReport(c);
            this.store.dispatch(new Actions.CreateSuccess(report));
            return report;
          }
          return null;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.CreateError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method RecruitReportService.getByCampaign
   * @description Retrieves a list of all ActivityReports for a Campaign
   * @returns Observable<ActivityReport[]>
   */
  public getByCampaign(campaign_id: string): Observable<ActivityReport[]> {
    const filters = JSON.stringify({ campaign_ids: [campaign_id] });
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ActivityReportBase[]>(this.endpoint, { filters }, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        switchMap((resp: any) => {
          const reports = resp.map((p: ActivityReportBase) => new ActivityReport(p));
          this.store.dispatch(new Actions.GetAllSuccess(reports));
          return this.store.pipe(select(state => state.recruit.reports.mapByCampaign[campaign_id]));
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.GetAllError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method RecruitReportService.getBySource
   * @description Retrieves a list of all ActivityReports for a Source
   * @returns Observable<ActivityReport[]>
   */
  public getBySource(source_id: string): Observable<ActivityReport[]> {
    const filters = JSON.stringify({ source_id });
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ActivityReportBase[]>(this.endpoint, { filters }, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        switchMap((resp: any) => {
          const reports = resp.map((p: ActivityReportBase) => new ActivityReport(p));
          this.store.dispatch(new Actions.GetAllSuccess(reports));
          return this.store.pipe(select(state => state.recruit.reports.mapBySource[source_id]));
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.GetAllError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method RecruitReportService.getAll
   * @description Retrieves a list of all ActivityReports in the Sample API
   * @returns Observable<ActivityReport[]>
   */
  public getAll(filters?: any): Observable<ActivityReport[]> {
    const filterObj = filters ? { filters: JSON.stringify(filters) } : {};
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<ActivityReportBase[]>(this.endpoint, filterObj, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => {
          const reports = resp.map((p: ActivityReportBase) => new ActivityReport(p));
          this.store.dispatch(new Actions.GetAllSuccess(reports, filterObj));
          return reports;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.GetAllError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method RecruitReportService.getByID
   * @description Retrieves a ActivityReport for a given ID
   * @param {string} id The ID of the ActivityReport to be retrieved
   * @returns Observable<ActivityReport>
   */
  public getByID(id: string | number, force?: boolean): Observable<ActivityReport> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return state.recruit.reports.map[id];
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      switchMap((report) => {
        if (!this.reports_requested[id] && (force || !report)) {
          this.reports_requested[id] = true;
          return this.panelAdminService.getPanelAdminUrl().pipe(
            switchMap(panelAdminUrl =>
              this.api.get<ActivityReportBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
                map((p) => {
                  const report = new ActivityReport(p);
                  this.store.dispatch(new Actions.GetSuccess(report));
                  this.reports_requested[id] = false;
                  return report;
                }),
                catchError((err: any) => {
                  this.store.dispatch(new Actions.GetError(id + "", err));
                  this.reports_requested[id] = false;
                  return this.panelAdminService.handleError(err);
                })
              )
            ),
            catchError(() => of(null))
          );
        }
        return of(report);
      })
    );
  }

  /**
   * @method RecruitReportService.update
   * @description Updates a ActivityReport on the server
   * @returns Observable<ActivityReport>
   */
  public update(id: string, report: ActivityReportBase, action?: string): Observable<ActivityReport> {
    const url = this.endpoint + '/' + (action ? action + '/' : '') + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(url, JSON.stringify({ data: report }), false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: ActivityReportBase) => {
          const report = new ActivityReport(resp);
          this.store.dispatch(new Actions.UpdateSuccess(report));
          return report;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.UpdateError(report.id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method RecruitReportService.poll
   * @description Polls for a ActivityReport for a given ID until the activity is processed
   * @param {string} id The ID of the ActivityReport to be polled
   * @returns Observable<ActivityReport>
   */
  private pollSub: Subscription;
  private poll_queue: {
    [id: string]: { id: string; subject: ReplaySubject<ActivityReport>; }
  } = {};
  private poll_obs: Observable<any>;
  public poll(id: string, force?: boolean): Observable<ActivityReport> {
    this.poll_queue[id] = this.poll_queue[id] || {
      id,
      subject: new ReplaySubject<ActivityReport>(1)
    };
    if (Object.keys(this.poll_queue).length === 1 || force) {
      if (this.pollSub) {
        this.pollSub.unsubscribe();
      }
      this.pollSub = this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
        this.api.poll<ActivityReport>(this.endpoint + '/' + id, (activity) => !!activity && !!Object.keys(activity.report || {}).length, null, panelAdminUrl, null, this.panelAdminService.options, true).pipe(
          tap((activity: ActivityReport) => {
            this.logger.log(activity);
            if (activity) {
              this.store.dispatch(new Actions.GetSuccess(activity));
              this.poll_queue[id].subject.next(activity);
              this.poll_queue[id].subject.complete();
              setTimeout(() => {
                delete this.poll_queue[id];
                const queue = Object.keys(this.poll_queue);
                if (queue.length) {
                  this.poll(queue[0], true);
                }
                else if (this.pollSub) {
                  this.pollSub.unsubscribe();
                }
              }, 10)
            }
          }),
          catchError((err: any) => {
            this.store.dispatch(new Actions.GetError(id, err));
            return this.panelAdminService.handleError(err);
          })
        )
      )).subscribe();
    }
    return this.poll_queue[id].subject.asObservable();

  }

  public delete(id: string) {
    const url = this.endpoint + '/' + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.delete<any>(url, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp) => {
          this.store.dispatch(new Actions.DeleteSuccess(id));
          return resp;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.DeleteError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

}
