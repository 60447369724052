import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SampleBatch, SampleBatchBase, SamplingAPIResponse } from 'src/app/sample.models';
import { ISampleStore } from 'src/app/store';
import * as SampleBatchActions from 'src/app/store/sample/sample-batch.actions';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { LoggerService } from '../logger.service';
import { SampleService } from './sample.service';



@Injectable({
  providedIn: 'root'
})
export class SampleBatchService extends SampleService<SampleBatch> {

  constructor(
    private api: ApiService,
    private store: Store<{ reward: ISampleStore }>,
    private logger: LoggerService,
  ) {
    super('batchList')
  }

  /**
   * @method SampleBatchService.create
   * @description Saves a new SampleBatch to the server
   * @param {SampleBatchBase} sampleBatch The new SampleBatch to be saved
   * @returns Observable<SampleBatch | null>
   */
  public create(sampleBatch: SampleBatch): Observable<SampleBatch | null> {

    const formData: FormData = new FormData();
    formData.append('file', sampleBatch.fileDocument, sampleBatch.fileDocument.name);
    formData.append('name', sampleBatch.name);
    sampleBatch.matchAttribute && formData.append('matchAttribute', sampleBatch.matchAttribute);
    sampleBatch.setAttribute && formData.append('setAttribute', sampleBatch.setAttribute);
    sampleBatch.matchValue && formData.append('matchValue', sampleBatch.matchValue);
    sampleBatch.description && formData.append('description', sampleBatch.description);
    sampleBatch.sampleProjectId && formData.append('sampleProjectId', sampleBatch.sampleProjectId);
    sampleBatch.setValue && formData.append('setValue', sampleBatch.setValue);

    return this.api.post<SampleBatchBase>(this.endpoint, formData as SampleBatchBase, false, environment.samplingServicesUrl)
      .pipe(
        map((resp: SampleBatchBase) => {
          this.logger.log(resp);
          if (resp) {
            const sb = new SampleBatch(resp);
            this.store.dispatch(new SampleBatchActions.CreateSuccess(sb));
            return sb;
          }
          return null;
        }),
        catchError((err: Error) => {
          this.store.dispatch(new SampleBatchActions.CreateError(err));
          return throwError(new Error(err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
        })
      );
  }

  /**
   * @method SampleBatchService.getAll
   * @description Retrieves a list of all SampleBatch lists in the Sample API
   * @returns Observable<SampleBatch[]>
   */
  public getAll(): Observable<SampleBatch[]> {
    return this.api.get(this.endpoint, {}, environment.samplingServicesUrl)
      .pipe(
        map((resp: SamplingAPIResponse<SampleBatchBase>) => {
          if (resp.results) {
            const batches = resp.results.map(sb => new SampleBatch(sb));
            this.store.dispatch(new SampleBatchActions.GetAllSuccess(batches))
            return batches;
          }
          return null;
        }),
        catchError((err: Error) => {
          this.store.dispatch(new SampleBatchActions.GetAllError(err));
          return throwError(new Error(err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
        })
      );
  }

  /**
   * @method SampleBatchService.getByID
   * @description Retrieves a SampleBatch for a given ID
   * @param {string} id The ID of the SampleBatch to be retrieved
   * @returns Observable<SampleBatch>
   */
  public getByID(id: string): Observable<SampleBatch> {
    return this.api.get(this.endpoint + '/' + id, null, environment.samplingServicesUrl)
      .pipe(
        map((resp: SampleBatchBase) => {
          const batch = resp && new SampleBatch(resp);
          this.store.dispatch(new SampleBatchActions.GetSuccess(batch))
          return batch;
        }),
      );
  }

  /**
   * @method SampleBatchService.update
   * @description Updates a SampleBatch in AWS
   * @returns Observable<SampleBatch>
   */
  public update(sampleBatch: SampleBatch): Observable<SampleBatch> {
    return this.api.put(this.endpoint, sampleBatch.asDataObj, true, environment.samplingServicesUrl)
      .pipe(
        map((resp: SampleBatchBase) => {
          const sb = new SampleBatch(resp)
          this.store.dispatch(new SampleBatchActions.UpdateSuccess(sb));
          return sb;
        })
      );
  }

  /**
   * @method SampleBatchService.delete
   * @description Updates the `archive` property of the given SampleDistribution
   * @param {id} string The id of SampleBatch to be queued for deletion
   * @returns Observable<boolean>
   */
  public delete(id: string): Observable<boolean> {
    return this.api.delete(this.endpoint + '/' + id, null, environment.samplingServicesUrl)
      .pipe(
        switchMap(() => this.getByID(id)),
        map(() => {
          //this.store.dispatch(new SampleBatchActions.DeleteSuccess(id));
          return true;
        }),
        catchError(() => of(false))
      );
  }
}
