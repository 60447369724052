import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AlertService, AngularFirestore, DialogService, LoggerService, UserService } from 'src/app/services';
import { User } from 'src/app/data.models';

@Component({
  selector: 'feedback-dialog',
  templateUrl: './feedback-dialog.component.html'
})
export class FeedbackDialog implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public form: FormGroup;
  public errorMsg: string;
  public title: string = "Feedback / Comment";
  private loadingTimer: any;
  public user: User;

  public feedbackTypes: { viewValue: string; value: string;}[] = [
    {
      viewValue: "User menu",
      value: "userMenu"
    },
    {
      viewValue: "Account",
      value: "account"
    },
    {
      viewValue: "Monetize",
      value: "monetize"
    },
    {
      viewValue: "Profile",
      value: "profile"
    },
    {
      viewValue: "Reward",
      value: "reward"
    },
    {
      viewValue: "Sample",
      value: "sample"
    },
    {
      viewValue: "Other",
      value: "other"
    },
  ];

  constructor(
    private dialogService: DialogService,
    private afStore: AngularFirestore,
    private userService: UserService,
    private logger: LoggerService,
    private fb: FormBuilder,
    private alert: AlertService,
    public dialogRef: MatDialogRef<FeedbackDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public close(): void {
    this.dialogService.close();
  }

  public save() {
    this.errorMsg = "";
    this.dialogService.setBusy();
    const feeback = this.afStore.collection("feedback").ref.doc();

    feeback.set(this.form.value, { merge: true })
      .then(() => {
        this.alert.info('Feedback sent!');
        this.close();
      }).catch(error => {
        this.dialogService.setBusy(false);
        this.logger.error("Error sending feedback: ", error);
      });
  }

  ngOnInit() {
    this.dialogService.setBusy();
    this.loadingTimer = setTimeout(() => this.dialogService.setBusy(false), 1000);

    this.form = this.fb.group({
      type: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      comment: ["", [Validators.required]]
    });
    this.subs.push(
      this.dialogRef.beforeClosed().subscribe(resp => {
        this.close();
      }),
      this.userService.user$.subscribe(user => {
        this.user = user;
        this.form.get('email').setValue(this.user.email)
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
