import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertService, AuthService, DialogService, LoggerService } from 'src/app/services';


@Component({
  selector: 'app-reset-password',
  template: ''
})
export class ResetPasswordComponent {

  constructor(
    private dialog: MatDialog,
  ) {
    this.openDialog()
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ResetPasswordDialog, {
      maxWidth: '800px',
      panelClass: ['app-dialog-wrapper', 'dialog', 'short'],
      backdropClass: 'dialog',
      disableClose: true
    });
  }
}

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordDialog implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public form: FormGroup;
  public errorMsg: string;
  public formErrorMsg: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private logger: LoggerService,
    private fb: FormBuilder,
    private alert: AlertService,
    private dialogRef: MatDialogRef<ResetPasswordDialog>
  ) { }

  public resetPassword() {
      const password = this.form.get("password").value;
      const code = this.form.get("code").value;
      return this.authService.resetPassword(password, code).subscribe(
        resp => {
          if (resp) {
            this.close('Your password has been reset. Please login using this from now on.');
            this.dialogService.setBusy(false);
          }
        },
        error => {
          this.logger.log(error);
          this.errorMsg = error.message;
          this.dialogService.setBusy(false);
        });
  }

  public triggerResetPassword() {
    this.errorMsg = '';
    this.dialogService.setBusy();
    if (this.hasCode) {
      return this.resetPassword()
    }
    if (this.form.invalid) {
        this.errorMsg = "Please check the form is filled out correctly.";
        this.dialogService.setBusy(false);
        return;
    }
    const email = this.form.get("email").value;
    this.authService.triggerResetPassword(email)
      .pipe(map(resp => true), catchError((error: Error) => {
        this.logger.log(error);
        this.errorMsg = error.message;
        return of(false);
      }))
      .subscribe((success: boolean) => {
        if (success) {
          this.close('If an account exists, an email will been sent to ' + email);
        }
        this.dialogService.setBusy(false);
      });
  }

  public close(message: string = ''): void {
    if (message) {
      this.alert.info(message);
    }
    this.dialogRef.close();
    this.dialogService.close();
  }

  public get hasCode() {
    return !!this.form.get("code").value;
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      code: ['']
    });
    this.subs.push(
      this.dialogRef.beforeClosed().subscribe(resp => {
        this.close();
      }),
      this.route.queryParams.subscribe(params => {
        if (params.oobCode) {
          this.authService.verifyPasswordResetCode(params.oobCode)
            .subscribe((email: string) => {
              this.form.get("email").setValue(email);
              this.form.get("email").disable();
              this.form.get("password").setValidators([Validators.required, Validators.pattern('^(?=.*\d).{4,}$')]);
              this.form.get("code").setValue(params.oobCode);
            },
            error => {
              this.logger.log(error);
              this.errorMsg = error.message;
            });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
