import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import KeenAnalysis from 'keen-analysis';
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggerService } from './logger.service';
import { UtilService } from './util.service';
import { formatDate } from '../data.models';

export type ExtractFn = (propery_value: string | number, force?: boolean) => Observable<Array<(string | number)[]>>;

export interface ExtractMap {
  visits?: ExtractFn;
  signups?: ExtractFn;
  activations?: ExtractFn;
}

export interface Extractions {
  cache?: {
    [id: string]: {
      [queryName: string]: {
        created_at: number;
        obs: Observable<Array<(string | number)[]>>;
      }
    }
  };
  last_15_days?: ExtractMap;
  last_7_days?: ExtractMap;
}

@Injectable({
  providedIn: 'root'
})
export class KeenService {

  private keen: any;

  constructor(
    private logger: LoggerService,
    private util: UtilService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {

    /* If we are in the client (i.e. not an SSR) then initialise the trackers */
    if (isPlatformBrowser(this.platformId)) {
      this.keen = new KeenAnalysis({
        projectId: environment.keen.projectId,
        readKey: environment.keen.readKey
      });
      this.extracts.recruit.campaign = {
        cache: {},
        last_15_days: {
          visits: ((campaign_id: string | number, force?: boolean) => {
            this.extracts.recruit.campaign.cache[campaign_id] = this.extracts.recruit.campaign.cache[campaign_id] || {};
            if (!this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.visits"] || this.util.isExpired(this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.visits"].created_at, 0, 10) || force) {
              this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.visits"] = {
                created_at: new Date().getTime(),
                obs: from(this.keen.query({
                  "analysis_type": "count_unique",
                  "event_collection": "landing_page_new_visits",
                  "target_property": "user.auth_token",
                  "interval": "daily",
                  "timeframe": "this_15_days",
                  "filters": [
                    {
                      "operator": "eq",
                      "property_type": "Number",
                      "property_name": "user.campaign_id",
                      "property_value": +campaign_id
                    }
                  ]
                })).pipe(
                  switchMap((data: any) => {
                    this.logger.log(data.result);
                    if (data.result && data.result.length) {
                      return this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.visits"].obs = of(data.result.map(res => [
                        formatDate(res.timeframe.start),
                        res.value
                      ]));
                    }
                    return throwError(new Error("No results"));
                  })
                )
              };
            }
            return this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.visits"].obs;
          }),
          signups: ((campaign_id: string | number, force?: boolean) => {
            this.extracts.recruit.campaign.cache[campaign_id] = this.extracts.recruit.campaign.cache[campaign_id] || {};
            if (!this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.signups"] || this.util.isExpired(this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.signups"].created_at, 0, 10) || force) {
              this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.signups"] = {
                created_at: new Date().getTime(),
                obs: from(this.keen.query({
                  "analysis_type": "count_unique",
                  "event_collection": "signups",
                  "target_property": "user.auth_token",
                  "interval": "daily",
                  "timeframe": "this_15_days",
                  "filters": [
                    {
                      "operator": "eq",
                      "property_type": "Number",
                      "property_name": "user.campaign_id",
                      "property_value": +campaign_id
                    }
                  ]
                })).pipe(
                  switchMap((data: any) => {
                    this.logger.log(data.result);
                    if (data.result && data.result.length) {
                      return this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.signups"].obs = of(data.result.map(res => [
                        formatDate(res.timeframe.start),
                        res.value
                      ]));
                    }
                    return throwError(new Error("No results"));
                  })
                )
              };
            }
            return this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.signups"].obs;
          }),
          activations: ((campaign_id: string | number, force?: boolean) => {
            this.extracts.recruit.campaign.cache[campaign_id] = this.extracts.recruit.campaign.cache[campaign_id] || {};
            if (!this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.activations"] || this.util.isExpired(this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.activations"].created_at, 0, 10) || force) {
              this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.activations"] = {
                created_at: new Date().getTime(),
                obs: from(this.keen.query({
                  "analysis_type": "count_unique",
                  "event_collection": "activations",
                  "target_property": "user.auth_token",
                  "interval": "daily",
                  "timeframe": "this_15_days",
                  "filters": [
                    {
                      "operator": "eq",
                      "property_type": "Number",
                      "property_name": "user.campaign_id",
                      "property_value": +campaign_id
                    }
                  ]
                })).pipe(
                  switchMap((data: any) => {
                    this.logger.log(data.result);
                    if (data.result && data.result.length) {
                      return this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.activations"].obs = of(data.result.map(res => [
                        formatDate(res.timeframe.start),
                        res.value
                      ]));
                    }
                    return throwError(new Error("No results"));
                  })
                )
              };
            }
            return this.extracts.recruit.campaign.cache[campaign_id]["last_15_days.activations"].obs;
          }),
        },
        last_7_days: {
          visits: ((campaign_id: string | number, force?: boolean) => {
            this.extracts.recruit.campaign.cache[campaign_id] = this.extracts.recruit.campaign.cache[campaign_id] || {};
            if (!this.extracts.recruit.campaign.cache[campaign_id]["last_7_days.visits"] || this.util.isExpired(this.extracts.recruit.campaign.cache[campaign_id]["last_7_days.visits"].created_at, 0, 10) || force) {
              this.extracts.recruit.campaign.cache[campaign_id]["last_7_days.visits"] = {
                created_at: new Date().getTime(),
                obs: from(this.keen.query({
                  "analysis_type": "count_unique",
                  "event_collection": "landing_page_new_visits",
                  "target_property": "user.auth_token",
                  "interval": "daily",
                  "timeframe": "this_7_days",
                  "filters": [
                    {
                      "operator": "eq",
                      "property_type": "Number",
                      "property_value": +campaign_id,
                      "property_name": "user.campaign_id"
                    }
                  ]
                })).pipe(
                  switchMap((data: any) => {
                    this.logger.log(data);
                    if (data.result && data.result.length) {
                      return this.extracts.recruit.campaign.cache[campaign_id]["last_7_days.visits"].obs = of(data.result.map(res => [
                        formatDate(res.timeframe.start),
                        res.value
                      ]));
                    }
                    return throwError(new Error("No results"));
                  })
                )
              }
            }
            return this.extracts.recruit.campaign.cache[campaign_id]["last_7_days.visits"].obs;
          })
        }
      };
      this.extracts.recruit.source = {
        cache: {},
        last_15_days: {
          visits: (source_id: string | number, force?: boolean) => {
            this.extracts.recruit.source.cache[source_id] = this.extracts.recruit.source.cache[source_id] || {};
            if (
              !this.extracts.recruit.source.cache[source_id]["last_15_days.visits"]
              || this.util.isExpired(this.extracts.recruit.source.cache[source_id]["last_15_days.visits"].created_at, 0, 10)
              || force
            ) {
              this.extracts.recruit.source.cache[source_id]["last_15_days.visits"] = {
                created_at: new Date().getTime(),
                obs: from(this.keen.query({
                  "analysis_type": "count_unique",
                  "event_collection": "landing_page_new_visits",
                  "target_property": "user.auth_token",
                  "group_by": null,
                  "limit": null,
                  "interval": "daily",
                  "timeframe": "this_15_days",
                  "force_exact": null,
                  "zero_fill": null,
                  "filters": [
                    {
                      "operator": "eq",
                      "property_type": "Number",
                      "property_name": "user.campaign.source_id",
                      "property_value": +source_id
                    }
                  ]
                })).pipe(
                  switchMap((data: any) => {
                    this.logger.log(data.result);
                    if (data.result && data.result.length) {
                      return this.extracts.recruit.source.cache[source_id]["last_15_days.visits"].obs = of(data.result.map(res => [
                        formatDate(res.timeframe.start),
                        res.value
                      ]));
                    }
                    return throwError(new Error("No results"));
                  })
                )
              };
            }
            return this.extracts.recruit.source.cache[source_id]["last_15_days.visits"].obs;
          },
          signups: ((source_id: string | number, force?: boolean) => {
            this.extracts.recruit.source.cache[source_id] = this.extracts.recruit.source.cache[source_id] || {};
            if (!this.extracts.recruit.source.cache[source_id]["last_15_days.signups"] || this.util.isExpired(this.extracts.recruit.source.cache[source_id]["last_15_days.signups"].created_at, 0, 10) || force) {
              this.extracts.recruit.source.cache[source_id]["last_15_days.signups"] = {
                created_at: new Date().getTime(),
                obs: from(this.keen.query({
                  "analysis_type": "count_unique",
                  "event_collection": "signups",
                  "target_property": "user.auth_token",
                  "group_by": null,
                  "limit": null,
                  "interval": "daily",
                  "timeframe": "this_15_days",
                  "force_exact": null,
                  "zero_fill": null,
                  "filters": [
                    {
                      "operator": "eq",
                      "property_type": "Number",
                      "property_name": "user.campaign.source_id",
                      "property_value": +source_id
                    }
                  ]
                })).pipe(
                  switchMap((data: any) => {
                    this.logger.log(data.result);
                    if (data.result && data.result.length) {
                      return this.extracts.recruit.source.cache[source_id]["last_15_days.signups"].obs = of(data.result.map(res => [
                        formatDate(res.timeframe.start),
                        res.value
                      ]));
                    }
                    return throwError(new Error("No results"));
                  })
                )
              };
            }
            return this.extracts.recruit.source.cache[source_id]["last_15_days.signups"].obs;
          }),
          activations: ((source_id: string | number, force?: boolean) => {
            this.extracts.recruit.source.cache[source_id] = this.extracts.recruit.source.cache[source_id] || {};
            if (!this.extracts.recruit.source.cache[source_id]["last_15_days.activations"] || this.util.isExpired(this.extracts.recruit.source.cache[source_id]["last_15_days.activations"].created_at, 0, 10) || force) {
              this.extracts.recruit.source.cache[source_id]["last_15_days.activations"] = {
                created_at: new Date().getTime(),
                obs: from(this.keen.query({
                  "analysis_type": "count_unique",
                  "event_collection": "activations",
                  "target_property": "user.auth_token",
                  "group_by": null,
                  "limit": null,
                  "interval": "daily",
                  "timeframe": "this_15_days",
                  "force_exact": null,
                  "zero_fill": null,
                  "filters": [
                    {
                      "operator": "eq",
                      "property_type": "Number",
                      "property_name": "user.campaign.source_id",
                      "property_value": +source_id
                    }
                  ]
                })).pipe(
                  switchMap((data: any) => {
                    this.logger.log(data.result);
                    if (data.result && data.result.length) {
                      return this.extracts.recruit.source.cache[source_id]["last_15_days.activations"].obs = of(data.result.map(res => [
                        formatDate(res.timeframe.start),
                        res.value
                      ]));
                    }
                    return throwError(new Error("No results"));
                  })
                )
              };
            }
            return this.extracts.recruit.source.cache[source_id]["last_15_days.activations"].obs;
          }),
        },
        last_7_days: {
          visits: ((source_id: string | number, force?: boolean) => {
            this.extracts.recruit.source.cache[source_id] = this.extracts.recruit.source.cache[source_id] || {};
            if (!this.extracts.recruit.source.cache[source_id]["last_7_days.visits"] || this.util.isExpired(this.extracts.recruit.source.cache[source_id]["last_7_days.visits"].created_at, 0, 10) || force) {
              this.extracts.recruit.source.cache[source_id]["last_7_days.visits"] = {
                created_at: new Date().getTime(),
                obs: from(this.keen.query({
                  "analysis_type": "count_unique",
                  "event_collection": "landing_page_new_visits",
                  "target_property": "user.auth_token",
                  "interval": "daily",
                  "timeframe": "this_7_days",
                  "filters": [
                    {
                      "operator": "eq",
                      "property_type": "Number",
                      "property_value": +source_id,
                      "property_name": "user.campaign.source_id"
                    }
                  ]
                })).pipe(
                  switchMap((data: any) => {
                    this.logger.log(data);
                    if (data.result && data.result.length) {
                      return this.extracts.recruit.source.cache[source_id]["last_7_days.visits"].obs = of(data.result.map(res => [
                        formatDate(res.timeframe.start),
                        res.value
                      ]));
                    }
                    throw new Error("No results");
                  })
                )
              }
            }
            return this.extracts.recruit.source.cache[source_id]["last_7_days.visits"].obs;
          }),
        },
      };
    }
  }

  extract(auth_token: string): Observable<{ referrer_url: string; parsed_ua: string; }> {
    return from(this.keen.query({
      analysisType: 'extraction',
      timeframe: 'this_30_days',
      eventCollection: 'landing_page_new_visits',
      filters: [
        { "property_name": "user.auth_token", "operator": "eq", "property_value": auth_token }
      ]
    })
      .then(data => {
        //this.logger.log(data);
        let referrer_url = "";
        let parsed_ua = "";
        if (data.result[0]) {
          referrer_url = data.result[0].referrer_url;
          const userAgent = data.result[0].parsed_user_agent;
          if (userAgent) {
            parsed_ua = (userAgent.device ? userAgent.device.family : "unknown-device");
            if (userAgent.os) {
              parsed_ua += " / " +
                (userAgent.os.family ? userAgent.os.family : "unknown-os") + " " +
                (userAgent.os.major ? userAgent.os.major : "");
            }
            if (userAgent.browser) {
              parsed_ua += " / " +
                (userAgent.browser.family ? userAgent.browser.family : "unknown-browser") + " " +
                (userAgent.browser.major ? userAgent.browser.major : "");
            }
          }
        }
        return { referrer_url, parsed_ua };
      })
      .catch(err => {
        this.logger.log(err);
        return err;
      })
    ) as Observable<{ referrer_url: string; parsed_ua: string; }>;
  }

  extracts: {
    recruit: {
      campaign: Extractions;
      source: Extractions;
    }
  } = {
      recruit: {
        campaign: {},
        source: {},
      }
    }
}
