import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService, LoggerService } from 'src/app/services';


@Component({
  selector: 'app-access-denied',
  template: ''
})
export class AccessDeniedComponent {

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.openDialog()
  }

  openDialog(): void {
    const data:any = {};
    if (this.route.data) {
      if (this.route.snapshot.data.message) {
        data.message = this.route.snapshot.data.message;
      }
    }
    const dialogRef = this.dialog.open(AccessDeniedDialog, {
      data: data,
      disableClose: true,
      panelClass: 'alert-dialog'
    });
  }
}

@Component({
  selector: 'app-access-denied-dialog',
  templateUrl: './access-denied.component.html'
})
export class AccessDeniedDialog implements OnInit {

  private subs: Subscription[] = [];

  public message: string = "An error occurred.";

  constructor(
    private dialogService: DialogService,
    private logger: LoggerService,
    public dialogRef: MatDialogRef<AccessDeniedDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.afterClosed().subscribe(resp => {
      this.close();
    });
    if (data.message) {
      this.message = data.message;
    }
  }

  public close(url: string = ''): void {
    this.dialogRef.close();
    this.dialogService.close();
  }

  ngOnInit() {
  }
}
