import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { SharedFormsModule } from 'src/app/components/shared-forms/shared-forms.module';

import { AccessDeniedComponent, AccessDeniedDialog } from './access-denied/access-denied.component';
import { AlertDialog } from './alert/alert.dialog';
import { LoginComponent, LoginDialog } from './login/login.component';
import { ResetPasswordComponent, ResetPasswordDialog } from './reset-password/reset-password.component';
import { FeedbackDialog } from './feedback-dialog/feedback-dialog.component';

import { DialogComponent } from './dialog.component';
import { DialogOverlayComponent } from './dialog-overlay.component';
import { DialogSwitchComponent } from './dialog-switch.component';
import { DialogOpenComponent } from './dialog-open.component';


import { QuestionsModule, QuestionPreviewDialog } from '../questions/questions.module';
import { PanelAdmin403Component, PanelAdmin403Dialog } from './panel-admin-403/panel-admin-403.dialog';

@NgModule({
  imports: [
    SharedModule, SharedFormsModule, QuestionsModule,
  ],
  declarations: [
    DialogComponent, DialogOverlayComponent,
    LoginComponent, LoginDialog,
    AccessDeniedComponent, AccessDeniedDialog, AlertDialog,
    ResetPasswordComponent, ResetPasswordDialog,
    DialogSwitchComponent, DialogOpenComponent,
    FeedbackDialog, QuestionPreviewDialog,
    PanelAdmin403Component, PanelAdmin403Dialog
  ],
  exports: [
    DialogComponent, DialogOverlayComponent,
    LoginComponent, LoginDialog,
    AccessDeniedComponent, AccessDeniedDialog, AlertDialog,
    ResetPasswordComponent, ResetPasswordDialog,
    DialogSwitchComponent, DialogOpenComponent,
    FeedbackDialog, PanelAdmin403Component, PanelAdmin403Dialog
  ],
  entryComponents: [
    LoginDialog, AccessDeniedDialog, ResetPasswordDialog, AlertDialog,
    FeedbackDialog, QuestionPreviewDialog, PanelAdmin403Dialog
  ],

})
export class DialogModule { }
export {
  DialogComponent, DialogOverlayComponent,
  LoginComponent, LoginDialog, AlertDialog,
  AccessDeniedComponent, AccessDeniedDialog,
  ResetPasswordComponent, ResetPasswordDialog,
  DialogSwitchComponent, DialogOpenComponent,
  FeedbackDialog, PanelAdmin403Component, PanelAdmin403Dialog
}
