import { Injectable } from '@angular/core';
import IntroJs from 'intro.js/intro.js';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from './cookie.service';
import { LoggerService } from './logger.service';


@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private cookiePrefix: string = "toured_state_";
  private introJs: introJs.IntroJs = IntroJs();
  private currentTourName: string;
  private features$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private loadThrottle: any;

  public featuresList: any[] = [];
  public get features(): Observable<any[]> {
    return this.features$.asObservable();
  }

  constructor(
    private logger: LoggerService,
    private cookieService: CookieService,
  ) {
    this.setup();
    //this.router.events.subscribe((event) => {
    //  if (event instanceof NavigationEnd) {
    //    this.currentTourName = event.url;
    //    this.loadThrottle = this.utilService.throttle(() => {
    //      if (!this.isToured(this.currentTourName) && this.featuresList.length) {
    //        this.startTour();
    //      }
    //    }, this.loadThrottle, 100, this);
    //  }
    //})
  }

  setup(): void {
    this.introJs.setOptions({
      showProgress: true,
      showBullets: false,
      prevLabel: "arrow_back",
      nextLabel: "arrow_forward",
    });
  }

  /**
   * @method FeatureService.addFeature
   * @description Adds a new element to the stack to be included in the tour
   */
  addFeature(step: IntroJs.Step, group?: string): void {
    if (group) {
      this.currentTourName = group;
    }
    this.featuresList.push(step);
    this.features$.next(this.featuresList);
    this.introJs.setOptions({
      steps: this.featuresList
    });
  }

  /**
   * @method FeatureService.clearFeatures
   * @description Removes all elements from the tour-stack
   */
  clearFeatures(): void {
    this.featuresList = [];
    this.features$.next(this.featuresList);
  }

  /**
   * @method FeatureService.startTour
   * @description Starts the tour running
   */
  startTour(): void {
    this.introJs.start();
    this.introJs.onexit(() => { this.setToured(this.currentTourName) });
    //this.introJs.oncomplete(() => { this.setToured(this.currentTourName) });
  }

  /**
   * @method FeatureService.addHint
   * @description Adds a new hint
   */
  addHint(): void {
    //ToDo: AdamA: Not Implemented
    this.logger.log("FeatureService.addHint(): Not Implemented")
  }

  /**
   * @method FeatureService.setToured
   * @param page The name of the page to be toured, if 
   * @param status Whether the page is toured or not. If true, will set the cookie to stop the tour playing next time.
   * @description Sets a cookie to mark the provided page tour as complete so it does not auto-play the next time the page is loaded
   */
  setToured(page: string, status: boolean = true): void {
    if (status) {
      this.cookieService.set(this.cookiePrefix + page, "true", 365, '/')
    }
    else {
      this.cookieService.delete(this.cookiePrefix + page)
    }
  }

  /**
   * @method FeatureService.isToured
   * @description Checks if the given page tour is complete
   * @returns boolean
   */
  isToured(page: string): boolean {
    return this.cookieService.check(this.cookiePrefix + 'all') || this.cookieService.check(this.cookiePrefix + page);
  }

}
