import * as BaseModels from '../../base.models';
import { CachedDataObj } from '../../sample.models';

export interface RecruitSurvey extends BaseModels.APIBase {
  append_hashed_url?: boolean;
  auto_schedule_reminders?: boolean;
  cpi?: string;
  created_at?: string;
  credit_for_loyalty?: boolean;
  default_filter?: any;
  default_length?: number;
  default_link?: string;
  default_link_params?: string;
  default_reward_value?: string;
  default_topic?: string;
  dynamic_reward_value?: boolean;
  easy_exit_links?: boolean;
  exit_key_qualified?: string;
  exit_key_unqualified?: string;
  fa_id?: string;
  forthright?: boolean;
  invitation_template_id?: string;
  invite_by_email?: boolean;
  invite_by_sms?: boolean;
  mobile_friendly?: boolean;
  name?: string;
  notes?: string;
  notes_last_saved_at?: string;
  number?: number;
  open?: boolean;
  paused?: boolean;
  post_survey_id?: string;
  prescreener_filter?: string;
  prescreener_id?: string;
  preval?: boolean;
  profiler_id?: string;
  questions_to_pass?: string[];
  quota?: string;
  revenue?: string;
  salt?: string;
  skip_profilers?: boolean;
  special_note?: string;
  token?: string;
  topic_category?: string;
  unique_cookie?: boolean;
  unique_ip?: boolean;
  unique_ip_and_ua?: boolean;
  updated_at?: string;
}

export interface SourceBase extends BaseModels.APIBase {
  name?: string;
  created_at?: string;
  updated_at?: string;
  total_spend?: number;
  campaigns?: CampaignBase[];
}

export class Source extends BaseModels.ApiDefaults implements SourceBase {
  name: string;
  created_at: string;
  updated_at: string;
  total_spend: number;
  campaigns: CampaignBase[];
  open_campaigns: CampaignBase[];

  constructor(data?: SourceBase) {
    super([
      'name',
      'created_at',
      'updated_at',
      'total_spend',
    ], data);
    this.campaigns = this.campaigns || [];
    this.open_campaigns = this.campaigns.filter(c => c.open);
  }
}

export interface CampaignBase extends BaseModels.APIBase {
  by_offer_only?: boolean;
  created_at?: string;
  doi_tracking_link?: string;
  external_signup?: boolean;
  hold_users?: boolean;
  key?: string;
  landing_page?: string;
  landing_page_group?: string;
  name?: string;
  notes?: string;
  notes_last_saved_at?: string;
  open?: boolean;
  paid?: boolean;
  requires_email_confirm?: boolean;
  reward_value?: string;
  soi_tracking_link?: string;
  source?: Source;
  source_id?: number;
  survey?: RecruitSurvey;
  survey_id?: number;
  tags?: string;
  unique_visitors?: number;
  updated_at?: string;
}

export class Campaign extends BaseModels.ApiDefaults implements CampaignBase {
  id: string;
  by_offer_only: boolean;
  created_at: string;
  doi_tracking_link: string;
  external_signup: boolean;
  hold_users: boolean;
  key: string;
  landing_page: string;
  landing_page_group: string;
  name: string;
  notes: string;
  notes_last_saved_at: string;
  open: boolean;
  paid: boolean;
  requires_email_confirm: boolean;
  reward_value: string;
  soi_tracking_link: string;
  source: Source;
  source_id: number;
  survey: RecruitSurvey;
  survey_id: number;
  tags: string;
  unique_visitors: number;
  updated_at: string;

  constructor(data?: SourceBase) {
    super([
      'by_offer_only',
      'doi_tracking_link',
      'external_signup',
      'hold_users',
      'key',
      'landing_page',
      'landing_page_group',
      'name',
      'notes',
      'notes_last_saved_at',
      'open',
      'paid',
      'requires_email_confirm',
      'reward_value',
      'soi_tracking_link',
      'source_id',
      'survey_id',
      'tags',
    ], data);
  }
}

export interface CampaignActivity {
  id?: string;
  campaign_id?: number;
  created_at?: string;
  error_log?: any;
  error_user_count?: number;
  file?: { url: string; }
  processed?: boolean;
  processed_user_count?: number;
  updated_at?: string;
}

export interface CampaignStatsBase {
  id?: string;
  unique_visitors?: number;
  signups?: number;
  validations?: number;
  invalid_signups?: number;
  activations?: number;
}

export class CampaignStats extends CachedDataObj<CampaignStatsBase> {
  id: string;
  unique_visitors: number;
  signups: number;
  validations: number;
  invalid_signups: number;
  activations: number;

  constructor(data?: CampaignStatsBase) {
    super([
      "id",
      "unique_visitors",
      "signups",
      "validations",
      "invalid_signups",
      "activations"
    ], data);
    this.unique_visitors = this.unique_visitors || 0;
    this.signups = this.signups || 0;
    this.validations = this.validations || 0;
    this.invalid_signups = this.invalid_signups || 0;
    this.activations = this.activations || 0;
  }
}


export interface SourceStatsBase {
  id?: string;
  unique_visitors?: number;
  signups?: number;
  validations?: number;
  invalid_signups?: number;
  activations?: number;
}

export class SourceStats extends CachedDataObj<SourceStatsBase> {
  id: string;
  unique_visitors: number;
  signups: number;
  validations: number;
  invalid_signups: number;
  activations: number;

  constructor(data?: SourceStatsBase) {
    super([
      "id",
      "unique_visitors",
      "signups",
      "validations",
      "invalid_signups",
      "activations"
    ], data);
    this.unique_visitors = this.unique_visitors || 0;
    this.signups = this.signups || 0;
    this.validations = this.validations || 0;
    this.invalid_signups = this.invalid_signups || 0;
    this.activations = this.activations || 0;
  }
}


export interface SiteImageBase {
  id?: string;
  alt_text?: string;
  image?: { url?: string; };
  name?: string;
}

export class SiteImage extends CachedDataObj<SiteImageBase> {
  id: string;
  alt_text: string;
  image: { url?: string; };
  name: string;

  constructor(data?: SiteImageBase) {
    super([
      "id",
    ], data);
    this.image = this.image || { url: "" };
  }
}


export interface BlogPostBase {
  id?: string;
  body?: string;
  date?: string;
  label?: string;
  teaser?: string;
  title?: string;
  image_id?: string;
  thumb_image_id?: string;
}

export class BlogPost extends CachedDataObj<BlogPostBase> {
  id: string;
  body: string;
  date: string;
  label: string;
  teaser: string;
  title: string;
  image_id: string;
  thumb_image_id: string;

  constructor(data?: BlogPostBase) {
    super([
      "id",
      "body",
      "date",
      "label",
      "teaser",
      "title",
      "image_id",
      "thumb_image_id",
    ], data);
  }
}

export interface ActivityReportReport {
  users_total?: number;
  users_usable?: number;
  users_activated?: number;
  banked_rewards?: string;
  forthright_surveys_finished?: number;
  forthright_surveys_qualified?: number;
  forthright_surveys_rewards?: string;
  forthright_surveys_estimated_revenue?: number;
  forthright_surveys_estimated_gross_profit?: number;
  partner_surveys_finished_legacy?: number;
  partner_surveys_qualified_legacy?: number;
  partner_surveys_rewards_legacy?: number;
  partner_surveys_estimated_revenue_legacy?: number;
  partner_surveys_estimated_gross_profit_legacy?: number;
  partner_surveys_attempted?: number;
  partner_surveys_qualified?: number;
  partner_surveys_rewards?: number;
  partner_surveys_revenue?: number;
  partner_surveys_gross_profit?: number;
  pollfish_surveys_attempted?: number;
  pollfish_surveys_qualified?: number;
  pollfish_surveys_rewards?: number;
  pollfish_surveys_revenue?: number;
  pollfish_surveys_gross_profit?: number;
  inbrain_surveys_attempted?: number;
  inbrain_surveys_qualified?: number;
  inbrain_surveys_rewards?: number;
  inbrain_surveys_revenue?: number;
  inbrain_surveys_gross_profit?: number;
  total_rewards?: string;
  total_revenue?: number;
  total_gross_profit?: number;
  revenue_per_user?: number;
  rewards_per_user?: number;
  gross_profit_per_user?: number;
};

export interface ActivityReportBase {
  id?: string;
  campaign_ids?: number[];
  created_at?: string;
  end_time?: string;
  error_log?: any;
  processed?: boolean;
  report?: ActivityReportReport;
  source_id?: number;
  start_time?: string;
  updated_at?: string;
}

export class ActivityReport extends CachedDataObj<ActivityReportBase> {
  id: string;
  campaign_ids: number[];
  created_at: string;
  end_time: string;
  error_log: any;
  processed: boolean;
  report: ActivityReportReport;
  source_id: number;
  start_time: string;
  updated_at: string;

  constructor(data?: ActivityReportBase) {
    super([
      "id",
      "campaign_ids",
      "created_at",
      "end_time",
      "error_log",
      "processed",
      "report",
      "source_id",
      "start_time",
      "updated_at",
    ], data);
  }
}
