import { FirebaseConfiguration, Environment } from './configuration.models'
import { firebaseProdConfig } from './firebase.config';

export const environment: Environment = {
  production: true,
  staging: false,
  hmr: false,
  membersAppDomain: "https://www.beforthright.com",
  firebaseConfig: firebaseProdConfig,
  panelServicesUrl: 'https://33fu3rzok0.execute-api.us-east-1.amazonaws.com/prod/',
  profilerWidgetUrl: 'https://d1kvuerrtc50n6.cloudfront.net/',
  giveawayWidgetUrl: "https://d19c2e85t5x8ut.cloudfront.net/",
  samplingServicesUrl: 'https://d6bhhs3mtd.execute-api.us-east-1.amazonaws.com/prod/',
  userStatsUrl: 'https://5rly4pd3sg3qchca4pvwx4xtum0zzlyd.lambda-url.us-east-1.on.aws/',
  sentry: {
    key: "https://7a0aa096885049faabcf97cda9ad4c57@o474251.ingest.sentry.io/5868986",
    tracesSampleRate: 0.1
  },
  keen: {
    projectId: "534fefa800111c7e37000017",
    readKey: "9c9b25dac1c753c0feb164f467bf896d5034e7bde5ccb167022726e4fc2c7143688ec45805466a54a0b459ee1e33f57417260e2c827d9542f8fa878689bc5468f3956d6e50a962a2d9bfe772050feb398db54effa8c35368461a4361fbdf8c5b661c26ed3e6f69ec432bd2d9af68145b"
  }
};
