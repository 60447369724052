import { Action } from '@ngrx/store';
import * as Models from 'src/app/data.models';

export enum Types {
  Get             = '[SampleProfile] Get',
  GetSuccess      = '[SampleProfile] GetSuccess',
  GetError        = '[SampleProfile] GetError',
  GetStats        = '[SampleProfile] GetStats',
  GetStatsSuccess = '[SampleProfile] GetStatsSuccess',
  GetStatsError   = '[SampleProfile] GetStatsError',
  GetAll          = '[SampleProfile] GetAll',
  GetAllSuccess   = '[SampleProfile] GetAllSuccess',
  GetAllError     = '[SampleProfile] GetAllError',
  GetArchived     = '[SampleProfile] GetArchived',
  Create          = '[SampleProfile] Create',
  CreateSuccess   = '[SampleProfile] CreateSuccess',
  CreateError     = '[SampleProfile] CreateError',
  Delete          = '[SampleProfile] Delete',
  DeleteSuccess   = '[SampleProfile] DeleteSuccess',
  DeleteError     = '[SampleProfile] DeleteError',
  Update          = '[SampleProfile] Update',
  UpdateSuccess   = '[SampleProfile] UpdateSuccess',
  UpdateError     = '[SampleProfile] UpdateError',
  Clear           = '[SampleProfile] Clear',
  ClearErrors     = '[SampleProfile] ClearErrors',
}

export class ClearErrors implements Action {
  readonly type = Types.ClearErrors;
  constructor(public name?: string) { }
}

export class GetAll implements Action {
  readonly type = Types.GetAll;
  constructor(public projectID?: string) { }
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: Models.SampleModels.SampleProfile[], public projectID: string) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() { }
}

export class Get implements Action {
  readonly type = Types.Get;
  constructor(public id: string) { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: Models.SampleModels.SampleProfileBase) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class GetStats implements Action {
  readonly type = Types.GetStats;
  constructor(public payload: Models.SampleModels.SampleProfile) { }
}

export class GetStatsSuccess implements Action {
  readonly type = Types.GetStatsSuccess;
  constructor(public id: string, public payload: Models.SampleModels.SampleStatsBase) { }
}

export class GetStatsError implements Action {
  readonly type = Types.GetStatsError;
  constructor(public id: string, public error: Error) { }
}

export class Delete implements Action {
  readonly type = Types.Delete;
  constructor(public id: string) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class Create implements Action {
  readonly type = Types.Create;
  constructor(public payload: Models.SampleModels.SampleProfileBase) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: Models.SampleModels.SampleProfileBase) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Update implements Action {
  readonly type = Types.Update;
  constructor(public payload: Models.SampleModels.SampleProfileBase) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: Models.SampleModels.SampleProfileBase) {
    console.log(this.type);
  }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = Get | GetSuccess | GetError | GetStats | GetStatsSuccess | GetStatsError | GetArchived | GetAll | GetAllSuccess | GetAllError | Delete | DeleteSuccess | DeleteError | Create | CreateSuccess | CreateError | Update | UpdateSuccess | UpdateError | Clear | ClearErrors;
