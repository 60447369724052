import { Pipe, PipeTransform } from '@angular/core';
import { platformAliasMap } from 'src/app/data.models';

@Pipe({
  name: 'monetizeLogTypeMap'
})
export class MonetizeLogTypeMapPipe implements PipeTransform {

  transform(value: any): any {
    if (typeof value === "string") {
      if (value.indexOf("P2Sample") > -1) {
        return value.replace("P2Sample", "Your Surveys");
      }
      if (value.indexOf("PeanutLab") > -1) {
        return value.replace("PeanutLab", "Peanut Lab");
      }
      if (value.indexOf("PrecisionSample") > -1) {
        return value.replace("PrecisionSample", "Precision Sample");
      }
      return platformAliasMap[value] ? platformAliasMap[value].name : (platformAliasMap[value.toLowerCase()] ? platformAliasMap[value.toLowerCase()].name : value);
    }
    return value;
  }
}
