<form [formGroup]="form" (submit)="save()" autocomplete="off" id="feedbackForm">
  <app-dialog class="short-dialog">
    <div dialog-title>{{title}}</div>
    <div dialog-content>


      <!-- Type -->
      <mat-form-field>
        <mat-label>My feedback relates to...</mat-label>
        <mat-select id="feedbackForm_type" formControlName="type">
          <mat-option *ngFor="let type of feedbackTypes" [value]="type.value">
            {{type.viewValue}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('type').dirty && form.get('type').hasError('required')">
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <!-- Email Address -->
      <mat-form-field class="mr-1" *ngIf="form.get('type').value" [hidden]="user?.email">
        <mat-label>Email Address</mat-label>
        <input matInput id="feedbackForm_email" formControlName="email" autocomplete="off" type="text" />
        <mat-error *ngIf="form.get('email').dirty && form.get('email').hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('email').dirty && form.get('email').hasError('email') && !form.get('email').hasError('required')">
          Email is <strong>invalid</strong>
        </mat-error>
      </mat-form-field>

      <!-- Comment -->
      <mat-form-field *ngIf="form.get('type').value">
        <mat-label>Comment / Request</mat-label>
        <textarea matInput id="feedbackForm_comment" formControlName="comment" rows="5"></textarea>
        <mat-error *ngIf="form.get('comment').dirty && form.get('comment').hasError('required')">
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

    </div>
    <div dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button type="button" id="feedbackForm_cancelButton" mat-stroked-button (click)="close()" class="mr-3">Cancel</button>
        <button type="submit" id="feedbackForm_saveButton" mat-flat-button class="bg-green">Send</button>
      </div>
    </div>
  </app-dialog>
</form>
