<div [style.height]="height + 'px'" *ngIf="loading; else contentTemplate">
  <content-loader [viewBox]="viewBox"
                  [speed]="speed"
                  [backgroundColor]="backgroundColor"
                  [foregroundColor]="foregroundColor">
    <ng-container *ngFor="let group of layout">
      <ng-template [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{ $implicit: group }"></ng-template>
    </ng-container>
  </content-loader>
</div>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>

<ng-template #rowTemplate let-group>
  <ng-container *ngFor="let child of group.children">
    <ng-container *ngIf="isRow(child)">
      <ng-template [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{ $implicit: child }"></ng-template>
    </ng-container>
    <ng-container *ngIf="!isRow(child)">
      <svg:rect [attr.x]="child.left + child.leftUnit" [attr.y]="child.top + 'px'"
                [attr.rx]="child.radius + 'px'" [attr.ry]="child.radius + 'px'"
                [attr.height]="child.height + 'px'" [attr.width]="child.width + child.widthUnit" />
    </ng-container>
  </ng-container>
</ng-template>
