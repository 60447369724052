import * as Actions from './sample-distribution.actions';
import * as Reducers from '../reducers';
import { SampleDistribution } from '../../sample.models';
import { extendObj } from '../../data.models';

export interface State extends Reducers.State<SampleDistribution> {
  byProject?: { [key: string]: SampleDistribution[] };
  byProjectMap?: { [key: string]: { [key: string]: SampleDistribution } };
  requestedAllAt?: number;
}

export const initialState: State = {
  data: null, archive: null, count: 0, map: {}, errors: {},
  byProject: {},
  byProjectMap: {},
  requestedAllAt: 0
};

export function sampleDistributionReducer(state: State = initialState, action: Actions.Any): State {

  const newState: State = extendObj(true, {}, state);
  let id = "";
  switch (action.type) {

    case Actions.Types.GetSuccess:
    case Actions.Types.UpdateSuccess:
      newState.errors = { ...newState.errors, ...(action.payload ? { [action.payload.id || ""]: null } : {}) };
    case Actions.Types.CreateSuccess:
      newState.map = { ...newState.map, ...(action.payload ? { [action.payload.id || ""]: action.payload } : {}) };
      newState.errors = { ...newState.errors, create: null };
      newState.archive = Object.values(newState.map).filter(p => p.archived);
      newState.data = Object.values(newState.map).filter(p => !p.archived);
      break;


    case Actions.Types.GetAllSuccess:
      if (action.projectID) {
        newState.map = {
          ...newState.map,
          ...Reducers.map(action.payload)
        };
      }
      else {
        newState.requestedAllAt = new Date().getTime();
        newState.map = Reducers.map(action.payload);
      }
      newState.data = Object.values(newState.map).filter(p => !p.archived);
      newState.archive = Object.values(newState.map).filter(p => p.archived);
      newState.errors = { ...newState.errors, all: null };
      break;


    case Actions.Types.DeleteSuccess:
      newState.data = (state.data || []).filter((reward) => reward.id !== action.id);
      newState.map = Reducers.map(newState.data);
      break;

      
    case Actions.Types.GetAllError:
      newState.map = {};
      newState.data = [];
    case Actions.Types.CreateError:
    case Actions.Types.GetError:
    case Actions.Types.DeleteError:
    case Actions.Types.UpdateError:
      newState.errors = {
        ...state.errors,
        [action.type === Actions.Types.GetAllError ? 'all'
         : action.type === Actions.Types.CreateError ? 'create'
         : action.id]: action.error
      };
      break;


    case Actions.Types.Clear:
      return initialState;

    default:
      return newState;

  }
  newState.byProjectMap = (newState.data || []).reduce((prev, curr: SampleDistribution, idx, arr) => {
    prev[curr.sampleProjectId] = prev[curr.sampleProjectId] || {};
    prev[curr.sampleProjectId][curr.id] = curr;
    return prev;
  }, {});
  newState.byProject = Object.entries(newState.byProjectMap).reduce((prev, curr: [string, { [key: string]: SampleDistribution }], idx, arr) => {
    prev[curr[0]] = Object.values(curr[1]);
    return prev;
  }, {});
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  !!newState.data && newState.data.length || (newState.data = null);
  return newState;
}
