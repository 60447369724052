import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap, startWith } from 'rxjs/operators';
import * as RewardProfileActions from './reward-profile.actions';
import { RewardProfileService } from 'src/app/services/reward/reward-profile.service';

@Injectable()
export class RewardProfileEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProfileActions.Types.GetAll),
      mergeMap(() => this.rewardProfileService.getAll()
        .pipe(
          map(profiles => (new RewardProfileActions.GetAllSuccess(profiles))),
          catchError((err: Error) => of(new RewardProfileActions.GetAllError(err)))
        ))
    ));

  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProfileActions.Types.GetArchived),
      mergeMap(() => this.rewardProfileService.getAll(true)
        .pipe(
          map(profiles => (new RewardProfileActions.GetAllSuccess(profiles))),
          catchError((err: Error) => of(new RewardProfileActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProfileActions.Types.Get),
      mergeMap((action: RewardProfileActions.Get) => this.rewardProfileService.getByID(action.id)
        .pipe(
          map(profile => (new RewardProfileActions.GetSuccess(profile))),
          catchError((err: Error) => of(new RewardProfileActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProfileActions.Types.Create),
      mergeMap((action: RewardProfileActions.Create) => this.rewardProfileService.create(action.payload)
        .pipe(
          map((profile) => (new RewardProfileActions.CreateSuccess(profile))),
          catchError((err: Error) => of(new RewardProfileActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProfileActions.Types.Update),
      mergeMap((action: RewardProfileActions.Update) => this.rewardProfileService.update(action.payload)
        .pipe(
          map((profile) => (new RewardProfileActions.UpdateSuccess(profile))),
          catchError((err: Error) => of(new RewardProfileActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(RewardProfileActions.Types.Delete),
      mergeMap((action: RewardProfileActions.Delete) => this.rewardProfileService.archive(action.id)
        .pipe(
          map(() => (new RewardProfileActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new RewardProfileActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private rewardProfileService: RewardProfileService
  ) { }
}
