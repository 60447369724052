import * as BlacklistReducer from './community-blacklist.reducers';
import * as MemberReducer from './community-member.reducers';
import * as TestUserReducer from './community-test-user.reducers';
export { MemberReducer, BlacklistReducer, TestUserReducer }

import * as BlacklistActions from './community-blacklist.actions';
import * as MemberActions from './community-member.actions';
import * as TestUserActions from './community-test-user.actions';
export { MemberActions, BlacklistActions, TestUserActions }

export { MemberEffects } from './community-member.effects';

export interface Store {
  members: any;
  blacklist: any;
  test_users: any;
}

export const COMMUNITY_TEST_STORE: Store = {
  members: MemberReducer.reducerFn,
  blacklist: BlacklistReducer.reducerFn,
  test_users: TestUserReducer.reducerFn,
}

export interface ICommunityStore extends Store {
  members: MemberReducer.State;
  blacklist: BlacklistReducer.State;
  test_users: TestUserReducer.State;
}
