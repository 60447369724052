import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SampleModels } from 'src/app/data.models';
import * as SampleProfileActions from 'src/app/store/sample/sample-profile.actions';
import { RootStore } from 'src/app/store/store';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { LoggerService } from '../logger.service';
import { SampleProjectService } from './sample-project.service';
import { SampleService } from './sample.service';
import { UserQueryService } from '../user-query.service';


@Injectable({
  providedIn: 'root'
})
export class SampleProfileService extends SampleService<SampleModels.SampleProfileBase> {

  private get currentID() {
    return this.sampleProjectService.currentID || "";
  }
  private statSubs: { [id: string]: Subscription } = {};

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private userQueryService: UserQueryService,
    private sampleProjectService: SampleProjectService,
    private store: Store<RootStore>,
  ) {
    super('sampleProfile');
  }

  /**
   * @method SampleProfileService.create
   * @description Saves a new SampleModels.SampleProfile to the server
   * @param {SampleModels.SampleProfile} profile The new profile to be saved
   * @param {string} sampleProjectID (default: SampleProfileService.currentID) The ID of the SampleModels.SampleProject to attach the new profile to.
   * @returns Observable<SampleModels.SampleProfile>
   */
  public create(profile: SampleModels.SampleProfileBase, sampleProjectID: string = this.currentID): Observable<SampleModels.SampleProfileBase> {
    if (!profile.sampleProjectId) {
      if (!sampleProjectID) {
        return throwError("Sample Project ID is required")
      }
      profile.sampleProjectId = sampleProjectID;
    }
    profile.createdAt = new Date().toISOString();
    return this.api.post<SampleModels.SampleProfileBase>(this.endpoint, profile, true, environment.samplingServicesUrl)
      .pipe(tap((resp: SampleModels.SampleProfileBase) => {
        this.logger.log(resp);
        if (resp) {
          this.store.dispatch(new SampleProfileActions.CreateSuccess(resp));
        }
      }), catchError((err: any) => {
        this.store.dispatch(new SampleProfileActions.CreateError(err));
        return throwError(new Error(err && err.errors ? err.errors[0].message : err && err.message || "An error occurred"));
      }));
  }

  /**
   * @method SampleProfileService.clone
   * @description Retrieves an up to date version (fresh from the server) of the
                  SampleModels.SampleProject with the id supplied and then returns a clone of it
   * @param {string} id The ID of the SampleModels.SampleProfile to be cloned
   * @returns Observable<SampleModels.SampleProfile>
   */
  public clone(id: string): Observable<SampleModels.SampleProfile> {
    return this.getByID(id).pipe(map((sampleProfile: SampleModels.SampleProfileBase) => {
      if (sampleProfile) {
        let profile = new SampleModels.SampleProfile(sampleProfile);
        delete profile.id;
        profile.name = "Copy of " + profile.name;
        profile.createdAt = new Date().toISOString();
        return profile;
      }
      return null;
    }));
  }

  /**
   * @method SampleProfileService.getStats
   * @description Gets the counts stats from the [`UserQueryService`](./user-query.service.md) for the given SampleModels.SampleProfile.
   * @param {SampleModels.SampleProfile} profile The ID of the SampleModels.SampleProject for which the profiles should be retrieved
   * @param {boolean} clear Whether to clear any current stats before getting new ones
   * @returns Observable<SampleModels.SampleProfile>
   */
  public getStats(profile: SampleModels.SampleProfile, clear?: boolean) {
    if (!profile) {
      return throwError(new Error("No profile provided for getStats()"));
    }
    if (clear) {
      this.store.dispatch(new SampleProfileActions.GetStatsSuccess(profile.id, null));
      return of(null);
    }
    return this.userQueryService.getSampleProfileStats(profile);
  }

  /**
   * @method SampleProfileService.getByProject
   * @description Retrieves a list of SampleProfiles belonging to a SampleModels.SampleProject with a given ID
   * @param {string} sampleProjectID The ID of the SampleModels.SampleProject for which the profiles should be retrieved
   * @returns Observable<SampleModels.SampleProfile[]>
   */
  public getByProject(sampleProjectID?: string): Observable<SampleModels.SampleProfile[]> {
    const id = sampleProjectID || this.currentID;
    if (!id) {
      if (this.sampleProjectService.currentID) {
        return this.getByProject(this.sampleProjectService.currentID);
      }
      else {
        return throwError(new Error("No sampleProjectID"));
      }
    }
    const url = this.sampleProjectService.endpoint + '/' + id + '/sampleProfiles';
    //this.api.get<SampleModels.SampleProfile[]>(this.endpoint, { sampleProjectId: sampleProjectID}, environment.samplingServicesUrl).subscribe(resp => {
    return this.api.get<SampleModels.SamplingAPIResponse<SampleModels.SampleProfileBase>>(url, null, environment.samplingServicesUrl)
      .pipe(map((resp: SampleModels.SamplingAPIResponse<SampleModels.SampleProfileBase>) => {
        if (resp && resp.results) {
          if (!resp.results.length) {
            throw new Error("No sample profiles found for this project");
          }
          return resp.results.map(p => new SampleModels.SampleProfile(p));
        }
        return [];
      }))
  }
  
  /**
   * @method SampleProfileService.getByID
   * @description Retrieves a specified SampleModels.SampleProfile with a given ID
   * @param {string} id The ID of the SampleModels.SampleProfile to be retrieved
   * @returns Observable<SampleModels.SampleProfile>
   */
  public getByID(id: string): Observable<SampleModels.SampleProfileBase> {
    return this.api.get<SampleModels.SampleProfileBase>(this.endpoint + '/' + id, null, environment.samplingServicesUrl)
      .pipe(catchError(() => of(null)));
  }


  /**
   * @method SampleProfileService.update
   * @description Updates a given SampleModels.SampleProfile and then refreshes the cache
   * @param {SampleModels.SampleProfile} profile The updated profile object
   * @returns Observable<SampleModels.SampleProfile>
   */
  public update(profile: SampleModels.SampleProfileBase): Observable<SampleModels.SampleProfileBase> {
    profile.updatedAt = new Date().toISOString();
    return this.api.put<SampleModels.SampleProfileBase>(this.endpoint, profile, true, environment.samplingServicesUrl).pipe(
      tap((resp: SampleModels.SampleProfileBase) => {
        if (resp) {
          this.store.dispatch(new SampleProfileActions.UpdateSuccess(resp));
        }
      }),
      catchError((err: any) => {
        this.store.dispatch(new SampleProfileActions.UpdateError(profile.id, err));
        return throwError(new Error(err && err.errors ? err.errors[0].message : err && err.message || "An error occurred"));
      })
    );
  }

  /**
   * @method SampleProfileService.delete
   * @description Deletes the SampleModels.SampleProfile with the given ID
   * @param {string} id The id of the profile to delete
   * @returns Observable<boolean|never>
   */
  public delete(id: string): Observable<boolean | never> {
    return this.api.delete(this.endpoint + '/' + id, null, environment.samplingServicesUrl).pipe(switchMap((resp: HttpResponse<any>) => {
      if (resp.statusText.toLowerCase() === "ok") {
        this.store.dispatch(new SampleProfileActions.DeleteSuccess(id));
        return of(true);
      }
      return this.deleteError(id, resp);
    }), catchError((resp: any) => {
      return this.deleteError(id, resp);
    }));
  }

  /**
   * @method SampleProfileService.deleteError
   * @description Helper method to write error message and return observable for catchError mode
   * @param {string} id The id of the profile that failed to delete
   * @param {any} resp The failed response body
   * @returns Observable<never>
   */
  private deleteError(id: string, resp: any): Observable<never> {
    const error = new Error(resp.body && resp.body.errors ? resp.body.errors[0].message : "An error occurred");
    this.store.dispatch(new SampleProfileActions.DeleteError(id, error));
    return throwError(error);
  }
}
