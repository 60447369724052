import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LoggerService } from 'src/app/services/logger.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private tokenName: string = 'Panel-Services-Auth-Token';

  constructor(
    private auth: AuthService,
    private logger: LoggerService
  ) {
  }

  /**
   * @method AuthInterceptor.intercept
   * @description Intercepts all API calls and adds the authorisation token to it
   * @param req The request being issued
   * @param next The next Http handler in the queue
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {


    //Check that the request is to the AWS server
    const isApiCall = req.url.indexOf(environment.panelServicesUrl) > -1 || req.url.indexOf(environment.samplingServicesUrl) > -1;

    //Check that a user exists
    if (isApiCall && this.auth.currentUser) {
      //this.logger.log("AuthInterceptor: Intercepting http " + req.method + " req to '" + req.url + "'");

      // Get the panelServicesToken from the AuthService.
      const authToken = this.auth.currentUser.panelServicesToken;
      if (authToken) {
        // Clone the request and add the new header
        const authReq = req.clone({
          headers: req.headers.set(this.tokenName, authToken)
        });
        // send cloned request with header to the next handler.
        return next.handle(authReq);
      }
      else {
        this.logger.error("AuthInterceptor: User has no authToken")
      }
    }
    //By default, pass the req onto the next handler
    return next.handle(req);

  }
}
