import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../../data.models';
import { Subscription } from 'rxjs';
import { UserService, AuthService } from '../../../services';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public user: User;
  public busy: boolean;
  public loaded: boolean;
  public loadingTimer: any;

  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) { }

  back() {
    if (window.history) {
      window.history.back();
    }
  }

  ngOnInit() {
    this.subs.push(
      this.authService.user$.subscribe(user => {
        this.user = user;
      }),
      this.userService.user$.subscribe(user => {
        this.user = user;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
