import * as RecruitCampaignReducer from './recruit-campaign.reducers';
import * as RecruitSourceReducer from './recruit-source.reducers';
import * as RecruitBlogPostReducer from './recruit-blog.reducers';
import * as RecruitSiteImageReducer from './recruit-image.reducers';
import * as RecruitReportReducer from './recruit-report.reducers';

import * as RecruitCampaignActions from './recruit-campaign.actions';
import * as RecruitSourceActions from './recruit-source.actions';
import * as RecruitPostActions from './recruit-blog.actions';
import * as RecruitImageActions from './recruit-image.actions';
import * as RecruitReportActions from './recruit-report.actions';

export {
  RecruitCampaignActions, RecruitSourceActions, RecruitPostActions, RecruitImageActions, RecruitReportActions,
  RecruitCampaignReducer, RecruitSourceReducer, RecruitBlogPostReducer, RecruitSiteImageReducer, RecruitReportReducer
}

export { RecruitCampaignEffects } from './recruit-campaign.effects';
export { RecruitSourceEffects } from './recruit-source.effects';

export interface RecruitStore {
  sources: any;
  campaigns: any;
  blogPosts: any;
  siteImages: any;
  reports: any;
}

export const RECRUIT_STORE: RecruitStore = {
  sources: RecruitSourceReducer.reducerFn,
  campaigns: RecruitCampaignReducer.reducerFn,
  blogPosts: RecruitBlogPostReducer.reducerFn,
  siteImages: RecruitSiteImageReducer.reducerFn,
  reports: RecruitReportReducer.reducerFn,
}

export interface IRecruitStore extends RecruitStore {
  sources: RecruitSourceReducer.State;
  campaigns: RecruitCampaignReducer.State;
  blogPosts: RecruitBlogPostReducer.State;
  siteImages: RecruitSiteImageReducer.State;
  reports: RecruitReportReducer.State;
}
