import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService, LoggerService } from 'src/app/services';


@Component({
  selector: 'app-dialog-switch',
  template: ''
})
export class DialogSwitchComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.subs.push(
      this.route.params.subscribe(params => {
        if (params.dialog) {
          this.router.navigate([''], { queryParamsHandling: 'merge' }).then(() => {
            this.dialogService.open(params.dialog);
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
