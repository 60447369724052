import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Log, Respondent, RespondentList, PagerConfig, RespondentListsPagerFilters, PagedHttpResponse } from 'src/app/data.models';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';
import { LoggerService } from './logger.service';
import { SupplyOptimizerService } from './supply-optimizer.service';
import { Pager, Pageable } from './paging.service';


@Injectable({
  providedIn: 'root'
})
export class RespondentService extends SupplyOptimizerService implements Pageable {

  private respondents: { [key: string]: BehaviorSubject<Respondent> } = {}; // ToDo: AdamA: Implement caching

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private snackBar: MatSnackBar,
    private customerService: CustomerService
  ) {
    super(customerService);
  }

  public pollLogs(queryString: string): Observable<PagedHttpResponse<Log[]>> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'v1/logs' + this.password + '&' + queryString.substring(1);
        return this.api.poll<PagedHttpResponse<Log[]>>(url, null, null, this.supplyOptimizerUrl, 'body', null, true, 10000, true);
      }));
  }

  public getLogsPager(config?: PagerConfig, filters?: RespondentListsPagerFilters): Pager {
    return this.currentPager = new Pager(this.pollLogs, this, filters, config);
  }

  public getRespondents(): Observable<Respondent[]> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'respondents';
        return this.api.get(url, { pw: this.supplyOptimizerPassword }, this.supplyOptimizerUrl)
          .pipe(map((resp: any) => {
            if (resp) {
              return resp.sessions as Respondent[];
            }
          }), catchError(err => throwError(new Error('Error fetching respondents'))));
      }));
  }

  getRespondent(id: string): Observable<Respondent> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'respondent/' + id;
        return this.api.get(url, { pw: this.supplyOptimizerPassword }, this.supplyOptimizerUrl)
          .pipe(map((resp: any) => {
            return resp as Respondent;
          }), catchError(err => throwError(new Error('Error fetching respondent'))));
      }));
  }

  getRespondentList(id: string): Observable<RespondentList> {
    return this.waitForPassword()
      .pipe(switchMap(() => {

        let url = 'respondent_list/' + id + this.password;
        // return Observable.of(new RespondentList()).map(() => RESPONDENTLISTDATAFULL);
        return this.api.get(url)
          .pipe(map((resp: any) => resp as RespondentList));
      }));
  }

  getRespondentLists(queryString?: string): Observable<PagedHttpResponse<RespondentList[]>> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        let url = 'v1/respondent_lists' + this.password + '&' + queryString.substring(1);
        // return Observable.of(new RespondentList()).map(() => RESPONDENTLISTDATAFULL);
        return this.api.get<PagedHttpResponse<RespondentList[]>>(url, null, this.supplyOptimizerUrl, 'body');
      }));
  }


  private currentPager: Pager;
  public getPager(): Pager {
    return this.currentPager || null;
  }

  public getRespondentListsPager(config?: PagerConfig, filters?: RespondentListsPagerFilters): Pager {
    return this.currentPager = new Pager(this.getRespondentLists, this, filters);
  }

  private respondentListSub: Subscription;
  createRespondentList(startDate: string, endDate: string) {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'respondent_lists/new' + this.password;
        const subject$: BehaviorSubject<RespondentList> = new BehaviorSubject(null);

        this.respondentListSub = this.api.post(url, { start: startDate, end: endDate } as any, false, this.supplyOptimizerUrl)
          .pipe(
            switchMap((respondentList: RespondentList) => {
              if (respondentList && respondentList.id) {
                subject$.next(respondentList);
                this.snackBar.open("Respondent list generating... This might take a few minutes.");
                return this.pollRespondentList(respondentList.id);
              }
              return of(null);
            })
          )
          .subscribe((respondentList: RespondentList) => {
            if (respondentList) {
              this.snackBar.open("Respondent list generated!");
              subject$.next(respondentList);
            }
          });
        return subject$.asObservable();
      }));
  }

  pollRespondentList(id: string): Observable<RespondentList> {
    return this.waitForPassword()
      .pipe(switchMap(() => {
        const url = 'respondent_list/' + id + this.password;
        return this.api.poll<RespondentList>(url, (resp: RespondentList) => resp && !!resp.file_url, null, this.supplyOptimizerUrl);
      }));
  }

  cancelPoll(): void {
    this.api.cancelPoll();
    if (this.respondentListSub) {
      this.respondentListSub.unsubscribe();
    }
  }
}
