import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Observable, merge, Subscription, of, interval } from 'rxjs';
import { timeInterval, startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit, OnDestroy {

  private timer: any;
  public loading: boolean = true;
  public overlay: boolean;
  public maxWait: number = 10000; //milliseconds
  public textTemplate: any;

  private dotLength: number = 0;
  public dot: Observable<string> = interval(300).pipe(
    startWith('.'),
    map(_ => {
      if (this.dotLength === 3) {
        this.dotLength = 0;
      }
      const dots: string[] = Array(++this.dotLength);
      dots.fill('.');
      return dots.join('');
    })
  );

  @Input() size: 'small'|'medium'|'large';
  @Input() textOnly: boolean;

  @Input('overlay') set _overlay(o: boolean) {
    this.overlay = o;
  }
  @Input('loading') set _loading(l: boolean) {
    this.loading = !!l;
    this.fulfil();
  }
  @Input('max-wait') set _maxWait(ms: number) {
    this.maxWait = ms;
    if (this.timer) {
      this.wait();
    }
  }

  @Input('await') set waitFor(trigger: any) {
      if (!trigger) {
        this.loading = true;
        this.wait();
      }
      else {
        this.fulfil();
        this.loading = false;
      }
  }

  constructor() { }

  private fulfil() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
  private wait() {
    this.fulfil();
    this.timer = setTimeout(() => this.loading = false, this.maxWait);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.textTemplate) {
      delete this.textTemplate;
    }
    if (this.timer) {
      clearTimeout(this.timer)
      delete this.timer;
    }
  }

}
