import { Observable } from 'rxjs';
import * as BaseModels from './base.models';

export { ISampleStore, SampleStore } from 'src/app/store/sample/sample.store';

export interface SampleProfileSettings {
  sampleFilterOptions?: SampleProfileFilterData[];
  defaultIncludes?: SampleFilterOptionsList;
  defaultExcludes?: SampleFilterOptionsList;
  defaultDistributionIds?: string[];
  batchListTemplates?: SampleBatchTemplate[];
}

export interface SampleProjectBase extends BaseModels.APIBase {
  name?: string;
  notes?: string;
  customerId?: string;
  xid?: string;
}

export class SampleProject extends BaseModels.ApiDefaults implements SampleProjectBase {
  name: string;
  notes: string;
  customerId: string;
  xid: string;

  constructor(data?: SampleProjectBase) {
    super([
      'name',
      'notes',
      'customerId',
      'xid',
    ], data);
  }
}


export interface SamplingAPIResponse<T = any> {
  results: T[];
  totalCount: number;
}

export interface SampleFilterBase {
  name?: string;
  includes?: SampleFilterOptionsList;
  excludes?: SampleFilterOptionsList;
}
export class SampleFilter extends BaseModels.DataObj implements SampleFilterBase {
  name: string;
  includes: SampleFilterOptionsList;
  excludes: SampleFilterOptionsList;
  constructor(specs?: (BaseModels.DataSpec | string)[], data?: any) {
    if (specs) {
      specs.unshift(
        'name',
        { name: 'includes', defaultValue: {} },
        { name: 'excludes', defaultValue: {} },
      );
    }
    super(specs, data);
  }
}


export interface SampleDistributionCellBase extends SampleFilterBase {
  target?: number;
  stats?: SampleStatsBase;
  stats$?: Observable<SampleStatsBase>;
}
export class SampleDistributionCell extends SampleFilter implements SampleDistributionCellBase {
  target: number;
  stats: SampleStatsBase;
  stats$: Observable<SampleStatsBase>;

  constructor(data?: SampleDistributionCellBase) {
    super([
      'target',
    ], data);
    if (data) {
      this.includes = this.ensureType(data.includes);
      this.excludes = this.ensureType(data.excludes);
    }
  }
  private ensureType(value: any) {
    return typeof value === 'string' ? JSON.parse(value) : value;
  }
}

export interface SampleDistributionBase extends BaseModels.APIBase {
  name?: string;
  description?: string;
  sampleProjectId?: string;
  cells?: SampleDistributionCellBase[];
  customerId?: string;
}
export class SampleDistribution extends BaseModels.ApiDefaults implements SampleDistributionBase {
  name: string;
  description: string;
  sampleProjectId: string;
  customerId: string;
  cells: SampleDistributionCellBase[];

  constructor(data?: SampleDistributionBase) {
    super([
      'name',
      'description',
      'sampleProjectId',
      'customerId',
      {
        name: 'cells',
        defaultValue: [],
        transform: (value: SampleDistributionCell[], spec: BaseModels.DataSpec) => {
          if (value && value.length) {
            return value.map(cell => cell.asDataObj)
          }
          return [];
        }
      },
    ], data);
    this.cells && (this.cells = this.cells.map(cell => new SampleDistributionCell(cell)));
  }
}

export class CachedDataObj<T> extends BaseModels.DataObj {
  constructor(specs: (BaseModels.DataSpec | string)[], data?: T, public _cacheAt: number = new Date().getTime()) {
    super(specs, data);
  }

  _expired: boolean;

  get isExpired() {
    if (this._expired) {
      return this._expired;
    }
    const now = new Date().getTime();
    const then = this._cacheAt;
    const after = 1000 * 60 * 15; // 15 minutes
    return (now - then) > after;
  }
}

export interface SampleStatsTable {
  status: string;
  percentage: string;
  count: string;
}

export interface SampleStatsBase {
  id?: string;
  invited?: number;
  qualified?: number;
  finished?: number;
  started?: number;
  total?: number;
  available?: number;
  selected?: number;
  invalidated?: number;
  invalidatedQualified?: number;
  invalidatedDisqualified?: number;
  feasible?: number;
  responseRate?: number;
  active?: number;
}

export class SampleStats extends CachedDataObj<SampleStatsBase> {
  id?: string;
  invited?: number;
  qualified?: number;
  finished?: number;
  started?: number;
  total?: number;
  available?: number;
  selected?: number;
  invalidated?: number;
  invalidatedQualified?: number;
  invalidatedDisqualified?: number;
  feasible?: number;
  responseRate?: number;
  active?: number;

  constructor(data?: SampleStatsBase) {
    super([
      "id",
      "invited",
      "qualified",
      "finished",
      "started",
      "total",
      "available",
      "selected",
      "invalidated",
      "invalidatedQualified",
      "invalidatedDisqualified",
      "feasible",
      "responseRate",
      "active"
    ], data);
  }

  get finishRate() {
    if (!this.finished || !this.started) {
      return '-';
    }
    return this.finished / this.started;
  }
  get qualifyRate() {
    if (!this.finished || !this.qualified) {
      return '-';
    }
    return this.qualified / this.finished;
  }
}

export interface SampleProfileBase extends SampleFilterBase, BaseModels.APIBase {
  xid?: string;
  sampleProjectId?: string;
  sampleDistributionIds?: string[];
  customerId?: string;
}
export class SampleProfile extends SampleFilter implements SampleProfileBase {
  id: string;
  archived: boolean;
  createdAt: string;
  xid: string;
  sampleProjectId: string;
  sampleDistributionIds: string[];
  customerId: string;

  constructor(data?: SampleProfileBase) {
    super([
      'id',
      'archived',
      'xid',
      'sampleProjectId',
      'sampleDistributionIds',
      'customerId'
    ], data);
    if (data) {
      // Check the API response has returned objects/arrays for the required properties and converts them if not
      this.excludes = this.ensureType(data.excludes);
      this.includes = this.ensureType(data.includes);
      this.sampleDistributionIds = this.ensureType(data.sampleDistributionIds);
    }
  }

  private ensureType(value: any) {
    return typeof value === 'string' ? JSON.parse(value) : value;
  }
}

export interface SampleError {
  data?: any;
  level?: string;
  message?: string;
  name?: string;
  timestamp?: string;
}

export interface SampleProgress {
  cells?: SampleProgressCell[];
}

export interface SampleProgressCell {
  name?: string;
  sizeRequested?: number;
  sizeCompleted?: number;
}

export type SampleType = "Invite" | "Remind" | "Close" | "Reopen" | "Export";
export interface SampleBase extends SampleFilterBase, BaseModels.APIBase {
  sampleProjectId?: string;
  sampleProfileId?: string;
  type?: SampleType;
  size?: number;
  fileURL?: string;
  sizeCompleted?: number;
  sizeForStarted?: boolean;
  distributed?: boolean;
  nestDistributions?: boolean;
  failWhenShort?: boolean;
  canceled?: boolean;
  processed?: boolean;
  processing?: boolean;
  sampleDistributionIds?: string[];
  userIds?: string[];
  pids?: string[];
  specs?: SampleSpecBase;
  customerId?: string;
  error?: SampleError;
  progress?: SampleProgress;
}

export class Sample extends SampleFilter implements SampleBase {
  id: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
  sampleDistributionIds: string[];
  customerId: string;
  sampleProjectId: string;
  sampleProfileId: string;
  type: SampleType;
  size: number;
  fileURL: string;
  sizeCompleted: number;
  sizeForStarted: boolean;
  distributed: boolean;
  nestDistributions: boolean;
  failWhenShort: boolean;
  canceled: boolean;
  processed: boolean;
  processing: boolean;
  userIds: string[];
  pids: string[];
  specs: SampleSpecBase;
  error: SampleError;
  progress?: SampleProgress;

  constructor(data?: SampleBase) {
    super([
      'id',
      'archived',
      'sampleProjectId',
      'error',
      { name: 'progress', defaultValue: null },
      { name: 'sampleDistributionIds', defaultValue: null },
      'customerId',
      'sampleProjectId',
      'sampleProfileId',
      { name: 'failWhenShort', defaultValue: true },
      'size',
      { name: 'type', defaultValue: 'Invite' },
      { name: 'nestDistributions', defaultValue: false },
      'sizeCompleted',
      'sizeForStarted',
      'distributed',
      'processed',
      'userIds',
      'pids',
      {
        name: 'specs',
        defaultValue: null,
        transform: (value: SampleSpec, spec: BaseModels.DataSpec) => {
          return value.asDataObj;
        }
      },
    ], data);
    data.specs && (this.addSpecs(data.specs))
  }
  addSpecs(specs: SampleSpecBase) {
    this.specs = new SampleSpec(specs)
  }
}

export interface SampleBatchTemplate {
  matchAttribute?: string;
  name?: string;
  setAttribute?: string;
}
export interface SampleBatchBase extends SampleBatchTemplate, BaseModels.APIBase {
  description?: string;
  error?: SampleError;
  file?: string; //file or filePath
  fileDocument?: File; //file or filePath
  matchValue?: any;
  processed?: boolean; //(default = false)
  queuedForDeletion?: boolean;
  rowsMatched?: number; //(default = 0)
  rowsProcessed?: number; //(default = 0)
  sampleProjectId?: string;
  setValue?: string;
}

export class SampleBatch extends BaseModels.ApiDefaults implements SampleBatchBase {
  createdAt: string;
  description: string;
  error: SampleError;
  file: string;
  fileDocument: File;
  id: string;
  matchAttribute: string;
  matchValue: any;
  name: string;
  processed: boolean;
  queuedForDeletion: boolean;
  rowsMatched: number;
  rowsProcessed: number;
  sampleProjectId: string;
  setAttribute: string;
  setValue: string;

  constructor(data?: SampleBatchBase) {
    super([
      'description',
      { name: 'error', defaultValue: null },
      'file',
      'fileDocument',
      'matchAttribute',
      'matchValue',
      'name',
      { name: 'processed', defaultValue: false },
      'queuedForDeletion',
      { name: 'rowsMatched', defaultValue: 0 },
      { name: 'rowsProcessed', defaultValue: 0 },
      'sampleProjectId',
      'setAttribute',
      'setValue',
    ], data);
  }
}

export interface SampleSpecBase {
  sampleProfile: SampleProfileBase;
  sampleDistributions: SampleDistributionBase[];
}

export class SampleSpec extends BaseModels.DataObj implements SampleSpecBase {
  sampleProfile: SampleProfileBase;
  sampleDistributions: SampleDistributionBase[];

  constructor(spec: SampleSpecBase);
  constructor(profile: SampleProfileBase, distributions: SampleDistributionBase[]);
  constructor(...args) {
    let pfl: SampleProfile;
    let dists: SampleDistribution[];
    if ((<SampleSpecBase>args[0]).sampleProfile) {
      pfl = new SampleProfile(args[0].sampleProfile);
      dists = args[0].sampleDistributions ? args[0].sampleDistributions.map(d => new SampleDistribution(d)) : [];
    }
    else {
      pfl = new SampleProfile(args[0])
      dists = args[1] ? args[1].map(d => new SampleDistribution(d)) : []
    }
    super([
      {
        name: 'sampleProfile',
        defaultValue: {},
        transform: (value: SampleProfile, spec: BaseModels.DataSpec) => {
          return value.asDataObj;
        }
      },
      {
        name: 'sampleDistributions',
        defaultValue: [],
        transform: (value: SampleDistribution[], spec: BaseModels.DataSpec) => {
          return value.map(d => d.asDataObj);
        }
      },
    ], { sampleProfile: pfl, sampleDistributions: dists });
  }
}

export interface SampleFilterOptionsList {
  usable?: boolean[];
  active?: boolean[];
  pid?: string[];
  projectsSelected?: string[];
  projectsInvited?: string[];
  projectsStarted?: string[];
  projectsFinished?: string[];
  projectsQualified?: string[];
  projectsInvalidated?: string[];
  answers?: { [key: string]: (string | number)[] }
}

export interface SampleProfileFilterData {
  name: string;
  alias?: string;
  hide?: boolean;
  lookup?: string;
  questions?: SampleProfileFilterQuestion[];
  filteredQuestions?: SampleProfileFilterQuestion[];
  sections?: SampleProfileFilterData[];
  categories?: string[];
  counts?: {
    includes?: number;
    excludes?: number;
  }
}

export interface SampleProfileFilterQuestion {
  name: string;
  alias: string;
  value?: string | string[];
  range?: BaseModels.Range;
  lookup?: string;
  section?: string;
  type?: BaseModels.QuestionType;
  options?: SampleProfileFilterOption[];
  included?: (string | number)[];
  excluded?: (string | number)[];
  selected?: SampleProfileFilterOption[];
}

export interface SampleProfileFilterOption extends BaseModels.Answer {
  included?: boolean;
}
