import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Customer, CustomerBase, User, UserRole } from 'src/app/data.models';
import { CustomerService, DialogService, LoggerService, MenuService, UserService } from 'src/app/services';

interface CustomerListItem extends CustomerBase {
  loadingUsers?: boolean;
  users$?: Observable<User[]>;
}

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html'
})
export class ManageUsersComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private init: boolean;
  private getListSub: Subscription;

  public user: User;
  public customerList: CustomerListItem[];
  public openList: number;
  public loadingUsers: boolean;

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map((result: BreakpointState) => result.matches));

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private dialogService: DialogService,
    private logger: LoggerService,
    private breakpointObserver: BreakpointObserver,
    private menuService: MenuService
  ) { }

  public addCustomer() {
    this.dialogService.open('create-customer');
  }

  public editCustomer(customerId) {
    this.dialogService.open('edit-customer', { customerId });
  }

  public deleteCustomer(id) {
    if (this.user.role === 0) {
      this.dialogService.open('delete-customer', { customerId: id });
    }
  }

  public addUser(customer?: Customer) {
    this.dialogService.open('create-user', customer ? { customerId: customer.id } : null)
      .subscribe(dialog => {
        if (dialog.action === "close") {
          this.getList();
        }
      });
  }

  public editUser(user?: User) {
    this.dialogService.open('edit-user', { id: user.authId })
      .subscribe(dialog => {
        if (dialog.action === "close") {
          this.getList();
        }
      });
  }

  public deleteUser(user?: User) {
    this.dialogService.open('delete-user', { id: user.authId })
      .subscribe(dialog => {
        if (dialog.action === "close") {
          this.getList();
        }
      });
  }

  public getList() {
    if (this.getListSub) {
      this.getListSub.unsubscribe();
    }
    if (this.user) {
      if (this.user.role === 0) {
        this.subs.push(
          this.getListSub = this.customerService.getAll().subscribe(customers => {
            this.customerList = customers.map((cust, i) => {
              return {
                ...cust,
                users$: this.userService.getUserListForCustomer(cust.id).pipe(
                  catchError(error => {
                    this.logger.error(error.message)
                    return [];
                  }),
                  tap(() => this.customerList[i].loadingUsers = false)
                ),
                loadingUsers: true
              };
            });
            if (!this.init) {
              this.init = true;
              this.openList = -1;
            }
          })
        );
      }
      else {
        this.subs.push(
          this.getListSub = this.customerService.getByID(this.user.customerId).subscribe(customer => {
            this.customerList = [{
              ...customer.asDataObj,
              users$: this.userService.getUserListForCustomer(customer.id).pipe(
                tap(() => this.customerList[0].loadingUsers = false)
              ),
              loadingUsers: true
            }];
            if (!this.init) {
              this.init = true;
              this.openList = -1;
            }
          })
        );
      }
    }
  }

  ngOnInit() {
    this.menuService.registerActions([
      {
        action: this.addUser,
        icon: "person_add",
        context: this,
        label: "Add User"
      },
      {
        icon: "playlist_add",
        action: this.addCustomer,
        restrict: UserRole.SuperAdmin,
        context: this,
        label: "Add Customer"

      }
    ])
    this.subs.push(
      this.userService.user$.subscribe(user => {
        if (user) {
          this.user = user;
          this.getList();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    this.menuService.clearActions();
  }

}
