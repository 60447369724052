import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    AccessDeniedComponent, DialogOpenComponent, DialogOverlayComponent, DialogSwitchComponent,
    PanelAdmin403Component
} from './components/dialog/dialog.module';
import { ResetPasswordComponent } from './components/dialog/reset-password/reset-password.component';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/home/not-found/not-found.component';
import { UserRole } from './data.models';
import { AuthAreaGuard } from './guards/auth-area.guard';
import { AuthDialogGuard } from './guards/auth-dialog.guard';
import { AuthRoleGuard } from './guards/auth-role.guard';


const routes: Routes = [
  {
    path: 'account',
    canActivate: [AuthRoleGuard],
    loadChildren: () => import('./components/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'profile',
    canActivate: [AuthAreaGuard, AuthRoleGuard],
    data: {
      role: UserRole.User,
      area: 'profile'
    },
    loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'monetize',
    canActivate: [AuthAreaGuard, AuthRoleGuard],
    data: {
      role: UserRole.User,
      area: 'monetize'
    },
    loadChildren: () => import('./components/monetize/monetize.module').then(m => m.MonetizeModule),
  },
  {
    path: 'sample',
    canActivate: [AuthAreaGuard, AuthRoleGuard],
    data: {
      role: UserRole.User,
      area: 'sample'
    },
    loadChildren: () => import('./components/sample/sample.module').then(m => m.SampleModule),
  },
  {
    path: 'community',
    canActivate: [AuthAreaGuard, AuthRoleGuard],
    data: {
      role: UserRole.User,
      area: 'community'
    },
    loadChildren: () => import('./components/community/community.module').then(m => m.CommunityModule),
  },
  {
    path: 'reward',
    canActivate: [AuthAreaGuard],
    data: {
      role: UserRole.User,
      area: 'reward'
    },
    loadChildren: () => import('./components/reward/reward.module').then(m => m.RewardModule),
  },
  {
    path: 'product-tests',
    canActivate: [AuthAreaGuard],
    data: {
      role: UserRole.User,
      area: 'productTests'
    },
    loadChildren: () => import('./components/product-tests/product-tests.module').then(m => m.ProductTestsModule),
  },
  {
    path: 'recruit',
    canActivate: [AuthAreaGuard],
    data: {
      role: UserRole.User,
      area: 'recruit'
    },
    loadChildren: () => import('./components/recruit/recruit.module').then(m => m.RecruitModule),
  },
  {
    path: 'login',
    redirectTo: '/'
  },
  {
    path: 'open-dialog/:dialog',
    component: DialogSwitchComponent,
  },
  {
    path: 'dialog',
    component: DialogOverlayComponent,
    outlet: 'dialog',
    children: [
      {
        path: 'not-allowed',
        data: {
          message: 'You do not have permission. You may need to log in or contact your administrator to increase your privileges.'
        },
        component: AccessDeniedComponent
      },
      {
        path: 'panel-admin-403',
        component: PanelAdmin403Component
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: ':dialog',
        canActivate: [AuthDialogGuard],
        data: {
          role: UserRole.All
        },
        component: DialogOpenComponent
      },
    ]
  },
  {
    path: 'drawer',
    component: DialogOverlayComponent,
    outlet: 'drawer',
    children: [
      {
        path: ':drawer',
        canActivate: [AuthDialogGuard],
        data: {
          role: UserRole.All
        },
        component: DialogOpenComponent
      },
    ]
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
