import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { LoginFormComponent } from './login-form/login-form.component';
import { PanelAdminLoginFormComponent } from './panel-admin-login-form/panel-admin-login-form.component';
import { PanelAdminRegistrationFormComponent } from './panel-admin-registration-form/panel-admin-registration-form.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    LoginFormComponent,
    PanelAdminLoginFormComponent,
    PanelAdminRegistrationFormComponent,
  ],
  exports: [
    LoginFormComponent,
    PanelAdminLoginFormComponent,
    PanelAdminRegistrationFormComponent,
  ],
})
export class SharedFormsModule { }
