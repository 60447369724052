import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { uniq } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RewardProfile, RewardProfileBase } from 'src/app/components/reward/reward.models';
import { FavoritesTypes } from 'src/app/data.models';
import { IRewardStore } from 'src/app/store';
import * as RewardProfileActions from 'src/app/store/reward/reward-profile.actions';
import { ApiService } from '../api.service';
import { FavoritesService } from '../favorites.service';
import { LoggerService } from '../logger.service';

@Injectable({
  providedIn: 'root'
})
export class RewardProfileService {

  private endpoint: string = 'rewardProfile';

  constructor(
    private store: Store<{ reward: IRewardStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
  ) {}

  /**
   * @method RewardProfileService.create
   * @description Saves a new RewardProfile to the server
   * @param {RewardProfile} profile The new RewardProfile to be saved
   * @returns Observable<RewardProfile>
   */
  public create(profile: RewardProfile): Observable<RewardProfile> {
    const url = this.endpoint;
    return this.api.post<RewardProfileBase>(url, profile.asDataObj).pipe(
      map((p) => {
        const profile = new RewardProfile(p)
        this.store.dispatch(new RewardProfileActions.CreateSuccess(profile));
        return profile;
      }),
      switchMap((profile) => {
        return this.favoritesService.addFavorite(FavoritesTypes.RewardProfiles, profile.id).pipe(map(() => profile));
      }),
      catchError((err: Error) => {
        this.store.dispatch(new RewardProfileActions.CreateError(err));
        return throwError(new Error(err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
      })
    );
  }

  /**
   * @method RewardProfileService.getAll
   * @description Retrieves a list of all RewardProfiles in the Sample API
   * @returns Observable<RewardProfile[]>
   */
  public getAll(archive?: boolean): Observable<RewardProfile[]> {
    const url = this.endpoint;
    return this.api.get<RewardProfileBase[]>(url, archive ? { Filter: 'all' } : {}).pipe(
      map((resp: any) => resp.Items.map((p: RewardProfileBase) => new RewardProfile(p))),
      catchError((resp: Error ) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardProfileService.getByID
   * @description Retrieves a RewardProfile for a given ID
   * @param {string} id The ID of the RewardProfile to be retrieved
   * @returns Observable<RewardProfile>
   */
  public getByID(id: string): Observable<RewardProfile> {
    const url = this.endpoint;
    return this.api.get<RewardProfileBase>(url, { id }).pipe(
      map((p) => {
        p.availableRewardTypes = uniq(p.availableRewardTypes);
        return new RewardProfile(p);
      }),
      catchError((resp: Error ) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardProfileService.getEmbedCode
   * @description Retrieves the Embed Code for a RewardProfile
   * @param {string} id The ID of the RewardProfile to be retrieved
   * @returns Observable<RewardProfile>
   */
  public getEmbedCode(id: string): Observable<string> {
    const url = this.endpoint;
    return this.api.get(url, { id }, null, 'all').pipe(
      map((resp: { body: { embedCode: string } }) => resp && resp.body && resp.body.embedCode),
      catchError((resp: Error ) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardProfileService.update
   * @description Updates a RewardProfile on the server
   * @returns Observable<RewardProfile>
   */
  public update(profile: RewardProfile): Observable<RewardProfile> {
    const url = this.endpoint;
    return this.api.put<RewardProfileBase>(url, profile.asDataObj).pipe(
      map((p) => {
        p.availableRewardTypes = uniq(p.availableRewardTypes);
        const profile = new RewardProfile(p);
        this.store.dispatch(new RewardProfileActions.UpdateSuccess(profile));
        return profile;
      }),
      catchError((err: Error) => {
        this.store.dispatch(new RewardProfileActions.UpdateError(profile.id, err));
        return throwError(new Error(err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
      })
    );
  }

  /**
   * @method RewardProfileService.archive
   * @description Archives a RewardProfile from the server, by setting it's 'archived' property to 'true'
   * @returns Observable<RewardProfile>
   */
  public archive(id: string): Observable<RewardProfile> {
    const url = this.endpoint;
    return this.api.put<RewardProfileBase>(url, { id, archived: true }).pipe(
      map((p) => new RewardProfile(p)),
      catchError((resp: Error ) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }
}
