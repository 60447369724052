import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BlacklistedEmail } from 'src/app/components/community/community.models';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { BlacklistActions } from 'src/app/store/actions';
import { ICommunityStore } from '../../../store/community';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityBlacklistService {

  private endpoint: string = 'blacklisted_reward_emails';
  private blacklist_requested: { [id: string]: boolean; } = {};

  constructor(
    private api: ApiService,
    private logger: LoggerService,
    private panelAdminService: PanelAdminService,
    private store: Store<{ community: ICommunityStore }>,
  ) { }

  /**
   * @method CommunityBlacklistService.create
   * @description Saves a new BlacklistedEmail to the server
   * @param {BlogPost} email The new BlacklistedEmail to be saved
   * @returns Observable<BlacklistedEmail>
   */
  public create(email: BlacklistedEmail): Observable<BlacklistedEmail> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<BlacklistedEmail>(this.endpoint, email, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((created) => {
          this.store.dispatch(new BlacklistActions.CreateSuccess(created));
          return created;
        }),
        catchError((err: any) => {
          this.store.dispatch(new BlacklistActions.CreateError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method CommunityBlacklistService.getByID
   * @description Retrieves a BlacklistedEmail
   * @returns Observable<BlacklistedEmail>
   */
  public getByID(id: string | number, force?: boolean): Observable<BlacklistedEmail> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return state.community.blacklist.map[id];
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      switchMap((item) => {
        if (!this.blacklist_requested[id] && (force || !item)) {
          this.blacklist_requested[id] = true;
          return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
            this.api.get<BlacklistedEmail>(this.endpoint + '/' + id, {}, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
              map((item) => {
                this.store.dispatch(new BlacklistActions.GetSuccess(item));
                this.blacklist_requested[id] = false;
                return item;
              }),
              catchError((err: any) => {
                this.store.dispatch(new BlacklistActions.GetError(id + "", err));
                this.blacklist_requested[id] = false;
                return this.panelAdminService.handleError(err);
              })
            )
          ),
            catchError(() => of(null))
          );
        }
        return of(item);
      })
    );
  }

  /**
   * @method CommunityBlacklistService.getAll
   * @description Retrieves a list of all BlacklistedEmails, filtered by the params set
   * @returns Observable<PagedHttpResponse<BlacklistedEmail>>
   */
  public getAll(filters?: any): Observable<BlacklistedEmail[]> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<BlacklistedEmail[]>(this.endpoint, filters || null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        switchMap((resp: any) => {
          this.store.dispatch(new BlacklistActions.GetAllSuccess(resp));
          return this.store.pipe(select(state => state.community.blacklist.data));
        }),
        catchError((err: any) => {
          this.store.dispatch(new BlacklistActions.GetAllError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityBlacklistService.update
   * @description Updates a BlacklistedEmail on the server
   * @returns Observable<BlacklistedEmail>
   */
  public update(id: string | string, email: BlacklistedEmail, action?: string): Observable<BlacklistedEmail> {
    const url = this.endpoint + '/' + (action ? action + '/' : '') + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(url, JSON.stringify({ data: email }), false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: BlacklistedEmail) => {
          this.store.dispatch(new BlacklistActions.UpdateSuccess(resp));
          return resp;
        }),
        catchError((err: any) => {
          this.store.dispatch(new BlacklistActions.UpdateError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityBlacklistService.delete
   * @description Deletes a BlacklistedEmail on the server
   * @returns Observable<boolean>
   */
  public delete(id: number | string): Observable<boolean> {
    const url = this.endpoint + '/' + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.delete<any>(url, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: any) => {
          this.store.dispatch(new BlacklistActions.DeleteSuccess(id));
          return true;
        }),
        catchError((err: any) => {
          this.store.dispatch(new BlacklistActions.DeleteError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

}
