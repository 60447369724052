import { Action } from '@ngrx/store';
import { RewardGiveaway } from 'src/app/components/reward/reward.models';

export enum Types {
  GetSuccess = '[RewardGiveaway] GetSuccess',
  GetError = '[RewardGiveaway] GetError',

  GetAllSuccess = '[RewardGiveaway] GetAllSuccess',
  GetAllError = '[RewardGiveaway] GetAllError',

  GetArchived = '[RewardGiveaway] GetArchived',

  CreateSuccess = '[RewardGiveaway] CreateSuccess',
  CreateError = '[RewardGiveaway] CreateError',

  DeleteSuccess = '[RewardGiveaway] DeleteSuccess',
  DeleteError = '[RewardGiveaway] DeleteError',

  UpdateSuccess = '[RewardGiveaway] UpdateSuccess',
  UpdateError = '[RewardGiveaway] UpdateError',

  Clear = '[RewardGiveaway] Clear',
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: RewardGiveaway[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetArchived implements Action {
  readonly type = Types.GetArchived;
  constructor() { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: RewardGiveaway) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: string, public error: Error) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: string, public error: Error) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: RewardGiveaway) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class UpdateSuccess implements Action {
  readonly type = Types.UpdateSuccess;
  constructor(public payload: RewardGiveaway) { }
}

export class UpdateError implements Action {
  readonly type = Types.UpdateError;
  constructor(public id: string, public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any = GetSuccess | GetError | GetArchived | GetAllSuccess | GetAllError | DeleteSuccess | DeleteError | CreateSuccess | CreateError | UpdateSuccess | UpdateError | Clear;
