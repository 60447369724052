import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap, skipWhile, tap } from 'rxjs/operators';
import * as SampleProfileActions from './sample-profile.actions';
import { SampleProfileService } from 'src/app/services/sample/sample-profile.service'
import { UserQueryService } from 'src/app/services/user-query.service'
import { SampleModels } from 'src/app/data.models';

@Injectable()
export class SampleProfileEffects {

  
  getAll$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProfileActions.Types.GetAll),
      mergeMap((action: SampleProfileActions.GetAll) => this.service.getByProject(action.projectID)
        .pipe(
          map(profiles => (new SampleProfileActions.GetAllSuccess(profiles, action.projectID || (profiles && profiles.length ? profiles[0].sampleProjectId : "unknown")))),
          catchError((err: Error) => of(new SampleProfileActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProfileActions.Types.Get),
      mergeMap((action: SampleProfileActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(profile => (new SampleProfileActions.GetSuccess(profile))),
          catchError((err: Error) => of(new SampleProfileActions.GetError(action.id, err)))
        ))
    ));

  
  getStats$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProfileActions.Types.GetStats),
      mergeMap((action: SampleProfileActions.GetStats) => this.service.getStats(action.payload)
        .pipe(
          map(stats => (new SampleProfileActions.GetStatsSuccess(action.payload.id, stats))),
          catchError((err: Error) => of(new SampleProfileActions.GetStatsError(action.payload.id, new Error(err && err.message ? err.message : 'Error loading stats'))))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProfileActions.Types.Create),
      mergeMap((action: SampleProfileActions.Create) => this.service.create(action.payload)
        .pipe(
          map((reward) => (new SampleProfileActions.CreateSuccess(reward))),
          catchError((err: Error) => of(new SampleProfileActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProfileActions.Types.Update),
      mergeMap((action: SampleProfileActions.Update) => this.service.update(action.payload)
        .pipe(
          map((reward) => (new SampleProfileActions.UpdateSuccess(reward))),
          catchError((err: Error) => of(new SampleProfileActions.UpdateError(action.payload.id, err)))
        ))
    ));

  
  delete$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleProfileActions.Types.Delete),
      mergeMap((action: SampleProfileActions.Delete) => this.service.delete(action.id)
        .pipe(
          map(() => (new SampleProfileActions.DeleteSuccess(action.id))),
          catchError((err: Error) => of(new SampleProfileActions.DeleteError(action.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: SampleProfileService,
    private userQueryService: UserQueryService,
  ) { }
}
