import { Action } from '@ngrx/store';
import { CommunityMember } from '../../components/community/community.models';

export enum Types {
  GetSuccess               = '[CommunityTestUser] GetSuccess',
  GetError                 = '[CommunityTestUser] GetError',

  GetAllSuccess            = '[CommunityTestUser] GetAllSuccess',
  GetAllError              = '[CommunityTestUser] GetAllError',

  CreateSuccess            = '[CommunityTestUser] CreateSuccess',
  CreateError              = '[CommunityTestUser] CreateError',

  DeleteSuccess            = '[CommunityTestUser] DeleteSuccess',
  DeleteError              = '[CommunityTestUser] DeleteError',
                           
  Clear                    = '[CommunityTestUser] Clear',
}

export class GetAllSuccess implements Action {
  readonly type = Types.GetAllSuccess;
  constructor(public payload: CommunityMember[]) { }
}

export class GetAllError implements Action {
  readonly type = Types.GetAllError;
  constructor(public error: Error) { }
}

export class GetSuccess implements Action {
  readonly type = Types.GetSuccess;
  constructor(public payload: CommunityMember) { }
}

export class GetError implements Action {
  readonly type = Types.GetError;
  constructor(public id: number | string, public error: Error) { }
}

export class DeleteSuccess implements Action {
  readonly type = Types.DeleteSuccess;
  constructor(public id: number | string) { }
}

export class DeleteError implements Action {
  readonly type = Types.DeleteError;
  constructor(public id: number | string, public error: Error) { }
}

export class CreateSuccess implements Action {
  readonly type = Types.CreateSuccess;
  constructor(public payload: CommunityMember) { }
}

export class CreateError implements Action {
  readonly type = Types.CreateError;
  constructor(public error: Error) { }
}

export class Clear implements Action {
  readonly type = Types.Clear;
}

export type Any =
    GetSuccess    | GetError
  | GetAllSuccess | GetAllError
  | DeleteSuccess | DeleteError
  | CreateSuccess | CreateError
  | Clear;
