import { ModuleWithProviders, NgModule } from '@angular/core';
import { AsFilterPipe } from './as-filter.pipe';
import { ChangeCasePipe } from './change-case.pipe';
import { FilterListPipe } from './filter-list.pipe';
import { FilterObjPipe } from './filter-obj.pipe';
import { LimitToPipe } from './limit-to.pipe';
import { LogPipe } from './log.pipe';
import { MonetizeLogTypeMapPipe } from './monetize-log-type-map.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { TranslatePipe } from './translate.pipe';
import { TypeOfPipe } from './type-of.pipe';


export { ChangeCasePipe, TypeOfPipe, TranslatePipe, FilterListPipe, FilterObjPipe, AsFilterPipe, LimitToPipe, LogPipe, MonetizeLogTypeMapPipe };

@NgModule({
  declarations: [
    ChangeCasePipe,
    TypeOfPipe,
    TranslatePipe,
    FilterListPipe,
    FilterObjPipe,
    AsFilterPipe,
    LimitToPipe,
    LogPipe,
    MonetizeLogTypeMapPipe,
    SanitizePipe,
  ],
  exports: [
    ChangeCasePipe,
    TypeOfPipe,
    TranslatePipe,
    FilterListPipe,
    FilterObjPipe,
    AsFilterPipe,
    LimitToPipe,
    LogPipe,
    MonetizeLogTypeMapPipe,
    SanitizePipe,
  ]
})
export class PipesModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: PipesModule,
      providers: [
        ChangeCasePipe,
        TypeOfPipe,
        TranslatePipe,
        FilterListPipe,
        FilterObjPipe,
        AsFilterPipe,
        LimitToPipe,
        LogPipe,
        MonetizeLogTypeMapPipe,
        SanitizePipe,
      ]
    }
  }
}
