import * as BaseModels from '../../base.models';

export { IRewardStore, RewardStore } from 'src/app/store';

export enum RewardType {
  Amazon = 'amazon',
  Bitcoin = 'bitcoin',
  Paypal = 'paypal',
  Rewardlink = 'rewardlink',
  Habitat = 'habitat',
}

export interface RewardTypeSettings {
  [key: string]: {
    "recipientFirstName": "Survey Participant",
    "senderEmail": "reward@beforthright.com",
    "senderFirstName": "Fortnight",
    "accountIdentifier": "A08174646",
    "customerIdentifier": "G08555300",
    "platformName": "BovitzV2Test",
    "message": "Thank you for your participation in our research study",
    "utid": "U666425",
    "platformAccessKey": "BCvKqG@qGCyBjvAtYL@ee!UZKNLMFMWyyToPVWhVj$dPUU"
  }
}

export const AllRewardTypes: RewardType[] = [RewardType.Amazon, RewardType.Bitcoin, RewardType.Paypal, RewardType.Rewardlink, RewardType.Habitat];

export interface RewardBase extends BaseModels.APIBase {
  amount?: number;
  autoClaim?: boolean;
  autoDeliver?: boolean;
  claimed?: boolean;
  claimLink?: string;
  currency?: BaseModels.CurrencyType;
  delivered?: boolean;
  deliveryAttempts?: number;
  recipientEmail?: string;
  recipientId?: string;
  rewardProfileId?: string;
  transactionId?: string;
  type?: RewardType;
  value?: number;
}

export class Reward extends BaseModels.ApiDefaults implements RewardBase {
  amount: number;
  autoClaim: boolean;
  autoDeliver: boolean;
  canceled: boolean;
  claimed: boolean;
  claimLink: string;
  currency: BaseModels.CurrencyType;
  delivered: boolean;
  deliveryAttempts: number;
  recipientEmail: string;
  recipientId: string;
  rewardProfileId: string;
  transactionId: string;
  type: RewardType;
  value: number;

  constructor(data?: RewardBase) {
    super([
      'name',
      "rewardProfileId",
      "transactionId",
      "recipientId",
      "recipientEmail",
      { name: "value", defaultValue: 0 },
      { name: "amount", defaultValue: 0 },
      { name: "type", defaultValue: RewardType.Bitcoin },
      { name: "currency", defaultValue: "USD" },
      { name: "autoClaim", defaultValue: false },
      { name: "autoDeliver", defaultValue: false },
      { name: "delivered", defaultValue: false },
      { name: "deliveryAttempts", defaultValue: 0 },
    ], data);
  }
}

export interface RewardProfileBase extends BaseModels.APIBase {
  name?: string;
  providerId?: string;
  customerId?: string;
  balance?: number;
  maxPayout?: number;
  autoDeliverRewards?: boolean;
  availableRewardTypes?: RewardType[];
  rewardTypeSettings?: RewardTypeSettings;
}

export class RewardProfile extends BaseModels.ApiDefaults implements RewardProfileBase {
  name: string;
  providerId: string;
  customerId: string;
  balance: number;
  maxPayout: number;
  autoDeliverRewards: boolean;
  availableRewardTypes: RewardType[];
  rewardTypeSettings: RewardTypeSettings;

  constructor(data?: RewardProfileBase) {
    super([
      'name',
      'providerId',
      'customerId',
      { name: "balance", defaultValue: 0 },
      { name: "maxPayout", defaultValue: 0 },
      { name: "autoDeliverRewards", defaultValue: false },
      { name: "availableRewardTypes", defaultValue: AllRewardTypes },
      { name: "rewardTypeSettings", defaultValue: {} },
    ], data);
  }
}

export interface RewardProviderBase extends BaseModels.APIBase {
  name?: string;
}

export class RewardProvider extends BaseModels.ApiDefaults implements RewardProviderBase {
  name: string;

  constructor(data?: RewardProviderBase) {
    super([
      'name',
    ], data);
  }
}

export interface RewardGiveawayBase extends BaseModels.APIBase {
  active?: boolean;
  archived?: boolean;
  createdAt?: string;
  customerId?: string;
  emailEntry?: boolean;
  endDate?: string;
  endDateTime?: string; // "6/30/2023, 4:59:59 PM"
  id?: string;
  maxEntriesPerUser?: number;
  name?: string;
  numPrizes?: number;
  numWinners?: number;
  opened?: boolean;
  prompt?: string;
  providerId?: string;
  rules?: string; // HTML content
  startDate?: string; //"2023-05-30"
  startDateTime?: string; // "5/29/2023, 5:00:00 PM"
  title?: string;
  totalEntries?: number;
  updatedAt?: string; //"Wed, 07 Jun 2023 00: 00: 25 GMT"
  winners?: string[]; // IDs
}

export class RewardGiveaway extends BaseModels.ApiDefaults implements RewardGiveawayBase {
  active: boolean;
  archived: boolean;
  createdAt: string;
  customerId: string;
  emailEntry: boolean;
  endDate: string;
  endDateTime: string;
  id: string;
  maxEntriesPerUser: number;
  name: string;
  numPrizes: number;
  numWinners: number;
  opened: boolean;
  prompt: string;
  providerId: string;
  rules: string;
  startDate: string;
  startDateTime: string;
  title: string;
  totalEntries: number;
  updatedAt: string;
  winners: string[];

  constructor(data?: RewardGiveawayBase, public rewardToken: string = "") {
    super([
      'name',
      'active',
      'archived',
      'createdAt',
      'customerId',
      'emailEntry',
      'endDate',
      'endDateTime',
      'id',
      'maxEntriesPerUser',
      'name',
      'numPrizes',
      'numWinners',
      'opened',
      'prompt',
      'providerId',
      'rules',
      'startDate',
      'startDateTime',
      'title',
      'totalEntries',
      'updatedAt',
      'winners'
    ], data);
  }
}

export interface RewardGiveawayEntry {
  createdAt?: string;
  customerId?: string;
  email?: string;
  giveawayId?: string;
  id?: string;
  updatedAt?: string;
  userId?: string;
  winner?: boolean;
}

export interface RewardPagerFilters {
  undelivered?: boolean;
  unclaimed?: boolean;
  profile?: string; //PlatformType
  recipientID?: string;
  recipientEmail?: string;
}
