import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { map, mergeMap, catchError, switchMap, tap } from 'rxjs/operators';
import * as SampleBatchActions from './sample-batch.actions';
import { SampleBatchService } from 'src/app/services/sample/sample-batch.service'
import { SampleBatch } from 'src/app/sample.models';

@Injectable()
export class SampleBatchEffects {

  private queue: { [key: string]: Observable<SampleBatch[]> } = {};
  
  getArchive$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleBatchActions.Types.GetArchived),
      mergeMap(() => this.service.getAll()
        .pipe(
          map(batches => (new SampleBatchActions.GetAllSuccess(batches))),
          catchError((err: Error) => of(new SampleBatchActions.GetAllError(err)))
        ))
    ));

  
  get$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleBatchActions.Types.Get),
      mergeMap((action: SampleBatchActions.Get) => this.service.getByID(action.id)
        .pipe(
          map(batch => (new SampleBatchActions.GetSuccess(batch))),
          catchError((err: Error) => of(new SampleBatchActions.GetError(action.id, err)))
        ))
    ));

  
  create$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleBatchActions.Types.Create),
      mergeMap((action: SampleBatchActions.Create) => this.service.create(action.payload)
        .pipe(
          map((batch) => (new SampleBatchActions.CreateSuccess(batch))),
          catchError((err: Error) => of(new SampleBatchActions.CreateError(err)))
        ))
    ));

  
  update$ = createEffect(() => this.actions$
    .pipe(
      ofType(SampleBatchActions.Types.Update),
      mergeMap((action: SampleBatchActions.Update) => this.service.update(action.payload)
        .pipe(
          map((batch) => (new SampleBatchActions.UpdateSuccess(batch))),
          catchError((err: Error) => of(new SampleBatchActions.UpdateError(action.payload.id, err)))
        ))
    ));

  constructor(
    private actions$: Actions,
    private service: SampleBatchService
  ) { }
}
