<form [formGroup]="form" (submit)="triggerResetPassword()" autocomplete="off" id="triggerResetPasswordForm">
  <app-dialog class="short-dialog">
    <div dialog-title>Reset Password</div>
    <div dialog-content>

      <p *ngIf="!hasCode">Enter the email address you used to sign up. If an account is associated with it, an email will be sent to this address with a link to reset your account password.</p>

      <p *ngIf="hasCode">Please enter a new password for your account.</p>

      <!-- Email -->
      <mat-form-field *ngIf="!hasCode">
        <mat-label>Email Address</mat-label>
        <input matInput id="triggerResetPasswordForm_email" formControlName="email" type="email" />
        <mat-error *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <!-- Password -->
      <mat-form-field *ngIf="hasCode">
        <mat-label>Password</mat-label>
        <input matInput id="triggerResetPasswordForm_password" formControlName="password" type="password" (focus)="showPasswordHint = true" (blur)="showPasswordHint = false" />
        <mat-hint *ngIf="showPasswordHint">
          <a *ngIf="user && user?.role === 0; else passwordHint" (click)="generatePassword()">Generate password</a>
          <ng-template #passwordHint>Password must be longer than 4 characters and contain at least one number</ng-template>
        </mat-hint>
        <mat-error *ngIf="form.get('password').hasError('pattern') && !form.get('password').hasError('required')">
          Password is invalid
        </mat-error>
        <mat-error *ngIf="form.get('password').hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      
      <!-- Error -->
    </div>
    <mat-error dialog-error *ngIf="errorMsg">
      {{errorMsg}}
    </mat-error>
    <div dialog-actions class="btn-row pb-2">
      <button type="button" id="triggerResetPasswordForm_cancelButton" mat-stroked-button (click)="close()" class="mr-3">Cancel</button>
      <button type="submit" id="triggerResetPasswordForm_saveButton" mat-flat-button class="bg-success">{{hasCode ? 'Reset Password' : 'Send email'}}</button>
    </div>
  </app-dialog>
</form>
