import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, NavigationCancel } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { HistoryService } from './history.service';
import { LoggerService } from './logger.service';
import { MenuService } from './menu.service';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private busyTimer: any;
  public busy$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public clean$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private routeSubs: Subscription[] = [];

  section: string;

  constructor(
    private router: Router,
    private logger: LoggerService,
    private menuService: MenuService,
    private historyService: HistoryService
  ) {
    this.listenToRoutes();
  }

  /**
   * @method AppStateService.setBusy
   * @description Set the status of the app to (not) busy
   * @returns void
   */
  setBusy(busy: boolean = true, delay: number = 0): void {
    this.busyTimer && clearTimeout(this.busyTimer);
    this.busyTimer = setTimeout(() => {
      this.busy$.next(!!busy);
    }, delay);
  }

  listenToRoutes(): void {
    if (this.routeSubs) {
      this.routeSubs.forEach(s => s.unsubscribe());
      this.routeSubs = [];
    }
    this.routeSubs.push(
      this.router.events.pipe(
        tap(event => {
          if (event instanceof NavigationStart) {
            const url = event.url;
            const section = this.menuService.getLoc(url);
            if (section !== this.section) {
              this.setBusy(true);
              this.section = section;
            }
          }
          if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
            this.setBusy(false, 500);
          }
        }),
        filter(event => event instanceof NavigationEnd),
        debounceTime(500)
      ).subscribe((event: NavigationEnd) => {
        this.historyService.update(event.urlAfterRedirects);
      })
    );
  }

  /**
   * @method AppStateService.clean
   * @description Sends an event around the application so listener services can clear any stored data caches
   * @returns void
   */
  clean(): void {
    this.clean$.next(true);
  }
}
