import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isDefined, User, UserRole } from 'src/app/data.models';
import { AuthService, DialogService, LoggerService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class AuthDialogGuard implements CanActivate {
  public allowed: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private logger: LoggerService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const role: UserRole = typeof route.data.role !== 'undefined' ? route.data.role : UserRole.All;

    return this.checkUser(role).pipe(map((hasAccess: boolean) => {
      if (!hasAccess) {
        this.logger.warn("AuthDialogGuard: ACESSS DENIED")
        this.dialogService.open('not-allowed');
      }
      return hasAccess;
    }));
  }

  checkUser(role: UserRole): Observable<boolean> {
    //If we have a stored isLoggedIn status we have a user already so lets use that for our tests
    if (this.authService.isLoggedIn) {
      //Return an Observable with the result of whether the user has the correct access level
      return of(this.hasRole(this.authService.currentUser, role));
    }
    else {
      // Otherwise lets observer the authState in case a login is imminent
      return this.authService.user$.pipe(
        map((user:User) => {
          if (!user) {
            let queries;

            //Navigate to the login
            this.router.navigate(['/login'], queries);

            //Return false - the user is not logged in and has no access
            return false;
          }
          //Return the result of whether the user has the correct access level
          return this.hasRole(user, role);
        })
      );
    }
  }

  hasRole(user: User, role: UserRole) {
    return isDefined(user.role) && user.role <= role;
  }
}
