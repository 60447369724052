<app-dialog>
  <div dialog-title class="text-tiny">{{title}}</div>
  <div dialog-content>
    <p class="text-center">Once you confirm, this action cannot be undone.</p>
    <mat-error *ngIf="errorMsg" class="text-center d-block">{{errorMsg}}</mat-error>
  </div>
  <div dialog-actions>
    <button type="button" id="deleteUserComponent_cancelButton" mat-stroked-button (click)="close()" class="mr-3">Cancel</button>
    <button type="button" id="deleteUserComponent_saveButton" mat-flat-button (click)="deleteUser()" color="warn">Delete User</button>
  </div>
</app-dialog>
