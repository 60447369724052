import { Injectable, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { copyArray, extendObj } from '../data.models';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    @Inject(DOCUMENT) private document?: Document
  ) { }

  public copyToClipboard(value: string) {
    if (window.navigator && window.navigator.clipboard && window.navigator.clipboard.writeText) {
      try {
        window.navigator.clipboard.writeText(value);
        return;
      }
      catch (e) { }
    }
    if (this.document) {
      /* Get the text field */
      var input = this.document.createElement("textarea");
      input.value = value;
      input.style.opacity = "0";
      input.style.position = "absolute";
      input.style.left = "-9999px";

      this.document.body.append(input);

      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      this.document.execCommand("copy");

      this.document.body.removeChild(input);
    }
  }

  public isExpired(then: number, mins: number = 10, hours: number = 1): boolean {
    const now = new Date().getTime();
    const expired = 1000 * 60 * (mins || 1) * (hours || 1);
    return (now - then) > expired;
  }

  public throttle(method: Function, prevThrottle: any, freq: number = 500, context?: any, ...args): any {
    if (prevThrottle) {
      clearTimeout(prevThrottle);
    }
    return setTimeout(() => {
      method.call(context || this, ...args);
    }, freq);
  }

  public fixDigit(input: number, count: number = 2): string {
    return ('000' + input).slice(-count);
  }

  public pluralise(str: string, length: number, suffix: string = 's'): string {
    return str + (length > 1 ? suffix : '')
  }

  public markFormGroupTouched(formGroup: FormGroup): any {
    (<any>Object).values(formGroup.controls).forEach((control: FormGroup) => {
      if (control.markAsTouched) {
        control.markAsTouched();
        control.markAsDirty();
        control.updateValueAndValidity();
      }

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public markFormGroupUntouched(formGroup: FormGroup): any {
    (<any>Object).values(formGroup.controls).forEach((control: FormGroup) => {
      if (control.markAsUntouched) {
        control.markAsUntouched();
        control.markAsPristine();
        control.updateValueAndValidity();
      }

      if (control.controls) {
        this.markFormGroupUntouched(control);
      }
    });
  }

  public getISODateString(date: Date): string {
    //To convert to format 'yyyy-mm-dd' for date-input element we first need to account for the GMT offset before converting to an ISO date
    const offset = date.getTimezoneOffset();
    return new Date(date.getTime() - (offset * 60 * 1000)).toISOString();
  }

  public testDates(startDate: string, endDate: string, dateFormat: RegExp = /\d\d\d\d-\d\d-\d\d/, range: number = 31): { passed: boolean; error: Error; } {
    dateFormat = dateFormat || /\d\d\d\d-\d\d-\d\d/;
    const tests = {
      passed: false,
      error: null
    };
    const day = 1000 * 60 * 60 * 24;
    if (!endDate) {
      tests.error = new Error("No end date supplied!");
    }
    else if (!startDate) {
      tests.error = new Error("No start date supplied!");
    }
    else if (!dateFormat.test(startDate) || !dateFormat.test(endDate)) {
      tests.error = new Error("Wrong date format supplied. Expected 'yyyy-mm-dd' but received start date: " + startDate + ", and end date: " + endDate + ".");
    }
    else if (new Date(endDate).getTime() < new Date(startDate).getTime()) {
      tests.error = new Error("End date cannot be earlier than start date.");
    }
    else if (range && new Date(endDate).getTime() - new Date(startDate).getTime() > (day * range)) {
      tests.error = new Error("Date range cannot be more than 31 days.");
    }
    else {
      tests.passed = true;
    }
    return tests;
  }

  public getPropFromString<T = any>(obj: any, prop: string, defaultValue?: T): T {
    if (prop) {
      const fallback = typeof defaultValue === "undefined" ? "" : defaultValue;
      const alias: string[] = prop.split('.');
      return alias.reduce((data, prop) => (typeof data[prop] !== "undefined" && data[prop] !== null ? data[prop] : fallback), obj) as T;
    }
    return obj;
  }

  public convertToParams(obj: any): { [key: string]: string; } {
    let params = {};
    for (let prop in obj) {
      if (typeof obj[prop] !== "undefined" && obj[prop] !== null) {
        params[prop] = '' + obj[prop];
      }
    }
    return params;
  }

  public extendObj<T = any>(...args: any[]): T;
  public extendObj<T = any>(deep: boolean, ...args: any[]): T;
  public extendObj<T = any>(...args: any[]): T {
    return extendObj(...args);
  }

  public copyArray(arr: any[]) {
    let copy = [];
    for (let i in arr) {

      if (Object.prototype.toString.call(arr[i]) === '[object Object]') {
        copy[i] = this.extendObj(true, {}, arr[i]);
      }
      else if (Object.prototype.toString.call(arr[i]) === '[object Array]') {
        copy[i] = this.copyArray(arr[i]);
      }
      else {
        copy[i] = arr[i];
      }
    }
    return copy;
  }

  public copyObj<T = any>(obj: T): T;
  public copyObj<T = any>(deep: boolean, obj: T): T;
  public copyObj<T = any>(...args): T {
    let deep = false;
    let extendible = args[0];
    if (Object.prototype.toString.call(args[0]) === '[object Boolean]') {
      deep = args[0];
      extendible = args[1];
    }
    return this.extendObj<T>(deep, {}, extendible);
  }

  public combineArrays<T = any>(arrays: Array<T[]>): Array<T[]> {

    // First, handle some degenerate cases...

    if (!arrays) {
      // Or maybe we should toss an exception...?
      return [];
    }

    if (!Array.isArray(arrays)) {
      // Or maybe we should toss an exception...?
      return [];
    }

    if (arrays.length == 0) {
      return [];
    }

    for (let i = 0; i < arrays.length; i++) {
      if (!Array.isArray(arrays[i]) || arrays[i].length == 0) {
        // If any of the arrays in array_of_arrays are not arrays or zero-length, return an empty array...
        return [];
      }
    }

    // Done with degenerate cases...

    // Start "odometer" with a 0 for each array in array_of_arrays.
    let tracker = new Array(arrays.length);
    tracker.fill(0);

    let output = [];

    let newCombination = this.formCombination(tracker, arrays);

    output.push(newCombination);

    while (this.tracker_increment(tracker, arrays)) {
      newCombination = this.formCombination(tracker, arrays);
      output.push(newCombination);
    }

    return output;
  }

  // Translate "odometer" to combinations from array_of_arrays
  private formCombination<T = any>(tracker: number[], arrays: Array<T[]>): T[] {
    // In Imperative Programmingese (i.e., English):
    // let s_output = "";
    // for( let i=0; i < odometer.length; i++ ){
    //    s_output += "" + array_of_arrays[i][odometer[i]]; 
    // }
    // return s_output;

    // In Functional Programmingese (Henny Youngman one-liner):
    return tracker.reduce<T[]>((accumulator: T[], value, index) => {
      accumulator.push(arrays[index][value]);
      return accumulator;
    }, []);
  }

  private tracker_increment<T = any>(tracker: number[], arrays: Array<T[]>): boolean {

    // Basically, work your way from the rightmost digit of the "tracker"...
    // if you're able to increment without cycling that digit back to zero,
    // you're all done, otherwise, cycle that digit to zero and go one digit to the
    // left, and begin again until you're able to increment a digit
    // without cycling it

    for (let i = tracker.length - 1; i >= 0; i--) {

      let maxee = arrays[i].length - 1;

      if (tracker[i] + 1 <= maxee) {
        // increment, and you're done...
        tracker[i]++;
        return true;
      }
      else {
        if (i - 1 < 0) {
          // No more digits left to increment, end of the line...
          return false;
        }
        else {
          // Can't increment this digit, cycle it to zero and continue
          // the loop to go over to the next digit...
          tracker[i] = 0;
          continue;
        }
      }
    }

  }

  wait(testFn: () => boolean, sub?: BehaviorSubject<boolean>, count: number = 0) {
    let test = testFn();
    const maxRetry = 10;
    const wait = 2000;

    const subject = sub || new BehaviorSubject<boolean>(test);
    if (test) {
      subject.next(true);
    }
    else if (count < maxRetry) {
      setTimeout(() => {
        this.wait(testFn, subject, ++count);
      }, wait);
    }
    else {
      const msg = "Waited " + (wait * count) + "ms. Exiting without loading.";
      console.warn(msg);
      subject.error(new Error(msg));
    }
    return subject.asObservable();
  }
}
