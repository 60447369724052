import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Campaign, CampaignBase, CampaignStats, CampaignStatsBase } from 'src/app/components/recruit/recruit.models';
import { FavoritesTypes } from 'src/app/data.models';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { LoggerService } from 'src/app/services/logger.service';
import * as Actions from 'src/app/store/recruit/recruit-campaign.actions';
import { IRecruitStore } from 'src/app/store/recruit/recruit.store';
import { UtilService } from '../../util.service';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class RecruitCampaignService {
  private tempCampaign$: ReplaySubject<Campaign> = new ReplaySubject(1);
  private _tempCampaign: Campaign;
  get tempCampaign(): Observable<Campaign> {
    return this.tempCampaign$.asObservable();
  }
  setTempCampaign(campaign: Campaign) {
    this._tempCampaign = new Campaign(this.util.copyObj(true, campaign.asDataObj));
    this.tempCampaign$.next(this._tempCampaign);
  }

  private endpoint: string = 'campaigns';
  private campaign_stats_queue: { [id: string]: Subscription; } = {};
  private campaigns_requested: { [id: string]: boolean; } = {};

  constructor(
    private store: Store<{ recruit: IRecruitStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
    private panelAdminService: PanelAdminService,
    private util: UtilService
  ) { }

  public getStats(id: string, force?: boolean): Observable<CampaignStats> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return state.recruit.campaigns.stats[id];
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      switchMap((stats) => {
        if (!this.campaign_stats_queue[id] && (force || !stats || stats.isExpired)) {
          force = false;
          return this.panelAdminService.getPanelAdminUrl().pipe(
            switchMap(panelAdminUrl =>
              this.api.get<CampaignStatsBase>(this.endpoint + '/stats/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
                switchMap((stats) => {
                  if (stats) {
                    stats.id = id;
                    this.store.dispatch(new Actions.GetStatsSuccess(id, stats));
                  }
                  return store_ref;
                }),
                catchError((err: any) => {
                  this.store.dispatch(new Actions.GetStatsError(id, err));
                  return of(null);
                })
              )
            )
          );
          //this.campaign_stats_queue[id] = obs.subscribe(
          //  () => {
          //    if (this.campaign_stats_queue[id]) {
          //      this.campaign_stats_queue[id].unsubscribe();
          //      delete this.campaign_stats_queue[id];
          //    }
          //  }
          //);
        }
        return of(stats);
      }),
      catchError(() => of(null))
    );
  }

  /**
   * @method RecruitCampaignService.create
   * @description Saves a new Campaign to the server
   * @param {Campaign} campaign The new Campaign to be saved
   * @returns Observable<Campaign>
   */
  public create(campaign: Campaign): Observable<Campaign> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<CampaignBase>(this.endpoint, campaign.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((c) => {
          const campaign = new Campaign(c);
          this.store.dispatch(new Actions.CreateSuccess(campaign));
          return campaign;
        }),
        switchMap((p) => {
          return this.favoritesService.addFavorite(FavoritesTypes.RecruitCampaigns, p.id).pipe(map(() => p));
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.CreateError(err));
          return this.panelAdminService.handleError(err);
        })
      )
    ));
  }

  /**
   * @method RecruitCampaignService.getAll
   * @description Retrieves a list of all Campaigns in the Sample API
   * @returns Observable<Campaign[]>
   */
  public getAll(archive?: boolean, force?: boolean): Observable<Campaign[]> {
    const store_ref: Observable<Campaign[]> = this.store.pipe(
      select(state => {
        try {
          if (state.recruit && state.recruit.campaigns) {
            return state.recruit.campaigns.data;
          }
          return null;
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      switchMap((stored_campaigns) => {
        const id = "all";
        if (!this.campaigns_requested[id] && (force || !stored_campaigns || stored_campaigns.length < 5)) {
          force = false;
          this.campaigns_requested[id] = true;
          return this.panelAdminService.getPanelAdminUrl().pipe(
            switchMap(panelAdminUrl =>
              this.api.get<CampaignBase[]>(this.endpoint, { filters: `{ "archived": "${!!archive}" }` }, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
                switchMap((resp: CampaignBase[]) => {
                  if (resp && resp.length) {
                    const campaigns = resp.map((c: CampaignBase) => new Campaign(c));
                    this.store.dispatch(new Actions.GetAllSuccess(campaigns));
                  }
                  this.campaigns_requested[id] = false;
                  return store_ref;
                }),
                catchError((err: any) => {
                  this.store.dispatch(new Actions.GetAllError(err));
                  this.campaigns_requested[id] = false;
                  return this.panelAdminService.handleError(err);
                })
              )
            ), catchError(() => of(null)));
        }
        return of(stored_campaigns);
      })
    );
  }

  /**
   * @method RecruitCampaignService.getByID
   * @description Retrieves a Campaign for a given ID
   * @param {string} id The ID of the Campaign to be retrieved
   * @returns Observable<Campaign>
   */
  public getByID(id: string | number, force?: boolean): Observable<Campaign> {
    const store_ref = this.store.pipe(
      select(state => {
        try {
          return state.recruit.campaigns.map[id];
        }
        catch (e) {
          this.logger.warn(e);
          return null;
        }
      })
    );
    return store_ref.pipe(
      switchMap((stored_campaign) => {
        if (!this.campaigns_requested[id] && (force || !stored_campaign)) {
          force = false;
          this.campaigns_requested[id] = true;
          return this.panelAdminService.getPanelAdminUrl().pipe(
            switchMap(panelAdminUrl =>
              this.api.get<CampaignBase>(this.endpoint + '/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
                switchMap((c) => {
                  if (c) {
                    const campaign = new Campaign(c);
                    this.store.dispatch(new Actions.GetSuccess(campaign));
                  }
                  this.campaigns_requested[id] = false;
                  return store_ref;
                }),
                catchError((err: any) => {
                  this.store.dispatch(new Actions.GetError(id + "", err));
                  this.campaigns_requested[id] = false;
                  return this.panelAdminService.handleError(err);
                })
              )
            ),
            catchError(() => of(null))
          );
        }
        return of(stored_campaign);
      })
    );
  }

  /**
   * @method RecruitCampaignService.update
   * @description Updates a Campaign on the server
   * @returns Observable<Campaign>
   */
  public update(id: string, campaign: CampaignBase, action?: string): Observable<Campaign> {
    const url = this.endpoint + '/' + (action ? action + '/' : '') + id;
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(url, JSON.stringify({ data: campaign }), false, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((resp: CampaignBase) => {
          const campaign = new Campaign(resp);
          this.store.dispatch(new Actions.UpdateSuccess(campaign));
          return campaign;
        }),
        catchError((err: any) => {
          this.store.dispatch(new Actions.UpdateError(campaign.id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method RecruitCampaignService.archive
   * @description Archives a Campaign from the server, by setting it's 'archived' property to 'true'
   * @returns Observable<Campaign>
   */
  public archive(id: string): Observable<Campaign> {
    return this.update(id, null, 'archive');
  }

  /**
   * @method RecruitCampaignService.unarchive
   * @description Unarchives a Campaign on the server, by setting it's 'archived' property to 'false'
   * @returns Observable<Campaign>
   */
  public unarchive(id: string): Observable<Campaign> {
    return this.update(id, null, 'unarchive');
  }

  /**
   * @method RecruitCampaignService.update_notes
   * @description Updates a Campaign's `notes` property on the server
   * @returns Observable<Campaign>
   */
  public update_notes(id: string, notes: string): Observable<Campaign> {
    return this.update(id, { notes }, 'notes');
  }

  /**
   * @method RecruitCampaignService.reopen
   * @description Updates a Campaign to `open: true` on the server
   * @returns Observable<Campaign>
   */
  public reopen(id: string): Observable<Campaign> {
    return this.update(id, null, 'reopen');
  }

  /**
   * @method RecruitCampaignService.close
   * @description Updates a Campaign to `open: false` on the server
   * @returns Observable<Campaign>
   */
  public close(id: string): Observable<Campaign> {
    return this.update(id, null, 'close');
  }

}
