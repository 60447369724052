import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as Models from 'src/app/data.models';
import { RewardModels } from 'src/app/data.models';
import * as RewardProviderActions from 'src/app/store/reward/reward-provider.actions';
import { ApiService } from '../api.service';
import { LoggerService } from '../logger.service';

@Injectable({
  providedIn: 'root'
})
export class RewardProviderService {

  private endpoint: string = 'provider';

  constructor(
    private store: Store<{ reward: Models.RewardModels.IRewardStore }>,
    private api: ApiService,
    private logger: LoggerService,
  ) {}

  /**
   * @method RewardProviderService.create
   * @description Saves a new RewardProvider to the server
   * @param {RewardModels.RewardProvider} provider The new RewardProvider to be saved
   * @returns Observable<RewardModels.RewardProvider>
   */
  public create(provider: RewardModels.RewardProvider): Observable<RewardModels.RewardProvider> {
    const url = this.endpoint;
    return this.api.post<{ provider: RewardModels.RewardProviderBase }>(url, provider.asDataObj).pipe(
      map((p) => {
        if (p) {
          const rewardProvider = new RewardModels.RewardProvider(p.provider);
          this.store.dispatch(new RewardProviderActions.CreateSuccess(rewardProvider));
          return rewardProvider;
        }
      }),
      catchError((err: Error) => {
        this.store.dispatch(new RewardProviderActions.CreateError(err));
        return throwError(new Error(err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
      })
    );
  }

  /**
   * @method RewardProviderService.getAll
   * @description Retrieves a list of all RewardProviders in the Sample API
   * @returns Observable<RewardModels.RewardProvider[]>
   */
  public getAll(archive?: boolean): Observable<RewardModels.RewardProvider[]> {
    const url = this.endpoint;
    return this.api.get<RewardModels.RewardProviderBase[]>(url, archive ? { Filter: 'all' } : {}).pipe(
      map((resp: any) => resp.Items.map((p: RewardModels.RewardProviderBase) => new RewardModels.RewardProvider(p))),
      catchError((resp: Error ) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardProviderService.getByID
   * @description Retrieves a RewardProvider for a given ID
   * @param {string} id The ID of the RewardProvider to be retrieved
   * @returns Observable<RewardModels.RewardProvider>
   */
  public getByID(id: string): Observable<RewardModels.RewardProvider> {
    const url = this.endpoint;
    return this.api.get<RewardModels.RewardProviderBase>(url, { id }).pipe(
      map((reward) => new RewardModels.RewardProvider(reward)),
      catchError((resp: Error ) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardProviderService.getEmbedCode
   * @description Retrieves the Embed Code for a RewardProvider
   * @param {string} id The ID of the RewardProvider to be retrieved
   * @returns Observable<RewardModels.RewardProvider>
   */
  public getEmbedCode(id: string): Observable<string> {
    const url = this.endpoint;
    return this.api.get(url, { id }, null, 'all').pipe(
      map((resp: { body: { embedCode: string } }) => resp && resp.body && resp.body.embedCode),
      catchError((resp: Error ) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardProviderService.update
   * @description Updates a RewardProvider on the server
   * @returns Observable<RewardModels.RewardProvider>
   */
  public update(provider: RewardModels.RewardProvider): Observable<RewardModels.RewardProvider> {
    const url = this.endpoint;
    return this.api.put<RewardModels.RewardProviderBase>(url, provider.asDataObj).pipe(
      map((p) => {
        const provider = new RewardModels.RewardProvider(p)
        this.store.dispatch(new RewardProviderActions.UpdateSuccess(provider));
        return provider;
      }),
      catchError((err: Error) => {
        this.store.dispatch(new RewardProviderActions.UpdateError(provider.id, err));
        return throwError(new Error(err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
      })
    );
  }

  /**
   * @method RewardProviderService.archive
   * @description Archives a RewardProvider from the server, by setting it's 'archived' property to 'true'
   * @returns Observable<RewardModels.RewardProvider>
   */
  public remove(id: string): Observable<boolean> {
    const url = this.endpoint;
    return this.api.delete(url, { id }).pipe(
      map((p) => {
        this.store.dispatch(new RewardProviderActions.DeleteSuccess(id));
        return true;
      }),
      catchError((resp: Error ) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }
}
