import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import 'firebase/firestore';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AccessToken, CommunityMember, CommunityMemberBase, CommunityMemberPagerFilters, MemberProfile, MemberStats, MiscPayout } from 'src/app/components/community/community.models';
import { FavoritesTypes, PagedHttpResponse, PagerConfig } from 'src/app/data.models';
import * as MemberActions from 'src/app/store/community/community-member.actions';
import { ICommunityStore } from 'src/app/store/community';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Pager } from 'src/app/services/paging.service';
import { PanelAdminService } from '../panel-admin.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityMemberService {

  private endpoint: string = 'user_management';

  constructor(
    private store: Store<{ community: ICommunityStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method CommunityMemberService.create
   * @description Saves a new CommunityMember to the server
   * @param {CommunityMember} member The new CommunityMember to be saved
   * @returns Observable<CommunityMember>
   */
  public create(member: CommunityMember): Observable<CommunityMember> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<CommunityMemberBase>(this.endpoint, member.asDataObj, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t) => {
          const storedProduct = new CommunityMember(t)
          this.store.dispatch(new MemberActions.CreateSuccess(storedProduct));
          return storedProduct;
        }),
        switchMap((p) => {
          return this.favoritesService.addFavorite(FavoritesTypes.ProductTestsTest, p.id).pipe(map(() => p));
        }),
        catchError((resp: any) => {
          this.store.dispatch(new MemberActions.CreateError(resp));
          return this.panelAdminService.handleError(resp);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityMemberService.getAll
   * @description Retrieves a list of all CommunityMembers in the Sample API
   * @returns Observable<CommunityMember[]>
   */
  public getAll(params?: string): Observable<PagedHttpResponse<CommunityMember>> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get(this.endpoint + (params || ''), {}, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        map((resp: PagedHttpResponse<CommunityMemberBase>) => {
          resp.data = resp.data.map((m: CommunityMemberBase) => new CommunityMember(m));
          return resp;
        }),
        catchError((resp: any) => this.panelAdminService.handleError(resp, true))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityMemberService.getByID
   * @description Retrieves a CommunityMember for a given ID
   * @param {string} id The ID of the CommunityMember to be retrieved
   * @returns Observable<CommunityMember>
   */
  public getByID(id: string, redirectOnError: boolean = true): Observable<CommunityMember> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<CommunityMemberBase>(this.endpoint + '/manage/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((r) => {
          return new CommunityMember(r);
        }),
        catchError((resp: any) => redirectOnError ? this.panelAdminService.handleError(resp) : of(null))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityMemberService.getProfile
   * @description Retrieves a full profile for a given Member
   * @param {string} id The ID of the CommunityMember's profile  to be retrieved
   * @returns Observable<MemberProfile>
   */
  public getProfile(id: string): Observable<MemberProfile> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<string>(this.endpoint + '/profile/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityMemberService.getProfileMini
   * @description Retrieves a short string unique for a given ID
   * @param {string} id The ID of the CommunityMember to be retrieved
   * @returns Observable<string>
   */
  public getProfileMini(id: string): Observable<string> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<string>(this.endpoint + '/profile_mini/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityMemberService.getStats
   * @description Retrieves a set of stats for a given Member ID
   * @param {string} id The ID of the CommunityMember whose stats are to be retrieved
   * @returns Observable<MemberStats>
   */
  public getStats(id: string): Observable<MemberStats> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<string>(this.endpoint + '/invitation_stats/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        catchError((resp: any) => this.panelAdminService.handleError(resp))
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityMemberService.member_access_token
   * @description Creates a new AccessToken for the given member
   * @param {string} id The ID of the CommunityMember to which the new token will belong
   * @returns Observable<AccessToken>
   */
  public createAccessToken(id: string): Observable<CommunityMember> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<AccessToken>(this.endpoint + '/member_access_token/' + id, null, true, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((t) => {
          this.store.dispatch(new MemberActions.Get("" + id));
          return t;
        }),
        catchError((resp: any) => {
          return this.panelAdminService.handleError(resp);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityMemberService.update
   * @description Updates a CommunityMember on the server
   * @returns Observable<CommunityMember>
   */
  public update(id: string, data?: any): Observable<any> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/' + id, (data ? JSON.stringify({ data }) : null), false, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        map((resp: { data: CommunityMemberBase }) => {
          if (resp.data) {
            const member = new CommunityMember(resp.data);
            this.store.dispatch(new MemberActions.UpdateSuccess(member));
            return member;
          }
          return resp;
        }),
        catchError((err: any) => {
          this.store.dispatch(new MemberActions.UpdateError(id, err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityMemberService.updateViaAction
   * @description Updates a CommunityMember on the server with a specific action
   * @returns Observable<CommunityMember>
   */
  private updateViaAction(id: string, action: string, data?: any, showErrors?: boolean): Observable<any> {
    if (!action) {
      return throwError(new Error("Invalid action"));
    }
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.patch<any>(this.endpoint + '/' + action + (id ? '/' + id : ''), (data ? JSON.stringify(data) : null), false, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        map((resp: { data: CommunityMemberBase }) => {
          if (resp.data && resp.data.id && (resp.data.id + "") === id) {
            const member = new CommunityMember(resp.data);
            this.store.dispatch(new MemberActions.UpdateSuccess(member));
            return member;
          }
          return resp;
        }),
        catchError((err: any) => {
          this.store.dispatch(new MemberActions.UpdateError(id, err));
          return (showErrors ? this.panelAdminService.handleError(err) : of(null));
        })
      )
    ));
  }

  /**
   * @method CommunityMemberService.update_notes
   * @description Updates the value of CommunityMember.notes on the server
   * @returns Observable<CommunityMember>
   */
  update_notes(id: string | number, notes: string) {
    return this.updateViaAction(id + "", "update_notes", { data: { notes } });
  }

  /**
   * @method CommunityMemberService.update_default_reward_email
   * @description Sets CommunityMember.default_reward_email to the new email address on the server
   * @returns Observable<CommunityMember>
   */
  update_default_reward_email(id: string | number, default_reward_email: string) {
    return this.updateViaAction(id + "", "update_default_reward_email", { default_reward_email });
  }

  /**
   * @method CommunityMemberService.reset_phone_attempts
   * @description Sets CommunityMember.email_accounts[x].dropped to false on the server
   * @returns Observable<CommunityMember>
   */
  reset_phone_attempts(id: string | number) {
    return this.updateViaAction(id + "", "reset_phone_attempts");
  }

  /**
   * @method CommunityMemberService.undrop_email_account
   * @description Sets CommunityMember.email_accounts[x].dropped to false on the server
   * @returns Observable<CommunityMember>
   */
  undrop_email_account(id: string | number) {
    return this.updateViaAction(id + "", "undrop_email_account");
  }

  /**
   * @method CommunityMemberService.unremove
   * @description Sets CommunityMember.removed to false on the server
   * @returns Observable<CommunityMember>
   */
  public unremove(id: string | number) {
    return this.updateViaAction(id + "", "unremove");
  }

  /**
   * @method CommunityMemberService.remove
   * @description Sets CommunityMember.removed to true on the server
   * @returns Observable<CommunityMember>
   */
  public remove(id: string | number) {
    return this.updateViaAction(id + "", "remove");
  }

  /**
   * @method CommunityMemberService.toggle_product_tests
   * @description Toggles the CommunityMember.product_test_approved on the server
   * @returns Observable<CommunityMember>
   */
  public toggle_product_tests(id: string | number) {
    return this.updateViaAction(id + "", "toggle_product_tests");
  }

  /**
   * @method CommunityMemberService.toggle_pollfish
   * @description Toggles the CommunityMember.pollfish_approved on the server
   * @returns Observable<CommunityMember>
   */
  public toggle_pollfish(id: string | number) {
    return this.updateViaAction(id + "", "toggle_pollfish");
  }

  /**
   * @method CommunityMemberService.toggle_inbrain
   * @description Toggles the CommunityMember.toggle_inbrain on the server
   * @returns Observable<CommunityMember>
   */
  public toggle_inbrain(id: string | number) {
    return this.updateViaAction(id + "", "toggle_inbrain");
  }

  /**
   * @method CommunityMemberService.toggle_partner_surveys
   * @description Toggles the CommunityMember.partner_survey_approved on the server
   * @returns Observable<CommunityMember>
   */
  public toggle_partner_surveys(id: string | number) {
    return this.updateViaAction(id + "", "toggle_partner_surveys");
  }

  /**
   * @method CommunityMemberService.removeAll
   * @description Sets multiple CommunityMember.removed to true on the server
   * @returns Observable<CommunityMember>
   */
  public removeAll(ids: string[]): Observable<{ message?: string, status?: string, code?: number }> {
    return this.updateViaAction(null, "remove_all", { user_ids: ids });
  }

  /**
   * @method CommunityMemberService.opt_out
   * @description Sets CommunityMember.opt_out to true on the server
   * @returns Observable<CommunityMember>
   */
  public opt_out(id: string | number) {
    return this.updateViaAction(id + "", "opt_out");
  }

  /**
   * @method CommunityMemberService.unopt_out
   * @description Sets CommunityMember.opt_out to false on the server
   * @returns Observable<CommunityMember>
   */
  public unopt_out(id: string | number) {
    return this.updateViaAction(id + "", "unopt_out");
  }

  /**
   * @method CommunityMemberService.hold
   * @description Sets CommunityMember.hold to true on the server
   * @returns Observable<CommunityMember>
   */
  public hold(id: string | number) {
    return this.updateViaAction(id + "", "place_on_hold");
  }

  /**
   * @method CommunityMemberService.release
   * @description Sets CommunityMember.hold to false on the server
   * @returns Observable<CommunityMember>
   */
  public release(id: string | number) {
    return this.updateViaAction(id + "", "release");
  }

  /**
   * @method CommunityMemberService.unvalidate
   * @description Sets CommunityMember.validate to false on the server
   * @returns Observable<CommunityMember>
   */
  public unvalidate(id: string | number) {
    return this.updateViaAction(id + "", "unvalidate");
  }

  /**
   * @method CommunityMemberService.validate
   * @description Sets CommunityMember.validate to true on the server
   * @returns Observable<CommunityMember>
   */
  public validate(id: string | number) {
    return this.updateViaAction(id + "", "validate");
  }

  /**
   * @method CommunityMemberService.deliver_pending_rewards
   * @description Delivers pending rewards to the member
   * @returns Observable<CommunityMember>
   */
  public deliver_pending_rewards(id: string | number) {
    return this.updateViaAction(id + "", "deliver_pending_rewards");
  }

  /**
   * @method CommunityMemberService.deliver_reward
   * @description Delivers reward to the member
   * @returns Observable<CommunityMember>
   */
  public deliver_reward(id: string | number) {
    return this.updateViaAction(id + "", "deliver_reward", null, true);
  }

  /**
   * @method CommunityMemberService.misc_payout
   * @description misc_payout
   * @returns Observable<{ message?: string, status?: string, code?: number }>
   */
  public misc_payout(id: string | number, payout: MiscPayout): Observable<{ message?: string, status?: string, code?: number }> {
    return this.updateViaAction(id + "", "misc_payout", payout).pipe(tap(() => this.store.dispatch(new MemberActions.Get(id + ""))));
  }

  /**
   * @method CommunityMemberService.invite_user
   * @description Invite the user to take the given survey
   * @returns Observable<CommunityMember>
   */
  public invite_user(id: string | number, survey_id: number, invite_id?: number): Observable<CommunityMember> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.post<any>(this.endpoint + '/invite_user/' + id, { ...(survey_id ? { survey_id } : {}), ...(invite_id ? { id: invite_id } : {}) }, null, panelAdminUrl, 'body', this.panelAdminService.options, false).pipe(
        map((resp: CommunityMemberBase) => {
          if (resp) {
            const member = new CommunityMember(resp);
            this.store.dispatch(new MemberActions.UpdateSuccess(member));
            return member;
          }
          return resp;
        }),
        catchError((err: any) => {
          this.store.dispatch(new MemberActions.UpdateError(id + "", err));
          return this.panelAdminService.handleError(err);
        })
      )
    ), catchError(() => of(null)));
  }

  /**
   * @method CommunityMemberService.redeem_bank
   * @description redeem_bank
   * @returns Observable<{ message?: string, status?: string, code?: number }>
   */
  public redeem_bank(id: string | number, redemption: { type: string; recipient_email: string; }): Observable<{ message?: string, status?: string, code?: number }> {
    return this.updateViaAction(id + "", "redeem_bank", redemption, true);
  }

  /**
   * @method CommunityMemberService.releaseAll
   * @description Sets multiple CommunityMember.removed to true on the server
   * @returns Observable<{ message?: string, status?: string, code?: number }>
   */
  public releaseAll(ids: string[]): Observable<{ message?: string, status?: string, code?: number }> {
    return this.updateViaAction(null, "release_all", { user_ids: ids });
  }

  /**
   * @method CommunityMemberService.lookup
   * @description Lookup a CommunityMember on the server by email, phone, pid, auth token, user id, invitation id, or ip address
   * @returns Observable<CommunityMember>
   */
  public lookup(search: string): Observable<CommunityMember> {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.get<CommunityMemberBase>(this.endpoint + '/lookup', { lookup: search }, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((r) => {
          const member = new CommunityMember(r);
          this.store.dispatch(new MemberActions.GetSuccess(member));
          return member;
        }),
        catchError((resp: any) => {
          if (resp.status === "failed") {
            return throwError(new Error(resp.message));
          }
          return this.panelAdminService.handleError(resp)
        })
      )
    ), catchError(() => of(null)));
  }

  deleteFlag(id: string) {
    return this.panelAdminService.getPanelAdminUrl().pipe(switchMap(panelAdminUrl =>
      this.api.delete('/flags/' + id, null, panelAdminUrl, null, this.panelAdminService.options, false).pipe(
        map((r) => {
          return true;
        }),
        catchError((resp: any) => this.panelAdminService.handleError(resp))
      )
    ), catchError(() => of(null)));
  }


  private currentPager: Pager;
  public getPager(config?: PagerConfig, filters?: CommunityMemberPagerFilters): Pager {
    return this.currentPager = new Pager(this.getAll, this, filters, config);
  }

}
