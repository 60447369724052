import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DialogService, LoggerService } from 'src/app/services';


@Component({
  selector: 'app-login',
  template: ''
})
export class LoginComponent {

  constructor(
    private dialog: MatDialog,
  ) {
    this.openDialog()
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginDialog, {
      data: {}
    });
  }
}

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login.component.html'
})
export class LoginDialog implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public loginForm: any;
  public errorMsg: string;

  constructor(
    private dialogService: DialogService,
    private logger: LoggerService,
    public dialogRef: MatDialogRef<LoginDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public setBusy(busy: boolean) {
    this.dialogService.setBusy(busy);
  }

  public onSuccess(success: boolean) {
    success && this.close();
  }

  public onError(error: string) {
    this.errorMsg = error;
  }

  public close(): void {
    this.dialogRef.close();
    this.dialogService.close();
  }

  ngOnInit() {
    this.subs.push(
      //NB: We use beforeClose here, because afterClose triggers after the component is destoryed and then the subscriptions get wiped.
      this.dialogRef.beforeClosed().subscribe(
        resp => {
          this.close();
        },
        err => {
          this.logger.error(err);
        }
      ));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
