import * as Reducers from '../reducers';
import * as Actions from './profile-question.actions';
import { extendObj, Question } from '../../data.models';

export interface State extends Reducers.State<Question> {
  mapByName: { [name: string]: Question };
  requestedAllAt?: number;
}

export const initialState: State = {
  data: null, archive: null, count: 0, map: {}, mapByName: {}, errors: {},
  requestedAllAt: 0
};

export function profileQuestionReducer(state: State = initialState, action: Actions.Any): State {

  const newState: State = extendObj(true, {}, state);
  let id = "";
  switch (action.type) {

    case Actions.Types.GetSuccess:
    case Actions.Types.UpdateSuccess:
      newState.errors = { ...newState.errors, ...(action.payload ? { [action.payload.id || ""]: null } : {}) };
    case Actions.Types.CreateSuccess:
      newState.map = { ...newState.map, ...(action.payload ? { [action.payload.id || ""]: action.payload } : {}) };
      newState.mapByName = { ...newState.mapByName, [action.payload.name]: action.payload };
      newState.errors = { ...newState.errors, create: null };
      newState.data = Reducers.orderData(newState, [(q) => new Date(q.createdAt || null)]);
      break;


    case Actions.Types.GetAllSuccess:
      newState.requestedAllAt = new Date().getTime();
      newState.map = Reducers.map(action.payload);
      newState.mapByName = Reducers.map(action.payload, "name");
      newState.data = Reducers.orderData(newState, [(q) => new Date(q.createdAt || null)]);
      newState.errors = { ...newState.errors, all: null };
      break;

    case Actions.Types.DeleteSuccess:
      newState.data = (state.data || []).filter((question) => question.id !== action.id);
      newState.map = Reducers.map(newState.data);
      newState.mapByName = Reducers.map(newState.data, "name");
      break;
      
    case Actions.Types.GetAllError:
      newState.map = {};
      newState.mapByName = {};
      newState.data = [];
    case Actions.Types.CreateError:
    case Actions.Types.GetError:
    case Actions.Types.DeleteError:
    case Actions.Types.UpdateError:
      console.log(action)
      newState.errors = {
        ...state.errors,
        [action.type === Actions.Types.GetAllError ? 'all'
         : action.type === Actions.Types.CreateError ? 'create'
         : action.id]: action.error
      };
      break;

    case Actions.Types.Clear:
      return initialState;

    default:
      return state;

  }
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  !!newState.data && newState.data.length || (newState.data = null);
  return newState;
}
