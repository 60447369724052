import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from '../../pipes';
import { OverflowShadowDirective, OverflowShadowInnerDirective, OverflowShadowScrollerDirective, FeatureElementDirective, AutofocusDirective } from '../../directives';

import { ContentLoaderModule } from '@ngneat/content-loader';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';

import { DragDropModule as MatDragDropModule } from '@angular/cdk/drag-drop';

import { ListViewComponent } from './list-view/list-view.component';
import { AutocompleteInputComponent } from './autocomplete-input/autocomplete-input.component';
import { DatatableComponent } from './datatable/datatable.component';
import { SortableListComponent } from './sortable-list/sortable-list.component';
import { TranslationInputComponent } from './translation-input/translation-input.component';
import { LoaderComponent } from './loader/loader.component';
import { BlankComponent } from './blank/blank.component';
import { RibbonComponent } from './ribbon/ribbon.component';
import { ThemeEmitterComponent } from './theme-emitter/theme-emitter.component';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { HelpButtonComponent } from './help-button/help-button.component';
import { FeedbackButtonComponent } from './feedback-button/feedback-button.component';
import { ExportCsvButtonComponent } from './export-csv-button/export-csv-button.component';
import { FilterInputComponent } from './filter-input/filter-input.component';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { ChartComponent } from './chart/chart.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { PercentageInputComponent } from './percentage-input/percentage-input.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';

@NgModule({
  imports: [
    //Angular Modules
    CommonModule, RouterModule,
    FormsModule, ReactiveFormsModule,

    //Material Modules
    MatToolbarModule,
    MatSidenavModule, MatIconModule,
    MatListModule, MatButtonModule, MatBadgeModule,
    MatCheckboxModule, MatCardModule,
    MatMenuModule, MatTabsModule,
    MatFormFieldModule, MatInputModule,
    MatDialogModule, MatSnackBarModule,
    MatSelectModule, MatProgressSpinnerModule, MatProgressBarModule,
    MatExpansionModule, MatTooltipModule,
    MatGridListModule, MatChipsModule,
    MatTableModule, MatDragDropModule,
    MatStepperModule, MatAutocompleteModule,
    MatDividerModule, MatSlideToggleModule,
    MatPaginatorModule, MatSortModule,
    MatRadioModule, MatButtonToggleModule,

    //Skeleton Content Loader
    ContentLoaderModule,

    //App Modules
    PipesModule,
  ],
  exports: [
    CommonModule, RouterModule,
    FormsModule, ReactiveFormsModule,

    MatToolbarModule, MatButtonModule, MatBadgeModule,
    MatSidenavModule, MatIconModule,
    MatListModule,
    MatCheckboxModule, MatCardModule,
    MatMenuModule, MatTabsModule,
    MatFormFieldModule, MatInputModule,
    MatDialogModule, MatSnackBarModule,
    MatSelectModule, MatProgressSpinnerModule,
    MatExpansionModule, MatTooltipModule,
    MatGridListModule, MatChipsModule,
    MatTableModule, MatDragDropModule,
    MatStepperModule, MatAutocompleteModule,
    MatDividerModule, MatSlideToggleModule,
    MatPaginatorModule, MatSortModule,
    MatRadioModule, MatButtonToggleModule,

    ContentLoaderModule,

    ListViewComponent, SortableListComponent,
    DatatableComponent, TranslationInputComponent,
    PipesModule, LoaderComponent, ThemeEmitterComponent,
    ActionMenuComponent, OverflowShadowDirective, OverflowShadowInnerDirective, OverflowShadowScrollerDirective,
    RibbonComponent, HelpButtonComponent, FeatureElementDirective, AutofocusDirective, AutocompleteInputComponent, ExportCsvButtonComponent,
    FilterInputComponent, FeedbackButtonComponent,
    CurrencyInputComponent, PercentageInputComponent,
    BlankComponent,
    ChartComponent, UploadImageComponent,

    SkeletonLoaderComponent,
  ],
  declarations: [
    ListViewComponent, SortableListComponent,
    DatatableComponent, TranslationInputComponent, LoaderComponent, ThemeEmitterComponent,
    ActionMenuComponent, OverflowShadowDirective, OverflowShadowInnerDirective, OverflowShadowScrollerDirective,
    RibbonComponent, HelpButtonComponent, FeatureElementDirective, AutofocusDirective, AutocompleteInputComponent, ExportCsvButtonComponent,
    FilterInputComponent, FeedbackButtonComponent,
    CurrencyInputComponent, PercentageInputComponent,
    BlankComponent,
    ChartComponent, UploadImageComponent,

    SkeletonLoaderComponent,

  ]
})
export class SharedModule { }
