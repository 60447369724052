import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, of } from 'rxjs';

import { DialogService, LoggerService } from 'src/app/services';
import { AuthDialogGuard } from '../../guards';
import { UserRole } from 'src/app/data.models';

import { DeleteCustomerDialog }  from './_dialogs/delete-customer/delete-customer.component';
import { AddEditCustomerDialog } from './_dialogs/add-edit-customer/add-edit-customer.component';
import { AddEditUserDialog }     from './_dialogs/add-edit-user/add-edit-user.component';
import { DeleteUserDialog }      from './_dialogs/delete-user/delete-user.component';
import { DOCUMENT } from '@angular/common';

export {
  DeleteCustomerDialog, AddEditCustomerDialog, AddEditUserDialog, DeleteUserDialog,
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html'
})
export class AccountComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(
    private dialogService: DialogService,
    private logger: LoggerService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.dialogService.registerDialog("create-user",     AddEditUserDialog,     this.dialog, { canActivate: AuthDialogGuard, data: { role: UserRole.All }});
    this.dialogService.registerDialog("edit-user",       AddEditUserDialog,     this.dialog);
    this.dialogService.registerDialog("delete-user",      DeleteUserDialog,     this.dialog, { classes: ['short'] });
    this.dialogService.registerDialog("create-customer", AddEditCustomerDialog, this.dialog);
    this.dialogService.registerDialog("edit-customer",   AddEditCustomerDialog, this.dialog);
    this.dialogService.registerDialog("delete-customer", DeleteCustomerDialog,  this.dialog, { classes: ['short'] });

    if (this.document) {
      this.document.documentElement.classList.add("account");
    }
  }

  ngOnDestroy() {
    if (this.document) {
      this.document.documentElement.classList.remove("account");
    }

    this.dialogService.deregisterDialog("create-user");
    this.dialogService.deregisterDialog("edit-user");
    this.dialogService.deregisterDialog("delete-user");
    this.dialogService.deregisterDialog("create-customer");
    this.dialogService.deregisterDialog("edit-customer");
    this.dialogService.deregisterDialog("delete-customer");
  }
}
