import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import { IRewardStore, RewardGiveaway, RewardGiveawayBase, RewardGiveawayEntry } from 'src/app/components/reward/reward.models';
import { FavoritesTypes } from 'src/app/data.models';
import * as RewardGiveawayActions from 'src/app/store/reward/reward-giveaway.actions';
import { ApiService } from '../api.service';
import { FavoritesService } from '../favorites.service';
import { LoggerService } from '../logger.service';
import { PanelAdminService } from '../panel-admin';

@Injectable({
  providedIn: 'root'
})
export class RewardGiveawayService {
  
  private endpoint: string = 'giveaway';

  constructor(
    private store: Store<{ reward: IRewardStore }>,
    private api: ApiService,
    private logger: LoggerService,
    private favoritesService: FavoritesService,
    private panelAdminService: PanelAdminService,
  ) { }

  /**
   * @method RewardGiveawayService.create
   * @description Saves a new RewardGiveaway to the server
   * @param {RewardGiveaway} giveaway The new RewardGiveaway to be saved
   * @returns Observable<RewardGiveaway>
   */
  public create(giveaway: RewardGiveaway): Observable<RewardGiveaway> {
    const url = this.endpoint;
    let data: RewardGiveawayBase = giveaway.asDataObj;
    delete data.opened;
    delete data.active;
    delete data.archived;
    return this.api.post<RewardGiveawayBase>(url, data).pipe(
      map((g) => {
        const new_giveaway = new RewardGiveaway(g)
        this.store.dispatch(new RewardGiveawayActions.CreateSuccess(new_giveaway));
        this.favoritesService.addFavorite(FavoritesTypes.RewardGiveaways, new_giveaway.id).pipe(take(1)).subscribe();
        return new_giveaway
      }),
      catchError((err: Error) => {
        this.store.dispatch(new RewardGiveawayActions.CreateError(err));
        return throwError(new Error(err && err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
      })
    );
  }

  /**
   * @method RewardGiveawayService.createEntry
   * @description Creates a new entry for a giveaway
   * @returns Observable<RewardGiveawayEntry>
   */
  public createEntry(entry: RewardGiveawayEntry) {
    const url = this.endpoint + "Entry";
    return this.api.post(url, entry).pipe(
      map((resp: any) => resp.data as RewardGiveawayEntry)
    );
  }

  /**
   * @method RewardGiveawayService.getGiveawayEntries
   * @description Get all the entries for a giveaway
   * @param {string} giveawayId The ID of the giveaway
   * @returns Observable<RewardGiveawayEntry>
   */
  public getGiveawayEntries(giveawayId: string) {
    const url = this.endpoint + "Entry";
    return this.api.get(url, { giveawayId }).pipe(
      map((resp: any) => resp.Items as RewardGiveawayEntry[])
    );
  }

  /**
   * @method RewardGiveawayService.selectWinners
   * @description Select the winners for a giveaway
   * @param {string} id The ID of the giveaway
   * @returns Observable<RewardGiveawayEntry>
   */
  public selectWinners(id: string) {
    const url = this.endpoint + "/selectWinner?id=" + id;
    return this.api.post<{ giveaway: RewardGiveawayBase }>(url, null).pipe(
      map((resp) => {
        const giveaway = new RewardGiveaway(resp.giveaway);
        this.store.dispatch(new RewardGiveawayActions.GetSuccess(giveaway));
        return giveaway;
      }),
      catchError((resp: { errors: Error[] }) => {
        if (resp.errors && resp.errors.length) {
          return throwError(new Error(resp.errors.reduce((msg, error) => {
            return msg += error.message + ". ";
          }, "")));
        }
        return throwError(new Error("An unknown error occurred"));
      })
    );
  }

  /**
   * @method RewardGiveawayService.getAll
   * @description Retrieves a list of all RewardGiveaways in the Sample API
   * @returns Observable<RewardGiveaway[]>
   */
  public getAll(archive?: boolean): Observable<RewardGiveaway[]> {
    const url = this.endpoint;
    return this.api.get<RewardGiveawayBase[]>(url, archive ? { Filter: 'all' } : {}).pipe(
      switchMap((resp: any) => {
        const giveaways = resp.Items.map((giveaway: RewardGiveawayBase) => new RewardGiveaway(giveaway));
        this.store.dispatch(new RewardGiveawayActions.GetAllSuccess(giveaways));
        return this.store.pipe(
          select((state) => state.reward.giveaways.data)
        );
      }),
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardGiveawayService.getByID
   * @description Retrieves a RewardGiveaway for a given ID
   * @param {string} id The ID of the RewardGiveaway to be retrieved
   * @returns Observable<RewardGiveaway>
   */
  public getByID(id: string): Observable<RewardGiveaway> {
    const url = this.endpoint;
    let sub: Subscription;
    sub = this.api.get<{ giveaway: RewardGiveawayBase; rewardToken: string; }>(url, { id }).pipe(
      catchError((resp: Error) => {
        this.store.dispatch(new RewardGiveawayActions.GetError(id, resp));
        return throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.'));
      }),
      first()
    ).subscribe(resp => {
      if (sub) {
        sub.unsubscribe();
      }
      const giveaway = new RewardGiveaway(resp.giveaway, resp.rewardToken);
      this.store.dispatch(new RewardGiveawayActions.GetSuccess(giveaway));
      return giveaway;
    });
    return this.store.pipe(
      select((state) => state.reward.giveaways.map[id])
    );
  }

  /**
   * @method RewardGiveawayService.getEmbedCode
   * @description Retrieves the Embed Code for a RewardGiveaway
   * @param {string} id The ID of the RewardGiveaway to be retrieved
   * @returns Observable<RewardGiveaway>
   */
  public getEmbedCode(id: string): Observable<string> {
    const url = this.endpoint;
    return this.api.get(url, { id }, null, 'all').pipe(
      map((resp: { body?: { embedCode?: string } }) => resp && resp.body && resp.body.embedCode),
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardGiveawayService.getRules
   * @description Retrieves the Embed Code for a RewardGiveaway
   * @param {string} id The ID of the RewardGiveaway to be retrieved
   * @returns Observable<RewardGiveaway>
   */
  public getRules(id: string): Observable<string> {
    const url = this.endpoint + '/rules';
    return this.api.get(url, { id }, null, 'all').pipe(
      map((resp: { body?: { embedCode?: string } }) => resp && resp.body && resp.body.embedCode),
      catchError((resp: Error) => throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.')))
    );
  }

  /**
   * @method RewardGiveawayService.update
   * @description Updates a RewardGiveaway on the server
   * @returns Observable<RewardGiveaway>
   */
  public update(giveaway: RewardGiveaway): Observable<RewardGiveaway> {
    const url = this.endpoint;
    return this.api.put<RewardGiveawayBase>(url, giveaway.asDataObj).pipe(
      map((p) => {
        const profile = new RewardGiveaway(p);
        this.store.dispatch(new RewardGiveawayActions.UpdateSuccess(profile));
        return profile;
      }),
      catchError((err: Error) => {
        this.store.dispatch(new RewardGiveawayActions.UpdateError(giveaway.id, err));
        return throwError(new Error(err.message ? err.message : 'There was an unknown error. Please check your connection and try again.'))
      })
    );
  }

  /**
   * @method RewardGiveawayService.archive
   * @description Archives a RewardGiveaway from the server
   * @returns Observable<RewardGiveaway>
   */
  public archive(id: string): Observable<RewardGiveaway> {
    const url = this.endpoint;
    return this.api.delete<RewardGiveawayBase>(url, { id }).pipe(
      map((p) => {
        this.store.dispatch(new RewardGiveawayActions.DeleteSuccess(id));
        return new RewardGiveaway(p);
      }),
      catchError((resp: Error) => {
        this.store.dispatch(new RewardGiveawayActions.DeleteError(id, resp));
        return throwError(new Error(resp.message ? resp.message : 'There was an unknown error. Please check your connection and try again.'));
      }),
    );
  }
}
