import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LoggerService } from 'src/app/services';

@Pipe({
  name: 'sanitize',
  pure: true
})
export class SanitizePipe implements PipeTransform {

  constructor(
    private logger: LoggerService,
    private sanitizer: DomSanitizer,
  ) {
  }

  transform(value: string, context?: string, bypass?: boolean): SafeHtml {
    if (value && typeof value === "string") {
      let securityContext: SecurityContext = SecurityContext.HTML;
      context = context || "html";
      switch (context.toLowerCase()) {
        case "html":
          if (bypass) {
            return this.sanitizer.bypassSecurityTrustHtml(value);
          }
          securityContext = SecurityContext.HTML;
          break;
        case "url":
          if (bypass) {
            return this.sanitizer.bypassSecurityTrustUrl(value);
          }
          securityContext = SecurityContext.URL;
          break;
        case "javascript":
        case "script":
          if (bypass) {
            return this.sanitizer.bypassSecurityTrustScript(value);
          }
          securityContext = SecurityContext.SCRIPT;
          break;
        case "css":
        case "stylesheet":
        case "style":
          if (bypass) {
            return this.sanitizer.bypassSecurityTrustStyle(value);
          }
          securityContext = SecurityContext.STYLE;
          break;
        case "resource":
        case "resource_url":
          if (bypass) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(value);
          }
          securityContext = SecurityContext.RESOURCE_URL;
          break;
        default:
          securityContext = SecurityContext.HTML;
          break;
      }
      return this.sanitizer.sanitize(securityContext, value);
    }
    return value || '';
  }
}
