import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Answer } from 'src/app/data.models';
import { LoggerService } from 'src/app/services';

@Component({
  selector: 'app-question-radio-list',
  templateUrl: './question-radio-list.component.html'
})
export class QuestionRadioListComponent implements OnInit {

  @Input() value: any;
  @Output() valueChange: EventEmitter<Answer> = new EventEmitter();

  public selectedOption: Answer;
  public options: Answer[];
  @Input("options") set _options(options: Answer[]) {
    this.options = options;
  }

  constructor(
    private logger:  LoggerService,
  ) { }

  onChange($event: MatRadioChange) {
    this.selectedOption = $event.value;
    this.valueChange.emit(this.selectedOption);
  }

  ngOnInit() {
  }

}
