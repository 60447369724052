<mat-form-field>
  <label [innerHTML]="label"></label>
  <ng-container *ngIf="long; else short">
    <textarea matInput [id]="id + '_long'" [(ngModel)]="value" (keyup)="valueChange.emit(value)" type="text" rows="4"></textarea>
  </ng-container>

  <ng-template #short>
    <input matInput [id]="id + '_short'" [(ngModel)]="value" (keyup)="valueChange.emit(value)" [type]="type" />
  </ng-template>
</mat-form-field>
