import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/components/shared/shared.module';

import { QuestionPreviewDialog } from './question-preview-dialog/question-preview-dialog.component';
import { QuestionRadioListComponent } from './question-radio-list/question-radio-list.component';
import { QuestionCheckListComponent } from './question-check-list/question-check-list.component';
import { QuestionPickerComponent } from './question-picker/question-picker.component';
import { QuestionTextComponent } from './question-text/question-text.component';
import { QuestionMultiComponent } from './question-multi/question-multi.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    QuestionRadioListComponent, QuestionCheckListComponent, QuestionPickerComponent,
    QuestionTextComponent, QuestionMultiComponent,
  ],
  exports: [
    QuestionRadioListComponent, QuestionCheckListComponent, QuestionPickerComponent,
    QuestionTextComponent, QuestionMultiComponent,
  ]
})
export class QuestionsModule { }

export { QuestionPreviewDialog }
