import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asFilter'
})
export class AsFilterPipe implements PipeTransform {

  transform(value: string): any {
    value.trim().toLowerCase();
  }
}
