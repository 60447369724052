import * as Reducers from '../reducers';
import * as Actions from './sample-profile.actions';
import { findIndex, sortBy } from 'lodash';
import { extendObj } from '../../data.models';
import { SampleProfile, SampleStats } from '../../sample.models';

export interface State extends Reducers.State<SampleProfile> {
  byProject?: { [projectId: string]: SampleProfile[] };
  byProjectMap?: { [projectId: string]: { [profileId: string]: SampleProfile } };
  requestedAllAt?: { [projectId: string]: number };
  stats?: { [profileId: string]: SampleStats };
  statsErrors?: { [profileId: string]: Error };
}

export const initialState: State = {
  data: null, archive: null, count: 0, map: {}, errors: {},
  byProject: {},
  byProjectMap: {},
  requestedAllAt: {},
  stats: {},
  statsErrors: {},
};

export function sampleProfileReducer(state: State = initialState, action: Actions.Any): State {
  const newState: State = { ...state };

  if (action.type === Actions.Types.ClearErrors) {
    if (action.name) {
      newState.errors = { ...newState.errors, [action.name]: null };
    }
    else {
      newState.errors = {};
    }
    return newState;
  }

  switch (action.type) {

    case Actions.Types.CreateSuccess:
      newState.errors = { ...newState.errors, create: null };
    case Actions.Types.UpdateSuccess:
      if (newState.stats[action.payload.id]) {
        newState.stats = {
          ...newState.stats,
          [action.payload.id]: null
        };
      }
      if (newState.statsErrors[action.payload.id]) {
        newState.statsErrors = {
          ...newState.statsErrors,
          [action.payload.id]: null
        };
      }
    case Actions.Types.GetSuccess:
      if (newState.errors[action.payload.id]) {
        newState.errors = { ...newState.errors, [action.payload.id]: null };
      }
      newState.map = { ...newState.map, [action.payload.id]: new SampleProfile(extendObj(true, {}, action.payload)) };
      newState.archive = Object.values(newState.map).filter(p => p.archived);
      newState.data = Object.values(newState.map).filter(p => !p.archived);
      break;


    case Actions.Types.GetStatsSuccess:
      newState.stats = {
        ...newState.stats,
        [action.id]: new SampleStats(extendObj(true, {}, action.payload))
      };
      if (newState.statsErrors[action.id]) {
        newState.statsErrors = {
          ...newState.statsErrors,
          [action.id]: null
        };
      }
      break;


    case Actions.Types.GetStatsError:
      if (newState.map[action.id]) {
        newState.statsErrors = {
          ...newState.statsErrors,
          [action.id]: action.error
        };
      }
      break;


    case Actions.Types.GetAllSuccess:
      if (action.projectID) {
        newState.map = {
          ...newState.map,
          ...Reducers.map(action.payload)
        };
        newState.requestedAllAt = {
          ...newState.requestedAllAt,
          [action.projectID]: new Date().getTime()
        };
      }
      newState.archive = Object.values(newState.map).filter(p => p.archived);
      newState.data = Object.values(newState.map).filter(p => !p.archived);
      newState.errors = { ...newState.errors, all: null };
      break;


    case Actions.Types.DeleteSuccess:
      newState.data = (state.data || []).filter((profile) => profile.id !== action.id);
      newState.map = Reducers.map(newState.data);
      break;


    case Actions.Types.GetAllError:
      newState.map = {};
      newState.data = [];
    case Actions.Types.CreateError:
    case Actions.Types.GetError:
    case Actions.Types.DeleteError:
    case Actions.Types.UpdateError:
      newState.errors = {
        ...newState.errors,
        [action.type === Actions.Types.GetAllError ? 'all'
          : action.type === Actions.Types.CreateError ? 'create'
            : action.id]: action.error
      };
      break;


    case Actions.Types.Clear:
      return initialState;

    default:
      return state;
  }

  if (action.type === Actions.Types.GetStatsSuccess || action.type === Actions.Types.GetStatsError) {
    const idxD = findIndex(newState.data, p => p.id === action.id);
    if (idxD >= 0) {
      newState.data = newState.data.map((v, i) => {
        if (i === idxD) {
          return newState.map[action.id];
        }
        return v;
      });
    }
    else {
      const idxA = findIndex(newState.archive, p => p.id === action.id);
      if (idxA >= 0) {
        newState.archive = newState.archive.map((v, i) => {
          if (i === idxA) {
            return newState.map[action.id];
          }
          return v;
        });
      }
    }
  }

  newState.byProjectMap = newState.data.reduce((prev, curr: SampleProfile, idx, arr) => {
    prev[curr.sampleProjectId] = prev[curr.sampleProjectId] || {};
    prev[curr.sampleProjectId][curr.id] = curr;
    return prev;
  }, {});
  newState.byProject = Object.entries(newState.byProjectMap).reduce((prev: { [key: string]: SampleProfile[] }, curr: [string, { [key: string]: SampleProfile }], idx, arr) => {
    prev[curr[0]] = sortBy(Object.values(curr[1]), (value) => new Date(value.createdAt).getTime());
    //console.group("PROJECT: " + curr[0]);
    //prev[curr[0]].forEach((v, i) => {
    //  console.log(i + ": " + v.name)
    //});
    //console.groupEnd();
    return prev;
  }, {});
  newState.count = newState.data ? newState.data.length : 0;
  !!newState.archive && newState.archive.length || (newState.archive = null);
  !!newState.data && newState.data.length || (newState.data = null);
  return newState;
}
